import React, { useState, useEffect, useRef, Fragment } from "react";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { ToastContainer, toast } from "react-toastify";
import Tooltip from "@material-ui/core/Tooltip";
import {
    Button,
    Radio,
    RadioGroup,
    Grid,
    FormLabel,
    TextField,
    FormControlLabel,
    IconButton,
    FormControl,
    InputLabel,
    FormHelperText,
    Select,
    MenuItem,
    ListItemText,
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Input,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { urlAction } from "../../../../action";
import merchantService from "../../../../services/merchantService";
import { store } from "../../../../index.js";
import "./style.css";
import subscriptionService from "../../../../services/subscriptionService";
import planService from "../../../../services/planService";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";
import jQuery from "jquery";
import AddonEditCreate from "../../Addon/AddonEditCreate";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import PlanForm from "../../Plan/PlanForm";
import promotionService from "../../../../services/promotionService";

const MySwal = withReactContent(Swal);

const DialogTitle = (props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className="m-0"
            style={{ padding: "16px 24px" }}
            {...other}
        >
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    style={{ position: "absolute", top: "8px", right: "8px" }}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

const ErrorTooltip = withStyles({
    tooltip: {
        color: "white",
        backgroundColor: "red",
    },
})(Tooltip);

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        minWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip);

const SubscriptionCreate = ({ history, match }) => {
    const [custId, setCustId] = useState({ value: "" });
    const [selectedPlan, setSelectedPlan] = useState({
        value: { plan_id: "" },
    });
    const [merchantInfo, setMerchantInfo] = useState();

    const [planQuantity, setPlanQuantity] = useState({ value: "1" });
    const [selectedAddons, setSelectedAddons] = useState({ value: [] });
    const [trialStart, setTrialStart] = useState({ value: "" });
    const [trialEnd, setTrialEnd] = useState({ value: "" });
    const [dateEnd, setDateEnd] = useState({ value: "" });
    const [priceTableRow, setPriceTableRow] = useState({});
    const [infoModal, setInfoModal] = useState({ isOpened: false });
    const [custFirstName, setCustFirstName] = useState({ value: "" });
    const [custLastName, setCustLastName] = useState({ value: "" });
    const [plansFromServer, setPlansFromServer] = useState([]);
    const [customersFromServer, setCustomersFromServer] = useState([]);
    const [promoInput, setPromoInput] = useState({ value: "" });
    const [promoApplied, setPromoApplied] = useState({ value: null });

    //First time initialization
    useEffect(() => {
        fetchPlans(); //fetch existing plan from server
        fetchCustomers();
    }, []);
    useEffect(() => {
        const splitURL = urlAction("get_merchant");
        merchantService.getMerchantInfo().then((e) => {
            let merchantInfo = e;

            let merchantId = merchantInfo
                .filter((obj) => obj.merch_name == splitURL)
                .map((obj) => {
                    return obj.id;
                });
            setMerchantInfo(merchantId);
        });
    }, []);
    //Listener When selectedPlan changes
    useEffect(() => {
        autoSelectMandatoryAddonForSelectedPlan(); //Will call when selectedplan changes
        //update trial info if the plan has trial period
        if (selectedPlan.value.trial_period > 0) {
            var trialStartDateTime = moment();
            setTrialStart({
                value: trialStartDateTime.format("yyyy-MM-DDTHH:mm"),
            });

            //update Trial end date
            var trialEndDateTime = null;
            switch (selectedPlan.value.trial_period_unit) {
                case "month":
                    trialEndDateTime = trialStartDateTime.add(
                        selectedPlan.value.trial_period,
                        "M"
                    );
                    break;
                case "day":
                    trialEndDateTime = trialStartDateTime.add(
                        selectedPlan.value.trial_period,
                        "days"
                    );
                    break;
            }
            setTrialEnd({ value: trialEndDateTime.format("yyyy-MM-DDTHH:mm") });
        } else {
            setTrialStart({ value: "" });
            setTrialEnd({ value: "" });
        }

        //Update end date of the subscription
        if (
            !!selectedPlan.value.billing_cycle &&
            selectedPlan.value.billing_cycle > 0
        ) {
            var subscriptionEndDateTime = moment();

            switch (selectedPlan.value.delivery_frequency) {
                case "daily":
                    subscriptionEndDateTime = subscriptionEndDateTime.add(
                        selectedPlan.value.billing_cycle,
                        "d"
                    );
                    break;
                case "weekly":
                    subscriptionEndDateTime = subscriptionEndDateTime.add(
                        selectedPlan.value.billing_cycle,
                        "w"
                    );
                    break;
                case "biweekly":
                    subscriptionEndDateTime = subscriptionEndDateTime.add(
                        selectedPlan.value.billing_cycle * 2,
                        "w"
                    );
                    break;
                case "monthly":
                    subscriptionEndDateTime = subscriptionEndDateTime.add(
                        selectedPlan.value.billing_cycle,
                        "M"
                    );
                    break;
                case "quarterly":
                    subscriptionEndDateTime = subscriptionEndDateTime.add(
                        selectedPlan.value.billing_cycle * 3,
                        "M"
                    );
                    break;
                case "half-yearly":
                    subscriptionEndDateTime = subscriptionEndDateTime.add(
                        selectedPlan.value.billing_cycle * 6,
                        "M"
                    );
                    break;
                case "annually":
                    subscriptionEndDateTime = subscriptionEndDateTime.add(
                        selectedPlan.value.billing_cycle,
                        "y"
                    );
                    break;
                case "biannually":
                    subscriptionEndDateTime = subscriptionEndDateTime.add(
                        selectedPlan.value.billing_cycle * 2,
                        "y"
                    );
                    break;
            }

            setDateEnd({
                value: subscriptionEndDateTime.format("yyyy-MM-DDTHH:mm"),
            });
        } else {
            setDateEnd({ value: null });
        }
    }, [selectedPlan]);

    //Listener When SelectedPlan and selectedAddons changes
    useEffect(() => {
        var pricingForSelectedPlan = { one_off: [], recurring: [] };
        var pricingForSelectedAddons = { one_off: [], recurring: [] };
        var tax = null;

        //Update the pricing info
        if (!!selectedPlan) {
            var itemInfo = {
                itemName: selectedPlan.value.plan_name,
                pricingType: selectedPlan.value.pricing_model,
                itemType: selectedPlan.value.plan_type,
            };
            switch (selectedPlan.value.plan_type) {
                case "usage_based":
                    pricingForSelectedPlan = {
                        ...pricingForSelectedPlan,
                        recurring: [
                            ...pricingForSelectedPlan.recurring,
                            {
                                ...itemInfo,
                                quantity: "To Be Defined",
                                price: "-",
                            },
                        ],
                    };
                    break;
                case "fixed_quantity":
                    switch (selectedPlan.value.pricing_model) {
                        case "flat_fee":
                            itemInfo = {
                                ...itemInfo,
                                quantity: 1,
                                price: selectedPlan.value.flat_fee_amt.base,
                            };
                            break;
                        case "per_unit":
                            itemInfo = {
                                ...itemInfo,
                                quantity: planQuantity.value,
                                price:
                                    selectedPlan.value.per_unit_amt.base *
                                    planQuantity.value,
                            };
                            break;
                        case "tiered":
                            var remainingQuantity = parseFloat(
                                planQuantity.value
                            );
                            var tieredModel = selectedPlan.value.tiered_model;
                            var totalPrice = 0;

                            tieredModel.tier.forEach((tier, i) => {
                                //Price for each Step
                                var priceForThisTier = 0;

                                if (tier.ending_unit != null) {
                                    var endingUnit = parseFloat(
                                        tier.ending_unit
                                    );
                                    var unitInThisTier =
                                        i == 0
                                            ? endingUnit
                                            : endingUnit -
                                              parseFloat(
                                                  tieredModel.tier[i - 1]
                                                      .ending_unit
                                              );
                                    if (remainingQuantity > unitInThisTier) {
                                        priceForThisTier =
                                            unitInThisTier *
                                            parseFloat(tier.price_per_unit);
                                        remainingQuantity -= unitInThisTier;
                                    } else {
                                        unitInThisTier = remainingQuantity;

                                        priceForThisTier =
                                            unitInThisTier *
                                            parseFloat(tier.price_per_unit);
                                        remainingQuantity -= unitInThisTier;
                                    }
                                } else {
                                    var unitInThisTier = remainingQuantity;

                                    priceForThisTier =
                                        unitInThisTier *
                                        parseFloat(tier.price_per_unit);
                                    remainingQuantity -= unitInThisTier;
                                }

                                totalPrice += priceForThisTier;
                                if (remainingQuantity == 0) {
                                    return;
                                }
                            });

                            itemInfo = {
                                ...itemInfo,
                                quantity: planQuantity.value,
                                price: totalPrice,
                            };
                            break;
                        case "volume":
                            var tieredModel = selectedPlan.value.tiered_model;
                            var quantity = parseFloat(planQuantity.value);
                            var totalPrice = 0;

                            tieredModel.tier.forEach((tier, i) => {
                                var startingUnit = parseFloat(
                                    tier.starting_unit
                                );

                                if (quantity >= startingUnit) {
                                    //if quantity is in this tier
                                    totalPrice =
                                        quantity *
                                        parseFloat(tier.price_per_unit); //replace the price
                                }
                            });

                            itemInfo = {
                                ...itemInfo,
                                quantity: planQuantity.value,
                                price: totalPrice,
                            };

                            break;
                        case "stairstep":
                            var tieredModel = selectedPlan.value.tiered_model;
                            var quantity = parseFloat(planQuantity.value);
                            var totalPrice = 0;

                            tieredModel.tier.forEach((tier, i) => {
                                //Price for each Step
                                var startingUnit = parseFloat(
                                    tier.starting_unit
                                );

                                if (quantity >= startingUnit) {
                                    totalPrice = parseFloat(
                                        tier.price_per_unit
                                    );
                                }
                            });

                            itemInfo = {
                                ...itemInfo,
                                quantity: quantity.value,
                                price: totalPrice,
                            };

                            break;
                    }

                    pricingForSelectedPlan = {
                        ...pricingForSelectedPlan,
                        recurring: [
                            ...pricingForSelectedPlan.recurring,
                            {
                                ...itemInfo,
                            },
                        ],
                    };
                    break;
                default:
            }

            if (selectedPlan.value.setup_fee > 0) {
                pricingForSelectedPlan = {
                    ...pricingForSelectedPlan,
                    one_off: [
                        ...pricingForSelectedPlan.one_off,
                        {
                            itemName: "Setup Fee",
                            itemType: "fixed_quantity",
                            pricingType: "flat_fee",
                            quantity: 1,
                            price: selectedPlan.value.setup_fee,
                        },
                    ],
                };
            }

            if (!!selectedPlan.value.tax) {
                tax = selectedPlan.value.tax;
            }
        }

        if (selectedAddons.value.length > 0) {
            //setSubscriptionPricingInfo
            selectedAddons.value.forEach((selectedAddon) => {
                const addon = selectedPlan.value.addons.find(
                    (addon) => addon.addon_id == selectedAddon.selectedAddonId
                );
                var itemInfo = {
                    itemName: addon.addon_name,
                    pricingType: addon.pricing_model,
                    itemType: addon.addon_type,
                };
                switch (addon.addon_type) {
                    case "usage_based":
                        itemInfo = {
                            ...itemInfo,
                            quantity: "To Be Defined",
                            price: "-",
                        };
                        break;
                    case "fixed_quantity":
                        switch (addon.pricing_model) {
                            case "flat_fee":
                                itemInfo = {
                                    ...itemInfo,
                                    quantity: 1,
                                    price: addon.addon_price,
                                };
                                break;
                            case "per_unit":
                                itemInfo = {
                                    ...itemInfo,
                                    quantity: selectedAddon.quantity,
                                    price:
                                        addon.addon_price *
                                        selectedAddon.quantity,
                                };
                                break;
                            case "tiered":
                                var remainingQuantity = parseFloat(
                                    selectedAddon.quantity
                                );
                                var tieredModel = addon.tiered_model;
                                var totalPrice = 0;

                                tieredModel.tier.forEach((tier, i) => {
                                    //Price for each Step
                                    var priceForThisTier = 0;

                                    if (tier.ending_unit != null) {
                                        var endingUnit = parseFloat(
                                            tier.ending_unit
                                        );
                                        var unitInThisTier =
                                            i == 0
                                                ? endingUnit
                                                : endingUnit -
                                                  parseFloat(
                                                      tieredModel.tier[i - 1]
                                                          .ending_unit
                                                  );
                                        if (
                                            remainingQuantity > unitInThisTier
                                        ) {
                                            priceForThisTier =
                                                unitInThisTier *
                                                parseFloat(tier.price_per_unit);
                                            remainingQuantity -= unitInThisTier;
                                        } else {
                                            unitInThisTier = remainingQuantity;

                                            priceForThisTier =
                                                unitInThisTier *
                                                parseFloat(tier.price_per_unit);
                                            remainingQuantity -= unitInThisTier;
                                        }
                                    } else {
                                        var unitInThisTier = remainingQuantity;

                                        priceForThisTier =
                                            unitInThisTier *
                                            parseFloat(tier.price_per_unit);
                                        remainingQuantity -= unitInThisTier;
                                    }

                                    totalPrice += priceForThisTier;
                                    if (remainingQuantity == 0) {
                                        return;
                                    }
                                });

                                itemInfo = {
                                    ...itemInfo,
                                    quantity: selectedAddon.quantity,
                                    price: totalPrice,
                                };
                                break;
                            case "volume":
                                var tieredModel = addon.tiered_model;
                                var addonQuantity = parseFloat(
                                    selectedAddon.quantity
                                );
                                var totalPrice = 0;

                                tieredModel.tier.forEach((tier, i) => {
                                    var startingUnit = parseFloat(
                                        tier.starting_unit
                                    );

                                    if (addonQuantity >= startingUnit) {
                                        //if quantity is in this tier
                                        totalPrice =
                                            addonQuantity *
                                            parseFloat(tier.price_per_unit); //replace the price
                                    }
                                });

                                itemInfo = {
                                    ...itemInfo,
                                    quantity: selectedAddon.quantity,
                                    price: totalPrice,
                                };

                                break;
                            case "stairstep":
                                var tieredModel = addon.tiered_model;
                                var addonQuantity = parseFloat(
                                    selectedAddon.quantity
                                );
                                var totalPrice = 0;

                                tieredModel.tier.forEach((tier, i) => {
                                    //Price for each Step
                                    var startingUnit = parseFloat(
                                        tier.starting_unit
                                    );

                                    if (addonQuantity >= startingUnit) {
                                        totalPrice = parseFloat(
                                            tier.price_per_unit
                                        );
                                    }
                                });

                                itemInfo = {
                                    ...itemInfo,
                                    quantity: selectedAddon.quantity,
                                    price: totalPrice,
                                };

                                break;
                        }
                        break;
                    default:
                }

                if (addon.addon_recurrence == "recurring") {
                    pricingForSelectedAddons = {
                        ...pricingForSelectedAddons,
                        recurring: [
                            ...pricingForSelectedAddons.recurring,
                            itemInfo,
                        ],
                    };
                } else {
                    pricingForSelectedAddons = {
                        ...pricingForSelectedAddons,
                        one_off: [
                            ...pricingForSelectedAddons.one_off,
                            itemInfo,
                        ],
                    };
                }

                return itemInfo;
            });
        }

        const finalPricingInfo = {
            plan: pricingForSelectedPlan,
            addons: pricingForSelectedAddons,
            tax: tax,
        };

        setPriceTableRow(finalPricingInfo);
    }, [selectedPlan, selectedAddons, planQuantity]);

    const redirectToSubscriptionListingPage = () => {
        history.push("/admin/subscription");
    };

    const createNewSubscriptionInServer = () => {
        if (validateForm()) {
            //validation success
            var dataBody = {
                customer_id: custId.value,
                add_ons: selectedAddons.value.map((addon) => {
                    return {
                        addon_id: addon.selectedAddonId.toString(),
                        quantity: parseFloat(addon.quantity),
                    };
                }),
                merchant_id: merchantInfo[0],
                plan_id: selectedPlan.value.plan_id,
            };

            if (selectedPlan.value.plan_type == "fixed_quantity") {
                dataBody = {
                    ...dataBody,
                    plan_quantity: parseFloat(planQuantity.value),
                };
            }

            if (!!promoApplied.value) {
                dataBody = {
                    ...dataBody,
                    promo_id: promoApplied.value.promo_id,
                };
            }
            subscriptionService
                .createSubscription(dataBody)
                .then((res) => {
                    Swal.fire({
                        title: "Subscription Successfully created",
                        text: "You will now redirect to the subscription listing page",
                        icon: "success",
                        confirmButtonColor: store.getState().colorState.color,
                    }).then((e) => {
                        redirectToSubscriptionListingPage();
                    });
                })
                .catch((res) => {
                    if (res.message == "subscription already exists") {
                        Swal.fire({
                            title: "Error Occurs!",
                            text: "This customer already subscribed to this plan",
                            icon: "error",
                            confirmButtonText: "ok",
                            confirmButtonColor:
                                store.getState().colorState.color,
                        });
                    } else {
                        toast.error(
                            <div>
                                Some Error Occur, Please try again later.
                                <br />
                                {JSON.stringify(res.message, null, 2)}
                                <br />
                            </div>,
                            {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            }
                        );
                    }
                });
        }
    };

    const fetchPlans = () => {
        const splitURL = urlAction("get_merchant");
        merchantService.getMerchantInfo().then((e) => {
            let merchantInfo = e;
            let merchantId = merchantInfo
                .filter((obj) => obj.merch_name == splitURL)
                .map((obj) => {
                    return obj.id;
                });
            planService
                .getAllPlansWithoutPagination(merchantId, ["active"])
                .then((res) => {
                    setPlansFromServer(res.plans);
                })
                .catch((res) => {});
        });
    };

    const fetchCustomers = () => {
        merchantService
            .getAllCustomerWithoutPagination()
            .then((res) => {
                setCustomersFromServer(res);
            })
            .catch((res) => {});
    };

    const autoSelectMandatoryAddonForSelectedPlan = () => {
        if (selectedPlan.value.addons?.length > 0) {
            const mandatoryAddonId = [];
            selectedPlan.value.addons.forEach((addon) => {
                if (addon.addon_applicability == "mandatory") {
                    mandatoryAddonId.push({
                        selectedAddonId: addon.addon_id,
                        quantity: "1",
                        error: {},
                    });
                }
            });

            setSelectedAddons({ value: mandatoryAddonId });
        } else {
            setSelectedAddons({ value: [] });
        }
    };

    const showAddonPopupModal = (addonId) => {
        setInfoModal({
            isOpened: true,
            infoType: "addon-view",
            infoId: addonId,
        });
    };

    const showPlanPopupModal = (planId) => {
        setInfoModal({ isOpened: true, infoType: "plan-view", infoId: planId });
    };

    const applyPromoCode = () => {
        if (validatePromoInput(promoInput.value)) {
            promotionService
                .validatePromo({
                    merchant_id: merchantInfo[0],
                    promo_code: promoInput.value,
                    plan_id: selectedPlan.value.plan_id,
                })
                .then((res) => {
                    /* EXAMPLE RESPONSE
                     *
                     *  {
                     *      "promo_id": 1,
                     *      "promo_code": "sad23423",
                     *      "promo_cap": 12,
                     *      "discount_amount": 123.0,
                     *      "promo_by_percentage": false
                     *  }
                     *
                     */

                    setPromoApplied({ value: res });
                    Swal.fire({
                        title: "Promotion code has been applied sucessfully",
                        text: "Promotion will be active when you make payment",
                        confirmButtonColor: store.getState().colorState.color,
                        icon: "success",
                    });
                })
                .catch((res) => {
                    toast.error(<div>{res.message}</div>, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }
    };
    const renderSelectedPlanInformation = () => {
        return (
            <Grid className="mt-4" item xs={12}>
                <FormControl component="fieldset" fullWidth>
                    <FormLabel className="text-left" component="legend">
                        Plans
                    </FormLabel>
                    <FormControlLabel
                        disabled
                        control={
                            <Checkbox
                                color="primary"
                                checked={true}
                                name="plan"
                            />
                        }
                        label={
                            <div
                                className="justify-content-between"
                                style={{ display: "flex", minWidth: "550px" }}
                            >
                                <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{ minWidth: "468px" }}
                                >
                                    <div
                                        className="text-left"
                                        style={{ width: "350px" }}
                                    >{`${selectedPlan.value.plan_name} (id: ${selectedPlan.value.plan_id})`}</div>
                                    {selectedPlan.value.plan_type ==
                                    "fixed_quantity" ? (
                                        <ErrorTooltip
                                            open={!!planQuantity.error}
                                            title={planQuantity.error}
                                        >
                                            <TextField
                                                disabled={
                                                    selectedPlan.value
                                                        .pricing_model ==
                                                        "usage_based" ||
                                                    selectedPlan.value
                                                        .pricing_model ==
                                                        "flat_fee"
                                                }
                                                className="plan-quantity-input"
                                                label="Quantitys"
                                                type="text"
                                                value={planQuantity.value}
                                                onChange={(e) => {
                                                    const newValue =
                                                        e.target.value;

                                                    if (
                                                        validatePlanQuantity(
                                                            newValue
                                                        )
                                                    ) {
                                                        setPlanQuantity({
                                                            value: newValue,
                                                        });
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    className:
                                                        "addon-quantity-input",
                                                }}
                                                variant="outlined"
                                            />
                                        </ErrorTooltip>
                                    ) : (
                                        <div
                                            style={{
                                                width: "88px",
                                                height: "100%",
                                            }}
                                        ></div>
                                    )}
                                </div>

                                <Button
                                    onClick={showPlanPopupModal.bind(
                                        this,
                                        selectedPlan.value.plan_id
                                    )}
                                    style={{ fontSize: "10px" }}
                                >
                                    View Plan Detail &nbsp;
                                </Button>
                            </div>
                        }
                    />
                </FormControl>
            </Grid>
        );
    };

    const renderSelectedAddonInformation = () => {
        return (
            <Grid item xs={12}>
                <FormControl component="fieldset" fullWidth>
                    <FormLabel className="text-left" component="legend">
                        Add on
                    </FormLabel>
                    {selectedAddons.value.length > 0 ? (
                        selectedAddons.value.map((selectedAddon, i) => {
                            const addonInfo = selectedPlan.value.addons.find(
                                (addon) =>
                                    addon.addon_id ==
                                    selectedAddon.selectedAddonId
                            );
                            return (
                                <FormControlLabel
                                    key={i}
                                    disabled
                                    control={
                                        <Checkbox
                                            color="primary"
                                            checked={true}
                                            name="plan"
                                        />
                                    }
                                    label={
                                        <div
                                            className="justify-content-between"
                                            style={{
                                                display: "flex",
                                                minWidth: "550px",
                                            }}
                                        >
                                            <div
                                                className="d-flex align-items-center justify-content-between"
                                                style={{ minWidth: "468px" }}
                                            >
                                                <div
                                                    className="text-left"
                                                    style={{ width: "350px" }}
                                                >{`${
                                                    addonInfo.addon_name
                                                } (id: ${
                                                    addonInfo.addon_id
                                                }) (${addonInfo.addon_type.toUpperCase()})`}</div>
                                                {addonInfo.addon_type ==
                                                "fixed_quantity" ? (
                                                    <ErrorTooltip
                                                        open={
                                                            !!selectedAddon
                                                                .error?.quantity
                                                        }
                                                        title={
                                                            selectedAddon.error
                                                                ?.quantity
                                                        }
                                                    >
                                                        <TextField
                                                            disabled={
                                                                addonInfo.addon_type ==
                                                                    "usage_based" ||
                                                                addonInfo.pricing_model ==
                                                                    "flat_fee"
                                                            }
                                                            className="addon-quantity-wrapper"
                                                            label="Quantity"
                                                            type="text"
                                                            value={
                                                                selectedAddon.quantity
                                                            }
                                                            onChange={(e) => {
                                                                const newValue =
                                                                    e.target
                                                                        .value;

                                                                const newSelectedAddons =
                                                                    selectedAddons;

                                                                if (
                                                                    validateAddonQuantity(
                                                                        newValue,
                                                                        i
                                                                    ) ||
                                                                    newValue ==
                                                                        ""
                                                                )
                                                                    newSelectedAddons.value[
                                                                        i
                                                                    ][
                                                                        "quantity"
                                                                    ] = newValue;

                                                                setSelectedAddons(
                                                                    {
                                                                        ...newSelectedAddons,
                                                                    }
                                                                );
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            inputProps={{
                                                                className:
                                                                    "addon-quantity-input",
                                                            }}
                                                            variant="outlined"
                                                        />
                                                    </ErrorTooltip>
                                                ) : (
                                                    <div
                                                        style={{
                                                            width: "88px",
                                                            height: "100%",
                                                        }}
                                                    ></div>
                                                )}
                                            </div>

                                            <Button
                                                style={{ fontSize: "10px" }}
                                                onClick={showAddonPopupModal.bind(
                                                    this,
                                                    addonInfo.addon_id
                                                )}
                                            >
                                                View Addon Detail
                                            </Button>
                                        </div>
                                    }
                                />
                            );
                        })
                    ) : (
                        <FormLabel
                            className="text-left mt-2"
                            style={{ fontSize: "12px" }}
                        >
                            No Addon Selected
                        </FormLabel>
                    )}
                </FormControl>
            </Grid>
        );
    };

    const renderEstimatedPriceForSubscription = () => {
        return (
            <Grid item xs={12} className="mt-5">
                <TableContainer component={Paper}>
                    <Table aria-label="spanning table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" colSpan={5}>
                                    Estimated Subscription Pricing
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Item Name</TableCell>
                                <TableCell align="right">
                                    Pricing Type
                                </TableCell>
                                <TableCell align="right">Item Type</TableCell>
                                <TableCell align="right">Quantity</TableCell>
                                <TableCell align="right">Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {priceTableRow.plan?.recurring.length > 0 ? (
                                <TableRow>
                                    <TableCell style={{ verticalAlign: "top" }}>
                                        <span className="font-weight-bold">
                                            Plans Recurring Item
                                        </span>
                                        <br />
                                        {priceTableRow.plan.recurring.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.itemName}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.plan.recurring.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.pricingType.toUpperCase()}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.plan.recurring.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.itemType.toUpperCase()}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.plan.recurring.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.quantity}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.plan.recurring.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {"RM " + item.price}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <TableRow></TableRow>
                            )}
                            {priceTableRow.plan?.one_off.length > 0 ? (
                                <TableRow>
                                    <TableCell style={{ verticalAlign: "top" }}>
                                        <span className="font-weight-bold">
                                            Plans Non-Recurring Item
                                        </span>
                                        <br />
                                        {priceTableRow.plan.one_off.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.itemName}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.plan.one_off.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.pricingType.toUpperCase()}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.plan.one_off.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.itemType.toUpperCase()}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.plan.one_off.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.quantity}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.plan.one_off.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {"RM " + item.price}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <TableRow></TableRow>
                            )}
                            {priceTableRow.addons?.recurring.length > 0 ? (
                                <TableRow>
                                    <TableCell style={{ verticalAlign: "top" }}>
                                        <span className="font-weight-bold">
                                            AddOn Recurring Item
                                        </span>
                                        <br />
                                        {priceTableRow.addons.recurring.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.itemName}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.addons.recurring.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.pricingType.toUpperCase()}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.addons.recurring.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.itemType.toUpperCase()}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.addons.recurring.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.quantity}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.addons.recurring.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {"RM " + item.price}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <TableRow></TableRow>
                            )}
                            {priceTableRow.addons?.one_off.length > 0 ? (
                                <TableRow>
                                    <TableCell style={{ verticalAlign: "top" }}>
                                        <span className="font-weight-bold">
                                            AddOn Non-Recurring Item
                                        </span>
                                        <br />
                                        {priceTableRow.addons.one_off.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.itemName}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.addons.one_off.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.pricingType.toUpperCase()}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.addons.one_off.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.itemType.toUpperCase()}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.addons.one_off.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {item.quantity}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        style={{ verticalAlign: "top" }}
                                    >
                                        <br />
                                        {priceTableRow.addons.one_off.map(
                                            (item, i) => (
                                                <span key={i}>
                                                    {"RM " + item.price}
                                                    <br />
                                                </span>
                                            )
                                        )}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <TableRow></TableRow>
                            )}

                            <TableRow>
                                <TableCell rowSpan={4} colSpan={2}></TableCell>
                                {!!priceTableRow.tax ? (
                                    <Fragment>
                                        <TableCell>Tax</TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right">{`${priceTableRow.tax.tax_name.toUpperCase()} (${
                                            priceTableRow.tax.tax_rate * 100
                                        }%)`}</TableCell>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <TableCell>Tax</TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right">
                                            No Taxes
                                        </TableCell>
                                    </Fragment>
                                )}
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Total Recurring
                                </TableCell>
                                <TableCell colSpan={2} align="right">
                                    {renderTotalRecurringValue()}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="font-weight-bold">
                                    Total Non Recurring
                                </TableCell>
                                <TableCell colSpan={2} align="right">
                                    {renderTotalNonRecurringValue()}
                                </TableCell>
                            </TableRow>
                            {!!promoApplied.value ? (
                                <TableRow className="bg-success">
                                    <TableCell className="font-weight-bold text-white">
                                        Promotion applied
                                    </TableCell>
                                    <TableCell
                                        colSpan={2}
                                        align="right"
                                        className="bg-success"
                                    >
                                        {renderAppliedPromoInfo()}
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <TableRow></TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        );
    };

    const renderTotalRecurringValue = () => {
        var totalRecurringValue = 0;
        var containUsageBasedItems = false;

        priceTableRow.plan?.recurring.forEach((item) => {
            if (item.itemType == "fixed_quantity") {
                totalRecurringValue += item.price;
            } else {
                containUsageBasedItems = true;
            }
        });

        priceTableRow.addons?.recurring.forEach((item) => {
            if (item.itemType == "fixed_quantity") {
                totalRecurringValue += item.price;
            } else {
                containUsageBasedItems = true;
            }
        });

        return (
            <span className="font-weight-bold">
                RM {totalRecurringValue}
                <br />
                {containUsageBasedItems ? (
                    <span>
                        + Usage-Based Item
                        <br />
                    </span>
                ) : (
                    ""
                )}
                {!!priceTableRow.tax ? (
                    <span>
                        +{" "}
                        {`${priceTableRow.tax.tax_name.toUpperCase()} (${
                            priceTableRow.tax.tax_rate * 100
                        }%)`}
                    </span>
                ) : (
                    ""
                )}
            </span>
        );
    };

    const renderTotalNonRecurringValue = () => {
        var totalNonRecurringValue = 0;
        var containUsageBasedItems = false;

        priceTableRow.plan?.one_off.forEach((item) => {
            if (item.itemType == "fixed_quantity") {
                totalNonRecurringValue += item.price;
            } else {
                containUsageBasedItems = true;
            }
        });

        priceTableRow.addons?.one_off.forEach((item) => {
            if (item.itemType == "fixed_quantity") {
                totalNonRecurringValue += item.price;
            } else {
                containUsageBasedItems = true;
            }
        });

        return (
            <span className="font-weight-bold">
                RM {totalNonRecurringValue}
                <br />
                {containUsageBasedItems ? (
                    <span>
                        + Usage-Based Item
                        <br />
                    </span>
                ) : (
                    ""
                )}
                {!!priceTableRow.tax ? (
                    <span>
                        +{" "}
                        {`${priceTableRow.tax.tax_name.toUpperCase()} (${
                            priceTableRow.tax.tax_rate * 100
                        }%)`}
                    </span>
                ) : (
                    ""
                )}
            </span>
        );
    };
    const renderPromoDurationInfo = () => {
        switch (promoApplied.value.discount_duration_type) {
            case "forever":
                return "Promo valid throughout the subscriptions";
                break;
            case "one_time":
                return "Promo valid for the first subscription cycle only";
                break;
            case "limited":
                return `Promo valid for ${promoApplied.value.limited_period_cycles} subscription cycle once the subscription begin`;
                break;
        }
    };

    const renderAppliedPromoInfo = () => {
        return (
            <span className="text-white">
                <span>Promo code({promoApplied.value.promo_code})</span>
                <br />
                {!!promoApplied.value.discount_type == "percentage" ? (
                    <span>
                        {promoApplied.value.discount_amount}% off{" "}
                        {!!promoApplied.value.promo_cap ? (
                            <span>
                                (Capped At RM{promoApplied.value.promo_cap})
                            </span>
                        ) : (
                            ""
                        )}
                    </span>
                ) : (
                    <span>RM{promoApplied.value.discount_amount} off</span>
                )}
                <br />
                <br />
                <span style={{ fontSize: "12px" }}>
                    ** {renderPromoDurationInfo()}
                </span>
            </span>
        );
    };

    const renderInfoModalTitle = () => {
        switch (infoModal.infoType) {
            case "addon-view":
                return "View Addon Details";
            case "plan-view":
                return "View Plan Details";
            default:
                return "Modal Dialog";
        }
    };

    const renderInfoModalContent = () => {
        switch (infoModal.infoType) {
            case "addon-view":
                return (
                    <AddonEditCreate
                        cleanBackground={true}
                        readonly={true}
                        match={{ params: { id: infoModal.infoId } }}
                    ></AddonEditCreate>
                );
            case "plan-view":
                return (
                    <PlanForm
                        cleanBackground={true}
                        readonly={true}
                        match={{ params: { id: infoModal.infoId } }}
                    ></PlanForm>
                );
            default:
                return <div></div>;
        }
    };

    const validateForm = () => {
        var isValid = true; // variable used to track if the form is valid

        isValid = validateCustomerId(custId.value) && isValid;
        isValid = validateSelectedPlan() && isValid;

        return isValid;
    };

    const validateCustomerId = (newValue) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        //Check empty
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "CustomerId cannot be empty";
            isValid = false;
        }

        //Check character length limit
        if (newValue.length > 50) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Only 50 character is allowed";
            isValid = false;
        }

        setCustId({ ...custId, error: errorMessage }); //Set Error message

        return isValid;
    };

    const validateSelectedPlan = () => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        //Check empty
        if (!selectedPlan.value.plan_id) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Selected Plan cannot be empty";
            isValid = false;
        }

        if (!!errorMessage)
            setSelectedPlan({ ...selectedPlan, error: errorMessage }); //Set Error message

        return isValid;
    };

    const validatePlanQuantity = (newValue) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        //Check empty
        if (newValue == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Plan quantity cannot be empty";
            isValid = false;
        }

        //Check if price per unit only contain number
        var positiveNumberRegex = "^[0-9]+$";
        if (!newValue.match(positiveNumberRegex)) {
            //newValue contain alphabet or negative value
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Plan quantity can only be positive number";
            isValid = false;
        } else {
            if (!(parseFloat(newValue) > 0)) {
                //newValue is less than equal zero
                if (!!errorMessage) errorMessage += "\n";
                errorMessage += "Plan addon quantity cannot be zero";
                isValid = false;
            }
        }

        setPlanQuantity({ ...planQuantity, error: errorMessage }); //Set Error message

        return isValid;
    };

    const validateAddonQuantity = (newValue = "", index = -1) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        const newSelectedAddons = {
            ...selectedAddons,
        };

        if (index == -1) {
            //if index is not passed in, means validate all tiers's ending unit
            selectedAddons.value.forEach((selectedAddon, i) => {
                errorMessage = "";
                //Check empty
                if (selectedAddon.quantity == "") {
                    if (!!errorMessage) errorMessage += "\n";
                    errorMessage += "Selected Addon Quantity cannot be empty";
                    isValid = false;
                }

                //Check if price per unit only contain number
                var positiveNumberRegex = "^[0-9]+$";
                if (!selectedAddon.quantity.match(positiveNumberRegex)) {
                    //newValue contain alphabet or negative value
                    if (!!errorMessage) errorMessage += "\n";
                    errorMessage +=
                        "Selected addon quantity can only be positive number";
                    isValid = false;
                } else {
                    if (!(parseFloat(selectedAddon.quantity) > 0)) {
                        //newValue is less than equal zero
                        if (!!errorMessage) errorMessage += "\n";
                        errorMessage +=
                            "Selected addon quantity cannot be zero";
                        isValid = false;
                    }
                }

                newSelectedAddons.value[i]["error"]["quantity"] = errorMessage;
            });
        } else {
            //validate only the specific addon quantity

            //Check empty
            if (newValue == "") {
                if (!!errorMessage) errorMessage += "\n";
                errorMessage += "Selected Addon Quantity cannot be empty";
                isValid = false;
            }

            //Check if price per unit only contain number
            var positiveNumberRegex = "^[0-9]+$";
            if (!newValue.match(positiveNumberRegex)) {
                //newValue contain alphabet or negative value
                if (!!errorMessage) errorMessage += "\n";
                errorMessage +=
                    "Selected addon quantity can only be positive number";
                isValid = false;
            } else {
                if (!(parseFloat(newValue) > 0)) {
                    //newValue is less than equal zero
                    if (!!errorMessage) errorMessage += "\n";
                    errorMessage += "Selected addon quantity cannot be zero";
                    isValid = false;
                }
            }

            newSelectedAddons.value[index]["error"]["quantity"] = errorMessage;
        }

        setSelectedAddons(newSelectedAddons); //Set Error message

        return isValid;
    };

    const validatePromoInput = (newValue) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        //Check empty
        if (!(newValue.length > 0)) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Promo Code cannot be empty";
            isValid = false;
        }

        //Check if contain space character
        if (/\s/.test(newValue)) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Promo Code cannot contain any space chracter";
            isValid = false;
        }

        if (!!errorMessage)
            setPromoInput({ ...promoInput, error: errorMessage }); //Set Error message

        return isValid;
    };
    return (
        <div className="container-fluid addon-container">
            <div className="container addon-form-container">
                <h2
                    style={{ color: "black" }}
                    className="text-left color-contrast-dark mt-4 p-4"
                >
                    New Subscription
                </h2>
                <Grid container className="p-4" spacing={3}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel error={!!selectedPlan.error} shrink>
                                Select a customer
                            </InputLabel>
                            <Select
                                labelId="existing-plan-dropdown"
                                id="existing-plan-dropdown"
                                className="text-left "
                                error={!!custId.error}
                                value={custId.value}
                                onChange={(e) => {
                                    setCustId({
                                        value: e.target.value,
                                    });
                                    // setCustFirstName({
                                    //     value
                                    // })
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "right",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "right",
                                    },
                                    keepMounted: true,
                                    getContentAnchorEl: null,

                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                            width: "200px",
                                        },
                                    },
                                }}
                            >
                                {customersFromServer.length > 0 ? (
                                    customersFromServer.map((customer, i) => (
                                        <HtmlTooltip
                                            className=""
                                            value={
                                                (customer.cust_id,
                                                customer.cust_fname,
                                                customer.cust_lname)
                                            }
                                            title={
                                                <React.Fragment>
                                                    <Grid
                                                        style={{
                                                            fontSize: "10px",
                                                        }}
                                                        container
                                                        spacing={1}
                                                    >
                                                        <Grid item xs={6}>
                                                            <span>
                                                                Customer First
                                                                Name:
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <span>
                                                                {
                                                                    customer.cust_fname
                                                                }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <span>
                                                                Customer Last
                                                                Name:
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <span>
                                                                {
                                                                    customer.cust_lname
                                                                }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <span>
                                                                Customer Email:
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <span>
                                                                {
                                                                    customer.cust_email
                                                                }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <span>
                                                                Customer Contact
                                                                No:
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <span>
                                                                {
                                                                    customer.cust_contact_no
                                                                }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <span>
                                                                Customer
                                                                Alternative
                                                                Contact:
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <span>
                                                                {
                                                                    customer.cust_alternative_contact
                                                                }
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <span>
                                                                Customer Company
                                                                Name:{" "}
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <span>
                                                                {
                                                                    customer.cust_company_name
                                                                }
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </React.Fragment>
                                            }
                                        >
                                            <MenuItem
                                                key={i}
                                                value={
                                                    (customer.cust_id,
                                                    customer.cust_fname,
                                                    customer.cust_lname)
                                                }
                                            >
                                                {customer.cust_fname +
                                                    " " +
                                                    customer.cust_lname}
                                            </MenuItem>
                                        </HtmlTooltip>
                                    ))
                                ) : (
                                    <MenuItem disabled>
                                        No Customers. Please create a customer
                                        first from Ar Team.
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        {!!custId.error ? (
                            <InputLabel
                                xs={12}
                                className="text-left mt-2 ml-1 new-line"
                                style={{ fontSize: "12px" }}
                                error
                            >
                                {custId?.error}
                            </InputLabel>
                        ) : (
                            ""
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel error={!!selectedPlan.error} shrink>
                                Select a plan
                            </InputLabel>
                            <Select
                                labelId="existing-plan-dropdown"
                                id="existing-plan-dropdown"
                                className="text-left"
                                error={!!selectedPlan.error}
                                value={selectedPlan.value.plan_id}
                                onChange={(e) => {
                                    setSelectedAddons({ value: [] });
                                    setSelectedPlan({
                                        value: plansFromServer.find(
                                            (plan) =>
                                                plan.plan_id == e.target.value
                                        ),
                                    });
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "top",
                                        horizontal: "right",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "right",
                                    },
                                    keepMounted: true,
                                    getContentAnchorEl: null,

                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                            width: "200px",
                                        },
                                    },
                                }}
                            >
                                {plansFromServer.length > 0 ? (
                                    plansFromServer.map((plan, i) => (
                                        <MenuItem key={i} value={plan.plan_id}>
                                            {plan.plan_name}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>
                                        No Existing Plans. Please create a plan
                                        first.
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                        {!!selectedPlan.error ? (
                            <InputLabel
                                xs={12}
                                className="text-left mt-2 ml-1 new-line"
                                style={{ fontSize: "12px" }}
                                error
                            >
                                {selectedPlan?.error}
                            </InputLabel>
                        ) : (
                            ""
                        )}
                    </Grid>
                    {!!selectedPlan.value.plan_id ? (
                        <Grid container spacing={3} style={{ padding: "12px" }}>
                            {selectedPlan.value.addons?.length > 0 ? (
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="addon-mutiple-checkbox-label">
                                            Select Addon for this subscription
                                        </InputLabel>
                                        <Select
                                            labelId="addon-mutiple-checkbox-label"
                                            id="addon-mutiple-checkbox"
                                            className="text-left"
                                            multiple
                                            value={selectedAddons.value.map(
                                                (e) => e.selectedAddonId
                                            )}
                                            onChange={(event) => {
                                                var selected =
                                                    event.target.value;

                                                const newSelectedAddons =
                                                    selected.map((addonId) => {
                                                        var existingAddon =
                                                            selectedAddons.value.find(
                                                                (
                                                                    selectedAddon
                                                                ) =>
                                                                    selectedAddon.selectedAddonId ==
                                                                    addonId
                                                            );
                                                        var quantity = "1";
                                                        if (!!existingAddon) {
                                                            quantity =
                                                                existingAddon.quantity;
                                                        }

                                                        return {
                                                            selectedAddonId:
                                                                addonId,
                                                            quantity: quantity,
                                                            error: {},
                                                        };
                                                    });

                                                setSelectedAddons({
                                                    ...selectedAddons,
                                                    value: newSelectedAddons,
                                                });
                                            }}
                                            input={<Input />}
                                            renderValue={(selectedAddonIds) => {
                                                var displayText = "";
                                                selectedAddonIds.forEach(
                                                    (selectedAddonId) => {
                                                        displayText +=
                                                            selectedPlan.value.addons.find(
                                                                (addon) =>
                                                                    addon.addon_id ==
                                                                    selectedAddonId
                                                            ).addon_name + ", ";
                                                    }
                                                );

                                                return displayText;
                                            }}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "top",
                                                    horizontal: "right",
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "right",
                                                },
                                                keepMounted: true,
                                                getContentAnchorEl: null,

                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200,
                                                        width: "200px",
                                                    },
                                                },
                                            }}
                                        >
                                            {!!selectedPlan.value.addons
                                                ? selectedPlan.value.addons.map(
                                                      (addon) => (
                                                          <MenuItem
                                                              color="primary"
                                                              disabled={
                                                                  addon.addon_applicability ==
                                                                  "mandatory"
                                                              }
                                                              key={
                                                                  addon.addon_id
                                                              }
                                                              value={
                                                                  addon.addon_id
                                                              }
                                                          >
                                                              <Checkbox
                                                                  checked={
                                                                      selectedAddons.value
                                                                          .map(
                                                                              (
                                                                                  e
                                                                              ) =>
                                                                                  e.selectedAddonId
                                                                          )
                                                                          .indexOf(
                                                                              addon.addon_id
                                                                          ) > -1
                                                                  }
                                                              />
                                                              <ListItemText
                                                                  primary={
                                                                      addon.addon_name
                                                                  }
                                                              />
                                                          </MenuItem>
                                                      )
                                                  )
                                                : ""}
                                        </Select>
                                    </FormControl>
                                    {!!selectedAddons.error ? (
                                        <InputLabel
                                            item
                                            xs={12}
                                            className="text-left mt-2 ml-1 new-line"
                                            style={{ fontSize: "12px" }}
                                            error
                                        >
                                            {selectedAddons?.error}
                                        </InputLabel>
                                    ) : (
                                        ""
                                    )}
                                </Grid>
                            ) : (
                                ""
                            )}

                            <Grid item xs={6} sm={6}>
                                {!!trialStart.value ? (
                                    <TextField
                                        required
                                        disabled
                                        fullWidth
                                        id="trialStart"
                                        label="Trial Start DateTime"
                                        type="datetime-local"
                                        defaultValue="No Trial"
                                        value={trialStart.value}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                ) : (
                                    <TextField
                                        required
                                        disabled
                                        label="Trial Start DateTime"
                                        fullWidth
                                        value="No Trial"
                                        disabled={true}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                {!!trialEnd.value ? (
                                    <TextField
                                        required
                                        disabled
                                        fullWidth
                                        id="trialEnd"
                                        label="Trial End DateTime"
                                        type="datetime-local"
                                        defaultValue="No Trial"
                                        value={trialEnd.value}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                ) : (
                                    <TextField
                                        required
                                        disabled
                                        label="Trial End DateTime"
                                        fullWidth
                                        value="No Trial"
                                        disabled={true}
                                    />
                                )}
                            </Grid>
                            <Grid container style={{ padding: "12px" }}>
                                {!!dateEnd.value ? (
                                    <Fragment>
                                        <Grid item xs={8}>
                                            <TextField
                                                required
                                                disabled
                                                fullWidth
                                                id="dateEnd"
                                                label="Subscription End datetime"
                                                type="datetime-local"
                                                value={dateEnd.value}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div
                                                style={{
                                                    position: "relative",
                                                    height: "100%",
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        position: "absolute",
                                                        fontSize: "14px",
                                                        fontWeight: 100,
                                                        bottom: 0,
                                                        left: 0,
                                                        width: "100%",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {!!dateEnd.value
                                                        ? `${moment(
                                                              dateEnd.value
                                                          ).diff(
                                                              moment(),
                                                              "days"
                                                          )} days left until Subscription Expire`
                                                        : ""}
                                                </span>
                                            </div>
                                        </Grid>
                                    </Fragment>
                                ) : (
                                    <TextField
                                        required
                                        disabled
                                        label="Subscription End datetime"
                                        fullWidth
                                        value="No End Date. This Subscription will only end if a cancel Subscription is triggered."
                                        disabled={true}
                                    />
                                )}
                            </Grid>
                            {renderSelectedPlanInformation()}
                            {renderSelectedAddonInformation()}

                            <Grid
                                item
                                xs={12}
                                className="py-4 shadow-sm"
                                style={{ backgroundColor: "#f5f5f5" }}
                            >
                                <h6 className="text-left">
                                    Apply promotion code to this subscription?{" "}
                                </h6>
                                <p
                                    className="text-left m-0 mb-1"
                                    style={{ fontSize: "12px" }}
                                >
                                    **Note: Please note that promotion code is
                                    case-sensitive.
                                </p>
                                <Grid container>
                                    <Grid item xs={9}>
                                        <TextField
                                            fullWidth
                                            required
                                            id="promo-code-apply"
                                            name="promo-code-apply"
                                            label={
                                                !!promoInput.value
                                                    ? ""
                                                    : "Promotion Code"
                                            }
                                            error={!!promoInput.error}
                                            helperText={promoInput.error || ""}
                                            value={promoInput.value}
                                            onChange={(e) => {
                                                const newValue = e.target.value;

                                                if (
                                                    validatePromoInput(
                                                        newValue
                                                    ) ||
                                                    newValue == ""
                                                )
                                                    setPromoInput({
                                                        value: newValue,
                                                    });
                                            }}
                                            inputProps={{
                                                className: "bg-white",
                                            }}
                                            InputLabelProps={{ shrink: false }}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item className="pl-2" xs={3}>
                                        <Button
                                            className="h-100 bg-success"
                                            fullWidth
                                            onClick={applyPromoCode}
                                            variant="contained"
                                            style={{
                                                backgroundColor: "#7A53EA",
                                                color: "white",
                                            }}
                                            endIcon={
                                                <MoneyOffIcon></MoneyOffIcon>
                                            }
                                        >
                                            Apply
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {renderEstimatedPriceForSubscription()}
                        </Grid>
                    ) : (
                        ""
                    )}
                    <Grid item className="mt-3" xs={12}>
                        <Button
                            variant="contained"
                            className="p-3"
                            onClick={createNewSubscriptionInServer}
                            style={{
                                backgroundColor: "black",
                                color: "white",
                            }}
                            endIcon={<AddIcon></AddIcon>}
                        >
                            Create Subscription
                        </Button>

                        <Button
                            className="ml-2 p-3"
                            variant="contained"
                            onClick={redirectToSubscriptionListingPage}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <Dialog
                fullWidth
                maxWidth="lg"
                onClose={() => {
                    setInfoModal({ ...infoModal, isOpened: false });
                }}
                aria-labelledby="customized-dialog-title"
                open={infoModal.isOpened}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => {
                        setInfoModal({ ...infoModal, isOpened: false });
                    }}
                >
                    {renderInfoModalTitle()}
                </DialogTitle>
                <DialogContent dividers>
                    {renderInfoModalContent()}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setInfoModal({ ...infoModal, isOpened: false });
                        }}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer />
        </div>
    );
};

export default SubscriptionCreate;
