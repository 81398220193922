import { useEffect, useLayoutEffect, useState } from "react";
import { Button, Card, Carousel, Col, Container, Form, Image, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Tracker from "../../Components/Tracker/tracker";
import no_image from "../../Images/no-image.svg";
import Swal from "sweetalert2";
import addOnService from "../../services/addOnService";
import planService from "../../services/planService";
import ItemSelection from "../../Components/ItemSelection/ItemSelection";
import SignInRegisterModal from "../../Components/SignInRegisterModal/SignInRegisterModal";
import "./style.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import PricingDetail from "../../Components/PricingDetail/PricingDetail";
import { store } from "../../index.js";
import merchantService from "../../services/merchantService";
const ProductDetail = ({}) => {
    const isLoggedIn = useSelector((state) => state.loggedInUserReducer.userData);
    var steps = ["Item Selection", "Sign In/Register", "Review & Pay"];
    const [activeStep, setActiveStep] = useState(
        JSON.parse(sessionStorage.getItem("active_step")) !== null
            ? JSON.parse(sessionStorage.getItem("active_step"))
            : 0
    );
    const [creditCardSettings, setCreditCardSettings] = useState();
    if (isLoggedIn) {
        // remove second index in steps
        steps.splice(1, 1);
    }
    const [completed, setCompleted] = useState({});
    function handleCompleteStep() {
        // const newCompleted = completed;
        // console.log(completed, "new completed");
        // if activeStep is true in completed, dont set completed animation
        // if (completed[activeStep]) {
        //     setCompletedAnimation(false);
        // } else {
        //     setCompletedAnimation(true);
        //     newCompleted[activeStep] = true;
        //     setCompleted(newCompleted);
        // }
        handleNext();
    }
    const history = useHistory();
    const handleBack = () => {
        if (activeStep === 0) {
            history.push("/");
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };
    const handleNext = () => {
        setActiveStep(1);
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const totalSteps = () => {
        return steps.length;
    };
    const completedSteps = () => {
        return Object.keys(completed).length;
    };
    const params = useParams();

    const [optionValidation, setOptionValidation] = useState(true);

    const [productDetail, setProductDetail] = useState();
    const [itemSelectionValidation, setItemSelectionValidation] = useState({
        error: "Please check your selection",
        valid: true,
        index: [],
    });
    const [attributeValidation, setAttributeValidation] = useState({
        variant: { index: [], message: "Please select an option" },
    });
    useLayoutEffect(() => {
        if (JSON.parse(sessionStorage.getItem("purchase_option")) == null) {
            planService.getSinglePlan(params.id).then((plan) => {
                setProductDetail(plan.plans);
            });
            addOnService.getAllAddonWithoutPagination().then((addOns) => {
                console.log(addOns);
            });
        } else if (JSON.parse(sessionStorage.getItem("purchase_option")) !== null) {
            planService.getSinglePlan(params.id).then((plan) => {
                setProductDetail({
                    ...plan.plans,
                    categories_item: {
                        categories: JSON.parse(sessionStorage.getItem("selected_items")),
                    },
                });
            });
            addOnService.getAllAddonWithoutPagination().then((addOns) => {
                console.log(addOns);
            });
        }
        merchantService
            .getCreditCardSettings()
            .then((e) => {
                setCreditCardSettings(e);
            })
            .catch((err) => console.error(err));
    }, []);

    const handleComplete = () => {
        var stepValid = true;
        if (activeStep === 0) {
            const quantityExist = productDetail.quantity_exist;
            const selectedItems = JSON.parse(sessionStorage.getItem("selected_items"));

            console.log(selectedItems, "this is the product detail");
            // check quantity restriction here
            const purchaseOption = JSON.parse(sessionStorage.getItem("purchase_option"));
            console.log(purchaseOption, "this is the purchase option");
            if (purchaseOption === null) {
                setOptionValidation(false);
                stepValid = false;
            } else {
                setOptionValidation(true);
                stepValid = true;
            }

            if (quantityExist) {
                const quantityRestrictions = productDetail.quantity_restrictions;
                var variantCategory = [];
                var variantValidate = [];

                selectedItems.forEach((item, category_index) => {
                    // check variant
                    console.log(item, "these are the items");
                    var itemVariantValidate = [];

                    item.items.forEach((item, item_index) => {
                        // if variant exist, check if chosen_variant_detail has existed before
                        if (
                            item.variant_exist &&
                            item.quantity > 0 &&
                            item.chosen_variant_detail === undefined
                        ) {
                            itemVariantValidate.push(item_index);
                            variantCategory.push(category_index);
                            stepValid = false;
                        }
                        console.log(itemVariantValidate, "item_index");
                    });
                    variantValidate.push(itemVariantValidate);

                    setAttributeValidation({
                        ...attributeValidation,
                        variant: {
                            ...attributeValidation.variant,
                            index: variantValidate,
                            category: variantCategory,
                        },
                    });
                });
                if (quantityRestrictions?.apply_to === "individual") {
                    var categoryValidate = [];

                    selectedItems.forEach((item, category_index) => {
                        // check each of the category row against the quantity restriction
                        quantityRestrictions.category.forEach((category, category_index) => {
                            if (category.category === item.name) {
                                // sum all the quantity of the selected items
                                var totalItemQuantity = 0;
                                // var itemVariantValidate = [];
                                item.items.forEach((item, item_index) => {
                                    totalItemQuantity += item.quantity;
                                });
                                if (category.number !== totalItemQuantity) {
                                    console.log(category_index, "this is the category index");
                                    categoryValidate.push(category_index);
                                    stepValid = false;
                                }

                                console.log(item, "this is the category");
                            }
                        });
                    });
                    setItemSelectionValidation({
                        ...itemSelectionValidation,
                        index: categoryValidate,
                    });
                } else if (quantityRestrictions?.apply_to === "all") {
                    var itemQuantity = 0;

                    selectedItems.forEach((item, category_index) => {
                        item.items.forEach((item_detail, item_index) => {
                            itemQuantity += parseInt(item_detail.quantity);
                        });
                    });
                    if (itemQuantity !== quantityRestrictions.category[0].number) {
                        setItemSelectionValidation({
                            ...itemSelectionValidation,
                            valid: false,
                        });
                        stepValid = false;
                    } else {
                        setItemSelectionValidation({
                            ...itemSelectionValidation,
                            valid: true,
                        });
                    }
                }
            }

            // console.log(stepValid, "this tep is valis");
            if (stepValid) {
                handleCompleteStep();
            }
        }
    };

    const backButton = () => {
        const words = "Back";
        return (
            <div className="text-left d-flex flex-row">
                <ArrowBackIcon className="back-word" onClick={() => handleBack()} />
                <p className="back-word" onClick={() => handleBack()}>
                    {" "}
                    {words}
                </p>
            </div>
        );
    };

    return (
        <>
            <Tracker
                steps={steps}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                completed={completed}
                setCompleted={setCompleted}
                handleComplete={handleCompleteStep}
                completedSteps={completedSteps}
                totalSteps={totalSteps}
            />

            <Container style={{ width: window.innerWidth > 991 && "85%" }}>
                {activeStep > 0 && backButton()}
                {activeStep === 0 && productDetail && (
                    <ItemSelection
                        activeStep={activeStep}
                        setProductDetail={setProductDetail}
                        productDetail={productDetail}
                        itemValidation={itemSelectionValidation}
                        optionValidation={optionValidation}
                        setOptionValidation={setOptionValidation}
                        attributeValidation={attributeValidation}
                        setAttributeValidation={setAttributeValidation}
                    />
                )}

                {activeStep === 1 && !isLoggedIn && productDetail && (
                    <SignInRegisterModal
                        className="mt-3"
                        activeStep={activeStep}
                        setActiveStep={(step) => {
                            setActiveStep(step);
                        }}
                        handleComplete={() => handleComplete()}
                    />
                )}
                {activeStep === 1 &&
                    isLoggedIn &&
                    productDetail &&
                    creditCardSettings !== undefined && (
                        <PricingDetail
                            creditCardSettings={creditCardSettings}
                            productDetail={productDetail}
                            setProductDetail={setProductDetail}
                            itemValidation={itemSelectionValidation}
                            setItemSelectionValidation={(value) => {
                                setItemSelectionValidation(value);
                            }}
                        />
                    )}

                <Row className="pb-5">
                    <Col className="pt-3 pb-3">
                        <Button
                            onClick={() => {
                                handleBack();
                            }}
                            style={{ textTransform: "none", display: activeStep > 0 ? "none" : "" }}
                            className="details-cancel-button"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                handleComplete();
                            }}
                            style={{
                                backgroundColor: store.getState().colorState.color,
                                borderColor: store.getState().colorState.color,
                                color: store.getState().colorState.font_color,
                                textTransform: "none",
                                display: activeStep > 0 ? "none" : "",
                            }}
                        >
                            Save & Continue
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ProductDetail;
