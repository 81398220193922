import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Dropdown from "react-bootstrap/Dropdown";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Button, Col, Row } from "react-bootstrap";
import curlec_logo from "../../Images/curlec_logo.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSelector } from "react-redux";
import { useEffect, useLayoutEffect, useState } from "react";
import LoginModal from "../LoginModal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LoginIcon from "@mui/icons-material/Login";
import "./breadcrumb.css";
import userService from "../../services/userService";
import { useHistory } from "react-router-dom";
import { store } from "../../index.js";
import customerService from "../../services/customerService";
import profileService from "../../services/profileService";
const Breadcrumb = ({ sidebarIsOpen, handleCloseSidebar }) => {
    console.log(store, "this is the store colour");
    const history = useHistory();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [signOutDropdown, setSignOutDropdown] = useState("none");
    const [customerData, setCustomerData] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [merchantLogo, setMerchantLogo] = useState("");
    const [primaryColor, setPrimaryColor] = useState();
    const [secondaryColor, setSecondaryColor] = useState();
    const isLoggedIn = useSelector((state) => state.loggedInUserReducer.userData);

    const getCustomerData = async () => {
        if (isLoggedIn !== undefined) {
            await customerService
                .getSpecificCustomerFromAR(
                    store.getState().loggedInUserReducer?.userData?.ar_system?.cust_id
                )
                .then((res) => {
                    setCustomerData(res);
                    setCustomerName({ value: res.cust_fname + " " + res.cust_lname });
                });
        }
    };

    const handleSignOut = () => {
        handleCloseSidebar();
        setTimeout(() => {
            userService.logout().then(() => window.location.replace("/"));
        }, 300);
    };

    useLayoutEffect(() => {
        getCustomerData();
        profileService.getProfile().then((result) => {
            const imagePreview = `${window.location.protocol + "//" + window.location.hostname}${
                process.env.REACT_APP_SITE_PORT_BACKEND
            }${result.settings.company_logo}`;
            setMerchantLogo(imagePreview);
            setPrimaryColor(result.settings.primary_colour);
            setSecondaryColor(result.settings.secondary_colour);
        });
    }, []);

    return (
        <Row className="breadcrumb-bar ">
            <Col
                className={`${
                    sidebarIsOpen && isLoggedIn ? "breadcrumb-design-expanded" : "breadcrumb-design"
                }`}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <Col xs={7} lg={4}>
                    <Stack spacing={2}>
                        {isLoggedIn && (
                            <Breadcrumbs
                                className="breadcrumb-details"
                                style={{ marginLeft: "4.8em" }}
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            />
                        )}
                    </Stack>
                </Col>
                <Col xs={0} lg={4}>
                    {(!sidebarIsOpen || !isLoggedIn) && (
                        <img
                            className="menu-logo "
                            style={{
                                border: "none",
                                width: "15vw",
                                paddingTop: ".5em",
                                paddingBottom: ".5em",
                                display: window.innerWidth < 991 && "none",
                            }}
                            src={merchantLogo}
                            onClick={() => history.replace("/")}
                            alt="Empty Logo."
                        ></img>
                    )}
                </Col>
                <Col
                    xs={5}
                    lg={4}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        marginRight: "2em",
                    }}
                >
                    <div>
                        {isLoggedIn ? (
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant="contained"
                                        className="toggle-sign-out"
                                    >
                                        <AccountCircleIcon className="mr-1 pb-1" />
                                        {isLoggedIn.userRole === "merchant" && isLoggedIn.name}
                                        {isLoggedIn.userRole === "customer" && customerName.value}
                                        {/* {JSON.parse(isLoggedIn.userData).name} */}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => {
                                                handleSignOut();
                                            }}
                                        >
                                            Sign Out
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        ) : (
                            <div style={{ marginRight: window.innerWidth < 991 && "40px" }}>
                                <Button
                                    className="sign-in-register d-flex"
                                    onClick={() => {
                                        setShowLoginModal(true);
                                    }}
                                    style={{
                                        backgroundColor: primaryColor,
                                        borderColor: primaryColor,
                                        color: store.getState().colorState.font_color,
                                        width: window.innerWidth < 991 && "max-content",
                                        marginRight: "2em",
                                    }}
                                >
                                    <p>Sign In/Register</p>
                                    <LoginIcon
                                        style={{ fontSize: window.innerWidth > 991 && "1.3rem" }}
                                        className="ml-2"
                                    />
                                </Button>{" "}
                                <LoginModal
                                    show={showLoginModal}
                                    handleClose={() => {
                                        setShowLoginModal(false);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </Col>
            </Col>
        </Row>
    );
};

export default Breadcrumb;
