import ReactGA from "react-ga";
import subscriptionService from "../subscriptionService";

/***************************************************** 
 * A service wrapper for react-ga
 * Note that this is using an older version of react-ga 
 * which uses UA ID instead of GA Measurement ID
 * ***************************************************/
// TODO: make UA ID configurable

const googleAnalyticService = {
    // initialize google analytics and trigger page view event
    init: () => {
        if (localStorage.getItem("google-analytics-enabled")) {
            console.log("Initializing google analytics");
            // this is currently hardcoded to picha's ID
	        ReactGA.initialize("UA-88930041-1");
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    },
    // trigger view content event
    trackViewContent : (itemName) => {
        if (localStorage.getItem("google-analytics-enabled")) {
            console.log("GA view content: " + itemName);
            ReactGA.event({
                category: 'Subscription',
                action: 'View Content',
                label: itemName,
              });
            // Below is the code for new version of react-ga (version 4)
            // ReactGA.event('view_item', {
            //     items: [
            //         {
            //             item_name: itemName
            //         }
            //     ]
            // });
        }
    },
    // trigger initiate checkout event
    trackInitiateCheckout : (itemName) => {
        if (localStorage.getItem("google-analytics-enabled")) {
            console.log("GA initiate checkout: " + itemName);
            ReactGA.event({
                category: 'Subscription',
                action: 'Initiate Checkout',
                label: itemName,
              });
            // Below is the code for new version of react-ga (version 4)
            // ReactGA.event('begin_checkout', {
            //     items: [
            //         {
            //             item_name: itemName
            //         }
            //     ]
            // });
        }
    },
    // trigger subscribe event, this is mainly used when user clicks the checkout button
    trackSubscribe : (price, itemName, customerId) => {
        if (localStorage.getItem("google-analytics-enabled")) {
            console.log("GA Subscribe: " + itemName);
            ReactGA.event({
                category: 'Subscription',
                action: 'Subscribe',
                label: itemName,
              });
            // Below is the code for new version of react-ga (version 4)
            // ReactGA.event('purchase', {
            //     currency: "MYR",
            //     transaction_id: customerId + Date.now(),
            //     value: price,
            //     items: [
            //         {
            //             item_name: itemName
            //         }
            //     ]
            // });
        }
    },
    // trigger purchase event, this is mainly used when user sees the confirmation pop up
    trackPurchase : (subsId) => {
        if (localStorage.getItem("google-analytics-enabled")) {
            console.log("GA subs id: " + subsId);
            subscriptionService.getSingleSubscription(subsId).then((res) => {
                var itemName = res.subs.plan_name
		var subsPrice = res.subs.subs_price
                console.log("GA purchase: ", itemName, subsPrice);
                ReactGA.event({
                    category: 'Subscription',
                    action: 'Purchase',
                    label: itemName,
		    value: subsPrice
                });
            });
        }
    },
};

export default googleAnalyticService;
