import "./MerchantLogin.css";
import { Row, Col, Form, Button } from "react-bootstrap";
import ErrorIcon from "@material-ui/icons/Error";
import curlec_logo from "../../Images/curlec_logo.png";
import secure_login from "../../Images/secure_login.png";
import userService from "../../services/userService";
import Swal from "sweetalert2";
import { store } from "../../index.js";
import { useHistory } from "react-router-dom";
import { useLayoutEffect, useState, useEffect } from "react";
import { el } from "date-fns/locale";
import profileService from "../../services/profileService";
import customerService from "../../services/customerService";
import { toast } from "react-toastify";

const MerchantLogin = () => {
    const history = useHistory();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [loginValidation, setLoginValidation] = useState(false);
    const [forgotPassword, setForgotPassword] = useState("");
    const [tabState, setTabState] = useState("sign_in");
    const login = () => {
        userService
            .login(username, password)
            .then((userData) => {
                setLoginValidation(false);
                const userRole = userData.data.userRole;
                if (userRole === "merchant") {
                    window.location.replace("/");
                }
            })
            .catch((res) => {
                setLoginValidation(true);
                console.log(res, "merchant login erro");
            });
    };

    const resetPassword = () => {
        customerService
            .forgotPassword({ email: forgotPassword })
            .then((e) => {
                console.log(e, "success");
                setEmailSent(true);
            })
            .catch((err) => setEmailSent(true));
    };


    //redirects to home page if already logged in
    useEffect(() => {
        if(userService.isLoggedIn())
            history.push("/")
    }, [])
        

    return (
        <div style={{ display: "block" }} className="overlay">
            <Row>
                <Col lg={8} style={{ backgroundColor: "#FFFFFF", height: "100vh" }}>
                    <div className="credential-bar">
                        <Row className="credential-logo">
                            <img
                                src={curlec_logo}
                                style={{ width: window.innerWidth < 667 ? "50%" : "25%" }}
                            />
                        </Row>
                        <Row className="pt-5">
                            <Col>
                                <h1>
                                    {" "}
                                    {`${
                                        tabState === "forgot_password"
                                            ? "Forgot password?"
                                            : "Sign In"
                                    }`}
                                </h1>
                                <h2
                                    className="pt-2"
                                    style={{
                                        color: store.getState().colorState.secondary_color,
                                        cursor: "pointer",
                                    }}
                                    onClick={() =>
                                        setTabState(
                                            tabState === "sign_in" ? "forgot_password" : "sign_in"
                                        )
                                    }
                                >
                                    {`${tabState === "sign_in" ? "Forgot password?" : "Sign In"}`}
                                </h2>
                                {tabState === "sign_in" && (
                                    <>
                                        <Form.Label className="pt-3">Username</Form.Label>
                                        <Form.Control
                                            onChange={(username) =>
                                                setUsername(username.target.value)
                                            }
                                            onKeyUp={(username) => {
                                                if (username.key === "Enter") {
                                                    login();
                                                } else {
                                                    setUsername(username.target.value);
                                                }
                                            }}
                                        />
                                        <Form.Label className="pt-3">Password</Form.Label>
                                        <Form.Control
                                            onChange={(password) =>
                                                setPassword(password.target.value)
                                            }
                                            onKeyUp={(password) => {
                                                if (password.key === "Enter") {
                                                    login();
                                                } else {
                                                    setPassword(password.target.value);
                                                }
                                            }}
                                            type="password"
                                        />
                                        {loginValidation && (
                                            <p className="m-0 mt-3" style={{ color: "red" }}>
                                                Username or password is wrong.
                                            </p>
                                        )}
                                        <Button
                                            className="mt-3 ml-lg-0"
                                            onClick={() => {
                                                login();
                                            }}
                                            style={{
                                                backgroundColor: store.getState().colorState.color,
                                                borderColor: store.getState().colorState.color,
                                                color: store.getState().colorState.font_color,
                                                textTransform: "none",
                                            }}
                                        >
                                            Sign In
                                        </Button>
                                    </>
                                )}
                                {tabState === "forgot_password" && (
                                    <>
                                        <Form.Label className="pt-3">Email</Form.Label>
                                        <Form.Control
                                            onChange={(username) =>
                                                setForgotPassword(username.target.value)
                                            }
                                            onKeyUp={(username) => {
                                                if (username.key === "Enter") {
                                                    resetPassword();
                                                } else {
                                                    setForgotPassword(username.target.value);
                                                }
                                            }}
                                        />
                                        {emailSent && (
                                            <p
                                                style={{
                                                    color: store.getState().colorState
                                                        .secondary_color,
                                                }}
                                            >
                                                Email sent! Please check email.
                                            </p>
                                        )}
                                        <Button
                                            className="mt-3 ml-lg-0"
                                            onClick={() => {
                                                resetPassword();
                                            }}
                                            style={{
                                                backgroundColor: store.getState().colorState.color,
                                                borderColor: store.getState().colorState.color,
                                                color: store.getState().colorState.font_color,
                                                textTransform: "none",
                                            }}
                                        >
                                            Send Email
                                        </Button>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col
                    lg={4}
                    style={{
                        backgroundColor: store.getState().colorState.color,
                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: "0em 2em",
                    }}
                >
                    <img src={secure_login} className="w-50" />
                    <h1 className="pt-3">Sign in to access your dashboard</h1>
                </Col>
            </Row>
        </div>
    );
};

export default MerchantLogin;
