import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toggleSidebar, getMerchantLogo } from "./action.js";
import "./App.css";
import Breadcrumb from "./Components/Breadcrumb/breadcrumb";
import MerchantLogin from "./Components/MerchantLogin/MerchantLogin";
import Sidebar from "./Components/Sidebar/sidebar";
import Admin from "./Containers/Admin";
import Customer from "./Containers/Customer";
import Homepage from "./Containers/Homepage/homepage";
import NotFoundPage from "./Containers/NotFoundPage";
import ProductDetail from "./Containers/ProductDetail/index.js";
import lets_encrypt_2022 from "./Images/lets_encrypt_2022.png";
import powered_by_curlec from "./Images/powered_by_curlec.png";
import { store } from "./index.js";
import ga4Service from "./services/analyticService/ga4";
import googleAnalyticService from "./services/analyticService/googleAnalytic";
import pixelService from "./services/analyticService/pixel";
import profileService from "./services/profileService";
import userService from "./services/userService";
// grab merchant color code from backend
// set store here

function Routes() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [merchantLogo, setMerchantLogo] = useState("");
    console.log(store.getState().merchantLogo, "merchant logo");
    console.log(store.getState().colorState, "this is a color state");
    const isLoggedIn = useSelector((state) => state.loggedInUserReducer.userData);

    useEffect(() => {
        setLoggedIn(userService.isLoggedIn());

        // enable pixel and google analytics for picheats
        var hostname = window.location.hostname;
        hostname = hostname.split(".");
        if (hostname[0] === "pichaeats") {
            localStorage.setItem("pixel-enabled", "true");
            localStorage.setItem("google-analytics-enabled", "true");
            pixelService.init();
            googleAnalyticService.init();
        } else if (hostname[0] === "subscribe" && hostname[1] === "ketoccino") {
            localStorage.setItem("google-analytics-4-enabled", "true");
            ga4Service.init();
        }
    }, []);

    useEffect(() => {
        store.dispatch(toggleSidebar(isOpen));
        setMerchantLogo(store.getState().merchantLogo.logo);
    }, [store]);

    const handleCloseSidebar = () => {
        setIsOpen(false);
        store.dispatch(toggleSidebar(false));
    };
    const handleOpenSidebar = () => {
        setIsOpen(true);
        store.dispatch(toggleSidebar(true));
    };

    return (
        <BrowserRouter>
            <div className="App" id="outer-container">
                {isLoggedIn && (
                    <Sidebar
                        // pageWrapId={`${isOpen ? "page-wrap" : "page-unwrap"}`}
                        style={{
                            marginLeft: isOpen && isLoggedIn ? "16em" : "0em",
                        }}
                        outerContainerId="outer-container"
                        isOpen={isOpen}
                        handleCloseSidebar={handleCloseSidebar}
                        handleOpenSidebar={handleOpenSidebar}
                        merchantLogo={merchantLogo}
                    />
                )}
                <Breadcrumb
                    merchantLogo={merchantLogo}
                    sidebarIsOpen={isOpen}
                    handleCloseSidebar={handleCloseSidebar}
                />

                <div
                    style={{
                        fontFamily: "'Mulish', sans-serif",
                        paddingTop: "4em",
                        height: "100vh",
                    }}
                    id={`${isOpen && isLoggedIn ? "page-wrap" : "page-unwrap"}`}
                >
                    <Switch>
                        <Route path="/" exact component={Homepage} />
                        <Route path="/product/:id/" exact component={ProductDetail} />
                        <Route path="/admin" component={Admin} />
                        <Route path="/admin/login" exact component={MerchantLogin} />
                        <Route path="/customer" component={Customer} />
                        <Route path="/not-found" exact component={NotFoundPage} />
                        <Route path="*" exact component={NotFoundPage} />{" "}
                    </Switch>
                    <div
                        style={{
                            position: "fixed",
                            bottom: "0px",
                            left: "0px",
                            backgroundColor: "white",
                            paddingTop: ".5em",
                            display: "flex",
                            justifyContent: "space-between",
                            width: "-webkit-fill-available",
                        }}
                        id={`${isOpen && isLoggedIn ? "page-wrap" : "page-unwrap"}`}
                        className="footer"
                    >
                        <div style={{ paddingRight: "20px" }}>
                            {" "}
                            <Image
                                className="w-50"
                                style={{ float: "left", marginLeft: "20px" }}
                                src={powered_by_curlec}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                fontSize: ".8rem",
                                alignItems: "center",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Image className=" mr-4 w-25" src={lets_encrypt_2022} />
                            <p
                                className="m-0 mr-3"
                                style={{ fontSize: window.innerWidth < 961 && ".5rem" }}
                            >
                                {" "}
                                &copy; 2022 Curlec Sdn Bhd
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {window.location.href.includes("/admin/login") && <MerchantLogin />}
            <ToastContainer />
        </BrowserRouter>
    );
}

export default Routes;
