import sign_in_prod_details from "../../Images/sign_in_prod_details.png";
import { Form, Image, Container, Row, Col, Button, InputGroup } from "react-bootstrap";
import "./SignInRegisterModal.css";
import { useState } from "react";
import userService from "../../services/userService";
import customerService from "../../services/customerService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { store } from "../../index.js";
const SignInRegisterModal = ({ handleComplete, setActiveStep, activeStep }) => {
    const [isSignIn, setIsSignIn] = useState(true);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [showPassword, setShowPassword] = useState();
    const [invalidCredential, setInvalidCredential] = useState(false);
    const [invalidRegister, setInvalidRegister] = useState(false);
    const authenticateUser = () => {
        if (isSignIn) {
            // login
            userService
                .login(email, password)
                .then((res) => setActiveStep(1))
                .catch((err) => setInvalidCredential(true));
        }
        if (!isSignIn) {
            customerService
                .createCustomer({
                    username: email,
                    password: password,
                    cust_email: email,
                    cust_fname: firstName,
                    cust_lname: lastName,
                    cust_contact_no: phoneNumber,
                })
                .then((res) => {
                    userService
                        .login(email, password)
                        .then((res) => {
                            sessionStorage.setItem("active_step", 1);
                            window.location.reload();
                        })
                        .catch((err) => setInvalidCredential(true));
                })
                .catch((err) => setInvalidRegister(true));
        }
    };
    return (
        <Container className="mt-4">
            <Row>
                <Col
                    className="text-left"
                    style={{
                        backgroundColor: "white",
                        padding: "3em 5em",
                        borderRadius: "10px 0 0 10px",
                    }}
                    lg={7}
                >
                    <h3 style={{ fontWeight: "bolder" }}>Sign {isSignIn ? "In" : "Up"}</h3>
                    <h2 className="pt-3 d-flex">
                        {isSignIn ? "Don't h" : "H"}ave an account?
                        {isSignIn === true && (
                            <h2
                                className="ml-2"
                                style={{ color: "#3395FF", fontWeight: "bold", cursor: "pointer" }}
                                onClick={() => {
                                    setIsSignIn(false);
                                    setInvalidCredential(false);
                                    setEmail("");
                                    setPassword("");
                                    setConfirmPassword("");
                                }}
                            >
                                Sign Up
                            </h2>
                        )}
                        {isSignIn === false && (
                            <h2
                                className="ml-2"
                                style={{ color: "#3395FF", fontWeight: "bold", cursor: "pointer" }}
                                onClick={() => {
                                    setIsSignIn(true);
                                    setEmail("");
                                    setPassword("");
                                    setConfirmPassword("");
                                    setInvalidRegister(false);
                                }}
                            >
                                {" "}
                                Sign In
                            </h2>
                        )}
                    </h2>
                    {!isSignIn && (
                        <>
                            <Row>
                                <Col>
                                    <Form.Label className="pt-3">First Name</Form.Label>
                                    <Form.Control
                                        value={firstName}
                                        onChange={(name) => setFirstName(name.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Label className="pt-3">Last Name</Form.Label>
                                    <Form.Control
                                        value={lastName}
                                        onChange={(name) => setLastName(name.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Form.Label className="pt-3">Phone Number</Form.Label>
                            <Form.Control
                                value={phoneNumber}
                                onChange={(phone) => setPhoneNumber(phone.target.value)}
                            />
                        </>
                    )}

                    <Form.Label className="pt-2">Email</Form.Label>
                    <Form.Control
                        value={email}
                        onChange={(email) => setEmail(email.target.value)}
                    />
                    <Form.Label className="pt-3">Password</Form.Label>
                    <Form.Group className="d-flex">
                        <Form.Control
                            value={password}
                            type={showPassword ? "text" : "password"}
                            style={{ borderRight: "none", borderRadius: "6px 0 0 6px" }}
                            onChange={(password) => setPassword(password.target.value)}
                        ></Form.Control>
                        <InputGroup.Text
                            style={{
                                backgroundColor: "white",
                                border: "1px solid black",
                                borderLeft: "none",
                                borderRadius: "0 6px 6px 0",
                                cursor: "pointer",
                            }}
                            onClick={() => setShowPassword(!showPassword)}
                            id="inputGroupPrepend"
                        >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </InputGroup.Text>
                    </Form.Group>

                    {!isSignIn && (
                        <>
                            <Form.Label className="pt-3">Confirm Password</Form.Label>{" "}
                            <Form.Group className="d-flex">
                                <Form.Control
                                    value={confirmPassword}
                                    style={{ borderRight: "none", borderRadius: "6px 0 0 6px" }}
                                    type={showPassword ? "text" : "password"}
                                    onChange={(password) =>
                                        setConfirmPassword(password.target.value)
                                    }
                                />
                                <InputGroup.Text
                                    style={{
                                        backgroundColor: "white",
                                        border: "1px solid black",
                                        borderLeft: "none",
                                        borderRadius: "0 6px 6px 0",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setShowPassword(!showPassword)}
                                    id="inputGroupPrepend"
                                >
                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </InputGroup.Text>
                            </Form.Group>
                        </>
                    )}
                    {invalidCredential && (
                        <p style={{ color: "red" }}>Username or password is wrong</p>
                    )}
                    {invalidRegister && (
                        <p style={{ color: "red" }}> Registration error, please check again</p>
                    )}
                    <Button
                        className="w-100 m-0 mt-4"
                        style={{
                            padding: ".8em",
                            textTransform: "none",
                            backgroundColor: store.getState().colorState.color,
                            borderColor: store.getState().colorState.color,
                            color: store.getState().colorState.font_color,
                        }}
                        onClick={() => authenticateUser()}
                    >
                        Sign {isSignIn ? "In" : "Up"}
                    </Button>
                </Col>
                <Col
                    className="sign-register-photo"
                    style={{
                        backgroundColor: store.getState().colorState.color,
                        borderColor: store.getState().colorState.color,
                        color: store.getState().colorState.font_color,
                        padding: "4em",
                        borderRadius: "0 10px 10px 0",
                    }}
                    lg={5}
                >
                    <Image className="w-75 " src={sign_in_prod_details} />
                    <h3
                        style={{
                            backgroundColor: store.getState().colorState.color,
                            borderColor: store.getState().colorState.color,
                            color: store.getState().colorState.font_color,
                            fontWeight: "bolder",
                            padding: ".5em",
                        }}
                    >
                        Sign {isSignIn ? "in" : "up"} to complete your purchase
                    </h3>
                </Col>
            </Row>
        </Container>
    );
};

export default SignInRegisterModal;
