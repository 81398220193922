import {
    Button,
    Card,
    Carousel,
    Col,
    Form,
    Image,
    Row,
    ButtonToolbar,
    ToggleButton,
} from "react-bootstrap";
import no_image from "../../Images/no-image.svg";
import "./ItemSelection.css";
import { Chip } from "@material-ui/core";
import { useState, useEffect, useLayoutEffect, useRef } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { store } from "../../index.js";
const ItemSelection = ({
    activeStep,
    productDetail,
    setProductDetail,
    itemValidation,
    optionValidation,
    setOptionValidation,
    attributeValidation,
    setAttributeValidation,
}) => {
    useEffect(() => {
        console.log(attributeValidation, "this si atrteibute validation");
    }, [attributeValidation]);
    const [purchaseOption, setPurchaseOption] = useState(
        JSON.parse(sessionStorage.getItem("purchase_option")) !== null
            ? JSON.parse(sessionStorage.getItem("purchase_option"))
            : []
    );

    const [selectedItems, setSelectedItems] = useState(
        JSON.parse(sessionStorage.getItem("selected_items")) !== null
            ? JSON.parse(sessionStorage.getItem("selected_items"))
            : []
    );

    useLayoutEffect(() => {
        if (
            selectedItems.length === 0 &&
            JSON.parse(sessionStorage.getItem("selected_items")) === null
        ) {
            const allItems = productDetail.categories_item.categories.map((item) => {
                return item;
            });
            console.log(allItems, " all items ");
            setSelectedItems(allItems);
            sessionStorage.setItem("selected_items", JSON.stringify(allItems));
        }
    }, []);

    useEffect(() => {
        setProductDetail({
            ...productDetail,
            categories_item: { categories: JSON.parse(sessionStorage.getItem("selected_items")) },
        });
    }, [selectedItems]);

    const renderImagePath = (path) => {
        const imagePath =
            window.location.protocol +
            "//" +
            window.location.hostname +
            process.env.REACT_APP_SITE_PORT_BACKEND +
            path;
        return imagePath;
    };

    useEffect(() => {
        const variant = document.getElementById("scroll-variant");
        console.log(attributeValidation, "this is the variant");
        var attributeValid = true;
        attributeValidation.variant.index.forEach((variant) => {
            console.log(variant, "attribute validation variant");
            if (variant.length > 0) {
                attributeValid = false;
            }
        });
        console.log(attributeValid, "this is attribute valid");
        if (!attributeValid) {
            variant.scrollIntoView({ behavior: "smooth", block: "center" });
            setTimeout(() => {}, 700);
        }
    }, [attributeValidation]);

    //scroll into incomplete field when there's error
    useEffect(() => {
        const section = document.getElementById("scroll-here");
        if (!optionValidation) {
            section.scrollIntoView({ behavior: "smooth", block: "center" });
            setTimeout(() => {
                setOptionValidation(true);
            }, 700);
        }
    }, [optionValidation]);

    const renderProductTags = (tags) => {
        return tags.map((tag) => {
            return (
                <Chip
                    label={tag}
                    style={{
                        borderRadius: "2px",
                        backgroundColor: store.getState().colorState.secondary_color,
                        color: "white",
                        textTransform: "uppercase",
                        margin: ".5em .5em 0em 0em",
                    }}
                />
            );
        });
    };

    const selectionRestriction = (categoryName) => {
        const quantityRestriction = productDetail.quantity_restrictions;

        // console.log(quantityRestriction, "this is the quantity restriction");
        if (quantityRestriction?.apply_to === "all") {
            const quantityRestriction = productDetail.quantity_restrictions.category[0].number;
            // console.log(quantityRestriction, "this is the quantity restriction");
            return quantityRestriction;
        } else if (quantityRestriction?.apply_to === "individual") {
            const categoryNameRestriction = quantityRestriction.category.filter((restriction) => {
                return restriction.category === categoryName;
            });
            // check if category name is in restriction
            if (categoryNameRestriction.length > 0) {
                return categoryNameRestriction[0].number;
            }
        }
    };
    const renderCarouselItem = (card) => {
        if (card.addon_image !== undefined) {
            if (card.addon_image.length > 0) {
                return card.addon_image.map((image, index) => {
                    return (
                        <Carousel.Item key={index}>
                            <Card.Img
                                variant="top"
                                className="card-image-design"
                                src={renderImagePath(image.image_name)}
                            />
                        </Carousel.Item>
                    );
                });
            } else {
                return (
                    <Carousel.Item>
                        <Card.Img variant="top" className="card-image-design" src={no_image} />
                    </Carousel.Item>
                );
            }
        }
    };
    const nextAvailableDate = () => {
        // date_exist
        // date_format
        // date_le      ad_time
        if (productDetail.date_exist) {
            if (productDetail.date_format === "MM/YY") {
                // return next available month
                // use lead time to calculate
                let nextAvailableMonth = new Date();
                nextAvailableMonth.setMonth(
                    nextAvailableMonth.getMonth() + productDetail.date_lead_time
                );
                // console.log(
                //     nextAvailableMonth.toLocaleString("default", { month: "long" }),
                //     nextAvailableMonth.getFullYear()
                // );
                return `Next Available Month: ${nextAvailableMonth.toLocaleString("default", {
                    month: "long",
                })}, ${nextAvailableMonth.getFullYear()}`;
            } else if (productDetail.date_format === "DD/MM/YY") {
                // return next available day
                // use lead time to calculate
                let nextAvailableDay = new Date();
                nextAvailableDay.setDate(nextAvailableDay.getDate() + productDetail.date_lead_time);
                return `Next Available Day: ${nextAvailableDay.toLocaleString("default", {
                    month: "long",
                    day: "numeric",
                })} , ${nextAvailableDay.getFullYear()}`;
            }
        }
    };

    const renderItemPrice = (category_index, data_index, category_data) => {
        // if variant exists, render variant option in item
        // if variant does not exist, render addon price
        const itemDetail = selectedItems[category_index].items[data_index];
        if (!category_data.variant_exist) {
            if (productDetail.quantity_exist) {
                var calculatePrice = itemDetail.addon_price * itemDetail.quantity;
                return `RM${calculatePrice.toFixed(2)}`;
            } else if (!productDetail.quantity_exist) {
                return `RM${itemDetail.addon_price.toFixed(2)}`;
            }
        } else if (category_data.variant_exist) {
            if (productDetail.quantity_exist) {
                var calculatePrice = itemDetail.chosen_variant_detail?.price * itemDetail.quantity;
                return (
                    itemDetail.chosen_variant_detail !== undefined &&
                    `RM${calculatePrice.toFixed(2)}`
                );
            } else if (!productDetail.quantity_exist) {
                return (
                    itemDetail.chosen_variant_detail !== undefined &&
                    `RM${parseFloat(itemDetail?.chosen_variant_detail?.price).toFixed(2)}`
                );
            }
        }
    };

    function renderItemCard() {
        const SelectNoQuantityCard = (category_data, categories_index, data_index) => {
            if (!category_data.quantity_exist) {
                var newSelectedItems = [...selectedItems];
                var currentItemDetail = newSelectedItems[categories_index].items[data_index];
                currentItemDetail.checked = !currentItemDetail.checked;
                newSelectedItems[categories_index].items[data_index] = currentItemDetail;
                setSelectedItems(newSelectedItems);
                sessionStorage.setItem("selected_items", JSON.stringify(newSelectedItems));
            }
        };
        const checkCategoryInRestriction = (category) => {
            // if current category is in restriction
            var categoryHasRestriction = false;
            if (productDetail.quantity_restrictions.category !== null) {
	    	productDetail.quantity_restrictions.category.forEach((cat) => {
                	if (category.name === cat.category) {
                    	categoryHasRestriction = true;
                	}
            	});
	    }
            return categoryHasRestriction;
        };
        return productDetail.categories_item.categories.map((category, categories_index) => {
            return (
                <>
                    {category.items.length > 0 && (
                        <>
                            <h3 className="pl-0" style={{ fontWeight: "400" }}>
                                {category.name}
                            </h3>
                            {productDetail.quantity_restrictions?.apply_to === "all"
                                ? null
                                : productDetail.quantity_restrictions_exist &&
                                  checkCategoryInRestriction(category) &&
                                  category.items.length > 0 && (
                                      <>
                                          <p className="pl-0">
                                              Choose{" "}
                                              {selectionRestriction(
                                                  category.name,
                                                  categories_index
                                              )}{" "}
                                              items
                                          </p>{" "}
                                          {(itemValidation.index.includes(categories_index) ||
                                              !itemValidation.valid) && (
                                              <div
                                                  className="d-flex align-items-center p-1"
                                                  style={{
                                                      backgroundColor: "#E86F7D",
                                                      color: "white",
                                                      maxWidth: "fit-content",
                                                  }}
                                              >
                                                  <ErrorIcon />
                                                  <p className="pl-3">
                                                      You can only choose{" "}
                                                      {selectionRestriction(
                                                          category.name,
                                                          categories_index
                                                      )}{" "}
                                                      items in this category
                                                  </p>
                                              </div>
                                          )}
                                      </>
                                  )}
                        </>
                    )}
                    {/*Rows of three cards */}
                    <Row className="pb-4 pt-3 pl-0 pr-0">
                        {selectedItems &&
                            selectedItems.length > 0 &&
                            category.items.map((category_data, data_index) => {
                                return (
                                    <Col
                                        style={{
                                            paddingTop:
                                                window.innerWidth < 991 && data_index > 0 && "1em",
                                        }}
                                        lg={4}
                                        className="p-1"
                                    >
                                        <Card
                                            style={{
                                                border:
                                                    (selectedItems[categories_index].items[
                                                        data_index
                                                    ].quantity >= 1 ||
                                                        selectedItems[categories_index].items[
                                                            data_index
                                                        ].checked) &&
                                                    "4px solid black",
                                                borderRadius: "6px",
                                            }}
                                            className="p-0 card-design"
                                        >
                                            <Carousel
                                                onClick={() =>
                                                    SelectNoQuantityCard(
                                                        category_data,
                                                        categories_index,
                                                        data_index
                                                    )
                                                }
                                                interval={null}
                                            >
                                                {renderCarouselItem(category_data)}
                                            </Carousel>

                                            <Card.Body>
                                                <Row
                                                    onClick={() =>
                                                        SelectNoQuantityCard(
                                                            category_data,
                                                            categories_index,
                                                            data_index
                                                        )
                                                    }
                                                >
                                                    <Card.Title>
                                                        {category_data.addon_name}
                                                    </Card.Title>
                                                </Row>
                                                <Row
                                                    onClick={() =>
                                                        SelectNoQuantityCard(
                                                            category_data,
                                                            categories_index,
                                                            data_index
                                                        )
                                                    }
                                                >
                                                    <Card.Text>
                                                        {category_data.addon_desc}
                                                    </Card.Text>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    {category_data.variant_exist && (
                                                        <Col lg={5} md={5}>
                                                            <Form.Select
                                                                id="scroll-variant"
                                                                onChange={(e) => {
                                                                    var newSelectedItems = [
                                                                        ...selectedItems,
                                                                    ];
                                                                    var currentVariantDetail =
                                                                        newSelectedItems[
                                                                            categories_index
                                                                        ].items[data_index];
                                                                    currentVariantDetail.chosen_variant_detail =
                                                                        currentVariantDetail.variant_detail.filter(
                                                                            (detail) =>
                                                                                detail.variant ===
                                                                                e.target.value
                                                                        )[0];

                                                                    if (
                                                                        !productDetail.quantity_exist
                                                                    ) {
                                                                        currentVariantDetail.checked = true;
                                                                    }
                                                                    newSelectedItems[
                                                                        categories_index
                                                                    ].items[data_index] =
                                                                        currentVariantDetail;
                                                                    setSelectedItems(
                                                                        newSelectedItems
                                                                    );
                                                                    sessionStorage.setItem(
                                                                        "selected_items",
                                                                        JSON.stringify(
                                                                            newSelectedItems
                                                                        )
                                                                    );
                                                                }}
                                                                value={
                                                                    selectedItems[categories_index]
                                                                        .items[data_index]
                                                                        .chosen_variant_detail
                                                                        ?.variant
                                                                }
                                                                isInvalid={
                                                                    attributeValidation.variant
                                                                        .index.length > 0 &&
                                                                    attributeValidation.variant.category.includes(
                                                                        categories_index
                                                                    ) &&
                                                                    attributeValidation.variant.index[
                                                                        categories_index
                                                                    ].includes(data_index)
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                <option value=""></option>
                                                                {category_data.variant_detail.map(
                                                                    (detail) => {
                                                                        return (
                                                                            <option
                                                                                value={
                                                                                    detail.variant
                                                                                }
                                                                            >
                                                                                {detail.variant}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </Form.Select>
                                                            <Form.Control.Feedback type="invalid">
                                                                {
                                                                    attributeValidation.variant
                                                                        .message
                                                                }
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    )}
                                                    {productDetail.quantity_exist && (
                                                        <Col
                                                            lg={6}
                                                            md={6}
                                                            style={{
                                                                flexDirection: "row",
                                                                display: "flex",
                                                                height: "fit-content",
                                                            }}
                                                        >
                                                            <Button
                                                                onClick={() => {
                                                                    var newSelectedItems = [
                                                                        ...selectedItems,
                                                                    ];
                                                                    var currentQuantity =
                                                                        newSelectedItems[
                                                                            categories_index
                                                                        ].items[data_index]
                                                                            .quantity;
                                                                    if (currentQuantity === 0) {
                                                                        // set checked to false
                                                                        newSelectedItems[
                                                                            categories_index
                                                                        ].items[
                                                                            data_index
                                                                        ].checked = false;
                                                                        newSelectedItems[
                                                                            categories_index
                                                                        ].items[
                                                                            data_index
                                                                        ].quantity = 0;
                                                                    } else {
                                                                        newSelectedItems[
                                                                            categories_index
                                                                        ].items[data_index]
                                                                            .quantity--;
                                                                    }
                                                                    setSelectedItems(
                                                                        newSelectedItems
                                                                    );
                                                                    sessionStorage.setItem(
                                                                        "selected_items",
                                                                        JSON.stringify(
                                                                            newSelectedItems
                                                                        )
                                                                    );
                                                                    console.log(
                                                                        newSelectedItems,
                                                                        "lol"
                                                                    );
                                                                }}
                                                                min={0}
                                                                style={{
                                                                    border: "1px solid black",
                                                                }}
                                                                className="m-0 quantity-minus-button"
                                                            >
                                                                -
                                                            </Button>
                                                            <Form.Control
                                                                value={
                                                                    selectedItems[categories_index]
                                                                        .items[data_index].quantity
                                                                }
                                                                className="quantity-input-button text-center"
                                                            />
                                                            <Button
                                                                onClick={() => {
                                                                    const newSelectedItems = [
                                                                        ...selectedItems,
                                                                    ];

                                                                    newSelectedItems[
                                                                        categories_index
                                                                    ].items[
                                                                        data_index
                                                                    ].quantity += 1;

                                                                    if (
                                                                        newSelectedItems[
                                                                            categories_index
                                                                        ].items[data_index]
                                                                            .quantity > 0
                                                                    ) {
                                                                        newSelectedItems[
                                                                            categories_index
                                                                        ].items[
                                                                            data_index
                                                                        ].checked = true;
                                                                    }
                                                                    setSelectedItems(
                                                                        newSelectedItems
                                                                    );
                                                                    sessionStorage.setItem(
                                                                        "selected_items",
                                                                        JSON.stringify(
                                                                            newSelectedItems
                                                                        )
                                                                    );
                                                                    console.log(
                                                                        newSelectedItems,
                                                                        "lol"
                                                                    );
                                                                }}
                                                                className="m-0 quantity-plus-button"
                                                                style={{
                                                                    border: "1px solid black",
                                                                }}
                                                            >
                                                                +
                                                            </Button>
                                                        </Col>
                                                    )}
                                                </Row>

                                                <Row
                                                    onClick={() =>
                                                        SelectNoQuantityCard(
                                                            category_data,
                                                            categories_index,
                                                            data_index
                                                        )
                                                    }
                                                    className="pt-3"
                                                >
                                                    <Card.Title>
                                                        {(category_data.variant_exist ||
                                                            category_data.quantity > 0 ||
                                                            category_data.checked) &&
                                                            renderItemPrice(
                                                                categories_index,
                                                                data_index,
                                                                category_data
                                                            )}
                                                    </Card.Title>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                );
                            })}

                        {/* <Col></Col> */}
                    </Row>{" "}
                    {/* {console.log(itemValidation, "item validation in item selection")} */}
                    {/* index is for multiple constraints (individual) and valid is for single constraint (all) */}
                </>
            );
        });
    }

    return (
        <>
            <Row className="pt-3" id="scroll-here">
                <Card
                    className="p-1 m-auto"
                    style={{
                        display: "flex",
                        flexDirection: window.innerWidth < 991 ? "column" : "row",
                        textAlign: "left",
                    }}
                >
                    <Col>
                        {productDetail.plan_image.length > 0 ? (
                            <Carousel
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                    verticalAlign: "center",
                                }}
                                // style={{ maxHeight: "300px", minHeight: "300px" }}
                                interval={null}
                            >
                                {productDetail.plan_image.map((image, index) => {
                                    return (
                                        <Carousel.Item key={index}>
                                            <Image
                                                style={{
                                                    width:
                                                        window.innerWidth < 991
                                                            ? "inherit"
                                                            : "32vw",
                                                    borderRadius:
                                                        window.innerWidth < 991
                                                            ? "0.5rem"
                                                            : "0.5rem 0 0 0.5rem",
                                                }}
                                                src={renderImagePath(image.image_name)}
                                                onClick={() => {
                                                    // if quantity does not exist, allow click and bordered
                                                    // need to check value as well
                                                }}
                                                alt="image"
                                            />
                                        </Carousel.Item>
                                    );
                                })}{" "}
                            </Carousel>
                        ) : (
                            <Card.Img
                                variant="top"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "37vw",
                                    borderRadius: "0 0 0.5rem 0.5rem",
                                }}
                                src={no_image}
                            />
                        )}
                    </Col>
                    <Card.Body className="">
                        {productDetail?.product_tags && (
                            <Col>{renderProductTags(productDetail.product_tags)}</Col>
                        )}
                        <Row>
                            <h1 style={{ fontWeight: "bolder" }}>{productDetail.plan_name}</h1>
                        </Row>
                        <Row>
                            <Form.Label style={{ fontWeight: "500" }} className="w-100">
                                {/* Next Available Date: June 2nd, 2022 */}
                                {nextAvailableDate()}
                            </Form.Label>
                        </Row>
                        <Row className="pt-2">
                            {optionValidation ? (
                                <Form.Label className="w-100">Purchase Options</Form.Label>
                            ) : (
                                <Form.Label className="w-100 error-field">
                                    Please choose a purchase option
                                </Form.Label>
                            )}
                            {productDetail.purchase_option.one_off && (
                                <Col lg={5} md={6}>
                                    <Button
                                        onClick={() => {
                                            setPurchaseOption("one_off");
                                            setOptionValidation(true);
                                            sessionStorage.setItem(
                                                "purchase_option",
                                                JSON.stringify("one_off")
                                            );
                                        }}
                                        className={`${
                                            optionValidation
                                                ? "default-purchase-options"
                                                : "purchase-options"
                                        } w-100 ${
                                            purchaseOption === "one_off"
                                                ? "selected-purchase-options"
                                                : ""
                                        }`}
                                    >
                                        ONE-TIME PURCHASE
                                    </Button>
                                </Col>
                            )}
                            {productDetail.purchase_option.recurring && (
                                <Col lg={5} md={6}>
                                    <Button
                                        onClick={() => {
                                            setPurchaseOption("recurring");
                                            setOptionValidation(true);
                                            sessionStorage.setItem(
                                                "purchase_option",
                                                JSON.stringify("recurring")
                                            );
                                        }}
                                        className={`${
                                            optionValidation
                                                ? "default-purchase-options"
                                                : "purchase-options"
                                        } w-100 ${
                                            purchaseOption === "recurring"
                                                ? "selected-purchase-options"
                                                : ""
                                        }`}
                                    >
                                        SUBSCRIPTION
                                    </Button>
                                </Col>
                            )}
                            <Col lg={10}>
                                <hr />
                            </Col>
                        </Row>

                        <Row>
                            <p style={{ fontWeight: "300", textAlign: "justify" }}>
                                {productDetail.plan_desc}
                            </p>
                        </Row>
                        <Row style={{ padding: "5em 0 0 0 " }}>
                            <h3 style={{ fontWeight: "bold" }}>
                                {productDetail.pricing_model === "flat_fee" &&
                                    `RM${productDetail.plan_price}${
                                        productDetail.subs_cycle_unit === "month" ? "/month" : ""
                                    }${productDetail.subs_cycle_unit === "year" ? "/year" : ""}`}
                            </h3>
                        </Row>
                    </Card.Body>
                </Card>
            </Row>

            {productDetail.item_exist && (
                <Row className="text-left mt-3 pt-2 pl-2">
                    {productDetail.quantity_restrictions?.apply_to === "all" && (
                        <h2 className="pl-0 " style={{ fontWeight: "700" }}>
                            Choose {productDetail.quantity_restrictions.category[0].number} items
                            across all categories
                        </h2>
                    )}
                    {!itemValidation.valid && (
                        <div
                            className="d-flex align-items-center p-1 pr-3"
                            style={{
                                backgroundColor: "#E86F7D",
                                color: "white",
                                maxWidth: "fit-content",
                            }}
                        >
                            <ErrorIcon />
                            <p className="pl-3">
                                You can only choose{" "}
                                {productDetail.quantity_restrictions.category[0].number} across all
                                categories
                            </p>
                        </div>
                    )}
                    {productDetail && renderItemCard()}
                </Row>
            )}
        </>
    );
};

export default ItemSelection;
