import { Button } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useEffect, useLayoutEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import { timeFromInt } from "time-number";
import SelectMultiple from "../../../../../Components/SelectMultiple/SelectMultiple.js";
import { store } from "../../../../../index.js";
import { useParams } from "react-router-dom";
import DeliveryWindow from "../../../../../Components/DeliveryWindow/deliveryWindow";
import "../style.css";
import { FormHelperText, MenuItem, Select } from "@material-ui/core";

const DateStep = ({ productData, validation, handleValidation }) => {
    const animatedComponents = makeAnimated();

    const [requireDate, setRequireDate] = useState(
        sessionStorage.getItem("require_date") === null
            ? false
            : sessionStorage.getItem("require_date")
    );
    const [dateFieldFormat, setDateFieldFormat] = useState(
        JSON.parse(sessionStorage.getItem("date_field_format")) === null
            ? "DD/MM/YY"
            : JSON.parse(sessionStorage.getItem("date_field_format"))
    );
    const [dateSectionName, setDateSectionName] = useState(
        JSON.parse(sessionStorage.getItem("date_section_name")) !== null
            ? JSON.parse(sessionStorage.getItem("date_section_name"))
            : "Delivery date"
    );
    const [otherDateSectionName, setOtherDateSectionName] = useState(
        JSON.parse(sessionStorage.getItem("other_date_section_name")) !== null
            ? JSON.parse(sessionStorage.getItem("other_date_section_name"))
            : ""
    );
    const [deliverable, setDeliverable] = useState(
        JSON.parse(sessionStorage.getItem("require_delivery")) !== null
            ? JSON.parse(sessionStorage.getItem("require_delivery"))
            : false
    );
    const [deliveryFrequency, setDeliveryFrequency] = useState(
        JSON.parse(sessionStorage.getItem("delivery_frequency")) !== null
            ? JSON.parse(sessionStorage.getItem("delivery_frequency"))
            : [{ value: "every_month", label: "Every month" }]
    );
    const [leadTime, setLeadTime] = useState(
        JSON.parse(sessionStorage.getItem("lead_time")) !== null
            ? JSON.parse(sessionStorage.getItem("lead_time"))
            : 4
    );
    const [openDeliveryModal, setOpenDeliveryModal] = useState(false);

    const [deliveryWindowId, setDeliveryWindowId] = useState(
        JSON.parse(sessionStorage.getItem("delivery_window_id")) !== null
            ? JSON.parse(sessionStorage.getItem("delivery_window_id"))
            : undefined
    );

    const [deliveryWindowTime, setDeliveryWindowTime] = useState(
        JSON.parse(sessionStorage.getItem("delivery_window_time")) !== null
            ? JSON.parse(sessionStorage.getItem("delivery_window_time"))
            : {
                  show: false,
                  value: [
                      // ["2018-07-25 20:23:22", new Date()],
                  ],
              }
    );
    const [deliveryDaySelection, setDeliveryDaySelection] = useState(
        JSON.parse(sessionStorage.getItem("delivery_window_day")) !== null
            ? JSON.parse(sessionStorage.getItem("delivery_window_day"))
            : {
                  value: {
                      Sunday: false,
                      Monday: false,
                      Tuesday: false,
                      Wednesday: false,
                      Thursday: false,
                      Friday: false,
                      Saturday: false,
                  },
              }
    );
    const [daysEditable, setDaysEditable] = useState(
        JSON.parse(sessionStorage.getItem("days_editable")) !== null
            ? JSON.parse(sessionStorage.getItem("days_editable"))
            : 0
    );
    const [allowSubscriptionEdit, setAllowSubscriptionEdit] = useState(
        JSON.parse(sessionStorage.getItem("allow_subs_edit")) !== null
            ? JSON.parse(sessionStorage.getItem("allow_subs_edit"))
            : false
    );
    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };
    const allDeliveryFrequency = [
        { value: "all_at_once", label: "All at once" },
        { value: "daily", label: "Daily" },
        { value: "every_week", label: "Every week" },
        { value: "every_two_weeks", label: "Every 2 weeks" },
        { value: "every_month", label: "Every month" },
        { value: "every_6_months", label: "Every 6 months" },
        { value: "every_year", label: "Every year" },
    ];
    const MultiValue = (props) => (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    );

    const handleSetOpenDeliveryModal = () => {
        setOpenDeliveryModal(!openDeliveryModal);
    };
    const handleDeliveryWindowId = (value) => {
        setDeliveryWindowId(value);
        sessionStorage.setItem("delivery_window_id", value);
    };
    const handleDeliveryWindowTime = (value) => {
        setDeliveryWindowTime(value);
        sessionStorage.setItem("delivery_window_time", JSON.stringify(value));
    };
    const handleDeliveryDaySelection = (value) => {
        setDeliveryDaySelection(value);
        sessionStorage.setItem("delivery_window_day", JSON.stringify(value));
    };
    const deliveryDaysRender = () => {
        // return array of the selected days
        // if true return the days which will be rendered
        const filterSelectedDays = Object.keys(deliveryDaySelection.value).filter(
            (obj) => deliveryDaySelection.value[obj] == true
        );
        if (filterSelectedDays.length > 0) {
            return "Every " + filterSelectedDays.join(", ");
        }
    };
    const deliveryTimeRender = () => {
        // render one p tag for every value
        var tempWindowTime = [];
        console.log(deliveryWindowTime, "this is delivery window time");
        if (deliveryWindowId === undefined || deliveryWindowTime.value.length === 0) {
            return "No time slot selected yet";
        } else {
            deliveryWindowTime.value.map((e) => {
                if (e.start_time === 86400) {
                    e.start_time = 0;
                }
                if (e.end_time === 86400) {
                    e.end_time = 0;
                }
                const startTime = timeFromInt(e.start_time, { format: 12 });
                const endTime = timeFromInt(e.end_time, { format: 12 });
                const timeRender = (
                    <>
                        <p className="m-0">
                            {startTime} to {endTime}
                        </p>
                    </>
                );
                tempWindowTime.push(timeRender);
            });
            return tempWindowTime;
        }
    };
    const params = useParams();

    useEffect(() => {
        console.log(productData, "this is the product data in date step");
        if (productData !== undefined && params.id !== "new") {
            if (productData.date_format !== "") {
                setRequireDate(productData.date_exist);
                setDateFieldFormat(productData.date_format);
                setDateSectionName(productData.date_usage);
                setLeadTime(productData.date_lead_time);
                setDeliverable(productData.is_deliverable);
                setDeliveryFrequency(productData.delivery_frequency);
                setDaysEditable(productData.days_before_edit);
                setAllowSubscriptionEdit(productData.allow_subscription_edit);
                // need to grab window id
                setDeliveryWindowId(
                    productData.delivery_window !== null
                        ? productData.delivery_window.delivery_window_id
                        : undefined
                );
                setDeliveryWindowTime(
                    productData.delivery_window !== null
                        ? { value: productData.delivery_window.time }
                        : { value: [] }
                );
                setDeliveryDaySelection({
                    value:
                        productData.delivery_window !== null
                            ? productData.delivery_window.day
                            : {
                                  Sunday: false,
                                  Monday: false,
                                  Tuesday: false,
                                  Wednesday: false,
                                  Thursday: false,
                                  Friday: false,
                                  Saturday: false,
                              },
                });

                sessionStorage.setItem("require_date", productData.date_exist);
                sessionStorage.setItem(
                    "date_field_format",
                    JSON.stringify(productData.date_format)
                );
                sessionStorage.setItem("date_section_name", JSON.stringify(productData.date_usage));
                sessionStorage.setItem("lead_time", JSON.stringify(productData.date_lead_time));
                productData.is_deliverable !== null &&
                    sessionStorage.setItem(
                        "require_delivery",
                        JSON.stringify(productData.is_deliverable)
                    );
                sessionStorage.setItem(
                    "delivery_frequency",
                    JSON.stringify(productData.delivery_frequency)
                );
                productData.delivery_window !== null &&
                    sessionStorage.setItem(
                        "delivery_window_id",
                        JSON.stringify(productData.delivery_window.delivery_window_id)
                    );
                // sessionStorage.setItem(
                //     "delivery_window_id",
                //     JSON.stringify(productData.delivery_window_id)
                // );
                // sessionStorage.setItem(
                //     "delivery_window_time",
                //     JSON.stringify(productData.delivery_window.time)
                // );
                // sessionStorage.setItem(
                //     "delivery_window_day",
                //     JSON.stringify(productData.delivery_window.day)
                // );
            } else if (productData.date_format === "") {
                setRequireDate(false);
            }
        }
    }, [productData]);
    useEffect(() => {
        if (JSON.parse(sessionStorage.getItem("date_section_name")) === null) {
            sessionStorage.setItem("date_section_name", JSON.stringify(dateSectionName));
        }
        if (JSON.parse(sessionStorage.getItem("delivery_frequency")) === null) {
            sessionStorage.setItem("delivery_frequency", JSON.stringify(deliveryFrequency));
        }
        if (JSON.parse(sessionStorage.getItem("date_field_format")) === null) {
            sessionStorage.setItem("date_field_format", JSON.stringify(dateFieldFormat));
        }
        if (JSON.parse(sessionStorage.getItem("require_date")) === null) {
            sessionStorage.setItem("require_date", JSON.stringify(requireDate));
        }
        if (JSON.parse(sessionStorage.getItem("require_delivery")) === null) {
            sessionStorage.setItem("require_delivery", JSON.stringify(deliverable));
        }
    }, []);
    useEffect(() => {
        var sectionNameValid = true;
        // var deliveryWindowValid = false;
        if (dateSectionName !== "" || dateSectionName !== null) {
            sectionNameValid = false;
        }
        // if (deliveryWindowId === undefined) {
        //     deliveryWindowValid = true;
        // }
        // console.log(deliveryWindowValid, "this is delivery window valid");
        handleValidation({
            section_name: {
                ...validation.section_name,
                invalid: sectionNameValid,
            },
            // delivery_window: {
            //     ...validation.delivery_window,
            //     invalid: deliveryWindowValid,
            // },
        });
    }, [dateSectionName]);

    const deliveryFrequencyOptions = [
        "All at once",
        "Daily",
        "Every week",
        "Every 2 weeks",
        "Every month",
        "Every 3 months",
        "Every 6 months",
        "Every year",
    ];

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
        },
    });

    useLayoutEffect(() => {
        const section = document.getElementById("scroll-here");
        section.scrollIntoView({ behavior: "smooth", block: "start" });
    }, []);

    return (
        <div className="step-container slide-right" id="scroll-here">
            <h4 style={{ fontWeight: "500" }}>Date Configuration</h4>
            {/* <h5 style={{ fontWeight: "450", paddingTop: ".8em" }}>Date Picker</h5> */}
            <Form.Group className="form-group required d-flex">
                <Form.Label className="pt-2 control-label">
                    Does this product require customers to select a date?
                    <CustomWidthTooltip
                        arrow
                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    border: "solid 1px",
                                    backgroundColor: "#f5f5f5",
                                    borderRadius: "6px",
                                    borderColor: "black",
                                    color: "black",
                                },
                            },
                        }}
                        title={
                            <p>
                                Examples of a date include delivery date, subscription start date,
                                etc.
                            </p>
                        }
                        placement="right"
                    >
                        <InfoIcon className=" ml-1 mb-1" />
                    </CustomWidthTooltip>
                </Form.Label>{" "}
            </Form.Group>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Form.Check
                    checked={requireDate}
                    onChange={() => {
                        setRequireDate(true);
                        sessionStorage.setItem("require_date", true);
                    }}
                    type={"radio"}
                    label={"Yes"}
                />
                <Form.Check
                    checked={!requireDate}
                    onChange={() => {
                        setRequireDate(false);
                        sessionStorage.setItem("require_date", false);
                    }}
                    className="ml-4"
                    type={"radio"}
                    label={"No"}
                />
            </div>
            {requireDate ? (
                <>
                    <Form.Group className="form-group required">
                        <Form.Label className="pt-2 control-label">Date Format</Form.Label>
                    </Form.Group>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {" "}
                        <Form.Check
                            checked={dateFieldFormat === "MM/YY"}
                            onChange={() => {
                                setDateFieldFormat("MM/YY");
                                sessionStorage.setItem(
                                    "date_field_format",
                                    JSON.stringify("MM/YY")
                                );
                            }}
                            type={"radio"}
                            label={"MM/YY"}
                        />
                        <Form.Check
                            className="ml-4"
                            checked={dateFieldFormat === "DD/MM/YY"}
                            onChange={() => {
                                setDateFieldFormat("DD/MM/YY");
                                sessionStorage.setItem(
                                    "date_field_format",
                                    JSON.stringify("DD/MM/YY")
                                );
                            }}
                            type={"radio"}
                            label={"DD/MM/YY"}
                        />
                    </div>
                    <Form.Group className="mb-3 form-group required">
                        <Form.Label className="pt-3 control-label">
                            What is the use of this date?
                            <CustomWidthTooltip
                                arrow
                                PopperProps={{
                                    sx: {
                                        "& .MuiTooltip-tooltip": {
                                            border: "solid 1px",
                                            backgroundColor: "#f5f5f5",
                                            borderRadius: "6px",
                                            borderColor: "black",
                                            color: "black",
                                        },
                                    },
                                }}
                                title={
                                    <p>
                                        Your selection here will determine what your customers see
                                        above the date picker/calendar during checkout when they
                                        have to select a date. <br />
                                        <br />
                                        Example: Class Commencement Date, Lease Start Date,
                                        Membership Payment Date, etc.
                                    </p>
                                }
                                placement="right"
                            >
                                <InfoIcon className="ml-2 mb-1" />
                            </CustomWidthTooltip>
                        </Form.Label>

                        <InputGroup className="" hasValidation>
                            <Form.Select
                                value={dateSectionName}
                                onChange={(title) => {
                                    setDateSectionName(title.target.value);
                                    sessionStorage.setItem(
                                        "date_section_name",
                                        JSON.stringify(title.target.value)
                                    );
                                }}
                                type="select"
                                placeholder=""
                                isInvalid={validation.section_name.invalid}
                                required
                            >
                                <option value="delivery_date">Delivery Date</option>
                                <option value="subs_start_date">Subscription Start Date</option>
                                <option value="others">Others</option>
                            </Form.Select>
                            {dateSectionName === "others" && (
                                <Form.Control
                                    placeholder="Please input your preference"
                                    className="w-100 mt-3"
                                    style={{ borderRadius: "4px" }}
                                    value={otherDateSectionName}
                                    onChange={(e) => {
                                        setOtherDateSectionName(e.target.value);
                                        sessionStorage.setItem(
                                            "other_date_section_name",
                                            JSON.stringify(e.target.value)
                                        );
                                    }}
                                />
                            )}
                            <Form.Control.Feedback type="invalid">
                                {validation.section_name.error}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>{" "}
                    <Form.Group className=" form-group required">
                        <Form.Label className="control-label">
                            How many {`${dateFieldFormat === "MM/YY" ? "months" : "days"}`} are
                            required to fulfill a customer’s order?
                            <CustomWidthTooltip
                                arrow
                                PopperProps={{
                                    sx: {
                                        "& .MuiTooltip-tooltip": {
                                            border: "solid 1px",
                                            backgroundColor: "#f5f5f5",
                                            borderRadius: "6px",
                                            borderColor: "black",
                                            color: "black",
                                        },
                                    },
                                }}
                                title={
                                    <p>
                                        This will determine the first date a customer can select.
                                        <br />
                                        For example, if 4 is inserted, then the next date a customer
                                        can select is 4 days after today.
                                        <br />
                                        If fulfillment is immediate, input 0.
                                    </p>
                                }
                                placement="right"
                            >
                                <InfoIcon className="ml-2 mb-1" />
                            </CustomWidthTooltip>
                        </Form.Label>{" "}
                    </Form.Group>
                    <Form.Control
                        value={leadTime}
                        onChange={(day) => {
                            setLeadTime(day.target.value);
                            sessionStorage.setItem("lead_time", JSON.stringify(day.target.value));
                        }}
                        placeholder=""
                        type="number"
                    />
                    <h5 style={{ fontWeight: "450", paddingTop: "3em" }}>Delivery Configuration</h5>
                    <Form.Group className="form-group required">
                        <Form.Label className="pt-2 control-label">
                            Does the product require physical delivery?
                        </Form.Label>
                    </Form.Group>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {" "}
                        <Form.Check
                            checked={deliverable}
                            onChange={() => {
                                setDeliverable(true);
                                sessionStorage.setItem("require_delivery", true);
                            }}
                            type={"radio"}
                            label={"Yes"}
                        />
                        <Form.Check
                            checked={!deliverable}
                            onChange={() => {
                                setDeliverable(false);
                                sessionStorage.setItem("require_delivery", false);
                            }}
                            className="ml-4"
                            type={"radio"}
                            label={"No"}
                        />
                    </div>
                    {deliverable ? (
                        <>
                            <Form.Group className="form-group required">
                                <Form.Label className="pt-3 control-label">
                                    Delivery Frequency
                                </Form.Label>
                            </Form.Group>
                            <SelectMultiple
                                options={allDeliveryFrequency}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                value={deliveryFrequency}
                                components={{ Option, MultiValue, animatedComponents }}
                                onChange={(select) => {
                                    setDeliveryFrequency(select);
                                    sessionStorage.setItem(
                                        "delivery_frequency",
                                        JSON.stringify(select)
                                    );
                                }}
                            />

                            {deliveryFrequency.length === 0 && (
                                <FormHelperText style={{ color: "red", fontSize: " 0.875em" }}>
                                    Please select at least one delivery frequency
                                </FormHelperText>
                            )}
                            {dateFieldFormat === "DD/MM/YY" && (
                                <>
                                    <Form.Label className="pt-3">Delivery Window</Form.Label>
                                    <Row
                                        style={{
                                            borderStyle: "solid",
                                            borderRadius: "6px",
                                            margin: ".1em",
                                            borderColor: validation.delivery_window.invalid
                                                ? "red"
                                                : store.getState().colorState.color,
                                        }}
                                    >
                                        <Row
                                            style={{
                                                backgroundColor: validation.delivery_window.invalid
                                                    ? "red"
                                                    : store.getState().colorState.color,
                                                color: store.getState().colorState.font_color,
                                                marginLeft: "0",
                                                padding: ".5em",
                                            }}
                                        >
                                            <Col lg={7} xs={6}>
                                                Day(s)
                                            </Col>
                                            <Col lg={5} xs={6}>
                                                Time(s)
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{
                                                marginLeft: "0",
                                                padding: ".5em",
                                                paddingTop: "1em",
                                                paddingBottom: "1em",
                                            }}
                                        >
                                            <Col lg={7} xs={6}>
                                                {deliveryWindowId !== undefined
                                                    ? deliveryDaysRender()
                                                    : "No day slot selected yet"}
                                            </Col>
                                            <Col lg={5} xs={6}>
                                                {deliveryTimeRender()}
                                            </Col>
                                        </Row>
                                    </Row>
                                    {validation.delivery_window.invalid && (
                                        <FormHelperText
                                            style={{ color: "red", fontSize: " 0.875em" }}
                                        >
                                            Please create a delivery window
                                        </FormHelperText>
                                    )}
                                    <Button
                                        startIcon={<EditIcon />}
                                        style={{
                                            backgroundColor: store.getState().colorState.color,
                                            borderColor: store.getState().colorState.color,
                                            color: store.getState().colorState.font_color,
                                            borderRadius: "6px",
                                            textTransform: "none",
                                        }}
                                        onClick={() => {
                                            setOpenDeliveryModal(true);
                                        }}
                                        variant="contained"
                                        color="primary"
                                        component="label"
                                        className="mt-2"
                                    >
                                        {`${deliveryWindowId !== undefined ? "Edit " : "Create "}`}
                                        Delivery Window
                                    </Button>
                                    <DeliveryWindow
                                        openDeliveryModal={openDeliveryModal}
                                        deliveryWindowId={deliveryWindowId}
                                        deliveryWindowTime={deliveryWindowTime}
                                        deliveryDaySelection={deliveryDaySelection}
                                        handleSetOpenDeliveryModal={handleSetOpenDeliveryModal}
                                        handleDeliveryWindowId={handleDeliveryWindowId}
                                        handleDeliveryWindowTime={handleDeliveryWindowTime}
                                        handleDeliveryDaySelection={handleDeliveryDaySelection}
                                    />{" "}
                                </>
                            )}
                        </>
                    ) : null}
                    <Form.Group className="form-group required">
                        <Form.Label className="pt-3 control-label">
                            Are customers allowed to change the delivery address before an order is
                            delivered?
                        </Form.Label>
                    </Form.Group>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {" "}
                        <Form.Check
                            checked={allowSubscriptionEdit}
                            onChange={() => {
                                setAllowSubscriptionEdit(true);
                                sessionStorage.setItem("allow_subs_edit", true);
                            }}
                            type={"radio"}
                            label={"Yes"}
                        />
                        <Form.Check
                            checked={!allowSubscriptionEdit}
                            onChange={() => {
                                setAllowSubscriptionEdit(false);
                                sessionStorage.setItem("allow_subs_edit", false);
                            }}
                            className="ml-4"
                            type={"radio"}
                            label={"No"}
                        />
                    </div>
                    {allowSubscriptionEdit && (
                        <>
                            <Form.Group className="form-group required">
                                <Form.Label className="pt-2 control-label">
                                    How many days before delivery can the delivery address be
                                    changed?
                                </Form.Label>
                            </Form.Group>
                            <Form.Control
                                value={daysEditable}
                                onChange={(day) => {
                                    setDaysEditable(day.target.value);
                                    sessionStorage.setItem(
                                        "days_editable",
                                        JSON.stringify(day.target.value)
                                    );
                                }}
                                placeholder=""
                                type="number"
                            />
                        </>
                    )}
                </>
            ) : null}
        </div>
    );
};

export default DateStep;
