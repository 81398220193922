import { Modal } from "react-bootstrap";
import { Grid } from "@material-ui/core";
import { Form } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import subscriptionService from "../../services/subscriptionService";
import Swal from "sweetalert2";
import { store } from "../../index.js";

import { useState } from "react";
const UnsubscribeSurvey = ({ subsId, modalState, closeModal }) => {
    const [cancellationReason, setCancellationReason] = useState({value: "", invalid: false, error: "Please enter a reason."});
    const [checkedOption, setCheckedOption] = useState();

    const updateSubsStatus = () => {
        const dataBody = {
            subs_status: "awaiting cancellation",
            additional_comment: cancellationReason.value,
        };

        subscriptionService
            .editSubscription(subsId, dataBody)
            .then((e) => {
                Swal.fire({
                    icon: "success",
                    text: "An email has been sent to the admin and we will contact you shortly.",
                    confirmButtonText: "OK",
                    confirmButtonColor: store.getState().colorState.color,
                })
                    .then((res) => {
                        window.location.reload();
                    })
                    .catch((err) => {});
            })
            .catch((e) => {
                console.error(e, "erro");
            });
    };

    return (
        <Modal
            size="md"
            dialogClassName="custom-modal"
            centered
            show={modalState}
            onHide={closeModal}
        >
            <Modal.Body>
                <Grid container className="m-2">
                    <Grid item xs={12} lg={12}>
                        <strong style={{ fontSize: "25px" }}>We're sad to see you go!</strong>
                    </Grid>
                    <Grid item className="mt-3 form-group required" xs={12} lg={12}>
                        <p className="control-label" style={{ fontSize: "15px" }}>
                            Please tell us why you're unsubscribing from us.
                        </p>
                    </Grid>

                    <Grid
                        item
                        className="mt-3 mr-5"
                        style={{ marginRight: "40px" }}
                        xs={11}
                        lg={12}
                    >
                        <Form.Group className="mb-3">
                            <Form.Control
                                style={{
                                    borderRadius: "5px",
                                    border: "1px solid black",
                                }}
                                placeholder="I'm unsubscribing because..."
                                as="textarea"
                                onChange={(e) => {
                                    setCancellationReason({
					...cancellationReason,
					value: e.target.value
				    });
                                }}
	    			isInvalid={
                                    cancellationReason.invalid
                                }
                                rows={4}
                            />
	    		    <Form.Control.Feedback
                                className="text-left"
                                type="invalid">
                                {cancellationReason.error}
                           </Form.Control.Feedback>
                        </Form.Group>
                    </Grid>
                    <Grid container className="mt-3 " justify="flex-end">
                        <Grid item align="left" xs={6} lg={6}>
                            <Button
                                className="btn-block"
                                variant="outline-primary"
                                style={{
                                    width: "95%",
                                    borderRadius: "5px",
                                    backgroundColor: "grey",
                                    color: "black",
                                    border: "1px solid grey",
                                    height: "5vh",
                                }}
                                onClick={() => {
                                    closeModal();
                                }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6} lg={6}>
                            <Button
                                className="btn-block mr-5"
                                variant="outline-primary"
                                style={{
                                    width: "90%",
                                    color: store.getState().colorState.font_color,
                                    backgroundColor: store.getState().colorState.color,
                                    borderColor: store.getState().colorState.color,
                                    borderRadius: "5px",
                                    border: "1px solid #ffd166 ",
                                    height: "5vh",
                                }}
                                onClick={() => {
                                    if (cancellationReason.value === "") {
					setCancellationReason({
						...cancellationReason,
						invalid: true,
					})
				    } else {
				    	updateSubsStatus();
                               	    }
				}}
                            >
                                Unsubscribe
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Modal.Body>
        </Modal>
    );
};

export default UnsubscribeSurvey;
