/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Container, Form, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Edit } from "@material-ui/icons";
import { Tooltip as TooltipIcon } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import customerService from "../../../../../services/customerService";
import invoiceService from "../../../../../services/invoiceService";
import axios from "axios";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Swal from "sweetalert2";
import moment from "moment";
import { store } from "../../../../../index.js";
import planService from "../../../../../services/planService";
import { useSelector } from "react-redux";
import { calculatePinnedRowsHeight } from "@mui/x-data-grid/hooks/features/rows/gridRowsUtils";
import profileService from "../../../../../services/profileService";

const DisplayOrderInformation = ({ editDetails, handleEditDetails, subsDetails }) => {
    const history = useHistory();
    const params = useParams();
    const isLoggedIn = useSelector((state) => state.loggedInUserReducer.userData);
    const [customerDetails, setCustomerDetails] = useState();
    const [discountData, setDiscountData] = useState();
    const [customerInvoices, setCustomerInvoices] = useState([]);
    const [planDetails, setPlanDetails] = useState();
    const [discountAmount, setDiscountAmount] = useState(0);
    const [discountApplied, setDiscountApplied] = useState(false);
    const [deliveryState, setDeliveryState] = useState("");
    const [allPostcodes, setAllPostcodes] = useState([]);
    const [orderPrice, setOrderPrice] = useState(0);
    useLayoutEffect(() => {
        profileService.getAllPostcode().then((e) => setAllPostcodes(e.postcode));
    }, []);
    useLayoutEffect(() => {
        console.log("in layout effect");
        customerService
            .getSpecificCustomerFromAR(subsDetails.customer_id)
            .then((cust_details) => {
                setCustomerDetails(cust_details);
            })
            .catch((err) => console.error(err));
        invoiceService
            .getInvoiceBySubsId(subsDetails.id)
            .then((inv) => setCustomerInvoices(inv))
            .catch((err) => console.error(err));
        planService
            .getSinglePlan(subsDetails.plan_id)
            .then((success) => {
                console.log(success.plans, "success plans");
                calculatePlanDiscountPrice(
                    subsDetails.plan_price + subsDetails.delivery_fee + itemsPrice(),
                    planDetails?.discount_setup[0]?.discount_amount,
                    planDetails?.discount_setup[0]?.discount_type,
                    planDetails?.discount_setup[0]
                );
                setPlanDetails(success.plans);
            })
            .catch((err) => console.error(err));
    }, []);
    useEffect(() => {
        getCurrentState(subsDetails.customer_postcode);
    }, [allPostcodes]);
    const getCurrentState = (currentPostcode) => {
        console.log(currentPostcode, "asd");
        var getState = allPostcodes.filter(
            (postcode) => postcode.postcode === parseInt(currentPostcode)
        );
        console.log(getState, "get state?");
        if (getState.length > 0) {
            console.log(getState, "is it state");
            setDeliveryState(getState[0].state);
        } else {
            setDeliveryState("");
        }
    };
    const itemsPrice = () => {
        var totalItemPrice = 0.0;
        subsDetails.categories_item?.categories.forEach((category) => {
            category.items.forEach((item) => {
                if (!item.variant_exist) {
                    if (planDetails?.quantity_exist) {
                        totalItemPrice += item.addon_price * item.quantity;
                    } else if (!planDetails?.quantity_exist) {
                        totalItemPrice += item.addon_price;
                    }
                } else if (item.variant_exist) {
                    if (planDetails?.quantity_exist) {
                        totalItemPrice +=
                            parseFloat(item.chosen_variant_detail.price) * item.quantity;
                    } else if (!planDetails?.quantity_exist) {
                        totalItemPrice += parseFloat(item.chosen_variant_detail.price);
                    }
                }
            });
        });
        return totalItemPrice;
    };

    const downloadInvoice = (invId) => {
        console.log(window.location, "window location");

        axios({
            method: "POST",
            url: `${
                window.location.protocol +
                "//" +
                window.location.hostname +
                process.env.REACT_APP_SITE_PORT_BACKEND
            }/api/invoice_pdf/`,
            data: { inv_id: invId },
            responseType: "blob",
        })
            .then((res) => {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                var url = window.URL.createObjectURL(res.data);
                a.href = url;
                a.download = `${invId}.pdf`;
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    icon: "error",
                    title: "Could not download the invoice now",
                    text: "Please try again later.",
                });
            });
    };
    const invoiceResolved = (
        invoice_resolved,
        invoice_collection_status,
        inv_collection_batch,
        dueDateStr
    ) => {
        var today = new Date();
        var todayDate = new Date(
            today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate() + 5
        );
        var dueDate = new Date(dueDateStr.slice(0, 10));

        if (
            (invoice_resolved == "true" || invoice_resolved == true) &&
            parseInt(invoice_collection_status) == 0
        ) {
            return "Paid";
        } else if (
            (invoice_collection_status != null && parseInt(invoice_collection_status) != 0) ||
            todayDate > dueDate
        ) {
            return "Overdue";
        } else {
            return "Awaiting Payment";
        }
    };
    const isSubsEditable = () => {
        // check if subscription is editable
        // depending on planInfo.allow_subscription_edit
        // check if still valid for days before edit
        if (isLoggedIn?.userRole === "merchant") {
            return false;
        }
        if (planDetails?.allow_subscription_edit) {
            // check delivery date against planInfo.days_before_edit
            var currentDeliveryDate = new Date(subsDetails.delivery_date);
            var todaysDate = new Date();
            var daysBeforeEdit = planDetails.days_before_edit;
            console.log(daysBeforeEdit, "days before edit");
            todaysDate = new Date(todaysDate.setDate(todaysDate.getDate() + daysBeforeEdit));
            // the difference between todaysDate and daysBeforeEdit, if positive then can edit
            if (currentDeliveryDate - todaysDate > 0) {
                return false;
            }
            return true;
        }
        return false;
    };
    const renderTooltip = (props) => {
        if (planDetails?.days_before_edit) {
            return (
                <Tooltip id="button-tooltip" {...props}>
                    You have a delivery on{" "}
                    {new Date(subsDetails.delivery_date).getDate() +
                        " " +
                        new Date(subsDetails.delivery_date).toLocaleString("default", {
                            month: "long",
                        }) +
                        " " +
                        new Date(subsDetails.delivery_date).getFullYear()}
                    , subscription can only be edited up until {planDetails?.days_before_edit} days
                    before delivery date
                </Tooltip>
            );
        }
        return <Tooltip></Tooltip>;
    };
    const renderVariantTitle = () => {
        // check variant_exist
        console.log(subsDetails, "this is the variant title");
        var variantExist = subsDetails.categories_item.categories.map((category) => {
            const filterCategory = category.items.filter((item) => item.variant_exist);
            return filterCategory.length > 0 && true;
        });
        variantExist = variantExist.filter((variant) => variant);
        return variantExist.length > 0 ? "Variant" : "";
    };
    const renderVariantData = (item) => {
        if (item.variant_exist) {
            return item.chosen_variant_detail.variant;
        }
    };
    const renderItemPrice = (item_data) => {
        var total = 0;
        if (!item_data.variant_exist) {
            if (planDetails?.quantity_exist) {
                total += item_data.addon_price * item_data.quantity;
            } else if (!planDetails?.quantity_exist) {
                total += item_data.addon_price;
            }
        } else if (item_data.variant_exist) {
            if (planDetails?.quantity_exist) {
                total += parseFloat(item_data.chosen_variant_detail.price) * item_data.quantity;
            } else if (!planDetails?.quantity_exist) {
                total += parseFloat(item_data.chosen_variant_detail.price);
            }
        }
        return total.toFixed(2);
    };
    const checkItemsLength = () => {
        var moreThanZero = false;
        subsDetails.categories_item.categories.forEach((item) => {
            const checkedItem = item.items.filter((item_detail) => !!item_detail.checked);
            if (checkedItem.length > 0) {
                moreThanZero = true;
            }
        });
        return moreThanZero;
    };
    useEffect(() => {
        setOrderPrice(subsDetails.subs_price.toFixed(2));
    }, [subsDetails, planDetails]);
    const calcTotalPrice = () => {
        var total = 0;
        const planPrice = planDetails?.pricing_model === "flat_fee" ? planDetails.plan_price : 0;
        var postcodeFee = subsDetails.delivery_fee !== null ? subsDetails.delivery_fee : 0;
        var totalItemsPrice = 0;
        console.log(planDetails, "this is the plan details");
        if (planDetails !== undefined) {
            if (checkItemsLength()) {
                subsDetails.categories_item.categories.forEach((items) => {
                    items.items.forEach((item) => {
                        if (item.checked) {
                            if (!item.variant_exist) {
                                if (planDetails.quantity_exist) {
                                    totalItemsPrice += item.addon_price * item.quantity;
                                } else if (!planDetails.quantity_exist) {
                                    totalItemsPrice += item.addon_price;
                                }
                            } else if (
                                item.variant_exist &&
                                item.chosen_variant_detail !== undefined
                            ) {
                                if (planDetails.quantity_exist) {
                                    totalItemsPrice +=
                                        parseFloat(item.chosen_variant_detail?.price) *
                                        item.quantity;
                                } else if (!planDetails.quantity_exist) {
                                    totalItemsPrice += parseFloat(
                                        item.chosen_variant_detail?.price
                                    );
                                }
                            }
                        }
                    });
                });
            }
        }

        total = planPrice + postcodeFee + totalItemsPrice;
        console.log(planPrice, "this is the plan price");
        console.log(postcodeFee, "this is the postcode fee");
        console.log(totalItemsPrice, "total items price");
        // evaluate on discount that is tied to the plan
        if (planDetails?.discount_setup.length > 0) {
            var planDiscountPrice = calculatePlanDiscountPrice(
                total,
                planDetails.discount_setup[0]?.discount_amount,
                planDetails.discount_setup[0]?.discount_type,
                planDetails.discount_setup[0]
            );
            total = planDiscountPrice;
     	    console.log(planDiscountPrice,'this is the plan discount pricee');
	}

        // if customer inputted valid discount
        // deduct total price with discount price
        const userDiscountPrice = calculateUserDiscountPrice(
            total,
            planPrice,
            postcodeFee,
            totalItemsPrice
        );
	console.log(total,'this is the total price');
        console.log(userDiscountPrice,'this is the user discount price');
	sessionStorage.setItem("plan_price", JSON.stringify(userDiscountPrice));
        return userDiscountPrice?.toFixed(2);
    };
    const calculateUserDiscountPrice = (total, planPrice, postcodeFee, totalItemsPrice) => {
        // check discount_data.discount_type
        //      if discount_data.discount_type === "fixed_amount"
        //      if discount_data.discount_type === "percentage"
        //          if discount_data.category === "order"
        //              take total price and minus discount off of everything except delivery fee
        //          if discount_data.category === "delivery"
        //              setDe
        //          deduct total based on result

        if (subsDetails.promo?.discount_type === "fixed_amount") {
            if (subsDetails.promo.category === "order") {
                var orderPrice = planPrice + totalItemsPrice;
                var newOrderPrice = orderPrice - subsDetails.promo.discount_amount;
                setDiscountAmount(subsDetails.promo.discount_amount);
                setDiscountApplied(true);
                return newOrderPrice + postcodeFee;
            } else if (subsDetails.promo?.category === "delivery") {
                var newDeliveryFee = postcodeFee - subsDetails.promo?.discount_amount;
                return planPrice + totalItemsPrice + newDeliveryFee;
            }
        } else if (subsDetails.promo?.discount_type === "percentage") {
            if (subsDetails.promo?.category === "order") {
                var orderPrice = planPrice + totalItemsPrice;
                var newOrderPrice =
                    orderPrice - orderPrice * (subsDetails.promo?.discount_amount / 100);
                return newOrderPrice + postcodeFee;
            } else if (subsDetails.promo?.category === "delivery") {
                var newDeliveryFee = orderPrice * (subsDetails.promo?.discount_amount / 100);
                return planPrice + totalItemsPrice + newDeliveryFee;
            }
        }
        return total;
    };

    const calculatePlanDiscountPrice = (total, disc_amount, disc_type, row) => {
        console.log(total, disc_amount, disc_type, row,'this is the discount informations');
	const processDiscountType = () => {
            console.log(disc_type, "this is the discount type");
            if (disc_type === "fixed_amount") {
                setDiscountAmount(disc_amount);
                setDiscountApplied(true);
                return total - disc_amount;
            } else if (disc_type === "percentage") {
                setDiscountAmount((total * disc_amount) / 100);
                setDiscountApplied(true);
                console.log(total - (total * disc_amount) / 100,'this is the discount plan discount price');
		return total - (total * disc_amount) / 100;
            }
        };
        const processOperator = (deduct_value, row_option) => {
            var newTotalPrice = total;
            if (row_option?.discount_operator === "greater") {
                newTotalPrice =
                    deduct_value > parseInt(row_option.amount) ? processDiscountType() : total;
            }
            if (row_option?.discount_operator === "lesser") {
                newTotalPrice =
                    deduct_value < parseInt(row_option.amount) ? processDiscountType() : total;
            }
            if (row_option?.discount_operator === "equal") {
                newTotalPrice =
                    deduct_value === parseInt(row_option.amount) ? processDiscountType() : total;
            }
            return newTotalPrice;
        };
        // discount applied on to determine where it is going to be applied
        const processDiscount = (row_option, row_purchase_option) => {
            console.log(row_option, "this is the row option");
            switch (row_option?.discount_on) {
                case "purchase_option":
                    // differentiate based on disc_type
                    if (
                        (row_option?.discount_operator === "one_time_subs" &&
                            subsDetails.subs_type === "one_off") ||
                        (row_option?.discount_operator === "is_subs" &&
                            subsDetails.subs_type === "recurring")
                    ) {
                        console.log("discountOperator", row_purchase_option);

                        return row_purchase_option !== null
                            ? processOperator(total, row_purchase_option)
                            : processDiscountType();
                    }
                    break;
                case "total_price":
                    return processOperator(total, row_option);
                case "quantity":
                    var totalMenuQuantityChosen = 0;
                    // loop through selected menu
                    // compare with quantity in row
                    // if row operator is fulfilled, deduct discount amount from total
                    subsDetails.categories_item.forEach((item) => {
                        item.items.forEach((selected_item) => {
                            totalMenuQuantityChosen += selected_item.quantity;
                        });
                    });
                    return processOperator(totalMenuQuantityChosen, row_option);
                default:
                    return total;
            }
        };
        // if mandatory row has purchase_option as value, check optional row instead
        // if optional row has purchase_option as value, check mandatory row
        // if both dont have purchase_option in discount_on, processOperator as usual
        var planDiscountPrice;

        if (row?.mandatory_row?.discount_on === "purchase_option") {
            planDiscountPrice = processDiscount(row?.mandatory_row, row?.optional_row);
        } else if (row?.optional_row?.discount_on === "purchase_option") {
            planDiscountPrice = processDiscount(row?.optional_row, row?.mandatory_row);
        } else {
            planDiscountPrice = processDiscount(row?.mandatory_row);
            planDiscountPrice = processDiscount(row?.optional_row);
        }

        return planDiscountPrice;
    };
    return (
        subsDetails !== undefined &&
        customerDetails !== undefined && (
            <div
                className="d-flex flex-column text-left pl-lg-5 pt-3 mr-lg-5 mb-5"
                style={{ padding: window.innerWidth < 991 && "5em 1em" }}
            >
                <h2>
                    <strong>Purchase Details</strong>
                </h2>
                <Button
                    className="d-flex flex-row"
                    onClick={() => history.goBack()}
                    style={{
                        width: "fit-content",
                        backgroundColor: store.getState().colorState.color,
                        borderColor: store.getState().colorState.color,
                        color: store.getState().colorState.font_color,
                    }}
                >
                    <ArrowBackIcon />
                    <p>Back to My Purchases</p>
                </Button>
                <Container
                    className="mt-3 pt-3 pl-4 pr-4"
                    style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        marginBottom: "4em",
                        boxShadow: "4px 4px #E1E1E1",
                    }}
                >
                    <h3>
                        <strong>
                            {`${
                                store.getState()?.loggedInUserReducer?.userData?.userRole ===
                                "customer"
                                    ? "Purchase"
                                    : "Order"
                            }`}{" "}
                            Information
                        </strong>
                    </h3>
                    {/* get customer information */}
                    <Form.Group className="pt-3">
                        {store.getState()?.loggedInUserReducer?.userData?.userRole ===
                            "merchant" && (
                            <>
                                <Form.Label>Customer Name</Form.Label>
                                <Form.Control
                                    value={
                                        customerDetails.cust_fname +
                                        " " +
                                        customerDetails.cust_lname
                                    }
                                    disabled
                                />
                            </>
                        )}
                        {isLoggedIn?.userRole === "merchant" && (
                            <>
                                <Form.Label className="pt-3">Customer ID</Form.Label>
                                <Form.Control disabled value={subsDetails.customer_id} />
                            </>
                        )}
                        <Form.Label className="pt-3">Product Name</Form.Label>
                        <Form.Control disabled value={subsDetails.plan_name} />
                        <Form.Label className="pt-3">Product Type</Form.Label>
                        <Form.Control disabled value={subsDetails.subs_type} />
                        <Form.Label className="pt-3">Contact No.</Form.Label>
                        <Form.Control disabled value={subsDetails.customer_contact_no} />
                        {subsDetails.delivery_date !== null && (
                            <>
                                <Form.Label className="pt-3">Delivery Address</Form.Label>
                                <Form.Control
                                    disabled
                                    value={`${subsDetails.delivery_address}, ${subsDetails.customer_postcode}, ${deliveryState}`}
                                />
                                <Form.Label className="pt-3">Delivery Date</Form.Label>
                                <Form.Control
                                    disabled
                                    value={new Date(subsDetails.delivery_date).toLocaleDateString(
                                        "en-GB"
                                    )}
                                />
                            </>
                        )}
                        {subsDetails.delivery_date !== null && (
                            <>
                                <Form.Label className="pt-3">Next Delivery Date</Form.Label>
                                <Form.Control
                                    disabled
                                    value={
                                        subsDetails.delivery_frequency.includes("month")
                                            ? new Date(
                                                  new Date(subsDetails.delivery_date).getFullYear(),
                                                  new Date(subsDetails.delivery_date).getMonth() +
                                                      1,
                                                  new Date(subsDetails.delivery_date).getDate()
                                              ).toLocaleDateString("en-GB")
                                            : new Date(
                                                  new Date(subsDetails.delivery_date).setDate(
                                                      new Date(
                                                          subsDetails.delivery_date
                                                      ).getDate() + 365
                                                  )
                                              ).toLocaleDateString("en-GB")
                                    }
                                />
                            </>
                        )}
                        {subsDetails.delivery_time !== null && (
                            <>
                                <Form.Label className="pt-3">Delivery Time</Form.Label>
                                <Form.Control disabled value={subsDetails.delivery_time} />
                            </>
                        )}
                    </Form.Group>
                    <div
                        className="d-flex flex-row pt-4"
                        style={{ justifyContent: window.innerWidth < 991 && "space-between" }}
                    >
                        <h3 className="mb-0 align-self-center">
                            <strong>
                                {`${
                                    store.getState()?.loggedInUserReducer?.userData?.userRole ===
                                    "customer"
                                        ? "Purchase"
                                        : "Order"
                                }`}{" "}
                                Details
                            </strong>
                        </h3>
                        <OverlayTrigger
                            placement="right"
                            // show={isSubsEditable()}
                            // delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                        >
                            <div>
                                <Button
                                    onClick={() => {
                                        // check if the date is allowing user to edit
                                        handleEditDetails();
                                    }}
                                    disabled={isSubsEditable()}
                                    className="d-flex flex-row ml-3"
                                    style={{
                                        width: "fit-content",
                                        backgroundColor: store.getState().colorState.color,
                                        borderColor: store.getState().colorState.color,
                                        color: store.getState().colorState.font_color,
                                    }}
                                >
                                    <Edit className="p-1" />
                                    <p style={{ display: window.innerWidth < 991 && "none" }}>
                                        Edit
                                    </p>
                                </Button>
                            </div>
                        </OverlayTrigger>
                    </div>
                    <div className="pt-3">
                        {/* Order headers */}
                        <Row>
                            <Col>
                                <p>
                                    <strong>Product</strong>
                                </p>
                            </Col>
                            <Col className="justify-content-end d-flex">
                                <p>
                                    <strong>Price</strong>
                                </p>
                            </Col>
                        </Row>
                        {/* Product name and price (must) */}
                        <Row className="pt-3">
                            <Col>
                                <p>{subsDetails?.plan_name}</p>
                            </Col>
                            <Col></Col>
                            <Col></Col>
                            <Col className="justify-content-end d-flex">
                                {subsDetails.plan_price !== null &&
                                    planDetails?.pricing_model === "flat_fee" &&
                                    `RM${subsDetails.plan_price.toFixed(2)}`}
                            </Col>
                        </Row>
                        {/* Order item headers (if there's items) 
                            (variant only if pricing model = property) 
                            (quantity only if = has_quantity */}
                        {subsDetails.categories_item?.categories[0].items.length > 0 && (
                            <>
                                {" "}
                                <hr className="bg-black" />
                                <Row>
                                    <Col>Items</Col>
                                    <Col className="d-flex justify-content-center">
                                        {/* need a variant filter */}
                                        {/* if true, render variant title*/}
                                        {renderVariantTitle()}
                                    </Col>
                                    <Col className="d-flex justify-content-center">
                                        {planDetails?.quantity_exist && "Quantity"}
                                    </Col>
                                    <Col className="justify-content-end d-flex">Price</Col>
                                </Row>
                            </>
                        )}
                        {/* Order item category name */}
                        {/* Order item details*/}
                        {subsDetails.categories_item?.categories[0].items.length > 0 &&
                            subsDetails.categories_item.categories.map((item) => {
                                return (
                                    <>
                                        {item.items.length > 0 && (
                                            <Row className="pt-2">
                                                <Col>{item.name}</Col>
                                            </Row>
                                        )}
                                        {item.items.map((item) => {
                                            return (
                                                <Row className="pt-1">
                                                    <Col>{item.addon_name}</Col>
                                                    <Col className="d-flex justify-content-center">
                                                        {renderVariantData(item)}
                                                    </Col>
                                                    <Col className="d-flex justify-content-center">
                                                        {planDetails?.quantity_exist &&
                                                            item.quantity}
                                                    </Col>
                                                    <Col className="justify-content-end d-flex">
                                                        RM
                                                        {/* {(item.addon_price * item.quantity).toFixed(
                                                            2
                                                        )} */}
                                                        {renderItemPrice(item)}
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </>
                                );
                            })}

                        <hr className="bg-black" />

                        {/* Delivery fee if exists */}
                        {subsDetails.delivery_fee !== null && subsDetails.delivery_fee > 0 &&(
                            <>
                                <Row>
                                    <Col>Delivery Fee</Col>
                                    <Col></Col>
                                    <Col></Col>
                                    <Col className="justify-content-end d-flex">
				{`RM${subsDetails.delivery_fee.toFixed(2)}`}
                                    </Col>
                                </Row>{" "}
                                <hr className="bg-black" />
                            </>
                        )}
                        {discountApplied && (
                            <Row style={{ color: "rgb(89, 193, 159)" }}>
                                <Col lg={2} md={2} xs={2}>
                                    Discount
                                </Col>
                                <Col
                                    lg={10}
                                    md={10}
                                    xs={10}
                                    className="justify-content-end d-flex flex-column text-right"
                                >
                                    {/* check if current plan has discount */}
                                    {/* check if customer previously had applied discount */}
                                    -RM{discountAmount.toFixed(2)}
                                </Col>
                            </Row>
                        )}
                        {/* Total price */}
                        <Row>
                            <Col lg={2} xs={2}>
                                Total
                            </Col>
                            {/* <Col xs={0}></Col>
                            <Col xs={0}></Col> */}
                            <Col
                                lg={10}
                                xs={10}
                                className="justify-content-end d-flex flex-column text-right"
                            >
                                <p>
                                    RM
                                    {orderPrice}
                                    {/* subsDetails.plan_price +
                                        subsDetails.delivery_fee +
                                        itemsPrice() */}
                                </p>
                                <small>
                                    {subsDetails.subs_type === "Recurring" &&
                                        !planDetails?.subs_auto_renew &&
                                        `Every ${planDetails?.subs_cycle_unit} for
                                ${planDetails?.subs_cycle_count} ${planDetails?.subs_cycle_unit}(s)`}
                                    {subsDetails.subs_type === "Recurring" &&
                                        planDetails?.subs_auto_renew &&
                                        `Every ${planDetails?.subs_cycle_unit}`}
                                    {/* {subsDetails.delivery_frequency} for {subsDetails.billing_cycle}{" "}
                                    {subsDetails.delivery_frequency}(s) */}
                                </small>
                            </Col>
                        </Row>
                    </div>

                    <div className="pt-3">
                        <h3>
                            <strong>Invoices</strong>
                        </h3>
                        {/* Invoice header */}
                        <Row className="pt-3">
                            <Col xs={6} lg={2}>
                                <strong>Invoice ID</strong>
                            </Col>
                            <Col
                                style={{ display: window.innerWidth < 991 && "none" }}
                                xs={0}
                                lg={2}
                            >
                                <strong>Date Generated</strong>
                            </Col>
                            <Col
                                style={{ display: window.innerWidth < 991 && "none" }}
                                xs={0}
                                lg={2}
                            >
                                <strong>Payment Date</strong>
                            </Col>
                            <Col
                                style={{ display: window.innerWidth < 991 && "none" }}
                                lg={2}
                                xs={0}
                            >
                                <strong>Invoice Total</strong>
                            </Col>
                            <Col xs={6} lg={2}>
                                <strong>Status</strong>
                            </Col>
                            <Col xs={0} lg={2}></Col>
                        </Row>
                        {/* Invoice data */}
                        {customerInvoices.map((invoice) => (
                            <Row className="pt-2">
                                <Col xs={5} lg={2}>
                                    {invoice.inv_id}
                                </Col>

                                <Col lg={2} style={{ display: window.innerWidth < 991 && "none" }}>
                                    {" "}
                                    {invoice.inv_issue_date !== null &&
                                        moment(invoice.inv_issue_date).format(
                                            "DD MMM yyyy hh:mm A"
                                        )}
                                </Col>
                                <Col
                                    style={{ display: window.innerWidth < 991 && "none" }}
                                    xs={0}
                                    lg={2}
                                >
                                    {moment(invoice.inv_due_date).format("DD MMM yyyy hh:mm A")}
                                </Col>
                                <Col
                                    style={{ display: window.innerWidth < 991 && "none" }}
                                    xs={0}
                                    lg={2}
                                >
                                    RM
                                    {invoice.inv_total_amount !== null &&
                                        invoice.inv_total_amt.toFixed(2)}
                                </Col>
                                <Col lg={2} xs={5}>
                                    {invoiceResolved(
                                        invoice.inv_resolved.toString(),
                                        invoice.inv_collection_status,
                                        invoice.inv_collection_batch,
                                        invoice.inv_due_date
                                    )}
                                </Col>
                                <Col lg={2} xs={2}>
                                    {" "}
                                    <TooltipIcon title="Download Invoice as pdf">
                                        <a onClick={downloadInvoice.bind(this, invoice.inv_id)}>
                                            <PictureAsPdfIcon className="invoice-action-icon" />
                                        </a>
                                    </TooltipIcon>
                                </Col>
                            </Row>
                        ))}
                    </div>
                </Container>
            </div>
        )
    );
};

export default DisplayOrderInformation;
