import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import EventIcon from "@mui/icons-material/Event";
import LockIcon from "@mui/icons-material/Lock";
import { useEffect, useLayoutEffect, useState } from "react";
import { Button, Card, Col, Container, Form, InputGroup, Modal, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import "react-datepicker/dist/react-datepicker.css";
import "react-month-picker/css/month-picker.css";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { timeFromInt } from "time-number";
import fpx from "../../Images/fpx.png";
import visa_card from "../../Images/visa.png";
import { store } from "../../index.js";
import customerService from "../../services/customerService";
import invoiceService from "../../services/invoiceService";
import merchantService from "../../services/merchantService";
import profileService from "../../services/profileService";
import promotionService from "../../services/promotionService";
import subscriptionService from "../../services/subscriptionService";
import "./PricingDetail.css";
// date-fns
import DatePicker from "react-datepicker";
import { Password } from "@mui/icons-material";

const PricingDetail = ({
    creditCardSettings,
    productDetail,
    setProductDetail,
    itemValidation,
    setItemSelectionValidation,
}) => {
    const history = useHistory();
    const isLoggedIn = useSelector((state) => state.loggedInUserReducer.userData);
    const [accordionActiveKey, setAccordionActiveKey] = useState(
        productDetail.is_deliverable
            ? 0
            : productDetail.date_exist && !productDetail.is_deliverable
            ? 1
            : 2
    );
    const [deliveryFee, setDeliveryFee] = useState();
    const [disabledDays, setDisabledDays] = useState(
        productDetail.delivery_window !== null
            ? Object.keys(productDetail?.delivery_window?.day).filter(
                  (keys) => !productDetail.delivery_window.day[keys]
              )
            : []
    );
    const [selectedItems, setSelectedItems] = useState(
        JSON.parse(sessionStorage.getItem("selected_items")) !== null
            ? JSON.parse(sessionStorage.getItem("selected_items"))
            : []
    );
    const [completedSteps, setCompletedSteps] = useState([]);
    const [curlecMerchantId, setCurlecMerchantId] = useState();
    const [curlecEmployeeId, setCurlecEmployeeId] = useState();
    const [discountAmount, setDiscountAmount] = useState(0);
    const [minDate, setMinDate] = useState();
    const [discountApplied, setDiscountApplied] = useState(false);
    const [discountData, setDiscountData] = useState(
        JSON.parse(sessionStorage.getItem("discount_data")) !== null
            ? JSON.parse(sessionStorage.getItem("discount_data"))
            : undefined
    );
    const [displayPaymentMethod, setDisplayPaymentMethod] = useState(
        JSON.parse(sessionStorage.getItem("payment_method")) !== null
            ? JSON.parse(sessionStorage.getItem("payment_method"))
            : "fpx"
    );
    const [maxMandateVisa, setMaxMandateVisa] = useState(0);
    const [purchaseOption, setPurchaseOption] = useState(
        JSON.parse(sessionStorage.getItem("purchase_option")) !== null
            ? JSON.parse(sessionStorage.getItem("purchase_option"))
            : ""
    );
    const [allPostcodes, setAllPostcodes] = useState([]);

    function SaveAndContinue({ nextStep, currentStep, checker }) {
        return (
            <Button
                className="p-2 justify-content-end"
                onClick={() => {
                    checker(nextStep, currentStep);
                }}
                style={{
                    width: window.innerWidth < 991 ? "75%" : "50%",
                    backgroundColor: store.getState().colorState.color,
                    borderColor: store.getState().colorState.color,
                    color: store.getState().colorState.font_color,
                    textTransform: "none",
                }}
            >
                Save & continue
            </Button>
        );
    }

    function tileDisabled({ date, view }) {
        var indexes = [];
        disabledDays.map((key) => {
            if (key == "Sunday") indexes.push(0);
            if (key == "Monday") indexes.push(1);
            if (key == "Tuesday") indexes.push(2);
            if (key == "Wednesday") indexes.push(3);
            if (key == "Thursday") indexes.push(4);
            if (key == "Friday") indexes.push(5);
            if (key == "Saturday") indexes.push(6);
        });
        if (view === "month" && indexes.some((Ddate) => date.getDay() === Ddate)) return true;
    }

    useEffect(() => {
        setProductDetail({
            ...productDetail,
            categories_item: { categories: JSON.parse(sessionStorage.getItem("selected_items")) },
        });
    }, [selectedItems]);

    useLayoutEffect(() => {
        profileService.getAllPostcode().then((e) => setAllPostcodes(e.postcode));
        merchantService.getMerchantInfo().then((e) => {
            setCurlecMerchantId({
                value: parseInt(e[0].merch_curlec_merchant_id),
            });
            setCurlecEmployeeId({
                value: parseInt(e[0].merch_curlec_employee_id),
            });
        });
        profileService.getProfile().then((e) => {
            setMaxMandateVisa(e.settings.max_mandate_cc);
        });
    }, []);

    useLayoutEffect(() => {
        var MinDate = new Date();
        MinDate.setDate(MinDate.getDate() + productDetail.date_lead_time);
        setMinDate(MinDate);
    }, []);

    function FlushExample() {
        const [addressLine, setAddressLine] = useState(
            JSON.parse(sessionStorage.getItem("address_one")) !== null
                ? JSON.parse(sessionStorage.getItem("address_one"))
                : ""
        );
        const [addressLine2, setAddressLine2] = useState(
            JSON.parse(sessionStorage.getItem("address_two")) !== null
                ? JSON.parse(sessionStorage.getItem("address_two"))
                : ""
        );
        const [addressState, setAddressState] = useState(
            JSON.parse(sessionStorage.getItem("state")) !== null
                ? JSON.parse(sessionStorage.getItem("state"))
                : ""
        );
        const [postcode, setPostcode] = useState(
            JSON.parse(sessionStorage.getItem("postcode")) !== null
                ? JSON.parse(sessionStorage.getItem("postcode"))
                : ""
        );
        const [addressValidation, setAddressValidation] = useState({
            address_line_1: { valid: true, error: "Address Line is required" },
            postcode: { valid: true, error: "Postcode is required" },
        });
        const [bankList, setBankList] = useState([]);
        const [openDeliveryWind, setOpenDeliveryWind] = useState(false);

        const [deliveryFreq, setDeliveryFreq] = useState(
            JSON.parse(sessionStorage.getItem("delivery_freq")) !== null
                ? JSON.parse(sessionStorage.getItem("delivery_freq"))
                : ""
        );

        const [deliveryTime, setDeliveryTime] = useState(
            JSON.parse(sessionStorage.getItem("delivery_time")) !== null
                ? JSON.parse(sessionStorage.getItem("delivery_time"))
                : ""
        );
        const [deliveryDate, setDeliveryDate] = useState(
            JSON.parse(sessionStorage.getItem("delivery_date")) !== null
                ? new Date(JSON.parse(sessionStorage.getItem("delivery_date")))
                : new Date(
                      productDetail.date_format === "MM/YY"
                          ? new Date().getMonth() + productDetail.date_lead_time
                          : new Date().getDate() + productDetail.date_lead_time
                  )
        );

        useLayoutEffect(() => {
            if (
                JSON.parse(sessionStorage.getItem("delivery_date")) === null &&
                productDetail.date_exist
            ) {
                var MinDate = new Date();
                MinDate.setDate(MinDate.getDate() + productDetail.date_lead_time);
                setDeliveryDate(MinDate);
                sessionStorage.setItem("delivery_date", JSON.stringify(MinDate));
            }
	    if (isLoggedIn?.userRole === "customer"){
	    	if (store.getState().loggedInUserReducer.userData.ar_system.cust_id !== null) {
        		const detailsBody = { cust_mandate_ref: store.getState().loggedInUserReducer.userData.ar_system.cust_id }
        		customerService.getPaymentDetails(detailsBody).then((res)=>{
                		setSelectedBank(res);
                		console.log(res,'res mandate details');
            		}).catch((err)=>console.error(err));
            	}
	    }
        }, []);

        const [dateValidation, setDateValidation] = useState(
            JSON.parse(sessionStorage.getItem("delivery_validation")) !== null
                ? JSON.parse(sessionStorage.getItem("delivery_validation"))
                : {
                      delivery_date: { valid: true, message: "" },
                      delivery_freq: { valid: true, message: "" },
                      delivery_time: { valid: true, message: "" },
                  }
        );
        const [paymentMethod, setPaymentMethod] = useState(
            JSON.parse(sessionStorage.getItem("payment_method")) !== null
                ? JSON.parse(sessionStorage.getItem("payment_method"))
                : "N/A"
        );
        useEffect(() => {
            if (JSON.parse(sessionStorage.getItem("payment_method")) === null) {
                sessionStorage.setItem("payment_method", JSON.stringify(paymentMethod));
            }
        }, []);
        const [selectedBank, setSelectedBank] = useState(
            JSON.parse(sessionStorage.getItem("selected_bank")) !== null
                ? JSON.parse(sessionStorage.getItem("selected_bank"))
                : ""
        );
        function convertLastDayOfMonth(convertDeliveryDate) {
            // console.log(new Date().getMonth());
            // console.log(convertDeliveryDate.getMonth() + 1, "current converted date");
            const deliveryDateFormatted = convertDeliveryDate;
            var convertedDate = new Date(
                deliveryDateFormatted.setMonth(deliveryDateFormatted.getMonth() + 1)
            );
            console.log(convertedDate.getDate(), "comforter");

            convertedDate = convertedDate.getDate() - 1;
            return convertedDate;
        }
        const deliveryFreqConvertInt = (delivery_freq) => {
            var convertDeliveryDate = new Date(deliveryDate);

            switch (delivery_freq) {
                case "Every month":
                    return new Date(
                        convertDeliveryDate.setDate(convertDeliveryDate.getDate() + 28)
                    );
		case "Every 2 weeks":
                    convertDeliveryDate = new Date(
                        convertDeliveryDate.setDate(convertDeliveryDate.getDate() + 14)
                    );

                    return convertDeliveryDate;
                case "Every week":
                    convertDeliveryDate = new Date(
                        convertDeliveryDate.setDate(convertDeliveryDate.getDate() + 7)
                    );

                    return convertDeliveryDate;
                default:
                    convertDeliveryDate = new Date(
                        convertDeliveryDate.setDate(convertDeliveryDate.getDate() + 0)
                    );

                    return convertDeliveryDate;
            }
        };

        const convertDeliveryDate = () => {
            return deliveryFreqConvertInt(deliveryFreq);
        };
        useLayoutEffect(() => {
            subscriptionService
                .getBanksForSubscription({ method: "00" })
                .then((res) => {
                    var result = res.replace(/'/g, '"');
                    var banks = JSON.parse(result);
                    setBankList(banks);
                })
                .catch((err) => {
                    console.error(err);
                });
            if (
                isLoggedIn &&
                store.getState().loggedInUserReducer.userData.userRole !== "merchant"
            ) {
                customerService
                    .getSpecificCustomerFromAR(
                        store.getState().loggedInUserReducer.userData?.ar_system?.cust_id
                    )
                    .then((res) => {
                        if (res.cust_addr_line_1 !== null && res.cust_addr_postcode !== null) {
                            setAddressLine(res.cust_addr_line_1);
                            setAddressLine2(res.cust_addr_line_2);
                            setPostcode(res.cust_addr_postcode);
                            setAddressState(res.cust_addr_state);
                        }
                    })
                    .catch((err) => console.error(err));
            }
        }, []);

        useEffect(() => {
            // if delivery frequency is not picked but there's options,
            // set delivery frequency to the first available option
            // same goes w delivery time

            if (deliveryFreq === "" && productDetail.delivery_frequency.length > 0) {
                const firstOption = productDetail.delivery_frequency[0];
                setDeliveryFreq(firstOption.label);
                sessionStorage.setItem("delivery_freq", JSON.stringify(firstOption.label));
            }
            if (productDetail.delivery_window !== null) {
                if (deliveryTime === "" && productDetail.delivery_window.time.length > 0) {
                    var firstOption = productDetail.delivery_window.time[0];
                    const startTime = timeFromInt(firstOption.start_time, {
                        format: 12,
                    });
                    const endTime = timeFromInt(firstOption.end_time, {
                        format: 12,
                    });
                    const timeDisplay = startTime + " - " + endTime;
                    setDeliveryTime(timeDisplay);
                    sessionStorage.setItem("delivery_time", JSON.stringify(timeDisplay));
                }
            }
        }, [deliveryFreq, deliveryTime]);

        useEffect(() => {
            if (
                JSON.parse(sessionStorage.getItem("bank_display_name")) === null &&
                selectedBank === "" &&
                bankList.length > 0
            ) {
                setSelectedBank(bankList[0]);
                sessionStorage.setItem(
                    "bank_display_name",
                    JSON.stringify(bankList[0].display_name[0])
                );
                sessionStorage.setItem("selected_bank", JSON.stringify(bankList[0]));
            }
        }, [bankList]);

        const updateAddress = () => {
            // update address information
            const customerId = store.getState().loggedInUserReducer.userData.ar_system.cust_id;
            var dataBody = {
                cust_addr_line_1: addressLine,
                cust_addr_line_2: addressLine2,
                cust_addr_state: addressState,
                cust_addr_postcode: postcode,
            };
            customerService
                .editCustomerFromAR(dataBody, customerId)
                .then((res) => {
                    return;
                })
                .catch((res) => {});
        };
        const getCurrentState = (currentPostcode) => {
            var getState = allPostcodes.filter(
                (postcode) => postcode.postcode === parseInt(currentPostcode)
            );
            if (getState.length > 0) {
                setAddressState(getState[0].state);
                sessionStorage.setItem("state", JSON.stringify(getState[0].state));
            } else {
                setAddressState("");
            }
        };

        return (
            <Accordion
                defaultActiveKey={accordionActiveKey}
                activeKey={accordionActiveKey}
                flush
                style={{ color: "black" }}
            >
                {productDetail.is_deliverable && (
                    <Accordion.Item eventKey={0} className="accordion-style text-left">
                        <Accordion.Header className="p-1">
                            <Container className="pr-1 pl-1">
                                <Row className="d-flex">
                                    <Col
                                        lg={8}
                                        xs={9}
                                        className="d-flex pr-0"
                                        style={{ alignSelf: "center", alignItems: "end" }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: store.getState().colorState.color,
                                                borderColor: store.getState().colorState.color,
                                            }}
                                            className="number-circle mr-3"
                                        >
                                            {completedSteps.includes(0) ? (
                                                <DoneIcon
                                                    style={{
                                                        color: store.getState().colorState
                                                            .font_color,
                                                    }}
                                                />
                                            ) : (
                                                <p style={{ paddingTop: ".2em" }}>1</p>
                                            )}
                                        </div>
                                        <h3
                                            style={{
                                                alignSelf: window.innerWidth < 991 && "center",
                                            }}
                                            className="mb-0 mobile-text"
                                        >
                                            Delivery Address
                                        </h3>
                                    </Col>
                                    <Col lg={4} xs={3} className="text-right">
                                        <Button
                                            style={{
                                                display:
                                                    (!completedSteps.includes(0) ||
                                                        accordionActiveKey === 0) &&
                                                    "none",
                                                backgroundColor: store.getState().colorState.color,
                                                borderColor: store.getState().colorState.color,
                                                color: store.getState().colorState.font_color,
                                            }}
                                            onClick={() => setAccordionActiveKey(0)}
                                        >
                                            <div className="d-flex">
                                                <EditIcon
                                                    className="mr-lg-2"
                                                    style={{
                                                        marginRight:
                                                            window.innerWidth > 991 && "1em",
                                                        fontSize: "1.3rem",
                                                        alignSelf: "center",
                                                    }}
                                                />
                                                <p
                                                    style={{
                                                        display: window.innerWidth < 991 && "none",
                                                    }}
                                                >
                                                    Edit
                                                </p>
                                            </div>
                                        </Button>
                                    </Col>
                                </Row>
                                <div style={{ display: accordionActiveKey === 0 && "none" }}>
                                    <h6 className="w-100 mt-3">
                                        {JSON.parse(sessionStorage.getItem("address_one"))}
                                    </h6>
                                    <h6 className="w-100">
                                        {JSON.parse(sessionStorage.getItem("address_two"))}
                                    </h6>
                                    <h6 className="w-100">
                                        {JSON.parse(sessionStorage.getItem("state"))}
                                    </h6>
                                    <h6 className="w-100">
                                        {JSON.parse(sessionStorage.getItem("postcode"))}
                                    </h6>
                                </div>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body className="pl-lg-5 pr-lg-5">
                            <Row>
                                <Form.Label className="pl-0">Address Line 1</Form.Label>
                                <Form.Control
                                    value={addressLine}
                                    onChange={(address) => {
                                        setAddressLine(address.target.value);
                                        sessionStorage.setItem(
                                            "address_line_1",
                                            JSON.stringify(address.target.value)
                                        );
                                    }}
                                    isInvalid={!addressValidation.address_line_1.valid}
                                    className="w-100"
                                />
                                <Form.Control.Feedback type="invalid" className="pl-0">
                                    {addressValidation.address_line_1.error}
                                </Form.Control.Feedback>

                                <Form.Label className="pl-0 pt-3">Address Line 2</Form.Label>
                                <Form.Control
                                    value={addressLine2}
                                    onChange={(address) => {
                                        setAddressLine2(address.target.value);
                                        sessionStorage.setItem(
                                            "address_line_2",
                                            JSON.stringify(address.target.value)
                                        );
                                    }}
                                    className="w-100"
                                />
                            </Row>
                            <Row className="pt-3">
                                <Col className="pl-0">
                                    <Form.Label className="pl-0">State</Form.Label>
                                    <Form.Control
                                        value={addressState}
                                        disabled
                                        onChange={(state) => {
                                            setAddressState(state.target.value);
                                        }}
                                        className="w-100"
                                    />
                                </Col>
                                <Col className="pl-0 pr-0">
                                    <Form.Label className="pl-0">Postcode</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={postcode}
                                        onChange={(postcode) => {
                                            if (postcode.target.value.length >= 5) {
                                                if (postcode.target.value.length === 7) {
                                                    postcode.preventDefault();
                                                } else {
                                                    getCurrentState(postcode.target.value);
                                                    setPostcode(postcode.target.value);
                                                    sessionStorage.setItem(
                                                        "postcode",
                                                        JSON.stringify(postcode.target.value)
                                                    );
                                                }
                                            } else {
                                                setPostcode(postcode.target.value);
                                            }
                                        }}
                                        isInvalid={!addressValidation.postcode.valid}
                                        className="w-100"
                                    />
                                    <Form.Control.Feedback type="invalid" className="pl-0">
                                        {addressValidation.postcode.error}
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row className="justify-content-center pt-3">
                                <SaveAndContinue
                                    nextStep={productDetail.is_deliverable ? 1 : 2}
                                    currentStep={0}
                                    checker={(nextStep, currentStep) => {
                                        // not the most ideal solution,
                                        // check postcode api first then only check address line 1
                                        // if (addressLine === null || addressLine === ""){

                                        profileService
                                            .getPostcode(postcode)
                                            .then((postcode_data) => {
                                                var addressValid = true;
                                                if (addressLine === null || addressLine === "") {
                                                    addressValid = false;
                                                }
                                                setAddressValidation({
                                                    ...addressValidation,
                                                    address_line_1: {
                                                        ...addressValidation.address_line_1,
                                                        valid: addressValid,
                                                    },
                                                    postcode: {
                                                        ...addressValidation.postcode,
                                                        valid: true,
                                                    },
                                                });
                                                if (addressValid) {
                                                    sessionStorage.setItem(
                                                        "address_one",
                                                        JSON.stringify(addressLine)
                                                    );
                                                    sessionStorage.setItem(
                                                        "address_two",
                                                        JSON.stringify(addressLine2)
                                                    );
                                                    sessionStorage.setItem(
                                                        "state",
                                                        JSON.stringify(addressState)
                                                    );
                                                    sessionStorage.setItem(
                                                        "postcode",
                                                        JSON.stringify(postcode)
                                                    );
                                                    sessionStorage.setItem(
                                                        "delivery_fee",
                                                        JSON.stringify(postcode_data.postcode.price)
                                                    );
                                                    const planPrice = JSON.parse(
                                                        sessionStorage.getItem("plan_price")
                                                    );
                                                    if (
                                                        window.location.hostname.includes(
                                                            "wildkombucha"
                                                        ) &&
                                                        ((planPrice > 55 &&
                                                            (postcode_data.postcode.area ===
                                                                "Klang Valley" ||
                                                                postcode_data.postcode.area ===
                                                                    "Selangor")) ||
                                                            (planPrice > 150 &&
                                                                (postcode_data.postcode.area !==
                                                                    "Klang Valley" ||
                                                                    postcode_data.postcode.area !==
                                                                        "Selangor")))
                                                    ) {
                                                        setDeliveryFee(0);
                                                    } else {
                                                        setDeliveryFee(
                                                            postcode_data.postcode.price
                                                        );
                                                    }

                                                    setAccordionActiveKey(nextStep);
                                                    var newSteps = [...completedSteps, currentStep];
                                                    newSteps = [...new Set(newSteps)];
                                                    setCompletedSteps(newSteps);
                                                    updateAddress();
                                                }
                                            })
                                            .catch((err) => {
                                                setAddressValidation({
                                                    ...addressValidation,
                                                    postcode: {
                                                        ...addressValidation.postcode,
                                                        valid: false,
                                                    },
                                                });
                                            });
                                    }}
                                />
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                )}
                {productDetail.date_exist && (
                    <Accordion.Item
                        eventKey={1}
                        className={`accordion-style  ${
                            productDetail.is_deliverable && "mt-4"
                        }  text-left`}
                    >
                        <Accordion.Header className="p-1">
                            <Container className="pr-1 pl-1">
                                <Row className="d-flex">
                                    <Col
                                        lg={8}
                                        xs={9}
                                        className="d-flex"
                                        style={{ alignSelf: "center", alignItems: "end" }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor: store.getState().colorState.color,
                                                borderColor: store.getState().colorState.color,
                                            }}
                                            className="number-circle mr-3"
                                        >
                                            {completedSteps.includes(1) ? (
                                                <DoneIcon
                                                    style={{
                                                        color: store.getState().colorState
                                                            .font_color,
                                                    }}
                                                />
                                            ) : productDetail.date_exist &&
                                              productDetail.is_deliverable ? (
                                                <p style={{ paddingTop: ".2em" }}>2</p>
                                            ) : (
                                                <p style={{ paddingTop: ".2em" }}>1</p>
                                            )}
                                        </div>{" "}
                                        <h3 className="mb-0 align-self-center mobile-text">
                                            {productDetail.is_deliverable &&
                                            productDetail.date_usage
                                                ? "Delivery Date"
                                                : "Subscription Start Date"}
                                        </h3>
                                    </Col>
                                    <Col lg={4} xs={3} className="ml-auto text-right">
                                        <Button
                                            style={{
                                                display:
                                                    (!completedSteps.includes(1) ||
                                                        accordionActiveKey === 1) &&
                                                    "none",
                                                backgroundColor: store.getState().colorState.color,
                                                borderColor: store.getState().colorState.color,
                                                color: store.getState().colorState.font_color,
                                            }}
                                            onClick={() => setAccordionActiveKey(1)}
                                        >
                                            <div className="d-flex">
                                                <EditIcon
                                                    className="mr-lg-2"
                                                    style={{
                                                        marginRight:
                                                            window.innerWidth > 991 && "1em",
                                                        fontSize: "1.3rem",
                                                        alignSelf: "center",
                                                    }}
                                                />
                                                <p
                                                    style={{
                                                        display: window.innerWidth < 991 && "none",
                                                    }}
                                                >
                                                    Edit
                                                </p>
                                            </div>
                                        </Button>
                                    </Col>
                                </Row>
                                <div
                                    className="pt-3"
                                    style={{ display: accordionActiveKey === 1 && "none" }}
                                >
                                    {/* depending on delivery frequency, 
                                        first delivery renders user input 
                                        second delivery renders user input + delivery freq */}
                                    {productDetail.is_deliverable ? (
                                        <>
                                            <h6 className="w-100">
                                                {`${
                                                    productDetail.purchase_option.recurring
                                                        ? "First delivery"
                                                        : "Delivery"
                                                } on ${
                                                    deliveryDate.getDate() +
                                                    " " +
                                                    deliveryDate.toLocaleString("default", {
                                                        month: "long",
                                                    })
                                                } ${deliveryDate.getFullYear()}`}
                                                {deliveryTime !== "" && `, ${deliveryTime}`}
                                            </h6>
                                            {productDetail.purchase_option.recurring && (
                                                <h6 className="w-100">
                                                    Second delivery on
                                                    {` ${
                                                        deliveryDate.getDate() +
                                                        " " +
                                                        new Date(
                                                            convertDeliveryDate()
                                                        ).toLocaleString("default", {
                                                            month: "long",
                                                        })
                                                    } ${deliveryDate.getFullYear()}`}
                                                    {deliveryTime !== "" && `, ${deliveryTime}`}
                                                </h6>
                                            )}
                                        </>
                                    ) : (
                                        <h6>
                                            Subscription start date:{" "}
                                            {`${deliveryDate.toLocaleString("default", {
                                                month: "long",
                                            })} ${deliveryDate.getFullYear()}`}
                                        </h6>
                                    )}
                                </div>
                            </Container>
                        </Accordion.Header>
                        <Accordion.Body>
                            {productDetail.date_lead_time > 0 && (
                                <Form.Label>
                                    Orders must be placed {productDetail.date_lead_time} days before
                                    delivery date
                                </Form.Label>
                            )}
                            <Form.Label className="w-100">
                                Select a{" "}
                                {productDetail.is_deliverable ? "delivery" : "subscription start"}{" "}
                                {productDetail.date_format === "DD/MM/YY" ? "date" : "month"}
                            </Form.Label>
                            {productDetail.date_format === "MM/YY" ? (
                                <DatePicker
                                    selected={deliveryDate}
                                    className="w-100 picker-overflow"
                                    onChange={(date) => {
                                        setDeliveryDate(date);

                                        sessionStorage.setItem(
                                            "delivery_date",
                                            JSON.stringify(date)
                                        );
                                    }}
                                    minDate={new Date()}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                />
                            ) : (
                                <InputGroup className="w-100">
                                    <Form.Control
                                        value={deliveryDate.toLocaleDateString("en-TT")}
                                        style={{ borderRight: "0" }}
                                    />
                                    <InputGroup.Text
                                        style={{
                                            backgroundColor: "white",
                                            border: "1px solid black",
                                            borderLeft: "0",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setOpenDeliveryWind(true)}
                                        id="basic-addon1"
                                    >
                                        <EventIcon />
                                    </InputGroup.Text>
                                </InputGroup>
                            )}
                            <Modal
                                show={openDeliveryWind}
                                onHide={() => {
                                    setOpenDeliveryWind(false);
                                }}
                                centered
                            >
                                {/* <Modal.Header closeButton>
                                        <Modal.Title>Modal heading</Modal.Title>
                                    </Modal.Header> */}
                                <Modal.Body className="d-flex justify-content-center">
                                    <Calendar
                                        onChange={(date) => {
                                            setDeliveryDate(date);
                                            sessionStorage.setItem(
                                                "delivery_date",
                                                JSON.stringify(date)
                                            );
                                        }}
                                        tileDisabled={tileDisabled}
                                        minDate={minDate}
                                    />
                                </Modal.Body>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        onClick={() => {
                                            sessionStorage.removeItem("delivery_date");
                                            setDeliveryDate(deliveryDate);
                                            sessionStorage.setItem(
                                                "delivery_date",
                                                JSON.stringify(new Date())
                                            );
                                            setOpenDeliveryWind(false);
                                        }}
                                        style={{
                                            backgroundColor: store.getState().colorState.color,
                                            borderColor: store.getState().colorState.color,
                                            color: store.getState().colorState.font_color,
                                        }}
                                        className="close-delivery-window-button"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        style={{
                                            backgroundColor: store.getState().colorState.color,
                                            borderColor: store.getState().colorState.color,
                                            color: store.getState().colorState.font_color,
                                        }}
                                        onClick={() => setOpenDeliveryWind(false)}
                                    >
                                        Confirm
                                    </Button>
                                </div>
                            </Modal>
                            {productDetail.is_deliverable &&
                                productDetail.delivery_frequency.length > 0 && (
                                    <>
                                        <Form.Label className="w-100 pt-3">
                                            Select a delivery frequency
                                        </Form.Label>
                                        <Form.Control
                                            class="form-control"
                                            style={{
                                                border: "1px solid black",
                                                borderRadius: "5px",
                                                color: "grey",
                                            }}
                                            value={deliveryFreq}
                                            placeholder="Preferred language"
                                            isInvalid={!dateValidation.delivery_freq.valid}
                                            as="select"
                                            onChange={(e) => {
                                                setDeliveryFreq(e.target.value);
                                                sessionStorage.setItem(
                                                    "delivery_freq",
                                                    JSON.stringify(deliveryFreq)
                                                );
                                            }}
                                        >
                                            <option hidden></option>
                                            {productDetail.delivery_frequency.map((freq) => {
                                                return (
                                                    <option value={freq.value}>{freq.label}</option>
                                                );
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid" className="pl-0">
                                            {dateValidation.delivery_freq.message}
                                        </Form.Control.Feedback>
                                    </>
                                )}
                            {productDetail.delivery_window !== null &&
                                productDetail.is_deliverable &&
                                productDetail.date_format === "DD/MM/YY" &&
                                productDetail.delivery_window.time.length > 0 && (
                                    <>
                                        <Form.Label className="w-100 pt-3">
                                            Select a delivery time
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={(e) => {
                                                setDeliveryTime(e.target.value);
                                                sessionStorage.setItem(
                                                    "delivery_time",
                                                    JSON.stringify(e.target.value)
                                                );
                                            }}
                                            value={deliveryTime}
                                        >
                                            <option hidden />

                                            {productDetail.delivery_window.time.map((e) => {
                                                const startTime = timeFromInt(e.start_time, {
                                                    format: 12,
                                                });
                                                const endTime = timeFromInt(e.end_time, {
                                                    format: 12,
                                                });

                                                // start time to end time
                                                // append to tempdeliverytime
                                                const timeDisplay = startTime + " - " + endTime;

                                                return (
                                                    <option value={timeDisplay}>
                                                        {timeDisplay}
                                                    </option>
                                                );
                                            })}
                                        </Form.Control>
                                    </>
                                )}{" "}
                            <Row className="justify-content-center pt-3">
                                <SaveAndContinue
                                    nextStep={2}
                                    currentStep={1}
                                    checker={(nextStep, currentStep) => {
                                        setAccordionActiveKey(nextStep);
                                        var newSteps = [...completedSteps, currentStep];
                                        newSteps = [...new Set(newSteps)];
                                        setCompletedSteps(newSteps);
                                    }}
                                />
                            </Row>
                        </Accordion.Body>
                    </Accordion.Item>
                )}

                <Accordion.Item
                    eventKey={2}
                    className={`accordion-style  ${
                        (productDetail.is_deliverable || productDetail.date_exist) && "mt-4"
                    }`}
                >
                    <Accordion.Header className="p-1">
                        <Container className="pr-1 pl-1">
                            <Row className="d-flex">
                                <Col
                                    lg={8}
                                    xs={9}
                                    className="d-flex pr-0"
                                    style={{ alignSelf: "center", alignItems: "end" }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: store.getState().colorState.color,
                                            borderColor: store.getState().colorState.color,
                                        }}
                                        className="number-circle mr-3"
                                    >
                                        {completedSteps.includes(2) ? (
                                            <DoneIcon
                                                style={{
                                                    color: store.getState().colorState.font_color,
                                                }}
                                            />
                                        ) : productDetail.is_deliverable &&
                                          productDetail.date_exist ? (
                                            <p style={{ paddingTop: ".2em" }}>3</p>
                                        ) : productDetail.date_exist ||
                                          productDetail.is_deliverable ? (
                                            <p style={{ paddingTop: ".2em" }}>2</p>
                                        ) : (
                                            <p style={{ paddingTop: ".2em" }}>1</p>
                                        )}
                                    </div>{" "}
                                    <h3 className="mb-0 align-self-center mobile-text">
                                        Payment Method
                                    </h3>
                                </Col>
                                <Col lg={4} xs={3} className="ml-auto text-right">
                                    <Button
                                        style={{
                                            display:
                                                (!completedSteps.includes(2) ||
                                                    accordionActiveKey === 2) &&
                                                "none",
                                            backgroundColor: store.getState().colorState.color,
                                            borderColor: store.getState().colorState.color,
                                            color: store.getState().colorState.font_color,
                                        }}
                                        onClick={() => setAccordionActiveKey(2)}
                                    >
                                        <div className="d-flex">
                                            <EditIcon
                                                className="mr-lg-2"
                                                style={{ fontSize: "1.3rem", alignSelf: "center" }}
                                            />
                                            <p
                                                style={{
                                                    display: window.innerWidth < 991 && "none",
                                                }}
                                            >
                                                Edit
                                            </p>
                                        </div>
                                    </Button>
                                </Col>
                            </Row>
                            <div
                                className="pt-3"
                                style={{
                                    display: accordionActiveKey === 2 && "none",
                                }}
                            >
                                <h6 className="w-100">
                                    <strong>Payment Type:</strong>{" "}
                                    {paymentMethod === "fpx"
                                        ? "Savings/Current Account (FPX)"
                                        : "Debit/Credit Card"}
                                </h6>
                                {paymentMethod === "fpx" && (
                                    <h6 className="w-100">
                                        <strong>Bank:</strong>{" "}
                                        {JSON.parse(sessionStorage.getItem("bank_display_name"))}{" "}
                                    </h6>
                                )}
                            </div>
                        </Container>
                    </Accordion.Header>
                    <Accordion.Body className="text-left">
                        <Row>
			   {console.log(creditCardSettings,'this is the credit card settings')}
			    {creditCardSettings?.online_banking_enable[0] && (
                                <Col>
                                    <Button
                                        value={"fpx"}
                                        onClick={() => {
                                            setPaymentMethod("fpx");
                                            setDisplayPaymentMethod("fpx");
                                            sessionStorage.setItem(
                                                "payment_method",
                                                JSON.stringify("fpx")
                                            );
                                        }}
                                        className={`w-100 p-2 m-0 ${
                                            paymentMethod === "fpx"
                                                ? "selected-payment-method-selection"
                                                : "payment-method-selection"
                                        }`}
                                        style={{
                                            backgroundColor: store.getState().colorState.color,
                                            borderColor: store.getState().colorState.color,
                                            // color: store.getState().colorState.font_color,
                                            display: window.innerWidth < 991 && "flex",
                                            alignItems: window.innerWidth < 991 && "center",
                                        }}
                                    >
                                        <strong style={{ fontSize: "smaller" }}>
                                            Savings/Current Account
                                        </strong>
                                        <img src={fpx} alt="fpx logo" className="ml-2" />
                                    </Button>
                                </Col>
                            )}
                            {creditCardSettings?.credit_card_enable[0] &&
                                (creditCardSettings?.credit_card_instant_pay[0] ===
                                    productDetail.purchase_option.recurring ||
                                    creditCardSettings?.credit_card_mandate[0] ===
                                        productDetail.purchase_option.one_off) && (
                                    <Col style={{ paddingTop: window.innerWidth < 991 && "1em" }}>
                                        <Button
                                            value={"visa"}
                                            onClick={() => {
                                                setPaymentMethod("visa");
                                                setDisplayPaymentMethod("visa");
                                                sessionStorage.setItem(
                                                    "payment_method",
                                                    JSON.stringify("visa")
                                                );
                                            }}
                                            className={`w-100 p-2 m-0 ${
                                                paymentMethod === "visa"
                                                    ? "selected-payment-method-selection"
                                                    : "payment-method-selection"
                                            }`}
                                            style={{
                                                backgroundColor: store.getState().colorState.color,
                                                borderColor: store.getState().colorState.color,
                                                // color: store.getState().colorState.font_color,
                                            }}
                                        >
                                            <strong style={{ fontSize: "smaller" }}>
                                                Credit/Debit Card
                                            </strong>
                                            <img
                                                src={visa_card}
                                                alt="visa mastercard logo"
                                                className="ml-2"
                                            />
                                        </Button>
                                    </Col>
                                )}
                        </Row>
                        {paymentMethod === "fpx" && creditCardSettings.online_banking_enable[0] && (
                            <>
                                <Form.Label className="pt-3">Select your bank</Form.Label>
                                <Form.Select
                                    class="form-control"
                                    style={{
                                        border: "1px solid black",
                                        borderRadius: "5px",
                                        color: "grey",
                                    }}
                                    value={JSON.parse(sessionStorage.getItem("selected_bank")) !== null 
					    ? JSON.parse(sessionStorage.getItem("selected_bank")).id
					    : selectedBank}
                                    as="select"
                                    onChange={(e) => {
                                        setSelectedBank(e.target.value);

                                        const bankDetails = bankList.filter(
                                            (bank) => bank.id[0] === parseInt(e.target.value)
                                        );
                                        sessionStorage.setItem(
                                            "bank_display_name",
                                            JSON.stringify(bankDetails[0].display_name)
                                        );
					console.log(bankDetails,'this is the bank details');
                                        sessionStorage.setItem(
                                            "selected_bank",
                                            JSON.stringify(bankDetails[0])
                                        );
                                    }}
                                >
                                    {/* get bank list */}
                                    {bankList.map((bank, index) => {
                                        return <option value={bank.id}>{bank.display_name}</option>;
                                    })}
                                </Form.Select>
                            </>
                        )}
                        <Row className="justify-content-center pt-3">
                            <SaveAndContinue
                                nextStep={3}
                                currentStep={2}
                                checker={(nextStep, currentStep) => {
                                    setAccordionActiveKey(nextStep);
                                    var newSteps = [...completedSteps, currentStep];
                                    newSteps = [...new Set(newSteps)];
                                    setCompletedSteps(newSteps);
                                }}
                            />
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        );
    }

    function OrderSummary() {
        const [discountCode, setDiscountCode] = useState(
            JSON.parse(sessionStorage.getItem("discount_code")) !== null
                ? JSON.parse(sessionStorage.getItem("discount_code"))
                : undefined
        );

        // useEffect(() => {
        //     if (JSON.parse(sessionStorage.getItem("discount_data")) !== null) {
        //         setDiscountData(JSON.parse(sessionStorage.getItem("discount_data")));
        //     }
        // }, [discountData]);
        const stepsCompleted = () => {
            // if dateExist and deliverable, equals 3
            // if dateExist, equals 2
            // if none, equals 1
            // compare length
            // use the cases
            if (productDetail.is_deliverable && productDetail.date_exist) {
                if (completedSteps.length === 3) {
                    return false;
                }
                return true;
            }
            if (productDetail.date_exist && !productDetail.is_deliverable) {
                if (completedSteps.length === 2) {
                    return false;
                }
                return true;
            }
            if (!productDetail.is_deliverable && !productDetail.date_exist) {
                if (completedSteps.length === 1) {
                    return false;
                }
                return true;
            }
        };
        const imagePreview = (location) => {
            return `${window.location.protocol + "//" + window.location.hostname}${
                process.env.REACT_APP_SITE_PORT_BACKEND
            }${location[0].image_name}`;
        };

        const checkout = () => {
            // check if fpx or visa
            // if fpx, redirect to
            // check categories restriction again
            var userInputValid = true;
            const quantityExist = productDetail.quantity_exist;

            if (quantityExist) {
                const quantityRestrictions = productDetail.quantity_restrictions;
                if (quantityRestrictions?.apply_to === "individual") {
                    var categoryValidate = [];
                    selectedItems.forEach((item, category_index) => {
                        // check each of the category row against the quantity restriction
                        quantityRestrictions.category.forEach((category, category_index) => {
                            if (category.category === item.name) {
                                // sum all the quantity of the selected items
                                var totalItemQuantity = 0;
                                item.items.forEach((item, item_index) => {
                                    totalItemQuantity += item.quantity;
                                });
                                if (category.number !== totalItemQuantity) {
                                    categoryValidate.push(category_index);
                                    userInputValid = false;
                                }
                            }
                        });
                    });
                    setItemSelectionValidation({
                        ...itemValidation,
                        index: categoryValidate,
                    });
                }
                if (quantityRestrictions?.apply_to === "all") {
                    var itemQuantity = 0;

                    selectedItems.forEach((item, category_index) => {
                        item.items.forEach((item_detail) => {
                            itemQuantity += parseInt(item_detail.quantity);
                        });
                    });
                    if (itemQuantity !== quantityRestrictions.category[0].number) {
                        setItemSelectionValidation({
                            ...itemValidation,
                            valid: false,
                        });
                        userInputValid = false;
                    } else {
                        setItemSelectionValidation({
                            ...itemValidation,
                            valid: true,
                        });
                    }
                }
            }
            if (userInputValid) {
                const paymentMethod = JSON.parse(sessionStorage.getItem("payment_method"));
                // check categories item here
                // if(productDetail.categories_item.length == 0 ){
                //     productDetail.categories_item = JSON.parse(sessionStorage.getItem("selected_items"))
                // }
                var newCategoriesItem = productDetail.categories_item;
                newCategoriesItem.categories.map((category) => {
                    var itemArr = [];
                    category.items.forEach((item) => {
                        if (item.checked || item.quantity > 0) {
                            itemArr.push(item);
                            console.log(item, "item arrayy");
                        }
                    });
                    category.items = itemArr;
                });

                console.log(newCategoriesItem, "new categories item");
                var dataBody = {
                    ...productDetail,
                    categories_item: newCategoriesItem,
                    subs_price: calcTotalPrice(),
		   
		    delivery_fee: JSON.parse(sessionStorage.getItem("delivery_fee")),
                    delivery_date: JSON.parse(sessionStorage.getItem("delivery_date")),
                    delivery_time: JSON.parse(sessionStorage.getItem("delivery_time")),
                    delivery_frequency: JSON.parse(sessionStorage.getItem("delivery_freq")),
                    subs_type: JSON.parse(sessionStorage.getItem("purchase_option")),
                    subs_status:
                        store.getState().loggedInUserReducer.userData?.ar_system
                            ?.cust_mandate_ref === null
                            ? "inactive"
                            : "pending",
                    delivery_address: `${JSON.parse(sessionStorage.getItem("address_one"))} ${
                        JSON.parse(sessionStorage.getItem("address_two")) !== null
                            ? JSON.parse(sessionStorage.getItem("address_two"))
                            : ""
                    }`,
                    customer_id: store.getState().loggedInUserReducer.userData?.ar_system?.cust_id,
                    customer_state: JSON.parse(sessionStorage.getItem("state")),
                    customer_fname:
                        store.getState().loggedInUserReducer.userData?.ar_system?.cust_fname,
                    customer_lname:
                        store.getState().loggedInUserReducer.userData?.ar_system?.cust_lname,
                    customer_email:
                        store.getState().loggedInUserReducer.userData?.ar_system?.cust_email,
                    customer_delivery_instructions:
                        store.getState().loggedInUserReducer.userData?.ar_system
                            ?.cust_delivery_instructions,
                    customer_contact_no:
                        store.getState().loggedInUserReducer.userData?.ar_system?.cust_contact_no,
                    customer_postcode: JSON.parse(sessionStorage.getItem("postcode")),
                    cust_mandate_type: paymentMethod,
                };
                const storageDiscountData = JSON.parse(sessionStorage.getItem("discount_data"));
                if (storageDiscountData !== null && storageDiscountData !== undefined) {
                    dataBody = { ...dataBody, promo_id: storageDiscountData.id };
                }
                console.log(dataBody, "data body");
                console.log(store.getState().loggedInUserReducer.userData,'this is the user data');
		const bankId = JSON.parse(sessionStorage.getItem("selected_bank"));
                if (isLoggedIn.userRole !== "merchant") {
                        subscriptionService
                            .createSubscription(dataBody)
                            .then((e) => {
                                redirectPurchaseOption(e, dataBody, paymentMethod, bankId);
                            })
                            .catch((e) => {});
                } else {
                    // swal error for admin
                    Swal.fire({
                        text: "You are not able to checkout using an admin account.",
                        icon: "error",
                        reverseButtons: true,
                        confirmButtonColor: store.getState().colorState.color,
                        confirmButtonText: "OK",
                    });
                }
            }
        };

        const redirectPurchaseOption = (res, dataBody, paymentMethod, bankId) => {
            const updateCustomerData = () => {
	    	var editDataBody = {
                	cust_mandate_type: paymentMethod === "fpx" ? 1 : 2,
                	cust_mandate_ref: store.getState().loggedInUserReducer.userData.ar_system.cust_id
            	}
		customerService.editCustomerFromAR(editDataBody, store.getState().loggedInUserReducer.userData.ar_system.cust_id)
	    } 
	    console.log(store.getState().loggedInUserReducer.userData,'this is the user data');	
	    const mandateStatus = store.getState().loggedInUserReducer.userData.ar_system.cust_mandate_status
	    console.log(mandateStatus,'this is the mandate status');
	    if (purchaseOption === "recurring") {
                // if customer has mandate, redirect to subscription page
                // need to add swal fire to indicate subscription is successful
                if (dataBody.cust_mandate_ref !== null && mandateStatus) {
                    var subsId = res["id"];
                    var data_body = { id: subsId };
                    subscriptionService.newActiveSubForInvoice(data_body).then((response) => {
                        Swal.fire({
                            title: "Congratulations!",
                            text: "You have subscribed to the plan! Click on the My Subscriptions Tab to view your Subscription",
                            icon: "success",
                            confirmButtonText: "OK",
                        });
                        history.push("/customer/order");
                    });
		} else if (!mandateStatus && !mandateStatus) {
                    // redirect to bank page if no mandate
                    updateCustomerData();
		    var subsId = res["id"];
                    window.open(
                       `${
                            process.env.REACT_APP_CURLEC_ENDPOINT
                        }/new-mandate?effectiveDate=&method=03&amount=${calcTotalPrice()}&frequency=MONTHLY&maximumFrequency=99&merchantId=${
                            curlecMerchantId.value
                        }&employeeId=${curlecEmployeeId.value}&emailAddress=${
                            store.getState().loggedInUserReducer.userData.ar_system.cust_email
                        }&name=${
                            store.getState().loggedInUserReducer.userData.ar_system.cust_fname
                        }%20${
                            store.getState().loggedInUserReducer.userData.ar_system.cust_lname
                        }&referenceNumber=${
                            store.getState().loggedInUserReducer.userData.ar_system.cust_id
                        }&purposeOfPayment=${productDetail.plan_id}&paymentMethod=${
                            paymentMethod === "fpx" ? 1 : 2
                        }&bankId=${bankId.id}&idType=OTHERS&idValue=12345&linkId=${subsId}`,
                        "_self"
                     );
                }
            } else if (purchaseOption === "one_off") {
                    var subsId = res["id"];
		     window.open(
                             `${
                                process.env.REACT_APP_CURLEC_ENDPOINT
                            }/new-instant-pay?effectiveDate=&method=03&merchantId=${
                                curlecMerchantId.value
                           }&employeeId=${curlecEmployeeId.value}&orderNo=${
                               subsId
                           }&email=${
                                store.getState().loggedInUserReducer.userData.ar_system.cust_email
                            }&description=${dataBody.plan_name.slice(0,18)}&notes=${
                               res.id
                           }&amount=${calcTotalPrice()}&paymentMethod=${
                                paymentMethod === "fpx" ? 1 : 2
                            }&bankCode=${bankId.code}`,
                            "_self"
                        );
		}
	    };

        const checkItemsLength = () => {
            var moreThanZero = false;
            selectedItems.forEach((item) => {
                const checkedItem = item.items.filter((item_detail) => !!item_detail.checked);
                if (checkedItem.length > 0) {
                    moreThanZero = true;
                }
            });
            return moreThanZero;
        };
        const selectionRestriction = (categoryName) => {
            const quantityRestriction = productDetail.quantity_restrictions;
            if (quantityRestriction?.apply_to === "all") {
                const quantityRestriction = productDetail.quantity_restrictions.category[0].number;
                return quantityRestriction;
            } else if (quantityRestriction?.apply_to === "individual") {
                const categoryNameRestriction = quantityRestriction.category.filter(
                    (restriction) => {
                        return restriction.category === categoryName;
                    }
                );
                // check if category name is in restriction
                if (categoryNameRestriction.length > 0) {
                    return categoryNameRestriction[0].number;
                }
            }
        };
        const calculateItemRowPrice = (row) => {
            var total = 0;
            row.items.map((item) => {
                if (item.checked) {
                    console.log(item, "item in pricing details");
                    if (!item.variant_exist) {
                        if (productDetail.quantity_exist) {
                            total += item.addon_price * item.quantity;
                        } else if (!productDetail.quantity_exist) {
                            total += item.addon_price;
                        }
                    } else if (item.variant_exist) {
                        if (productDetail.quantity_exist) {
                            total += parseFloat(item.chosen_variant_detail.price) * item.quantity;
                        } else if (!productDetail.quantity_exist) {
                            total += parseFloat(item.chosen_variant_detail.price);
                        }
                    }
                }
            });
            return total.toFixed(2);
        };

        const calculatePlanDiscountPrice = (total, disc_amount, disc_type, row) => {
            const processDiscountType = () => {
                console.log(disc_type, "this is the discount type");
                if (disc_type === "fixed_amount") {
                    setDiscountAmount(disc_amount);
                    setDiscountApplied(true);
                    return [total - disc_amount, true];
                } else if (disc_type === "percentage") {
                    setDiscountAmount((total * disc_amount) / 100);
                    setDiscountApplied(true);
                    return [total - (total * disc_amount) / 100, true];
                }
            };
            const processOperator = (deduct_value, row_option) => {
                var newTotalPrice = total;
                if (row_option?.discount_operator === "greater") {
                    newTotalPrice =
                        deduct_value > parseInt(row_option.amount) ? [processDiscountType(), true] : [total, false];
                }
                if (row_option?.discount_operator === "lesser") {
                    newTotalPrice =
                        deduct_value < parseInt(row_option.amount) ? [processDiscountType(), true] : [total, false];
                }
                if (row_option?.discount_operator === "equal") {
                    newTotalPrice =
                        deduct_value === parseInt(row_option.amount)
                            ? [processDiscountType(), true]
                            : [total, false];
                }
                return newTotalPrice;
            };
            // discount applied on to determine where it is going to be applied
            const processDiscount = (row_option, row_purchase_option) => {
                console.log(row_option, "this is the row option");
                console.log(purchaseOption, "purhcase option");
                switch (row_option?.discount_on) {
                    case "purchase_option":
                        // differentiate based on disc_type
                        if (
                            (row_option?.discount_operator === "one_time_subs" &&
                                purchaseOption === "one_off") ||
                            (row_option?.discount_operator === "is_subs" &&
                                purchaseOption === "recurring")
                        ) {
                            return row_purchase_option !== undefined
                                ? processOperator(total, row_purchase_option)
                                : processDiscountType();
                        } else {
                            return [total, false];
                        }
                        break;

                    case "total_price":
                        return processOperator(total, row_option);
                    case "quantity":
                        var totalMenuQuantityChosen = 0;
                        // loop through selected menu
                        // compare with quantity in row
                        // if row operator is fulfilled, deduct discount amount from total
                        selectedItems.forEach((item) => {
                            item.items.forEach((selected_item) => {
                                totalMenuQuantityChosen += selected_item.quantity;
                            });
                        });
                        return processOperator(totalMenuQuantityChosen, row_option);
                    default:
                        return [total, false];
                }
            };
            // if mandatory row has purchase_option as value, check optional row instead
            // if optional row has purchase_option as value, check mandatory row
            // if both dont have purchase_option in discount_on, processOperator as usual
            var planDiscountPrice;
	   
            if (row.optional_row !== null) {
                if (row.optional_row?.discount_on === "purchase_option") {
                    planDiscountPrice = processDiscount(row.optional_row, row.mandatory_row)[0];
                } else if (row.mandatory_row?.discount_on === "purchase_option") {
                    planDiscountPrice = processDiscount(row.mandatory_row, row.optional_row)[0];
                } else {
                    if (row.optional_row.operator === "or") {
			if (processDiscount(row.mandatory_row)[1] && processDiscount(row.optional_row)[1]){
			    planDiscountPrice = processDiscount(row.mandatory_row)[0];
                            planDiscountPrice = processDiscount(row.optional_row)[0];
			} else if (processDiscount(row.mandatory_row)[1] && !processDiscount(row.optional_row)[1]) {
			    planDiscountPrice = processDiscount(row.mandatory_row)[0];
			} else if (!processDiscount(row.mandatory_row)[1] && processDiscount(row.optional_row)[1]) {
			    planDiscountPrice = processDiscount(row.optional_row)[0];
			}
                    } else if (row.optional_row.operator === "and") {
			if (processDiscount(row.mandatory_row)[1] && processDiscount(row.optional_row)[1]){
			    planDiscountPrice = processDiscount(row.mandatory_row)[0];
			    planDiscountPrice = processDiscount(row.optional_row)[0];
			} 
                    }
		}
            } else if (row.optional_row === null) {
                if (row.mandatory_row?.discount_on === "purchase_option") {
                    planDiscountPrice = processDiscount(row.mandatory_row)[0];
                } else {
                    planDiscountPrice = processDiscount(row.mandatory_row)[0];
                }
            }
	    console.log(planDiscountPrice,'this is the plan discount price');
            return planDiscountPrice;
        };

        useEffect(()=>{calcTotalPrice()},[discountData])
	
	const calculateUserDiscountPrice = (total, planPrice, postcodeFee, totalItemsPrice) => {
            // check discount_data.discount_type
            //      if discount_data.discount_type === "fixed_amount"
            //      if discount_data.discount_type === "percentage"
            //          if discount_data.category === "order"
            //              take total price and minus discount off of everything except delivery fee
            //          if discount_data.category === "delivery"
            //              setDe
            //          deduct total based on result

            if (discountData?.discount_type === "fixed_amount") {
                if (discountData.category === "order") {
                    var orderPrice = planPrice + totalItemsPrice;
                    var newOrderPrice = orderPrice - discountData.discount_amount;
                    setDiscountAmount(discountData.discount_amount);
                    setDiscountApplied(true);
                    return newOrderPrice + postcodeFee;
                } else if (discountData?.category === "delivery") {
                    var newDeliveryFee = postcodeFee - discountData?.discount_amount;
                    setDiscountAmount(discountData.discount_amount) 
		    return planPrice + totalItemsPrice + newDeliveryFee;
                }
            } else if (discountData?.discount_type === "percentage") {
                if (discountData?.category === "order") {
                    var orderPrice = planPrice + totalItemsPrice;
                    var newOrderPrice =
                        orderPrice - orderPrice * (discountData?.discount_amount / 100);            
		    setDiscountAmount(orderPrice * (discountData?.discount_amount / 100));
		    return newOrderPrice + postcodeFee;
                } else if (discountData?.category === "delivery") {
                    var newDeliveryFee = orderPrice * (discountData?.discount_amount / 100);
                    return planPrice + totalItemsPrice + newDeliveryFee;
                }
            }
            return total;
        };
        const calcTotalPrice = () => {
            var total = 0;
            const planPrice =
                productDetail.pricing_model === "flat_fee" ? productDetail.plan_price : 0;
            var postcodeFee = deliveryFee !== undefined ? deliveryFee : 0;
            var totalItemsPrice = 0;
            if (checkItemsLength()) {
                selectedItems.forEach((items) => {
                    items.items.forEach((item) => {
                        if (item.checked) {
                            if (!item.variant_exist) {
                                if (productDetail.quantity_exist) {
                                    totalItemsPrice += item.addon_price * item.quantity;
                                } else if (!productDetail.quantity_exist) {
                                    totalItemsPrice += item.addon_price;
                                }
                            } else if (
                                item.variant_exist &&
                                item.chosen_variant_detail !== undefined
                            ) {
                                if (productDetail.quantity_exist) {
                                    totalItemsPrice +=
                                        parseFloat(item.chosen_variant_detail?.price) *
                                        item.quantity;
                                } else if (!productDetail.quantity_exist) {
                                    totalItemsPrice += parseFloat(
                                        item.chosen_variant_detail?.price
                                    );
                                }
                            }
                        }
                    });
                });
            }
            total = planPrice + postcodeFee + totalItemsPrice;
            // evaluate on discount that is tied to the plan
            console.log(productDetail.discount_setup,'this is the discoutn setup');
	    console.log(discountData,'discount data')
	    if (productDetail.discount_setup.length > 0) {
		var planDiscountPrice = calculatePlanDiscountPrice(
                    total,
                    productDetail.discount_setup[0]?.discount_amount,
                    productDetail.discount_setup[0]?.discount_type,
                    productDetail.discount_setup[0]
                );
		console.log(planDiscountPrice,'this is the plan discount price');
                total = planDiscountPrice;
            }

            // if customer inputted valid discount
            // deduct total price with discount price
            const userDiscountPrice = calculateUserDiscountPrice(
                total,
                planPrice,
                postcodeFee,
                totalItemsPrice
            );
            console.log(userDiscountPrice,'this is the user discount price');
	    sessionStorage.setItem("plan_price", JSON.stringify(userDiscountPrice));
            return Array.isArray(userDiscountPrice) ? userDiscountPrice[0].toFixed(2) : userDiscountPrice.toFixed(2);
        };

        const applyDiscount = () => {
            const paymentMethod = JSON.parse(sessionStorage.getItem("payment_method"));
            promotionService
                .validatePromo({
                    discount_code: discountCode,
                    payment_type: paymentMethod,
                    plan_id: productDetail.plan_id,
                    customer_id:
                        store.getState()?.loggedInUserReducer?.userData?.ar_system?.cust_id,
                })
                .then((success) => {
                    // swal fire here
                    // deduct discount price to total price
                    setDiscountData(success);
                    sessionStorage.setItem("discount_data", JSON.stringify(success));
                    setDiscountApplied(true);
		    toast.success(
                        <div className="d-flex">
                            <CheckCircleIcon className="mr-2" />
                            <p>Discount applied</p>
                        </div>,
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                })
                .catch((err) => {
                    // discount does not exist
                    console.log(err, "this is the error");
                    toast.error(
                        <div className="d-flex">
                            <ReportRoundedIcon className="mr-2" />
                            <p>
                                {err.message !== ""
                                    ? err.message
                                    : "Discount does not exist. Please try again."}
                            </p>
                        </div>,
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                    console.error(err);
                });
        };

        return (
            <Card
                className="text-left p-lg-2 pt-1"
                style={{ marginTop: window.innerWidth < 991 && "1em" }}
            >
                <Card.Body>
                    {checkItemsLength() && (
                        <h3 className="mb-0 ">
                            <strong>Order Summary</strong>
                        </h3>
                    )}
                    {!itemValidation.valid && (
                        <div
                            className="d-flex align-items-center p-1 pr-3"
                            style={{
                                backgroundColor: "#E86F7D",
                                color: "white",
                                maxWidth: "fit-content",
                            }}
                        >
                            <ErrorIcon />
                            <p className="pl-3">
                                You can only choose{" "}
                                {productDetail.quantity_restrictions.category[0].number} across all
                                categories
                            </p>
                        </div>
                    )}

                    {selectedItems.map((item, item_key) => {
                        const checkedItem = item.items.filter(
                            (item_detail) => !!item_detail.checked
                        );
                        // category name
                        // checked menus

                        //lmao
                        return (
                            <Container className={`${checkedItem.length > 0 && "pt-3"} p-0`}>
                                {checkedItem.length > 0 && (
                                    <>
                                        <h4 className="mb-0 ">{item.name}</h4>
                                    </>
                                )}
                                {(itemValidation.index.includes(item_key) ||
                                    (!itemValidation.valid &&
                                        productDetail.quantity_restrictions.apply_to !==
                                            "all")) && (
                                    <div
                                        className="d-flex align-items-center p-1 pr-3"
                                        style={{
                                            backgroundColor: "#E86F7D",
                                            color: "white",
                                            maxWidth: "fit-content",
                                        }}
                                    >
                                        <ErrorIcon />
                                        <p className="pl-3">
                                            You can only choose{" "}
                                            {selectionRestriction(item.name, item_key)} items in
                                            this category
                                        </p>
                                    </div>
                                )}
                                {checkedItem.map((item, item_num) => {
                                    return (
                                        <Row className="pt-3">
                                            <Col lg={4} className="d-flex align-self-center">
                                                {" "}
                                                <img
                                                    className="w-100"
                                                    style={{ borderRadius: "6px" }}
                                                    src={imagePreview(item.addon_image)}
                                                />
                                            </Col>
                                            <Col className="pl-lg-0">
                                                <div className="w-100 justify-content-between  d-flex">
                                                    <p>{item.addon_name} </p>{" "}
                                                    <ClearIcon
                                                        onClick={() => {
                                                            const allSelectedItems = [
                                                                ...selectedItems,
                                                            ];
                                                            var allCurrentCatItems =
                                                                selectedItems[item_key].items;
                                                            const currentIndexInSelItems =
                                                                allCurrentCatItems.findIndex(
                                                                    (result) =>
                                                                        result.addon_id ===
                                                                        item.addon_id
                                                                );
                                                            allCurrentCatItems[
                                                                currentIndexInSelItems
                                                            ].quantity = 0;
                                                            allCurrentCatItems[
                                                                currentIndexInSelItems
                                                            ].checked = false;
                                                            allSelectedItems[item_key].items =
                                                                allCurrentCatItems;
                                                            sessionStorage.setItem(
                                                                "selected_items",
                                                                JSON.stringify(allSelectedItems)
                                                            );
                                                            setSelectedItems(allSelectedItems);
                                                        }}
                                                        style={{ color: "grey", cursor: "pointer" }}
                                                    />
                                                </div>

                                                <div className="pt-3 d-flex justify-content-between align-items-center">
                                                    <p>
                                                        {!item.variant_exist &&
                                                            !productDetail.quantity_exist &&
                                                            `RM${item.addon_price.toFixed(2)}`}
                                                        {!item.variant_exist &&
                                                            productDetail.quantity_exist &&
                                                            `RM${(
                                                                item.quantity * item.addon_price
                                                            ).toFixed(2)}`}
                                                        {item.variant_exist &&
                                                            productDetail.quantity_exist &&
                                                            `RM${(
                                                                item.quantity *
                                                                item.chosen_variant_detail.price
                                                            ).toFixed(2)}`}
                                                        {item.variant_exist &&
                                                            !productDetail.quantity_exist &&
                                                            `RM${parseFloat(
                                                                item.chosen_variant_detail.price
                                                            ).toFixed(2)}`}
                                                    </p>
                                                    {productDetail.quantity_exist && (
                                                        <div className="d-flex align-items-center">
                                                            <p
                                                                className="pl-2 pr-2 mr-2"
                                                                style={{
                                                                    borderRadius: "6px",
                                                                    backgroundColor:
                                                                        store.getState().colorState
                                                                            .color,
                                                                    borderColor:
                                                                        store.getState().colorState
                                                                            .color,
                                                                    color: store.getState()
                                                                        .colorState.font_color,
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    // filter current addon_id against
                                                                    // minus 1 from item.quantity
                                                                    const allSelectedItems = [
                                                                        ...selectedItems,
                                                                    ];
                                                                    const allCurrentCatItems =
                                                                        selectedItems[item_key]
                                                                            .items;
                                                                    var currentItemData =
                                                                        allCurrentCatItems.filter(
                                                                            (check_item) =>
                                                                                check_item.addon_id ===
                                                                                item.addon_id
                                                                        );
                                                                    const currentIndexInSelItems =
                                                                        allCurrentCatItems.findIndex(
                                                                            (result) =>
                                                                                result.addon_id ===
                                                                                item.addon_id
                                                                        );
                                                                    console.log(
                                                                        currentItemData,
                                                                        "current item data quantity"
                                                                    );
                                                                    if (
                                                                        currentItemData[0]
                                                                            .quantity > 0
                                                                    ) {
                                                                        currentItemData[0].quantity -= 1;
                                                                        allCurrentCatItems[
                                                                            currentIndexInSelItems
                                                                        ] = currentItemData[0];
                                                                    }

                                                                    allSelectedItems[
                                                                        item_key
                                                                    ].items = allCurrentCatItems;
                                                                    sessionStorage.setItem(
                                                                        "selected_items",
                                                                        JSON.stringify(
                                                                            allSelectedItems
                                                                        )
                                                                    );
                                                                    setSelectedItems(
                                                                        allSelectedItems
                                                                    );
                                                                }}
                                                            >
                                                                -
                                                            </p>{" "}
                                                            <p>{item.quantity}</p>{" "}
                                                            <p
                                                                className="pl-2 pr-2 ml-2"
                                                                style={{
                                                                    backgroundColor:
                                                                        store.getState().colorState
                                                                            .color,
                                                                    borderColor:
                                                                        store.getState().colorState
                                                                            .color,
                                                                    color: store.getState()
                                                                        .colorState.font_color,
                                                                    borderRadius: "6px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => {
                                                                    const allSelectedItems = [
                                                                        ...selectedItems,
                                                                    ];
                                                                    const allCurrentCatItems =
                                                                        selectedItems[item_key]
                                                                            .items;
                                                                    var currentItemData =
                                                                        allCurrentCatItems.filter(
                                                                            (check_item) =>
                                                                                check_item.addon_id ===
                                                                                item.addon_id
                                                                        );
                                                                    const currentIndexInSelItems =
                                                                        allCurrentCatItems.findIndex(
                                                                            (result) =>
                                                                                result.addon_id ===
                                                                                item.addon_id
                                                                        );

                                                                    currentItemData[0].quantity += 1;
                                                                    allCurrentCatItems[
                                                                        currentIndexInSelItems
                                                                    ] = currentItemData[0];

                                                                    allSelectedItems[
                                                                        item_key
                                                                    ].items = allCurrentCatItems;
                                                                    sessionStorage.setItem(
                                                                        "selected_items",
                                                                        JSON.stringify(
                                                                            allSelectedItems
                                                                        )
                                                                    );
                                                                    setSelectedItems(
                                                                        allSelectedItems
                                                                    );
                                                                }}
                                                            >
                                                                +
                                                            </p>{" "}
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    );
                                })}{" "}
                            </Container>
                        );
                    })}
                    {/* Discount application */}

                    <div className={`pt-3`}>
                        <h4>Discount Code</h4>
                        <InputGroup hasValidation>
                            <Form.Control
                                value={discountCode}
                                onChange={(code) => {
                                    sessionStorage.setItem(
                                        "discount_code",
                                        JSON.stringify(code.target.value)
                                    );
                                    setDiscountCode(code.target.value);
                                }}
                                className="border-right-0"
                            />
                            <InputGroup.Text
                                style={{
                                    backgroundColor: store.getState().colorState.color,
                                    borderColor: store.getState().colorState.color,
                                    color: store.getState().colorState.font_color,
                                    border: "1px solid black",
                                    cursor: "pointer",
                                }}
                                onClick={() => applyDiscount()}
                            >
                                Apply
                            </InputGroup.Text>
                        </InputGroup>
                        {discountApplied && (
                            <div
                                className="p-2 pl-3 mt-3"
                                style={{
                                    backgroundColor: "#59C19F",
                                    borderRadius: "6px",
                                    color: "white",
                                }}
                            >
                                <p>Discount applied</p>
                            </div>
                        )}
                    </div>

                    {/* Price breakdown */}
                    {/* categories item breakdown */}
                    {/* check selecteditems length, need a function to sum up all items price (quantity * price),
                     based on pricing model*/}
                    <div className="pt-1">
                        {checkItemsLength() &&
                            selectedItems.map((items) => {
                                const itemRowPrice = calculateItemRowPrice(items);
                                return (
                                    <div className="d-flex w-100 pt-2 justify-content-between">
                                        <p>{items.name}</p>
                                        <p>RM{itemRowPrice}</p>
                                    </div>
                                );
                            })}
                        {productDetail.pricing_model === "flat_fee" && (
                            <div className="d-flex w-100 pt-2 justify-content-between">
                                <p>Plan price</p>
                                <p>RM{productDetail.plan_price.toFixed(2)}</p>
                            </div>
                        )}

                        {deliveryFee !== undefined  && (
                            <div className="d-flex w-100 pt-2 justify-content-between">
                                <p>Delivery fee</p>
                                <p>RM{deliveryFee.toFixed(2)}</p>
                            </div>
                        )}
                        {discountApplied && (
                            <div
                                style={{ color: "#59C19F" }}
                                className="d-flex w-100 pt-2 justify-content-between"
                            >
                                <p>Discount</p>
                                <p>- RM{discountAmount.toFixed(2)}</p>
                            </div>
                        )}
                        <div className="d-flex w-100 pt-2 justify-content-between">
                            <p>Total</p>
                            <p>
                                RM
                                {calcTotalPrice()}
                            </p>
                        </div>
                        <div className="d-flex justify-content-end">
                            <p style={{ fontSize: "10px" }}>
                                {purchaseOption === "recurring" &&
                                    !productDetail.subs_auto_renew &&
                                    `Every ${productDetail.subs_cycle_unit} for
                                ${productDetail.subs_cycle_count} ${productDetail.subs_cycle_unit}(s)`}
                                {purchaseOption === "recurring" &&
                                    productDetail.subs_auto_renew &&
                                    `Every ${productDetail.subs_cycle_unit}`}
                                {/* {purchaseOptionOption === "one_off" } */}
                            </p>
                        </div>
                    </div>
                    <div style={{ cursor: stepsCompleted() && "not-allowed" }} className="pt-3">
                        {/* if all activated steps is not in yet */}
                        <Button
                            disabled={stepsCompleted()}
                            onClick={() => checkout()}
                            className="p-2 w-100 m-0"
                            style={{
                                backgroundColor: store.getState().colorState.color,
                                borderColor: store.getState().colorState.color,
                                color: store.getState().colorState.font_color,
                            }}
                        >
                            <div className="d-flex justify-content-center">
                                <LockIcon className="mr-2" />
                                <p>Secure Checkout</p>
                            </div>
                        </Button>
                    </div>
                    <p
                        className="pt-3 text-align-justify"
                        style={{ fontSize: "12px", textAlign: "justify" }}
                    >
                        {JSON.parse(sessionStorage.getItem("purchase_option")) === "one_off" &&
                            JSON.parse(sessionStorage.getItem("payment_method")) === "fpx" && (
                                <div className="d-flex">
                                    <p>
                                        By clicking "Secure Checkout", I fully understand and agree
                                        to the{" "}
                                        <a
                                            href="https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp"
                                            target="_blank"
                                        >
                                            Terms & Conditions{" "}
                                        </a>
                                        of Instant Pay Services.
                                    </p>
                                </div>
                            )}
                        {JSON.parse(sessionStorage.getItem("purchase_option")) === "one_off" &&
                            JSON.parse(sessionStorage.getItem("payment_method")) === "visa" &&
                            ` By clicking "Secure Checkout", I acknowledge that upon successful completion of this online authorisation, my card will be charged RM${calcTotalPrice()}. I am aware that this transaction line item will show up as CURLEC MY-ECOM on my credit card statement.`}
                        {JSON.parse(sessionStorage.getItem("purchase_option")) === "recurring" &&
                            JSON.parse(sessionStorage.getItem("payment_method")) === "fpx" && (
                                <p>
                                    I fully understand and agree to the{" "}
                                    <a href="terms-and-condition" target="_blank">
                                        Terms & Conditions
                                    </a>{" "}
                                    of Direct Debit services and I acknowledge that upon successful
                                    completion of this online application, RM1.00 shall be debited
                                    from my selected account to ensure the said account is active
                                    for the purpose of application for this service. Please note
                                    that Curlec Sdn Bhd at its sole discretion may refund the RM1.00
                                    subject to its own Terms & Condition. Please contact Curlec Sdn
                                    Bhd for further details.
                                </p>
                            )}
                        {JSON.parse(sessionStorage.getItem("purchase_option")) === "recurring" &&
                            JSON.parse(sessionStorage.getItem("payment_method")) === "visa" && (
                                <p>
                                    By completing this purchase, I consent to my card details being
                                    tokenized for future payments. I understand that RM
                                    {calcTotalPrice()} will be charged to my credit/debit card on a
                                    {productDetail.subs_cycle_unit}ly basis. Where the payment
                                    amount is variable, every transaction is limited to RM
                                    {maxMandateVisa.toFixed(2)}. I am aware that this transaction
                                    will show up as CURLEC MY-RECURRING on my credit card
                                    statement.`
                                </p>
                            )}
                    </p>
                </Card.Body>
            </Card>
        );
    }

    return (
        // 2 column layout
        <Container className="pricing-detail-container">
            <Row className="pt-3">
                <Col lg={8} className="pl-0 pr-0">
                    <FlushExample />
                </Col>
                <Col lg={4} className="pl-lg-4 pl-0 pr-0">
                    <OrderSummary />
                </Col>
            </Row>
        </Container>
    );
};

export default PricingDetail;
