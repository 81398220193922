import { callAPI, callCurlecAPI } from "../../utils/apiUtils";
import axios from "axios";

const subscriptionService = {
    getSubscriptionListing: (pageIndex = 2, search = "", parameter = "") => {
        return new Promise((resolve, reject) => {
            callAPI(
                `/subscription/merc_list?search=${search}&page=${pageIndex}${parameter}`
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getAllSubscriptionWithoutPagination: () => {
        return new Promise((resolve, reject) => {
            callAPI(`/subscription/merc_list/all`)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getSingleSubscription: (subsId) => {
        return new Promise((resolve, reject) => {
            callAPI(`/subscription/${subsId}`, "get")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    updateSubscriptionListing: (subsList) => {
        return new Promise((resolve, reject) => {
            callAPI(`/subscription/update_listing/`, "post", subsList)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    editSubscription: (subsId, dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/subscription/${subsId}`, "patch", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    deleteSubscription: (subsId) => {
        return new Promise((resolve, reject) => {
            callAPI(`/subscription/${subsId}`, "delete")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    reactivateSubscription: (subsId, dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/subscription/${subsId}`, "patch", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    createSubscription: (dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/subscription/`, "post", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getBanksForSubscription: (dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/subscription/banks/`, "post", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    newActiveSubForInvoice: (dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/subscription/new_subscription/`, "post", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    newSubEmail: (dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/subscription/new_subs_email/`, "post", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    cancelSubEmail: (dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/subscription/cancel_subs_email/`, "post", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    // check if customer has done instant pay & mandate auth
    // this is only for farcapital
    getCustomerProgress: (dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/subscription/get_customer_progress/`, "post", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    updateCustomerSubscription: (planId) => {
        return new Promise((resolve, reject) => {
            callAPI(`/subscription/update_customer_subscription/`, "patch", {
                plan_id: planId,
            })
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    exportSubsCSV: () => {
        return new Promise((resolve, reject) => {
            callAPI(`/subscription/export_csv/`, "post")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
};

export default subscriptionService;
