import { Button } from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import "react-bootstrap-tagsinput/dist/index.css";
import ReactLoading from "react-loading";
import { useHistory, useParams } from "react-router-dom";
import Tracker from "../../../../Components/Tracker/tracker";
import { store } from "../../../../index.js";
import planImagesService from "../../../../services/planImagesService";
import planService from "../../../../services/planService";
import DateStep from "./Steps/DateStep";
import InformationStep from "./Steps/InformationStep";
import ItemStep from "./Steps/ItemStep";
import PricingStep from "./Steps/PricingStep";
import "./style.css";

const ProductForm = () => {
    const history = useHistory();
    const params = useParams();
    const steps = ["Information", "Item", "Date", "Pricing"];

    const [productImage, setProductImage] = useState(
        JSON.parse(sessionStorage.getItem("product_images")) === null
            ? []
            : JSON.parse(sessionStorage.getItem("product_images"))
    );
    const [productData, setProductData] = useState();

    const [imageCropModel, setImageCropModel] = useState({
        isOpened: false,
        selectedImageDataUrl: null,
        crop: { x: 0, y: 0 },
        rotation: 0,
        zoom: 0.4,
        aspect: 1 / 1,
        croppedAreaPixels: null,
        croppedImage: null,
    });
    const [previewImg, setPreviewImg] = useState([]);
    const [completedModal, setCompletedModal] = useState(false);
    const [activeStep, setActiveStep] = useState(
        JSON.parse(sessionStorage.getItem("active_step")) !== null
            ? JSON.parse(sessionStorage.getItem("active_step"))
            : 0
    );
    const [completed, setCompleted] = useState({});
    const [completedAnimation, setCompletedAnimation] = useState(false);
    const [informationValidation, setInformationValidation] = useState({
        product_name: { error: "", invalid: false },
        product_summary: { error: "", invalid: false },
        product_desc: { error: "", invalid: false },
        product_image: { error: "", invalid: false },
    });
    const [itemValidation, setItemValidation] = useState({
        has_item: { value: false },
        category_name: { index: [], error: "Please provide a section name", invalid: false },
        category_items: { index: [], error: "Please select at least one item", invalid: false },
        addons: { value: false },
    });
    const [quantityRestrictValidation, setQuantityRestrictValidation] = useState({
        item_amount: { index: [], error: "Quantity cannot be empty or 0", invalid: false },
    });
    const [dateValidation, setDateValidation] = useState({
        section_name: { error: "Please provide a section name", invalid: false },
        delivery_freq: { error: "", invalid: false },
        delivery_window: { error: "", invalid: false },
    });
    const [pricingValidation, setPricingValidation] = useState({
        purchase_options: { error: "Please select at least one purchase option", invalid: true },
        product_price: { error: "Please provide a price", invalid: false },
    });
    const [subsConfigValidation, setSubsConfigValidation] = useState({
        subs_duration: { error: "Please provide a duration", invalid: false },
    });
    const [discountValidation, setDiscountValidation] = useState({
        discount_amount: { error: "Please provide a discount amount", invalid: false },
        amount: {
            error: "Amount cannot be 0",
            invalid_mandatory_row: [],
            invalid_optional_row: [],
        },
        discount_cap: { error: "Please provide a discount cap", invalid: false },
    });
    const currentStepContainer = () => {
        if (activeStep === 0) {
            return (
                <InformationStep
                    productData={productData}
                    validation={informationValidation}
                    handleValidation={handleInformationValidation}
                    previewImg={previewImg}
                    imageCropModel={imageCropModel}
                    productImage={productImage}
                    handlePreviewImg={handlePreviewImg}
                    handleImageCropModel={handleImageCropModel}
                    handleProductImage={handleProductImage}
                    changePreviewImg={changePreviewImg}
                    changeProductImg={changeProductImg}
                />
            );
        }
        if (activeStep === 1) {
            return (
                <ItemStep
                    productData={productData}
                    itemValidation={itemValidation}
                    handleItemValidation={handleItemValidation}
                    quantityValidation={quantityRestrictValidation}
                    handleQuantityValidation={handleQuantityValidation}
                />
            );
        }
        if (activeStep === 2) {
            return (
                <DateStep
                    productData={productData}
                    validation={dateValidation}
                    handleValidation={handleDateValidation}
                />
            );
        }
        if (activeStep === 3) {
            return (
                <>
                    <PricingStep
                        productData={productData}
                        pricingValidation={pricingValidation}
                        handlePricingValidation={handlePricingValidation}
                        handleSubsConfigValidation={handleSubsConfigValidation}
                        subsConfigValidation={subsConfigValidation}
                        discountValidation={discountValidation}
                        handleDiscountValidation={handleDiscountValidation}
                    />
                </>
            );
        }
    };

    useEffect(() => {
        // if completed animation is true, set timeout animation for processing
        if (completedAnimation) {
            setTimeout(() => {
                setCompletedAnimation(false);
            }, 500);
        }
    }, [completedAnimation]);
    const renderImage = (path) => {
        const imagePath =
            window.location.protocol +
            "//" +
            window.location.hostname +
            process.env.REACT_APP_SITE_PORT_BACKEND +
            path;
        return imagePath;
    };
    useEffect(() => {
        if (params.id !== "new") {
            // fetch plan using id
            planService
                .getSinglePlan(params.id)
                .then((res) => {
                    console.log(res);
                    const data = res.plans;
                    setProductData(data);
                    if (data.plan_image.length > 0) {
                        setProductImage(data.plan_image.map((image) => image.image_id));
                        setPreviewImg(
                            data.plan_image.map((image) => {
                                return {
                                    imagePreview: renderImage(image.image_name),
                                    imageId: image.image_id,
                                };
                            })
                        );
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, []);
    const uploadImage = async (data) => {
        console.log(data, "uploading image");
        const imageFiles = data.map((e) => {
            return e.imageFile;
        });
        var dataBody = {
            image_name: imageFiles,
        };
        const sendImage = await planImagesService
            .createPlanImage(dataBody)
            .then((res) => {
                const imageId = res.data.map((data) => {
                    return data.image_id;
                });
                return imageId;
            })
            .catch((response) => console.error(response));
        return sendImage;
    };

    const createNewPlan = async (dataBody) => {
        planService
            .createPlan(dataBody)
            .then((res) => {
                console.log(res);
                setCompletedModal(true);
                sessionStorage.clear();
            })
            .catch((response) => console.error(response));
    };

    const editPlan = async (dataBody, planId) => {
        planService
            .editPlan(dataBody, planId)
            .then((res) => {
                console.log(res);
                setCompletedModal(true);
                sessionStorage.clear();
            })
            .catch((response) => console.error(response));
    };
    const handleComplete = async () => {
        // based on active step, check if step
        function handleCompleteStep() {
            const newCompleted = completed;
            // console.log(completed, "new completed");
            // if activeStep is true in completed, dont set completed animation
            if (completed[activeStep]) {
                // newCompleted[activeStep] = false;
                // setCompleted(newCompleted);
                setCompletedAnimation(false);
            } else {
                setCompletedAnimation(true);
                newCompleted[activeStep] = true;
                setCompleted(newCompleted);
            }
            handleNext();
        }
        // every step needs to pass in validator in validation process
        var stepValid = true;
        var dataBody =
            JSON.parse(sessionStorage.getItem("dataBody")) !== null
                ? JSON.parse(sessionStorage.getItem("dataBody"))
                : {};
        if (activeStep === 0) {
            const productName = JSON.parse(sessionStorage.getItem("product_name"));
            const productSummary = JSON.parse(sessionStorage.getItem("product_summary"));
            const productDesc = JSON.parse(sessionStorage.getItem("product_desc"));
            console.log(productDesc, "this is desc line break");
            const productTags = JSON.parse(sessionStorage.getItem("product_tags"));
            var validationResult = {
                product_name: { error: "", invalid: false },
                product_summary: { error: "", invalid: false },
                product_desc: { error: "", invalid: false },
                product_image: { error: "", invalid: false },
            };
            // upload images and get ids, append to array
            if (previewImg.length === 0) {
                validationResult = {
                    ...validationResult,
                    product_image: { error: "Please upload an image", invalid: true },
                };
                stepValid = false;
            }
            if (previewImg.length > 0) {
                var images = [];
                const existingImage = previewImg.filter((img) => {
                    if (img.imageId) {
                        return img;
                    }
                });
                const newImage = previewImg.filter((img) => {
                    if (!img.imageId) {
                        return img;
                    }
                });
                var imageId = await uploadImage(newImage);
                if (imageId !== undefined) {
                    images = images.concat(imageId);
                }

                if (existingImage.length > 0) {
                    var retrieveExistingId = existingImage.map((img) => {
                        return img.imageId;
                    });

                    images = images.concat(retrieveExistingId);
                    console.log(images, "image id in concat");
                }
                console.log(images, "new image id");
                dataBody = {
                    ...dataBody,
                    plan_image: images,
                };
            }
            // console.log(productImage, "this is the product iamge");
            // product name should be mandatory
            // product name cannot be more than 50 characters
            if (productName === null || productName === "") {
                validationResult = {
                    ...validationResult,
                    product_name: {
                        error: "Please provide a product name",
                        invalid: true,
                    },
                };
                stepValid = false;
            }
            if (productName?.length > 50) {
                validationResult = {
                    ...validationResult,
                    product_name: {
                        error: "Product name should be less than 50 characters",
                        invalid: true,
                    },
                };
                stepValid = false;
            }
            if (productSummary === null || productSummary === "") {
                validationResult = {
                    ...validationResult,
                    product_summary: {
                        error: "Please provide a product summary",
                        invalid: true,
                    },
                };
                stepValid = false;
            }
            if (
                productSummary?.length +
                    (productSummary?.match(/\n/) !== null
                        ? productSummary?.match(/\n/gm).length
                        : 0) >
                150
            ) {
                validationResult = {
                    ...validationResult,
                    product_summary: {
                        error: "Product summary should be less than 150 characters",
                        invalid: true,
                    },
                };
                stepValid = false;
            }
            if (productDesc === null || productDesc === "") {
                validationResult = {
                    ...validationResult,
                    product_desc: {
                        error: "Please provide a product description",
                        invalid: true,
                    },
                };
                stepValid = false;
            }
            if (
                productDesc?.length +
                    (productDesc?.match(/\n/) !== null ? productDesc?.match(/\n/gm).length : 0) >
                500
            ) {
                validationResult = {
                    ...validationResult,
                    product_desc: {
                        error: "Product description should be less than 500 characters",
                        invalid: true,
                    },
                };
                stepValid = false;
            }
            console.log(validationResult, "this is the validation result");
            setInformationValidation(validationResult);
            if (stepValid) {
                dataBody = {
                    ...dataBody,
                    plan_name: productName,
                    plan_summary: productSummary,
                    plan_desc: productDesc,
                };
                if (productTags !== null) {
                    const productTagsValue = productTags.map((tags) => {
                        return tags.text;
                    });
                    // console.log(productTags, "this is product tags");
                    // console.log(productTagsValue, "this is the product tag value");
                    dataBody = {
                        ...dataBody,
                        product_tags: productTagsValue,
                    };
                }
                sessionStorage.setItem("dataBody", JSON.stringify(dataBody));
                handleCompleteStep();
            }
        } else if (activeStep === 1) {
            const itemChecker = JSON.parse(sessionStorage.getItem("item_checker"));
            const quantityChecker = JSON.parse(sessionStorage.getItem("quantity_checker"));
            const addonChecked = JSON.parse(sessionStorage.getItem("addons_checked"));

            const categoryColumn = JSON.parse(sessionStorage.getItem("categories_column"));
            const quantityRestrictions = JSON.parse(sessionStorage.getItem("restrictions"));
            const addonData = JSON.parse(sessionStorage.getItem("addons"));
            const quantityRestrictionsChecked = JSON.parse(
                sessionStorage.getItem("quantity_restrictions_checker")
            );
            const quantityRestrictionsApplied = JSON.parse(
                sessionStorage.getItem("restrictions_apply_to")
            );
            if (itemChecker && categoryColumn !== null) {
                // check category column validation
                // check quantity restrictions validation
                let categoryNameValidation = [];
                let categoryItemValidation = [];
                categoryColumn.map((column, key) => {
                    if (column.name === "") {
                        categoryNameValidation = [...itemValidation.category_name.index, key];
                        stepValid = false;
                    }
                    if (column.items.length === 0) {
                        categoryItemValidation = [...itemValidation.category_items.index, key];
                        stepValid = false;
                    }

                    setItemValidation({
                        ...itemValidation,
                        category_items: {
                            ...itemValidation.category_items,
                            index: categoryItemValidation,
                        },
                        category_name: {
                            ...itemValidation.category_name,
                            index: categoryNameValidation,
                        },
                    });
                });
            }
            if (quantityChecker) {
                if (quantityRestrictionsChecked) {
                    // check number of items allowed here
                    // if individual categories is selected, then quantity can be 0 and to be treated as unlimited amount

                    if (quantityRestrictionsApplied === "individual") {
                        quantityRestrictions.map((restriction, key) => {
                            const itemNumber = parseInt(restriction.number);
                            var updateIndex;
                            console.log(itemNumber, "this is the item number ");
                            if (
                                itemNumber === 0 ||
                                itemNumber === "" ||
                                itemNumber === null ||
                                isNaN(itemNumber)
                            ) {
                                updateIndex = [
                                    ...quantityRestrictValidation.item_amount.index,
                                    key,
                                ];
                                stepValid = false;
                            } else {
                                // remove current index from quantityRestrictValidation
                                updateIndex = quantityRestrictValidation.item_amount.index.filter(
                                    (index) => index !== key
                                );
                            }
                            updateIndex = [...new Set(updateIndex)];
                            setQuantityRestrictValidation({
                                ...quantityRestrictValidation,
                                item_amount: {
                                    ...quantityRestrictValidation.item_amount,
                                    index: updateIndex,
                                },
                            });
                        });
                    } else if (quantityRestrictionsApplied === "all") {
                        if (
                            quantityRestrictions.number === 0 ||
                            quantityRestrictions.number === "" ||
                            quantityRestrictions.number === null
                        ) {
                            setQuantityRestrictValidation({
                                ...quantityRestrictValidation,
                                item_amount: {
                                    ...quantityRestrictValidation.item_amount,
                                    invalid: true,
                                },
                            });
                            stepValid = false;
                        }
                    }
                }
            }
            if (addonChecked && addonData !== null) {
                if (addonData.length === 0) {
                    stepValid = false;
                    setItemValidation({
                        ...itemValidation,
                        addons: { value: true, error: "Please add at least one addon" },
                    });
                }
            }
            if (stepValid) {
                // add data to databody here
                dataBody = {
                    ...dataBody,
                    addon_exist: addonChecked,
                    item_exist: itemChecker,
                    quantity_exist: quantityChecker,
                    quantity_restrictions_exist: quantityRestrictionsChecked,
                    categories_item: JSON.stringify({
                        categories: categoryColumn,
                    }),
                    quantity_restrictions: quantityRestrictionsChecked
                        ? JSON.stringify({
                              apply_to: quantityRestrictionsApplied,
                              category: quantityRestrictions,
                          })
                        : null,
                    addon: addonData !== null ? addonData : [],
                };
                sessionStorage.setItem("dataBody", JSON.stringify(dataBody));
                handleCompleteStep();
            }
        } else if (activeStep === 2) {
            // if date picker is checked
            // check if section name is filled
            const dateChecker = JSON.parse(sessionStorage.getItem("require_date"));
            if (dateChecker) {
                const dateSectionName = JSON.parse(sessionStorage.getItem("date_section_name"));
                var sectionNameValid = false;
                if (dateSectionName === "" || dateSectionName === null) {
                    sectionNameValid = true;
                    stepValid = false;
                }
            }
            // if delivery picker is checked
            // make sure delivery frequency is at least 1
            // make sure delivery window is created
            const deliveryChecker = JSON.parse(sessionStorage.getItem("require_delivery"));
            if (deliveryChecker) {
                const deliveryFrequency = JSON.parse(sessionStorage.getItem("delivery_frequency"));
                console.log(deliveryFrequency, "selected delivery freqq");
                // const deliveryWindowId = JSON.parse(sessionStorage.getItem("delivery_window_id"));
                var deliveryFrequencyValid = false;
                // var deliveryWindowValid = false;
                if (deliveryFrequency?.length === 0) {
                    deliveryFrequencyValid = true;
                    stepValid = false;
                }
                // if (deliveryWindowId === null) {
                //     deliveryWindowValid = true;
                //     stepValid = false;
                // }
            }
            setDateValidation({
                ...dateValidation,
                section_name: {
                    ...dateValidation.section_name,
                    invalid: sectionNameValid,
                },
                delivery_freq: {
                    ...dateValidation.delivery_freq,
                    invalid: deliveryFrequencyValid,
                },
                // delivery_window: {
                //     ...dateValidation.delivery_window,
                //     invalid: deliveryWindowValid,
                // },
            });
            if (stepValid) {
                const dateFormat = JSON.parse(sessionStorage.getItem("date_field_format"));
                var dateSectionName = JSON.parse(sessionStorage.getItem("date_section_name"));
                if (dateSectionName === "others") {
                    dateSectionName = JSON.parse(sessionStorage.getItem("other_date_section_name"));
                }
                const dateLeadTime = JSON.parse(sessionStorage.getItem("lead_time"));
                const deliveryWindow = JSON.parse(sessionStorage.getItem("delivery_window_id"));
                const isDeliverable = JSON.parse(sessionStorage.getItem("require_delivery"));
                const allowSubsEdit = JSON.parse(sessionStorage.getItem("allow_subs_edit"));
                const daysEditable =
                    JSON.parse(sessionStorage.getItem("days_editable")) !== null
                        ? JSON.parse(sessionStorage.getItem("days_editable"))
                        : 0;
                var deliveryFrequency = JSON.parse(sessionStorage.getItem("delivery_frequency"));
                var rearrangeDelFreq = deliveryFrequency.map((freq) => freq.value);
                console.log(deliveryFrequency, "this is the delivery frequency");
                console.log(rearrangeDelFreq, "rearrange delivery freq");
                dataBody = {
                    ...dataBody,
                    date_exist: dateChecker,
                    date_format: dateFormat,
                    date_usage: dateSectionName,
                    date_lead_time: dateLeadTime !== null ? dateLeadTime : 0,
                    delivery_frequency: rearrangeDelFreq,
                    is_deliverable: isDeliverable,
                    allow_subscription_edit: allowSubsEdit,
                    days_before_edit: daysEditable,
                };
                if (deliveryWindow !== null) {
                    dataBody = {
                        ...dataBody,
                        delivery_window: deliveryWindow,
                    };
                }
                console.log(dataBody, "this is the databody");
                sessionStorage.setItem("dataBody", JSON.stringify(dataBody));
                handleCompleteStep();
            }
        } else if (activeStep === 3) {
            // Must choose at least 1 purchase option
            // Flat fee price should always be more than 0
            // duration default to 12 Months, more than 0,be less than 12, a number
            // if one of the option is true, set validation to true
            const purchaseOptions = JSON.parse(sessionStorage.getItem("purchase_options"));
            // check if there is any false value in purhcaseOptions dict
            // if there is, set validation to true
            var isValid = true;
            var tempPurchase = Object.keys(purchaseOptions).map(
                (option) => purchaseOptions[option]
            );

            console.log(tempPurchase, "temp purchase");
            var falseCount = 0;
            // for (let key in tempPurchase) {
            //     console.log(key, "key");
            //     falseCount += key === false ? 1 : 0;
            // }
            tempPurchase.forEach((purchase) => (falseCount += purchase === false ? 1 : 0));

            console.log(falseCount, "false count");
            if (falseCount === 2) {
                setPricingValidation({
                    ...pricingValidation,
                    purchase_options: {
                        ...pricingValidation.purchase_options,
                        invalid: true,
                    },
                });
                stepValid = false;
                return;
            } else {
                setPricingValidation({
                    ...pricingValidation,
                    purchase_options: {
                        ...pricingValidation.purchase_options,
                        invalid: false,
                    },
                });
            }
            const pricingModel = JSON.parse(sessionStorage.getItem("pricing_model"));
            const productPrice = parseFloat(JSON.parse(sessionStorage.getItem("product_price")));
            if (pricingModel === "flat_fee") {
                if (
                    productPrice === null ||
                    productPrice === 0.0 ||
                    productPrice === "" ||
                    productPrice === 0
                ) {
                    setPricingValidation({
                        ...pricingValidation,
                        product_price: {
                            ...pricingValidation.product_price,
                            invalid: true,
                        },
                    });
                    stepValid = false;
                    return;
                }
            }
            console.log(stepValid, "step valid");

            const subsDuration = JSON.parse(sessionStorage.getItem("subs_duration"));
            const subsDurationConfig = JSON.parse(
                sessionStorage.getItem("limited_period_subs_config")
            );
            if (subsDuration === "limited_period") {
                // need to check if duration is more than 1 or not empty
                if (subsDurationConfig.count === 0 || subsDurationConfig.count === null) {
                    setSubsConfigValidation({
                        ...subsConfigValidation,
                        subs_duration: { ...subsConfigValidation.subs_duration, invalid: true },
                    });
                    stepValid = false;
                }
            }

            // check discount setup
            // loop through each row, if discount_on is either "equal" or "lesser",
            // amount cannot be 0
            const discountSetup = JSON.parse(sessionStorage.getItem("discount_setup"));
            var updateMandatoryRow = [...discountValidation.amount.invalid_mandatory_row];
            var updateOptionalRow = [...discountValidation.amount.invalid_optional_row];
            discountSetup.map((row, key) => {
                // need to check mandatory_row and optional_row for their discount_operator and amount
                const mandatoryDiscountOperator = row.mandatory_row.discount_operator;
                const optionalDiscountOperator = row.optional_row?.discount_operator;

                if (
                    mandatoryDiscountOperator === "equal" ||
                    mandatoryDiscountOperator === "lesser"
                ) {
                    const mandatoryAmount = parseFloat(row.mandatory_row.amount);
                    if (
                        mandatoryAmount === 0.0 ||
                        mandatoryAmount === null ||
                        mandatoryAmount === "" ||
                        mandatoryAmount === 0
                    ) {
                        updateMandatoryRow = [...updateMandatoryRow, key];
                        stepValid = false;
                    } else {
                        updateMandatoryRow = discountValidation.amount.invalid_mandatory_row.filter(
                            (item) => item !== key
                        );
                    }
                } else if (mandatoryDiscountOperator === "greater") {
                    // remove key from invalid_mandatory_row
                    updateMandatoryRow = discountValidation.amount.invalid_mandatory_row.filter(
                        (item) => item !== key
                    );
                }

                if (optionalDiscountOperator === "equal" || optionalDiscountOperator === "lesser") {
                    const optionalAmount = parseFloat(row.optional_row.amount);
                    if (
                        optionalAmount === 0.0 ||
                        optionalAmount === null ||
                        optionalAmount === "" ||
                        optionalAmount === 0
                    ) {
                        // add key to invalid array
                        updateOptionalRow = [...updateOptionalRow, key];
                        stepValid = false;
                    } else {
                        updateOptionalRow = discountValidation.amount.invalid_optional_row.filter(
                            (item) => item !== key
                        );
                    }
                } else if (optionalDiscountOperator === "greater") {
                    // remove key from invalid_mandatory_row
                    updateOptionalRow = discountValidation.amount.invalid_optional_row.filter(
                        (item) => item !== key
                    );
                }

                // make sure updateMandatoryRow and updateOptionalRow have no duplicates
                updateMandatoryRow = [...new Set(updateMandatoryRow)];
                updateOptionalRow = [...new Set(updateOptionalRow)];
            });

            if (discountSetup !== undefined) {
                // make sure discount amount could never amount to 0
                const discountAmount = discountSetup[0]?.discount_amount;
                var discountAmountValid = false;
                if (discountAmount === 0.0 || discountAmount === null || discountAmount === "") {
                    discountAmountValid = true;
                    stepValid = false;
                    return;
                } else {
                    discountAmountValid = false;
                    stepValid = true;
                }
                setDiscountValidation({
                    ...discountValidation,
                    discount_amount: {
                        ...discountValidation.discount_amount,
                        invalid: discountAmountValid,
                    },
                    amount: {
                        ...discountValidation.amount,
                        invalid_mandatory_row: updateMandatoryRow,
                        invalid_optional_row: updateOptionalRow,
                    },
                });
                // create product here
            }
            if (stepValid) {
                const subsDuration = JSON.parse(sessionStorage.getItem("subs_duration"));
                const subsAutoRenew = JSON.parse(sessionStorage.getItem("subs_auto_renew"));
                const billingFrequency = JSON.parse(sessionStorage.getItem("billing_frequency"));
                const subsCycleUnit = JSON.parse(sessionStorage.getItem("subs_cycle_unit"));
                const subsCycleCount = JSON.parse(sessionStorage.getItem("subs_cycle_count"));

                dataBody = {
                    ...dataBody,
                    purchase_option: JSON.stringify(purchaseOptions),
                    pricing_model: pricingModel,
                    plan_price: parseFloat(productPrice),
                    discount_setup: JSON.stringify(discountSetup),
                    subs_duration_type: subsDuration,
                    billing_frequency: billingFrequency,
                    subs_cycle_count: subsCycleCount,
                    subs_cycle_unit: subsCycleUnit,
                    subs_auto_renew: subsAutoRenew,
                };
                console.log(dataBody, "dataBody 4");
                if (params.id !== "new") {
                    editPlan(dataBody, params.id);
                } else if (params.id === "new") {
                    createNewPlan(dataBody);
                    // handleCompleteStep();
                }
            }
        }
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };
    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                  steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            history.push("/");
        } else {
            var newCompleted = completed;
            if (completed[activeStep]) {
                newCompleted[activeStep] = false;
                setCompleted(newCompleted);
            }
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const totalSteps = () => {
        return steps.length;
    };

    const handleInformationValidation = (info) => {
        setInformationValidation({
            ...informationValidation,
            ...info,
        });
    };

    const handleProductImage = (image) => {
        setProductImage([...productImage, image]);
    };

    const handlePreviewImg = (image) => {
        setPreviewImg([...previewImg, image]);
    };

    const changePreviewImg = (images) => {
        setPreviewImg(images);
    };
    const changeProductImg = (images) => {
        setProductImage(images);
    };

    const handleImageCropModel = (image) => {
        setImageCropModel(image);
    };

    const handleItemValidation = (info) => {
        setItemValidation({
            ...itemValidation,
            ...info,
        });
    };

    const handleQuantityValidation = (info) => {
        setQuantityRestrictValidation({
            ...quantityRestrictValidation,
            ...info,
        });
    };

    const handleDateValidation = (info) => {
        setDateValidation({
            ...dateValidation,
            ...info,
        });
    };
    const handlePricingValidation = (info) => {
        setPricingValidation({
            ...pricingValidation,
            ...info,
        });
    };

    const handleSubsConfigValidation = (info) => {
        setSubsConfigValidation({
            ...subsConfigValidation,
            ...info,
        });
    };

    const handleDiscountValidation = (info) => {
        setDiscountValidation({
            ...discountValidation,
            ...info,
        });
    };
    const editOrCreate = () => {
        if (params.id !== "new") {
            return "Edit product";
        }
        return "Create product";
    };

    return (
        <div className="product-config-container">
            <h1 className="config-title"> Product Configuration</h1>
            <h4 className="config-subtitle">Create your product by following the steps below</h4>
            <div>
                <Tracker
                    steps={steps}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    completed={completed}
                    setCompleted={setCompleted}
                    handleComplete={handleComplete}
                    completedSteps={completedSteps}
                    totalSteps={totalSteps}
                />
            </div>
            <Modal
                show={completedModal}
                onHide={() => {
                    setCompletedModal(false);
                }}
                centered
                backdrop="static"
            >
                <Modal.Body>
                    <Container
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "1em",
                        }}
                    >
                        <CheckCircleIcon
                            style={{ fontSize: "72px", color: store.getState().colorState.color }}
                        />
                        <h4 className="text-center pt-3">
                            Product {`${params.id !== "new" ? "edit" : "creation"}`} successful
                        </h4>
                        <p>
                            Your product has been {`${params.id !== "new" ? "edited" : "created"}`}
                        </p>
                        <Button
                            class="btn-primary w-100 p-2 mt-4"
                            onClick={() => window.location.replace("/")}
                            style={{
                                backgroundColor: store.getState().colorState.color,
                                borderColor: store.getState().colorState.color,
                                color: store.getState().colorState.font_color,
                            }}
                        >
                            Return to Home
                        </Button>
                    </Container>{" "}
                </Modal.Body>
            </Modal>
            {completedAnimation && activeStep !== 4 ? (
                <div className="step-container slide-right config-loading-container">
                    <div style={{ verticalAlign: "center" }}>
                        <ReactLoading
                            type={"spokes"}
                            className="config-loading-icon"
                            color="#000000"
                        />
                        <p className="mt-5 text-center loading">Working on it</p>
                    </div>
                </div>
            ) : (
                currentStepContainer()
            )}
            {activeStep !== 4 && (
                <div style={{ marginTop: "1.5em" }}>
                    {activeStep >= 0 && (
                        <Button
                            className="back-button"
                            style={{
                                backgroundColor: store.getState().colorState.color,
                                borderColor: store.getState().colorState.color,
                                color: store.getState().colorState.font_color,
                            }}
                            onClick={handleBack}
                        >
                            {activeStep === 0 ? "Cancel" : "Back"}
                        </Button>
                    )}
                    <Button
                        // className="continue-button"
                        style={{
                            backgroundColor: store.getState().colorState.color,
                            borderColor: store.getState().colorState.color,
                            color: store.getState().colorState.font_color,
                        }}
                        onClick={() => {
                            handleComplete();
                        }}
                    >
                        <p className="config-continue">
                            {activeStep === 3 ? editOrCreate() : "Save & continue"}
                        </p>
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ProductForm;
