import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import { useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Container, Row, Image } from "react-bootstrap";
import ProductGridView from "../../Components/ProductGridView/productGridView";
import ProductListView from "../../Components/ProductListView/productListView";
import planService from "../../services/planService";
import empty_product_merchant from "../../Images/empty_product_merchant.png";
import "./homepage.css";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { store } from "../../index.js";
import { getMerchantLogo, toggleColor } from "../../action.js";
import profileService from "../../services/profileService";
import addOnService from "../../services/addOnService";
import userService from "../../services/userService";

const Homepage = () => {
    const history = useHistory();
    const [allProductsData, setAllProductsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userType, setUserType] = useState("");
    const [allAddonData, setAllAddonData] = useState([]);
    const isLoggedIn = useSelector((state) => state.loggedInUserReducer.userData);
    useLayoutEffect(() => {
        sessionStorage.clear();
        profileService
            .getProfile()
            .then((result) => {
                console.log(result, "this is the merchant settings");
                const imagePreview = `${
                    window.location.protocol + "//" + window.location.hostname
                }${process.env.REACT_APP_SITE_PORT_BACKEND}${result.settings.company_logo}`;
                console.log(imagePreview, "this is the image preview");
                store.dispatch(
                    toggleColor({
                        primary: result.settings.primary_colour,
                        secondary: result.settings.secondary_colour,
                    })
                );
                store.dispatch(
                    getMerchantLogo({
                        logo: imagePreview,
                    })
                );
            })
            .catch((err) => console.error(err));
	const userToken = JSON.parse(localStorage.getItem("user_token"));
	if (userToken !== null) {
	    userService.fetchUserDataWithTokenIntoRedux().then((res)=>console.log(res,'success retrieving user token')).catch((err)=>console.error(err));
	}
	console.log(JSON.parse(localStorage.getItem("user_token")),'this is the user token');
    }, []);

    useEffect(() => {
        // check userType

        if (isLoggedIn) {
            if (isLoggedIn.userRole === "merchant") {
                setUserType("merchant");
            } else if (isLoggedIn.userRole === "customer") {
                setUserType("customer");
            }
        } else {
            setUserType("guest");
        }
        // get all plans
        planService
            .getAllPlansWithoutPagination()
            .then((res) => {
                setLoading(false);
                const filterArchived = res.plans.filter((plan) => {
                    return plan.plan_status !== "archived" && plan.plan_status !== "deleted";
                });
                setAllProductsData(filterArchived);
            })
            .catch((err) => {
                console.log(err);
            });
        addOnService
            .getAllAddonWithoutPagination()
            .then((res) => {
                console.log(res, "all addon data in homepage");
                setAllAddonData(res);
            })
            .catch((err) => console.error(err));
    }, []);

    const [viewMode, setViewMode] = useState("grid");
    const renderViewType = () => {
        if (viewMode === "grid") {
            return <ProductGridView planData={allProductsData} addonData={allAddonData} />;
        } else if (viewMode === "list") {
            return <ProductListView planData={allProductsData} addonData={allAddonData} />;
        }
    };
    return (
        <>
            {!loading && (
                <Container
                    className="homepage-wrapper"
                    style={{
                        minHeight: "85vh",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Container className="homepage-display homepage-text">
                        {/* if user type is merchant and it has no data, show creation image */}
                        {/* if it has data, show homepage  */}

                        {userType === "merchant" && allProductsData.length === 0 && (
                            <Row
                                className={`${window.innerWidth < 991 ? "p-1" : "p-5"} text-center`}
                                style={{
                                    display: "flex",
                                    verticalAlign: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <img className="w-50" src={empty_product_merchant} />
                                <h2 className="text-center pt-4">
                                    There are currently no products available
                                </h2>
                                <p className="pt-2">
                                    Create your first product by clicking the button below
                                </p>
                                <Button
                                    style={{
                                        width: "60%",
                                        textTransform: " none",
                                        padding: ".6em",
                                        backgroundColor: store.getState().colorState.color,
                                        borderColor: store.getState().colorState.color,
                                        color: store.getState().colorState.font_color,
                                    }}
                                    onClick={() => history.push("/admin/product/new")}
                                    class="btn-primary"
                                >
                                    Create Product
                                </Button>
                            </Row>
                        )}
                        {allProductsData.length > 0 && (
                            <>
                                {" "}
                                <div className="title-bar">
                                    {" "}
                                    <div
                                        style={{ paddingTop: "1em" }}
                                        className="d-flex justify-content-between w-100"
                                    >
                                        <div className="d-flex">
                                            <h1 className="homepage-title mb-0 pl-1">
                                                All Products
                                            </h1>

                                            {isLoggedIn && isLoggedIn.userRole === "merchant" && (
                                                <Button
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        textTransform: "none",
                                                        backgroundColor:
                                                            store.getState().colorState.color,
                                                        borderColor:
                                                            store.getState().colorState.color,
                                                        color: store.getState().colorState
                                                            .font_color,
                                                        // margin: "8px",
                                                    }}
                                                    onClick={() => {
                                                        sessionStorage.clear();
                                                        history.push("/admin/product/new");
                                                    }}
                                                    className="ml-3"
                                                >
                                                    <AddIcon />
                                                    <p className="m-0 p-0 hide-text-mobile">
                                                        Add product
                                                    </p>
                                                </Button>
                                            )}
                                        </div>
                                        {/* <Button
                                            className="view-button"
                                            onClick={() => {
                                                viewMode === "grid"
                                                    ? setViewMode("list")
                                                    : setViewMode("grid");
                                            }}
                                            style={{
                                                backgroundColor: store.getState().colorState.color,
                                                borderColor: store.getState().colorState.color,
                                                color: store.getState().colorState.font_color,
                                            }}
                                        >
                                            <div className="button-display">
                                                {viewMode === "list" ? (
                                                    <>
                                                        <GridViewIcon className="layout-button-icon" />
                                                        <p
                                                            style={{ margin: "auto" }}
                                                            className="hide-text-mobile"
                                                        >
                                                            Grid View
                                                        </p>
                                                    </>
                                                ) : (
                                                    <>
                                                        <FormatListBulletedIcon className="layout-button-icon" />
                                                        <p
                                                            style={{ margin: "auto" }}
                                                            className="hide-text-mobile"
                                                        >
                                                            List View
                                                        </p>
                                                    </>
                                                )}{" "}
                                            </div>
                                        </Button> */}
                                    </div>
                                </div>
                                <div>{renderViewType()}</div>
                            </>
                        )}
                    </Container>
                </Container>
            )}
        </>
    );
};

export default Homepage;
