// import "./font/font.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "./App.css";
import Path from "./router";

function App() {
    return <Path />;
}

export default App;
