import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import SelectMultiple from "../../../../Components/SelectMultiple/SelectMultiple";
import { components } from "react-select";
import makeAnimated from "react-select/animated";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import CurrencyInput from "react-currency-input";
import { store } from "../../../../index.js";
import "./DiscountEditCreate.css";
import planService from "../../../../services/planService";
import promotionService from "../../../../services/promotionService";
import { useHistory, useParams } from "react-router-dom";
const DiscountEditCreate = () => {
    const history = useHistory();
    const params = useParams();
    const [discountName, setDiscountName] = useState("");
    const [discountCode, setDiscountCode] = useState("");
    const [discountDescription, setDiscountDescription] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [discountPlan, setDiscountPlan] = useState([]);
    const [maxRedemption, setMaxRedemption] = useState();
    const [discountAmount, setDiscountAmount] = useState(0);
    const [discountCap, setDiscountCap] = useState();
    const [discountDuration, setDiscountDuration] = useState("one_off");
    const [planList, setPlanList] = useState([]);
    const [discountType, setDiscountType] = useState("fixed_amount");
    const [validation, setValidation] = useState({
        discount_name: { invalid: false, message: "" },
        discount_code: { invalid: false, message: "" },
        discount_description: { invalid: false, message: "" },
        discount_applicability: { invalid: false, message: "" },
    });
    const [discountCategory, setDiscountCategory] = useState("order");
    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };
    const MultiValue = (props) => (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    );

    const animatedComponents = makeAnimated();
    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
        },
    });

    useEffect(() => {
        // fetch all plan data
        planService.getAllPlansWithoutPagination().then((plans) => {
            const allPlans = plans.plans;
            var applyDiscountPlan = [];
            allPlans.map((plan) => {
                var discountPlanDetails = {
                    value: plan.plan_id,
                    label: plan.plan_name,
                };
                applyDiscountPlan.push(discountPlanDetails);
            });
            // console.log(applyDiscountPlan, "this is the apply discount plan");
            setPlanList(applyDiscountPlan);
        });
        // TODO: 1. get current data item based on id
        // 2. populate field
        // 3. change button create to edit if id exist
        // 4. send to edit page
        // 5. edit row session storage
        if (params.id !== "new") {
            // get discount data using id
            promotionService
                .getSinglePromotion(params.id)
                .then((promo) => {
                    const promotionData = promo.promotion;
                    setDiscountName(promotionData.discount_name);
                    setDiscountCode(promotionData.discount_code);
                    setDiscountDescription(promotionData.discount_desc);
                    setDiscountDuration(promotionData.discount_duration);
                    setStartDate(promotionData.start_date);
                    setEndDate(promotionData.end_date);
                    setDiscountPlan(promotionData.discount_applied_plan);
                    setDiscountType(promotionData.discount_type);
                    setDiscountAmount(promotionData.discount_amount);
                    setDiscountCategory(promotionData.category);
                    setDiscountCap(promotionData.cap);
                    setMaxRedemption(promotionData.max_redemption);
                })
                .catch((err) => console.log(err, "this is the error"));
        }
    }, []);

    const validateForm = () => {
        // discount name cannot be more than 50 && empty
        // discount code cannot be more than 20 && empty
        // discount description cannot be more than 150
        // discount applicability cannot be 0
        var discountNameInvalid = false;
        var discountNameMessage = "";
        if (discountName.length > 50) {
            discountNameInvalid = true;
            discountNameMessage = "Discount name cannot be more than 50 characters.";
        }
        if (discountName.length === 0) {
            discountNameInvalid = true;
            discountNameMessage = "Discount name cannot be empty.";
        }
        var discountCodeInvalid = false;
        var discountCodeMessage = "";
        if (discountCode.length > 20) {
            discountCodeInvalid = true;
            discountCodeMessage = "Discount code cannot be more than 20 characters.";
        }
        if (discountCode.length === 0) {
            discountCodeInvalid = true;
            discountCodeMessage = "Discount code cannot be more than 20 characters.";
        }
        var discountDescInvalid = false;
        var discountDescMessage = "";
        if (discountDescription.length > 150) {
            discountDescInvalid = true;
            discountDescMessage = "Discount description cannot be more than 150 characters.";
        }
        var discountApplicabilityInvalid = false;
        var discountApplicabilityMessage = "";
        if (discountPlan.length === 0) {
            discountApplicabilityInvalid = true;
            discountApplicabilityMessage = "Discount should be applied on at least one plan.";
        }
        console.log(discountApplicabilityInvalid, "discount applicability invalid");

        setValidation({
            ...validation,
            discount_name: {
                invalid: discountNameInvalid,
                message: discountNameMessage,
            },
            discount_code: {
                invalid: discountCodeInvalid,
                message: discountCodeMessage,
            },
            discount_description: {
                invalid: discountDescInvalid,
                message: discountDescMessage,
            },
            discount_applicability: {
                invalid: discountApplicabilityInvalid,
                message: discountApplicabilityMessage,
            },
        });
        if (
            discountNameInvalid ||
            discountDescInvalid ||
            discountCodeInvalid ||
            discountApplicabilityInvalid
        ) {
            return false;
        }
        return true;
    };

    const createDiscount = () => {
        const formValid = validateForm();
        console.log(formValid, "form valid");
        if (formValid) {
            const appliedPlanId = discountPlan.map((plan) => plan.value);
            console.log(appliedPlanId, "this si hte plapied plan");
            var dataBody = {
                discount_name: discountName,
                discount_code: discountCode,
                discount_desc: discountDescription,
                discount_duration: discountDuration,
                start_date: startDate,
                end_date: endDate,
                discount_applied_plan: appliedPlanId,
                max_redemption: maxRedemption,
                discount_type: discountType,
                discount_amount: discountAmount,
                cap: discountCap,
                category: discountCategory,
            };
            if (params.id !== "new") {
                console.log("is it in?");
                promotionService
                    .updatePromotion(params.id, dataBody)
                    .then((success) => {
                        sessionStorage.setItem("edit_row", JSON.stringify(success.id));
                        history.push("/admin/discount/");
                    })
                    .catch((err) => console.log(err));
            }
            if (params.id === "new") {
                promotionService
                    .createPromo(dataBody)
                    .then((success) => {
                        // set session storage
                        // redirect to discount listing page
                        sessionStorage.setItem("new_row", JSON.stringify(success.id));
                        history.push("/admin/discount/");
                    })
                    .catch((err) => console.log(err));
                console.log(dataBody, "this is the databody");
            }
        }
    };

    const section1 = document.getElementById("disc_name");
    const section2 = document.getElementById("disc_code");
    const section3 = document.getElementById("disc_app");

    useEffect(() => {
        if (validation.discount_name.invalid)
            section1?.scrollIntoView({ behavior: "smooth", block: "center" });
        if (validation.discount_code.invalid)
            section2?.scrollIntoView({ behavior: "smooth", block: "center" });
        if (validation.discount_applicability.invalid)
            section3?.scrollIntoView({ behavior: "smooth", block: "center" });
    }, [validation]);

    return (
        <Container style={{ paddingBottom: "5em" }} className="mb-5 d-flex flex-column pt-4">
            <Row>
                <h1 className="font-weight-bolder">
                    {`${params.id !== "new" ? "Edit" : "Create"}`} Discount
                </h1>
                <h5>Create your discount by filling in the fields below</h5>
            </Row>
            <Row
                style={{ boxShadow: "4px 4px #E1E1E1", width: window.innerWidth < 991 && "80vw" }}
                className="pl-lg-4 pr-lg-4 text-left w-50-l bg-white pt-3 mt-3 pb-3 m-auto"
            >
                <Form.Group className="form-group required">
                    <Form.Label className="pl-0 pt-3 w-100 control-label">
                        <p style={{ float: "left" }}>Discount Name</p>
                        <p
                            style={{
                                float: "right",
                                color: validation.discount_name.invalid ? "red" : "black",
                            }}
                        >
                            {`${50 - discountName.length}`}
                        </p>
                    </Form.Label>
                    <Form.Control
                        value={discountName}
                        isInvalid={validation.discount_name.invalid}
                        onChange={(name) => setDiscountName(name.target.value)}
                    />
                    <Form.Control.Feedback type="invalid" id="disc_name">
                        {validation.discount_name.message}
                    </Form.Control.Feedback>{" "}
                    <Form.Label className="pl-0 w-100 pt-3 control-label">
                        <p style={{ float: "left" }}> Discount Code</p>
                        <p
                            style={{
                                float: "right",
                                color: validation.discount_code.invalid ? "red" : "black",
                            }}
                        >
                            {`${20 - discountCode.length}`}
                        </p>
                    </Form.Label>
                    <Form.Control
                        value={discountCode}
                        isInvalid={validation.discount_code.invalid}
                        onChange={(code) => setDiscountCode(code.target.value)}
                    />
                    <Form.Control.Feedback type="invalid" id="disc_code">
                        {validation.discount_code.message}
                    </Form.Control.Feedback>{" "}
                    <Form.Label className="pl-0 pt-3 w-100">
                        <p style={{ float: "left" }}> Discount Description</p>
                        <p
                            style={{
                                float: "right",
                                color: validation.discount_description.invalid ? "red" : "black",
                            }}
                        >
                            {`${150 - discountDescription.length}`}
                        </p>
                    </Form.Label>
                    <Form.Control
                        value={discountDescription}
                        isInvalid={validation.discount_description.invalid}
                        onChange={(desc) => setDiscountDescription(desc.target.value)}
                        as="textarea"
                    />
                    <Form.Control.Feedback type="invalid">
                        {validation.discount_description.message}
                    </Form.Control.Feedback>{" "}
                    <Form.Label className="pl-0 pt-3 control-label">Discount Duration</Form.Label>
                    <Form.Check
                        type="radio"
                        value="one_off"
                        checked={discountDuration === "one_off"}
                        onChange={(discount_duration) =>
                            setDiscountDuration(discount_duration.target.value)
                        }
                        label={"One-off (Per Customer)"}
                    />
                    <Form.Check
                        className="pt-2"
                        value="limited_period"
                        checked={discountDuration === "limited_period"}
                        onChange={(discount_duration) =>
                            setDiscountDuration(discount_duration.target.value)
                        }
                        type="radio"
                        label={"Limited Period"}
                    />
                    <Form.Check
                        className="pt-2"
                        value="forever"
                        checked={discountDuration === "forever"}
                        onChange={(discount_duration) =>
                            setDiscountDuration(discount_duration.target.value)
                        }
                        type="radio"
                        label={"Forever"}
                    />
                    <Row className="pt-3">
                        <Col>
                            <Form.Label className="control-label w-100">Start Date</Form.Label>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={startDate}
                                    minDate={new Date()}
                                    onChange={(start_date) => setStartDate(start_date)}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        {discountDuration !== "forever" && (
                            <Col>
                                <Form.Label className="w-100">End Date</Form.Label>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        inputFormat="dd/MM/yyyy"
                                        value={endDate}
                                        minDate={new Date()}
                                        onChange={(end_date) => setEndDate(end_date)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Col>
                        )}
                    </Row>
                    {(discountDuration === "limited_period" || discountDuration === "forever") && (
                        <>
                            <Form.Label className="pt-3">
                                Maximum Redemptions Per Customer
                                <CustomWidthTooltip
                                    arrow
                                    PopperProps={{
                                        sx: {
                                            "& .MuiTooltip-tooltip": {
                                                border: "solid 1px",
                                                backgroundColor: "#f5f5f5",
                                                borderRadius: "6px",
                                                borderColor: "black",
                                                color: "black",
                                            },
                                        },
                                    }}
                                    title={
                                        <p>
                                            Number of times a customer can redeem the discount.
                                            Leave the field blank if the discount has unlimited
                                            redemptions per customer.
                                        </p>
                                    }
                                    placement="right"
                                >
                                    <InfoIcon className=" ml-1 mb-1" />
                                </CustomWidthTooltip>
                            </Form.Label>
                            <Form.Control
                                value={maxRedemption}
                                onChange={(redemption) => setMaxRedemption(redemption.target.value)}
                                type="number"
                            />
                        </>
                    )}
                    <Form.Label className="pt-3 control-label">Discount Applicability</Form.Label>
                    <SelectMultiple
                        options={planList}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        value={discountPlan}
                        components={{ Option, MultiValue, animatedComponents }}
                        onChange={(select) => setDiscountPlan(select)}
                    />
                    {validation.discount_applicability.invalid && (
                        <p style={{ color: "red" }}>{validation.discount_applicability.message}</p>
                    )}
                    <Form.Label className="pt-3">Discount Type</Form.Label>
                    <Col className="d-flex">
                        <Form.Check
                            type="radio"
                            value="fixed_amount"
                            checked={discountType === "fixed_amount"}
                            onChange={(discount_type) =>
                                setDiscountType(discount_type.target.value)
                            }
                            label={"Fixed amount"}
                        />
                        <Form.Check
                            className="ml-3"
                            value="percentage"
                            checked={discountType === "percentage"}
                            onChange={(percentage) => setDiscountType(percentage.target.value)}
                            type="radio"
                            label={"Percentage"}
                        />
                    </Col>
                    {discountType === "fixed_amount" && (
                        <>
                            <Form.Label className="pt-3">Discount Amount</Form.Label>
                            <CurrencyInput
                                className="w-100"
                                style={{
                                    borderRadius: "6px",
                                    borderWidth: "thin",
                                    padding: "0.375rem 1.75rem 0.375rem 0.75rem",
                                }}
                                value={discountAmount}
                                onChange={(price) => {
                                    // remove prefix
                                    const newPrice = price.replace(/[^0-9.]/g, "");
                                    setDiscountAmount(parseFloat(newPrice));
                                }}
                                prefix="RM"
                            />
                        </>
                    )}
                    {discountType === "percentage" && (
                        <Row>
                            <Col>
                                <Form.Label className="pt-3">Discount Amount(%)</Form.Label>
                                <Form.Control
                                    value={discountAmount}
                                    onChange={(price) => setDiscountAmount(price.target.value)}
                                    type="number"
                                />
                            </Col>{" "}
                            <Col>
                                <Form.Label className="pt-3">Discount Cap(RM)</Form.Label>
                                <Form.Control
                                    value={discountCap}
                                    onChange={(cap) => setDiscountCap(cap.target.value)}
                                    type="number"
                                />
                            </Col>
                        </Row>
                    )}
                    <Form.Label className="pt-3 control-label">
                        Discount Category
                        <CustomWidthTooltip
                            arrow
                            PopperProps={{
                                sx: {
                                    "& .MuiTooltip-tooltip": {
                                        border: "solid 1px",
                                        backgroundColor: "#f5f5f5",
                                        borderRadius: "6px",
                                        borderColor: "black",
                                        color: "black",
                                    },
                                },
                            }}
                            title={
                                <p>
                                    The discount applies for:{" "}
                                    <li>
                                        {" "}
                                        Whole Order: Discount will be applied to the customer's
                                        total checkout price excluding the delivery fee.
                                    </li>
                                    <li>
                                        {" "}
                                        Only Delivery: Discount only applies to the delivery fee
                                        portion.
                                    </li>
                                </p>
                            }
                            placement="right"
                        >
                            <InfoIcon className=" ml-1 mb-1" />
                        </CustomWidthTooltip>
                    </Form.Label>
                    <Col className="d-flex">
                        <Form.Check
                            type="radio"
                            value="order"
                            checked={discountCategory === "order"}
                            onChange={(discount_category) =>
                                setDiscountCategory(discount_category.target.value)
                            }
                            label={"Whole Order"}
                        />
                        <Form.Check
                            className="ml-3"
                            value="delivery"
                            checked={discountCategory === "delivery"}
                            onChange={(percentage) => setDiscountCategory(percentage.target.value)}
                            type="radio"
                            label={"Only Delivery"}
                        />
                    </Col>
                </Form.Group>
            </Row>
            <Row
                className="d-flex pt-3"
                style={{
                    width: window.innerWidth < 991 && "80vw",
                    margin: window.innerWidth < 991 && "auto",
                }}
            >
                <Col className="d-flex justify-content-end ">
                    <Button
                        onClick={() => history.push("/admin/discount")}
                        className="bg-white"
                        style={{
                            color: "black",
                            textTransform: "none",
                        }}
                    >
                        <p style={{ fontSize: "12px" }}>Cancel</p>
                    </Button>
                </Col>
                <Col className="d-flex justify-content-start ">
                    <Button
                        style={{
                            backgroundColor: store.getState().colorState.color,
                            borderColor: store.getState().colorState.color,
                            color: store.getState().colorState.font_color,
                            textTransform: "none",
                        }}
                        onClick={createDiscount}
                    >
                        <p style={{ fontSize: "12px" }}>{`${
                            params.id !== "new" ? "Edit" : "Create"
                        } Discount`}</p>
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default DiscountEditCreate;
