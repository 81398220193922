import { Button as MUIButton } from "@material-ui/core";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState, useLayoutEffect } from "react";
import { Button, Col, Container, Form, Image, InputGroup, Row } from "react-bootstrap";
import CurrencyInput from "react-currency-input";
import { useHistory, useParams } from "react-router-dom";
// import { TagsInput } from "react-tag-input-component";
import TagsInput from "react-tagsinput";

import { toast } from "react-toastify";
import ImageCropper from "../../../../Components/ImageCropper";
import addOnService from "../../../../services/addOnService";
import { readImageFileIntoDataUrl } from "../../../../utils/imageUtils";
import "./style.css";
import { store } from "../../../../index.js";
import "react-tagsinput/react-tagsinput.css";

const CreateItem = ({ handleModalClose }) => {
    // Fix autofocus issues with CurrencyInput
    // on iOS it will still auto focus even if autoFocus=false
    // see https://github.com/jsillitoe/react-currency-input/issues/90
    let componentDidMount_super = CurrencyInput.prototype.componentDidMount;
    CurrencyInput.prototype.componentDidMount = function () {
        if (!this.props.autoFocus) {
            let setSelectionRange_super = this.theInput.setSelectionRange;
            this.theInput.setSelectionRange = () => {};
            componentDidMount_super.call(this, ...arguments);
            this.theInput.setSelectionRange = setSelectionRange_super;
        } else {
            componentDidMount_super.call(this, ...arguments);
        }
    };
    let componentDidUpdate_super = CurrencyInput.prototype.componentDidUpdate;
    CurrencyInput.prototype.componentDidUpdate = function () {
        if (!this.props.autoFocus) {
            let setSelectionRange_super = this.theInput.setSelectionRange;
            this.theInput.setSelectionRange = () => {};
            componentDidUpdate_super.call(this, ...arguments);
            this.theInput.setSelectionRange = setSelectionRange_super;
        } else {
            componentDidMount_super.call(this, ...arguments);
        }
    };
    const history = useHistory();
    const params = useParams();
    console.log(params, "this is the params");
    const [itemName, setItemName] = useState("");
    const [itemDesc, setItemDesc] = useState("");
    const [imageCropModel, setImageCropModel] = useState({
        isOpened: false,
        selectedImageDataUrl: null,
        crop: { x: 0, y: 0 },
        rotation: 0,
        zoom: 0.4,
        aspect: 1 / 1,
        croppedAreaPixels: null,
        croppedImage: null,
    });
    const [variantOption, setVariantOption] = useState(
        JSON.parse(sessionStorage.getItem("variant_option")) != null
            ? JSON.parse(sessionStorage.getItem("variant_option"))
            : []
    );
    const [previewImg, setPreviewImg] = useState([]);
    const [itemPrice, setItemPrice] = useState(0.0);
    const [itemPriceEdited, setItemPriceEdited] = useState(false);
    const [validation, setValidation] = useState({
        item_name: { invalid: false, error: "Please provide an item name" },
        item_price: { invalid: false, error: "Please provide an item price" },
        item_desc: { invalid: false, error: "Please provide an item description" },
        item_image: { invalid: false, error: "Please provide an image or more." },
    });
    const [variantExist, setVariantExist] = useState(false);

    const [stockUnit, setStockUnit] = useState();
    const itemPriceValidateHelperText = () => {
        if (validation.item_price.invalid) {
            return (
                <p
                    style={{
                        color: "red",
                        fontSize: "0.8rem",
                        fontWeight: "400",
                        marginTop: "5px",
                        marginBottom: "auto",
                        paddingLeft: "0",
                    }}
                >
                    Please enter a valid price
                </p>
            );
        }
    };

    const renderImage = (path) => {
        const imagePath =
            window.location.protocol +
            "//" +
            window.location.hostname +
            process.env.REACT_APP_SITE_PORT_BACKEND +
            path;
        return imagePath;
    };
    useLayoutEffect(() => {
        // fetch addon data with id from parameter
        if (params.id !== "new") {
            addOnService
                .getSingleAddon(params.id)
                .then((addon) => {
                    console.log(addon, "this is the addon data");
                    setItemName(addon.addons.addon_name);
                    setItemDesc(addon.addons.addon_desc);
                    setVariantExist(addon.addons.variant_exist);
                    if (
                        addon.addons.variant_detail !== null &&
                        addon.addons.variant_detail !== undefined
                    ) {
                        setVariantDetail(addon.addons.variant_detail);
                        sessionStorage.setItem(
                            "variant_detail",
                            JSON.stringify(addon.addons.variant_detail)
                        );
                    }
                    if (addon.addons.variant_tags !== null) {
                        setVariantOption(addon.addons.variant_tags);
                        sessionStorage.setItem(
                            "variant_option",
                            JSON.stringify(addon.addons.variant_tags)
                        );
                    }
                    setItemPrice(addon.addons.addon_price);
                    setPreviewImg(
                        addon.addons.addon_image.map((image) => {
                            return {
                                image_id: image.image_id,
                                imagePreview: renderImage(image.image_name),
                            };
                        })
                    );
                })
                .catch((err) => {
                    console.log(err, "this is the erro");
                });
        }
    }, []);

    useEffect(() => {
        // check if product name is more than 50 characters, if yes, show error
        if (itemName.length > 50) {
            setValidation({
                ...validation,
                item_name: {
                    error: "Item name should be lesser than 50 characters",
                    invalid: true,
                },
            });
        } else {
            setValidation({
                ...validation,
                item_name: {
                    error: "",
                    invalid: false,
                },
            });
        }
    }, [itemName]);

    useEffect(() => {
        if (
            (itemDesc !== ""
                ? itemDesc[0]?.length +
                  (itemDesc[0]?.match(/\n/) === null ? 0 : itemDesc[0]?.match(/\n/gm).length)
                : 0) -
                1 >=
            150
        ) {
            setValidation({
                ...validation,
                item_desc: {
                    error: "Item description should be less than 150 characters",
                    invalid: true,
                },
            });
        } else {
            setValidation({
                ...validation,
                item_desc: {
                    error: "",
                    invalid: false,
                },
            });
        }
    }, [itemDesc]);
    const [variantDetail, setVariantDetail] = useState(
        JSON.parse(sessionStorage.getItem("variant_detail")) !== null
            ? JSON.parse(sessionStorage.getItem("variant_detail"))
            : []
    );

    const restructureVariantPair = (var_opt) => {
        var variantPair = [];
        // variant must have 2 options to render pairing
        // if variant length === 1, render single
        if (var_opt.length === 2 && var_opt[1].variant_tags.length > 0) {
            var variantOptionOne = var_opt[0].variant_tags;
            var variantOptionTwo = var_opt[1].variant_tags;
            var currentVariantDetail = variantDetail;
            console.log(currentVariantDetail);
            variantOptionOne.forEach((option_one) => {
                variantOptionTwo.forEach((option_two) => {
                    var concatVariant = option_one;
                    concatVariant += "/" + option_two;
                    var existingVariant = currentVariantDetail.filter(
                        (variant) => variant.variant === concatVariant
                    );
                    console.log(existingVariant, "asdasdasd");
                    console.log(currentVariantDetail, "existing variant");
                    variantPair.push(
                        existingVariant.length > 0
                            ? existingVariant[0]
                            : {
                                  variant: concatVariant,
                                  price: 0,
                                  stock_keeping_unit: "",
                              }
                    );
                });
            });
            setVariantDetail(variantPair);
            sessionStorage.setItem("variant_detail", JSON.stringify(variantPair));
        } else if (
            (var_opt.length === 1 && var_opt[0].variant_tags.length > 0) ||
            (var_opt.length === 2 && var_opt[1].variant_tags.length === 0)
        ) {
            var_opt[0].variant_tags.map((tag) => {
                variantPair.push({ variant: tag, price: 0, stock_keeping_unit: "" });
            });
            setVariantDetail(variantPair);
            sessionStorage.setItem("variant_detail", JSON.stringify(variantPair));
        }
    };

    useEffect(() => {
        if (itemPrice <= 0.0 && itemPriceEdited) {
            setValidation({
                ...validation,
                item_price: {
                    error: "Item price should be more than 0",
                    invalid: true,
                },
            });
        } else {
            setValidation({
                ...validation,
                item_price: {
                    error: "",
                    invalid: false,
                },
            });
        }
    }, [itemPrice]);
    const uploadImage = async (data) => {
        // if image_id exist, add to existing array
        // else add to new array
        var existing_arr = [];
        var new_arr = [];
        data.map((e) => {
            if (e.image_id) {
                existing_arr.push(e.image_id);
            }
            if (e.imageFile) {
                new_arr.push(e.imageFile);
            }
        });
        if (new_arr.length > 0) {
            var dataBody = {
                image_name: new_arr,
            };
            const saveImage = await addOnService
                .createAddonImage(dataBody)
                .then((res) => {
                    const imageId = res.data.map((data) => {
                        return data.image_id;
                    });
                    return imageId;
                })
                .catch((response) => console.error(response));
            // existing_arr.concat(sendImage);
            // return existing_arr;
            return saveImage;
        }
        console.log(existing_arr, "existing arr");
    };

    const configureItem = async () => {
        console.log(itemName, "???");
        // item name length cannot be more than 50
        // item description length cannot be more than 150
        // item price cannot be less than 0
        // if image is not uploaded is ok
        const variantDetail = JSON.parse(sessionStorage.getItem("variant_detail"));
        const variantOption = JSON.parse(sessionStorage.getItem("variant_option"));
        console.log(variantDetail, "this is the variantDetail");
        console.log(previewImg, "ioqwdnioqwdniqwodnioqwdniqoinqwo");
        var itemNameValid = true;
        var itemDescValid = true;
        var itemPriceValid = true;
        var itemImageValid = true;
        var variantExistValid = true;
        if (itemName.length > 50 || itemName.length === 0) {
            itemNameValid = false;
        }
        if (
            (itemDesc !== ""
                ? itemDesc[0].length +
                  (itemDesc[0].match(/\n/) === null ? 0 : itemDesc[0].match(/\n/gm).length)
                : 0) === 0
        ) {
            itemDescValid = false;
        }
        if (
            (itemDesc !== ""
                ? itemDesc[0]?.length +
                  (itemDesc[0]?.match(/\n/) === null ? 0 : itemDesc[0]?.match(/\n/gm).length)
                : 0) > 150
        ) {
            itemDescValid = false;
        }
        if (previewImg.length === 0) {
            itemImageValid = false;
        }
        if (!variantExist) {
            if (itemPrice <= 0) {
                itemPriceValid = false;
            }
        } else if (variantExist && variantOption.length > 0) {
            if (variantOption[0].variant_tags.length === 0) {
                variantExistValid = false;
            }
        }
        setValidation({
            ...validation,
            item_name: {
                error: "Item name cannot be empty or more than 50 characters",
                invalid: itemNameValid === false ? true : false,
            },
            item_desc: {
                error: "Item description cannot be empty or more than 150 characters",
                invalid: itemDescValid === false ? true : false,
            },
            item_image: {
                error: "At least 1 image must be uploaded.",
                invalid: itemImageValid === false ? true : false,
            },
            item_price: {
                error: "Item price cannot be 0",
                invalid: itemPriceValid === false ? true : false,
            },
        });
        console.log(itemNameValid, itemDescValid, itemImageValid, variantExistValid);
        if (itemNameValid && itemDescValid && itemImageValid && variantExistValid) {
            var dataBody = {
                addon_name: itemName,
                addon_desc: itemDesc,
                addon_price: itemPrice,
                variant_exist: variantExist,
            };
            if (variantExist) {
                dataBody = {
                    ...dataBody,
                    variant_detail: variantDetail,
                    variant_tags: variantOption,
                };
            }
            console.log(previewImg, "this preview img");

            if (previewImg.length > 0) {
                var images = [];
                const existingImage = previewImg.filter((img) => {
                    if (img.image_id) {
                        images.push(img.image_id);
                    }
                });
                console.log(existingImage, "this is the preveiew img");

                const newImage = previewImg.filter((img) => {
                    if (!img.imageId) {
                        return img;
                    }
                });
                var imageId = await uploadImage(newImage);
                if (imageId !== undefined) {
                    images = images.concat(imageId);
                }

                if (existingImage.length > 0) {
                    var retrieveExistingId = existingImage.map((img) => {
                        return img.imageId;
                    });

                    images = images.concat(retrieveExistingId);
                }
                dataBody = {
                    ...dataBody,
                    addon_image: images,
                };
            }
            console.log(dataBody, "this is the databoYd");
            if (params.id !== "new" && !window.location.href.includes("product")) {
                addOnService
                    .updateAddon(params.id, dataBody)
                    .then((edited) => {
                        toast.success("Item edited", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        sessionStorage.clear();
                        sessionStorage.setItem("item_edited", JSON.stringify(edited.data.addon_id));

                        if (window.location.href.includes("/admin/item")) {
                            history.replace("/admin/item");
                        } else {
                            handleModalClose();
                        }
                    })
                    .catch((err) => console.error(err));
            } else if (params.id === "new" || window.location.href.includes("product")) {
                addOnService
                    .createAddon(dataBody)
                    .then((e) => {
                        console.log(e, "this is success response");
                        toast.success("Item created", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        sessionStorage.setItem("item_created", JSON.stringify(e.data.addon_id));
                        if (window.location.href.includes("/admin/item")) {
                            sessionStorage.clear();
                            history.replace("/admin/item");
                        } else {
                            handleModalClose();
                        }
                    })
                    .catch((e) => {
                        console.error(e, "error");
                    });
            }
        }

        // check all data first then only upload image
        // upload image
    };

    const section1 = document.getElementById("name");
    const section2 = document.getElementById("desc");
    const section3 = document.getElementById("image");

    useEffect(() => {
        if (validation.item_name.invalid)
            section1.scrollIntoView({ behavior: "smooth", block: "center" });
        if (validation.item_desc.invalid)
            section2.scrollIntoView({ behavior: "smooth", block: "center" });
        if (validation.item_image.invalid)
            section3.scrollIntoView({ behavior: "smooth", block: "center" });
    }, [validation]);

    return (
        <div className="mb-5">
            {window.location.href.includes("item") && (
                <h1 className="config-title pt-3">Create Item</h1>
            )}
            <div
                className="text-left mt-4"
                style={{
                    padding: "1em 1em 3em 1em",
                    width:
                        window.location.href.includes("item") && window.innerWidth > 991
                            ? "50%"
                            : "100%",
                    margin: ".5em auto 10px",
                    backgroundColor: "white",
                }}
            >
                {window.location.href.includes("plan") && (
                    <>
                        <h2>{`${params.id !== "new" ? "Edit" : "Create"}`} Item</h2>
                        <hr style={{ backgroundColor: "black" }} />
                    </>
                )}
                <h4>Item Information</h4>
                <Form.Group className="mb-3 pt-3 form-group required">
                    <Form.Label className="w-100 mb-0 control-label">
                        <p style={{ float: "left" }}>Item Name</p>
                        <p
                            style={{
                                float: "right",
                                color: validation.item_name.invalid ? "red" : "black",
                            }}
                        >
                            {50 - itemName.length}
                        </p>
                    </Form.Label>
                    <InputGroup className="mt-2" hasValidation>
                        <Form.Control
                            onChange={(item_name) => {
                                setItemName(item_name.target.value);
                                console.log(itemName, "item name");
                            }}
                            value={itemName}
                            isInvalid={validation.item_name.invalid}
                            required
                        />
                        <Form.Control.Feedback type="invalid" id="name">
                            {validation.item_name.error}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>{" "}
                <Form.Group className=" form-group required">
                    <Form.Label className="w-100 mb-0 control-label">
                        <p style={{ float: "left" }}>Item Description</p>
                        <p
                            style={{
                                float: "right",
                                color: validation.item_desc.invalid ? "red" : "black",
                            }}
                        >
                            {150 -
                                (itemDesc !== ""
                                    ? itemDesc[0]?.length +
                                      (itemDesc[0]?.match(/\n/) === null
                                          ? 0
                                          : itemDesc[0]?.match(/\n/gm).length)
                                    : 0)}
                        </p>
                    </Form.Label>{" "}
                    <Form.Label>
                        <small style={{ color: "grey" }}>
                            This appears on the item cards and provides a short description of the
                            item.
                        </small>
                    </Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            as="textarea"
                            placeholder="This is an example of an item description."
                            style={{
                                height: "100px",
                                borderColor: validation.item_desc.invalid ? "red" : "black",
                            }}
                            onChange={(item_desc) => {
                                setItemDesc([item_desc.target.value]);
                            }}
                            value={itemDesc}
                            isInvalid={validation.item_desc.invalid}
                            required
                        />
                        <Form.Control.Feedback type="invalid" id="desc">
                            {validation.item_desc.error}
                        </Form.Control.Feedback>{" "}
                    </InputGroup>{" "}
                </Form.Group>{" "}
                <Form.Group className="pt-3 form-group required">
                    <Form.Label className="w-100 mb-0 control-label" id="image">
                        <p style={{ float: "left" }}>Item Image(s)</p>
                    </Form.Label>{" "}
                </Form.Group>{" "}
                <MUIButton
                    className="mt-3"
                    startIcon={<PhotoLibraryIcon />}
                    style={{
                        backgroundColor: store.getState().colorState.color,
                        color: store.getState().colorState.font_color,
                        textTransform: "none",
                        border: "1px solid black",
                        borderColor: validation.item_image.invalid
                            ? "red"
                            : store.getState().colorState.color,
                    }}
                    variant="contained"
                    component="label"
                >
                    Upload Image
                    <input
                        accept="image/*"
                        type="file"
                        onChange={async (e) => {
                            const file = e.target.files[0];
                            let imageDataUrl = await readImageFileIntoDataUrl(file);
                            //after an image file is selected open the cropping model
                            setImageCropModel({
                                ...imageCropModel,
                                isOpened: true,
                                selectedImageDataUrl: imageDataUrl,
                            });
                        }}
                        onClick={(event) => (event.target.value = null)}
                        hidden
                    />
                </MUIButton>
                {validation.item_image.invalid && (
                    <p className="pt-1" style={{ fontSize: ".875rem", color: "#dc3545" }}>
                        {validation.item_image.error}
                    </p>
                )}
                <ImageCropper
                    imgCrop={imageCropModel}
                    handleImgCrop={(imgData) => {
                        setImageCropModel(imgData);
                    }}
                    imgPreview={previewImg}
                    handleImgPreview={(img) => {
                        setPreviewImg([...previewImg, img]);
                    }}
                />
                {previewImg.length > 0 && (
                    <>
                        <Form.Label style={{ color: "grey" }} className="mt-3 w-100">
                            Image Preview
                        </Form.Label>
                        <Row
                            lg={3}
                            className="d-flex m-1 p-2"
                            style={{
                                border: "1px solid black",
                                borderRadius: "6px",
                            }}
                        >
                            {previewImg !== undefined &&
                                previewImg.map((img, img_index) => {
                                    return (
                                        <Col style={{ position: "relative" }}>
                                            <Image
                                                className="img-preview-images ml-0"
                                                src={img.imagePreview}
                                            />
                                            <ClearIcon
                                                onClick={() => {
                                                    previewImg.splice(img_index, 1);
                                                    setPreviewImg([...previewImg]);
                                                }}
                                                style={{
                                                    position: "absolute",
                                                    cursor: "pointer",
                                                    right: "0px",
                                                    top: "0px",
                                                }}
                                            />
                                        </Col>
                                    );
                                })}
                        </Row>
                    </>
                )}
                <p className="pt-3">
                    <strong>Item Pricing</strong>
                </p>
                <Form.Group className="pt-3 form-group required">
                    <Form.Label className="w-100 mb-0 control-label">
                        <p style={{ float: "left" }}>Does this item have variants? </p>
                    </Form.Label>
                    <Form.Label>
                        <small style={{ color: "grey" }}>
                            Example of variants include size and colour.
                        </small>
                    </Form.Label>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Form.Check
                            checked={variantExist}
                            onChange={() => {
                                setVariantExist(true);
                                if (variantOption.length < 1) {
                                    setVariantOption([
                                        ...variantOption,
                                        { variant_name: "size", variant_tags: [] },
                                    ]);
                                    sessionStorage.setItem(
                                        "variant_option",
                                        JSON.stringify([
                                            ...variantOption,
                                            { variant_name: "size", variant_tags: [] },
                                            "variant_exist",
                                            true,
                                        ])
                                    );
                                }
                            }}
                            type={"radio"}
                            label={"Yes"}
                        />
                        <Form.Check
                            checked={!variantExist}
                            onChange={() => {
                                setVariantExist(false);
                                sessionStorage.setItem("variant_exist", false);
                            }}
                            className="ml-4"
                            type={"radio"}
                            label={"No"}
                        />
                    </div>
                </Form.Group>
                {!variantExist ? (
                    <Form.Group className="w-100 mt-3 form-group required">
                        <Form.Label className="control-label">Item Price</Form.Label>
                        <CurrencyInput
                            className="w-100"
                            style={{
                                borderRadius: "6px",
                                // borderColor: validation.item_price.invalid ? "red" : "black",
                                borderColor: "black",
                                borderWidth: "thin",
                                padding: "0.375rem 1.75rem 0.375rem 0.75rem",
                            }}
                            onChange={(price) => {
                                console.log(price, "this is the new price");
                                // remove prefix
                                const newPrice = price.replace(/[^0-9.]/g, "");
                                setItemPrice(newPrice);
                                setItemPriceEdited(true);
                            }}
                            // ref={(node) => (this.input = node)}
                            prefix="RM"
                            value={itemPrice}
                        />
                        {/* {itemPriceValidateHelperText()} */}
                        <Form.Label className="pt-3">Stock Keeping Unit (SKU)</Form.Label>
                        <Form.Control
                            onChange={(stock) => {
                                setStockUnit(stock.target.value);
                            }}
                        />
                    </Form.Group>
                ) : (
                    <>
                        <p className="pt-3">
                            <strong>Options</strong>
                        </p>
                        {variantOption &&
                            variantOption.map((variant, variant_key) => {
                                return (
                                    <Row
                                        className="pt-3 ml-2 mr-2"
                                        style={{
                                            paddingBottom: "1em",
                                            borderBottom: "1px solid black",
                                        }}
                                    >
                                        <Col className="pl-0 pr-0" lg={4} md={4} xs={4}>
                                            <Form.Label>Option {variant_key + 1}</Form.Label>
                                            <Form.Select
                                                onChange={(option) => {
                                                    var allVarOption = [...variantOption];
                                                    var currentVariant = allVarOption[variant_key];
                                                    currentVariant.variant_name =
                                                        option.target.value;
                                                    allVarOption[variant_key] = currentVariant;
                                                    setVariantOption(allVarOption);
                                                    sessionStorage.setItem(
                                                        "variant_option",
                                                        JSON.stringify(allVarOption)
                                                    );
                                                }}
                                                value={variant.variant_name}
                                                as="select"
                                            >
                                                <option value="size">Size</option>
                                                <option value="flavor">Flavor</option>
                                                <option value="others">Others</option>
                                            </Form.Select>
                                            {variant.variant_name === "others" && (
                                                <Form.Control
                                                    className="mt-3"
                                                    onChange={(option) => {
                                                        var allVarOption = [...variantOption];
                                                        var currentVariant =
                                                            allVarOption[variant_key];
                                                        currentVariant.others = option.target.value;
                                                        allVarOption[variant_key] = currentVariant;
                                                        setVariantOption(allVarOption);
                                                        sessionStorage.setItem(
                                                            "variant_option",
                                                            JSON.stringify(allVarOption)
                                                        );
                                                    }}
                                                    value={variant.others}
                                                />
                                            )}
                                        </Col>
                                        <Col className=" pr-0" lg={8} md={8} xs={8}>
                                            <div className="d-flex justify-content-between">
                                                <small
                                                    style={{
                                                        color: "grey",
                                                        fontSize: ".7rem",
                                                        alignSelf: "center",
                                                    }}
                                                >
                                                    Type in your options, separated by pressing
                                                    "Enter"
                                                </small>
                                                <p
                                                    onClick={() => {
                                                        var varOpt = [...variantOption];
                                                        varOpt.splice(variant_key, 1);
                                                        restructureVariantPair(varOpt);
                                                        setVariantOption(varOpt);
                                                        sessionStorage.setItem(
                                                            "variant_option",
                                                            JSON.stringify(varOpt)
                                                        );
                                                    }}
                                                    style={{
                                                        color: "red",
                                                        cursor: "pointer",
                                                        display:
                                                            variantOption.length === 1 && "none",
                                                    }}
                                                >
                                                    Remove
                                                </p>
                                            </div>
                                            <div
                                                className={`${
                                                    variantOption.length === 1 && "pt-2"
                                                }`}
                                            >
                                                <TagsInput
                                                    value={variant.variant_tags}
                                                    onChange={(variant_list) => {
                                                        var newVariant = [...variantOption];
                                                        newVariant[variant_key].variant_tags =
                                                            variant_list;
                                                        setVariantOption(newVariant);
                                                        sessionStorage.setItem(
                                                            "variant_option",
                                                            JSON.stringify(newVariant)
                                                        );
                                                        restructureVariantPair(newVariant);
                                                    }}
                                                    name={variant_key}
                                                    placeHolder="Enter variant"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                );
                            })}
                        {variantOption.length < 2 && (
                            <Button
                                onClick={() => {
                                    setVariantOption([
                                        ...variantOption,
                                        { variant_name: "size", variant_tags: [] },
                                    ]);
                                    sessionStorage.setItem(
                                        "variant_option",
                                        JSON.stringify([
                                            ...variantOption,
                                            { variant_name: "size", variant_tags: [] },
                                        ])
                                    );
                                }}
                                style={{
                                    backgroundColor: store.getState().colorState.color,
                                    borderColor: store.getState().colorState.color,
                                    color: store.getState().colorState.font_color,
                                    textTransform: "none",
                                }}
                                className="d-flex pt-2 pb-2 mt-3"
                            >
                                <AddIcon />
                                <p> Add Another Option</p>
                            </Button>
                        )}

                        {variantOption.length > 0 && (
                            <Container className="pt-2">
                                <Form.Group className="pt-3">
                                    <h5 className="control-label">Preview of Variants</h5>
                                </Form.Group>{" "}
                                <Row className="pt-3">
                                    <Col>Variant</Col>
                                    <Col>
                                        <div className="form-group required">
                                            <p className="control-label"> Price (RM)</p>
                                        </div>
                                    </Col>
                                    <Col>Stock Keeping Unit (SKU)</Col>
                                </Row>
                                {variantDetail.map((pair, pair_index) => {
                                    return (
                                        <Row className="pt-3">
                                            <Col>{pair.variant}</Col>
                                            <Col>
                                                <CurrencyInput
                                                    className="w-75 text-center"
                                                    style={{
                                                        borderRadius: "6px",
                                                        borderWidth: "thin",
                                                        padding:
                                                            "0.375rem 1.75rem 0.375rem 0.75rem",
                                                    }}
                                                    onChange={(price) => {
                                                        // save price
                                                        var allVariantPair = [...variantDetail];
                                                        var currentPair = variantDetail[pair_index];
                                                        currentPair.price = price;
                                                        allVariantPair[pair_index] = currentPair;
                                                        setVariantDetail(allVariantPair);
                                                        sessionStorage.setItem(
                                                            "variant_detail",
                                                            JSON.stringify(allVariantPair)
                                                        );
                                                    }}
                                                    value={pair.price}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    onChange={(unit) => {
                                                        // need to save stock keeping unit value
                                                        var allVariantPair = [...variantDetail];
                                                        var currentPair = variantDetail[pair_index];
                                                        currentPair.stock_keeping_unit =
                                                            unit.target.value;
                                                        allVariantPair[pair_index] = currentPair;
                                                        setVariantDetail(allVariantPair);
                                                        sessionStorage.setItem(
                                                            "variant_detail",
                                                            JSON.stringify(allVariantPair)
                                                        );
                                                    }}
                                                    value={pair.stock_keeping_unit}
                                                    className="w-75 text-center"
                                                />
                                            </Col>
                                        </Row>
                                    );
                                })}
                            </Container>
                        )}
                    </>
                )}
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1em",
                    paddingBottom: "5em",
                }}
            >
                {/* create two button, create and cancel */}
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: "white",
                        color: "black",
                        marginRight: ".5em",
                        borderColor: store.getState().colorState.color,
                        textTransform: "none",
                    }}
                    onClick={() => {
                        if (window.location.href.includes("/admin/item")) {
                            history.replace("/admin/item");
                        } else {
                            handleModalClose();
                        }
                    }}
                >
                    Cancel
                </Button>
                <Button
                    style={{
                        backgroundColor: store.getState().colorState.color,
                        borderColor: store.getState().colorState.color,
                        color: store.getState().colorState.font_color,
                        textTransform: "none",
                    }}
                    onClick={() => configureItem()}
                >
                    {`${params.id !== "new" ? "Edit" : "Create"}`} Item
                </Button>
            </div>
        </div>
    );
};
export default CreateItem;
