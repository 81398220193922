import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import EventIcon from "@mui/icons-material/Event";
import { useLayoutEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import Calendar from "react-calendar";
import { useHistory } from "react-router-dom";
import { timeFromInt } from "time-number";
import { useSelector } from "react-redux";
import planService from "../../../../../../services/planService";
import subscriptionService from "../../../../../../services/subscriptionService";
import "../EditOrderItem/EditOrderItem.css";
import { store } from "../../../../../../index.js";
import DatePicker from "react-datepicker";
import profileService from "../../../../../../services/profileService";
const EditOrderDate = ({ subsDetails, allPlans, handleEditDetails, planDetails }) => {
    const history = useHistory();
    const isLoggedIn = useSelector((state) => state.loggedInUserReducer.userData);

    const [deliveryTimeOptions, setDeliveryTimeOptions] = useState([]);
    const [openDeliveryWind, setOpenDeliveryWind] = useState();
    const [deliveryDate, setDeliveryDate] = useState();
    const [deliveryTime, setDeliveryTime] = useState();
    const [deliveryAddress, setDeliveryAddress] = useState();
    const [deliveryPostcode, setDeliveryPostcode] = useState({});
    const [deliveryState, setDeliveryState] = useState();
    const [minDate, setMinDate] = useState();

    const [deliveryDateOption, setDeliveryDateOption] = useState();
    const [addressLineInput, setAddressLineInput] = useState("");
    const [addressStateInput, setAddressStateInput] = useState("");
    const [postcodeInput, setPostcodeInput] = useState("");
    const [allPostcodes, setAllPostcodes] = useState([]);
    useLayoutEffect(() => {
        profileService.getAllPostcode().then((e) => setAllPostcodes(e.postcode));

        // based on current subs plan id, retrieve available delivery time from all plans
        planService
            .getSinglePlan(subsDetails.plan_id)
            .then((result) => {
                console.log(result, "this is the result");
                // declare delivery window time here
                var deliveryTimeOptionsArr = [];
                result.plans.delivery_window?.time.map((time) => {
                    const startTime = timeFromInt(time.start_time, {
                        format: 12,
                    });
                    const endTime = timeFromInt(time.end_time, {
                        format: 12,
                    });
                    const timeDisplay = startTime + " - " + endTime;
                    deliveryTimeOptionsArr.push(timeDisplay);
                });
                setDeliveryTimeOptions(deliveryTimeOptionsArr);
            })
            .catch((err) => console.error(err));
    }, [subsDetails, allPlans]);
    const getCurrentState = (currentPostcode, setState) => {
        var getState = allPostcodes.filter(
            (postcode) => postcode.postcode === parseInt(currentPostcode)
        );
        console.log(getState, "get state");
        if (getState.length > 0) {
            setState(getState[0].state);
        } else {
            setState("");
        }
    };
    const saveDateAndTime = () => {
        var newSubsDetails = {
            ...subsDetails,
        };
        if (deliveryDateOption !== undefined) {
            newSubsDetails = {
                ...newSubsDetails,
                delivery_date: new Date(deliveryDateOption).toISOString(),
            };
        }
        if (deliveryTime !== undefined) {
            newSubsDetails = {
                ...newSubsDetails,
                delivery_time: deliveryTime,
            };
        }
        if (addressLineInput !== "") {
            newSubsDetails = {
                ...newSubsDetails,
                delivery_address: addressLineInput,
            };
        }
        if (addressStateInput !== "") {
            newSubsDetails = {
                ...newSubsDetails,
                customer_state: addressStateInput,
            };
        }
        if (
            planDetails.date_exist &&
            planDetails.allow_subscription_edit &&
            isLoggedIn.userRole === "customer" &&
            (postcodeInput !== "" || addressLineInput !== "" || addressStateInput !== "")
        ) {
            // check postcode in backend
            console.log(postcodeInput, "post code intpu");
            profileService
                .getPostcode(parseInt(postcodeInput.value))
                .then((result) => {
                    newSubsDetails = {
                        ...newSubsDetails,
                        customer_postcode: postcodeInput.value,
                    };
                    subscriptionService
                        .editSubscription(parseInt(subsDetails.id), newSubsDetails)
                        .then((success) => {
                            // confirm popup and refresh page
                            if (isLoggedIn.userRole === "merchant") {
                                history.push("/admin/order/");
                            } else {
                                history.push("/customer/order/");
                            }
                            sessionStorage.setItem("edit_row", JSON.stringify(subsDetails.id));
                        })
                        .catch((err) => console.log(err));
                })
                .catch((err) => {
                    setPostcodeInput({
                        ...postcodeInput,
                        invalid: true,
                        error: "Postcode does not exist",
                    });
                });
        } else {
            subscriptionService
                .editSubscription(parseInt(subsDetails.id), newSubsDetails)
                .then((success) => {
                    // confirm popup and refresh page
                    if (isLoggedIn.userRole === "merchant") {
                        history.push("/admin/order/");
                    } else {
                        history.push("/customer/order/");
                    }

                    sessionStorage.setItem("edit_row", JSON.stringify(subsDetails.id));
                })
                .catch((err) => console.log(err));
        }
    };
    function tileDisabled({ date, view }) {
        if (planDetails.delivery_window !== null && planDetails.delivery_window !== undefined) {
            var indexes = [];
            const disabledDays = Object.keys(planDetails.delivery_window?.day).filter(
                (keys) => !planDetails.delivery_window.day[keys]
            );
            disabledDays.map((key) => {
                if (key === "Sunday") indexes.push(0);
                if (key === "Monday") indexes.push(1);
                if (key === "Tuesday") indexes.push(2);
                if (key === "Wednesday") indexes.push(3);
                if (key === "Thursday") indexes.push(4);
                if (key === "Friday") indexes.push(5);
                if (key === "Saturday") indexes.push(6);
            });
            if (view === "month" && indexes.some((Ddate) => date.getDay() === Ddate)) return true;
        }
    }
    useLayoutEffect(() => {
        var MinDate = new Date();
        MinDate.setDate(MinDate.getDate() + planDetails.date_lead_time);
        setMinDate(MinDate);
        setDeliveryDate(new Date(subsDetails.delivery_date));
        setDeliveryPostcode(subsDetails.customer_postcode);
        getCurrentState(subsDetails.customer_postcode, setDeliveryState);
        setDeliveryDateOption(new Date(subsDetails.delivery_date));
    }, []);

    return (
        <>
            {planDetails?.date_exist && (
                <>
                    <Row>
                        <Form.Label>Delivery Date</Form.Label>
                    </Row>

                    <Row className="pr-0">
                        <Col className="mr-0 pr-0" lg={5} md={5} xs={12}>
                            <Form.Control
                                disabled
                                value={
                                    (planDetails.date_format !== "MM/YY"
                                        ? new Date(deliveryDate).getDate() + " "
                                        : "") +
                                    new Date(deliveryDate).toLocaleString("default", {
                                        month: "long",
                                    }) +
                                    ", " +
                                    +new Date(deliveryDate).getFullYear()
                                }
                            />
                        </Col>
                        <Col
                            className={`${
                                window.innerWidth < 991 && "pt-2 pb-2"
                            } d-flex align-self-center justify-content-center`}
                            lg={1}
                            md={1}
                            xs={12}
                        >
                            <ArrowRightAltIcon
                                style={{ transform: window.innerWidth < 991 && "rotate(90deg)" }}
                            />
                        </Col>
                        <Col className="mr-0 pr-0" lg={6} md={6} xs={12}>
                            {planDetails.date_format !== "MM/YY" ? (
                                <InputGroup className="w-100">
                                    <Form.Control
                                        value={
                                            deliveryDateOption !== undefined
                                                ? new Date(deliveryDateOption).getDate() +
                                                  " " +
                                                  new Date(deliveryDateOption).toLocaleString(
                                                      "default",
                                                      {
                                                          month: "long",
                                                      }
                                                  ) +
                                                  ", " +
                                                  new Date(deliveryDateOption).getFullYear()
                                                : ""
                                        }
                                        disabled
                                        style={{ borderRight: "0" }}
                                    />
                                    <InputGroup.Text
                                        style={{
                                            backgroundColor: "white",
                                            border: "1px solid black",
                                            borderLeft: "0",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => setOpenDeliveryWind(true)}
                                        id="basic-addon1"
                                    >
                                        <EventIcon />
                                    </InputGroup.Text>{" "}
                                    <Modal
                                        show={openDeliveryWind}
                                        onHide={() => {
                                            setOpenDeliveryWind(false);
                                        }}
                                        centered
                                    >
                                        <Modal.Body className="d-flex justify-content-center">
                                            <Calendar
                                                value={deliveryDateOption}
                                                onChange={(date) => {
                                                    setDeliveryDateOption(date);
                                                }}
                                                tileDisabled={tileDisabled}
                                                minDate={minDate}
                                            />
                                        </Modal.Body>
                                        <div className="d-flex justify-content-center">
                                            <Button
                                                onClick={() => {
                                                    sessionStorage.removeItem("delivery_date");
                                                    setDeliveryDateOption();
                                                    sessionStorage.setItem(
                                                        "delivery_date",
                                                        JSON.stringify(new Date())
                                                    );
                                                    setOpenDeliveryWind(false);
                                                }}
                                                className="close-delivery-window-button"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                style={{
                                                    backgroundColor:
                                                        store.getState().colorState.color,
                                                    borderColor: store.getState().colorState.color,
                                                    color: store.getState().colorState.font_color,
                                                }}
                                                onClick={() => setOpenDeliveryWind(false)}
                                            >
                                                Confirm
                                            </Button>
                                        </div>
                                    </Modal>
                                </InputGroup>
                            ) : (
                                <DatePicker
                                    selected={deliveryDateOption}
                                    className="w-100 picker-overflow"
                                    onChange={(date) => {
                                        setDeliveryDateOption(date);

                                        sessionStorage.setItem(
                                            "delivery_date",
                                            JSON.stringify(date)
                                        );
                                    }}
                                    minDate={new Date()}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                />
                            )}
                        </Col>
                    </Row>
                    {deliveryTimeOptions.length > 0 && (
                        <>
                            <Row className="pt-3">
                                <Form.Label>Delivery Time</Form.Label>
                            </Row>
                            <Row className="pr-0 ">
                                <Col lg={5} md={5} xs={5}>
                                    <Form.Control disabled value={subsDetails.delivery_time} />
                                </Col>
                                <Col
                                    className={`${
                                        window.innerWidth < 991 && "pt-2 pb-2"
                                    } d-flex align-self-center justify-content-center`}
                                    lg={1}
                                    md={1}
                                    xs={1}
                                >
                                    <ArrowRightAltIcon
                                        style={{
                                            transform: window.innerWidth < 991 && "rotate(90deg)",
                                        }}
                                    />
                                </Col>
                                <Col className="mr-0 pr-0" lg={6} md={6} xs={6}>
                                    <Form.Select
                                        onChange={(time) => {
                                            setDeliveryTime(time.target.value);
                                            subsDetails.delivery_time = time.target.value;
                                        }}
                                    >
                                        {deliveryTimeOptions.map((time) => {
                                            return <option value={time}>{time}</option>;
                                        })}
                                    </Form.Select>
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            )}
            {planDetails.allow_subscription_edit && (
                <>
                    <Row className="pt-3">
                        <Form.Label>Delivery Address</Form.Label>
                    </Row>
                    <Row>
                        <small>Address Line</small>
                    </Row>
                    <Row className="pr-0">
                        <Col className="mr-0 pr-0" lg={5} md={5} xs={12}>
                            <Form.Control disabled value={subsDetails.delivery_address} />
                        </Col>
                        <Col
                            className={`${
                                window.innerWidth < 991 && "pt-2 pb-2"
                            } d-flex align-self-center justify-content-center`}
                            lg={1}
                            md={1}
                            xs={12}
                        >
                            <ArrowRightAltIcon
                                style={{ transform: window.innerWidth < 991 && "rotate(90deg)" }}
                            />
                        </Col>
                        <Col className="mr-0 pr-0" lg={6} md={6} xs={12}>
                            <Form.Control
                                disabled={
                                    !planDetails.allow_subscription_edit &&
                                    isLoggedIn.userRole !== "merchant"
                                }
                                onChange={(address) => {
                                    setAddressLineInput(address.target.value);
                                    // subsDetails.delivery_address = address.target.value;
                                }}
                                value={addressLineInput}
                            />
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <small>Address State</small>
                    </Row>
                    <Row className="pr-0">
                        <Col className="mr-0 pr-0" lg={5} md={5} xs={12}>
                            <Form.Control disabled value={subsDetails.customer_state} />
                        </Col>
                        <Col
                            className={`${
                                window.innerWidth < 991 && "pt-2 pb-2"
                            } d-flex align-self-center justify-content-center`}
                            lg={1}
                            md={1}
                            xs={12}
                        >
                            <ArrowRightAltIcon
                                style={{ transform: window.innerWidth < 991 && "rotate(90deg)" }}
                            />
                        </Col>
                        <Col className="mr-0 pr-0" lg={6} md={6} xs={12}>
                            <Form.Control
                                disabled={
                                    !planDetails.allow_subscription_edit &&
                                    isLoggedIn.userRole !== "merchant"
                                }
                                onChange={(address) => {
                                    setAddressStateInput(address.target.value);
                                    // subsDetails.customer_state = address.target.value;
                                }}
                                value={addressStateInput}
                            />
                        </Col>
                    </Row>
                    <Row className="pr-0 pt-3">
                        <Row>
                            <small>Postcode</small>
                        </Row>
                        <Col className="mr-0 pr-0 " lg={5} md={5} xs={12}>
                            <Form.Control disabled value={subsDetails.customer_postcode} />
                        </Col>
                        <Col
                            className={`${
                                window.innerWidth < 991 && "pt-2 pb-2"
                            } d-flex align-self-center justify-content-center`}
                            lg={1}
                            md={1}
                            xs={12}
                        >
                            <ArrowRightAltIcon
                                style={{ transform: window.innerWidth < 991 && "rotate(90deg)" }}
                            />
                        </Col>
                        <Col className="mr-0 pr-0 " lg={6} md={6} xs={12}>
                            <InputGroup hasValidation>
                                <Form.Control
                                    value={postcodeInput?.value}
                                    onKeyPress={(address) => {
                                        if (address.key === "Backspace") {
                                            setPostcodeInput({
                                                ...postcodeInput,
                                                value: address.target.value,
                                            });
                                        }
                                    }}
                                    type="number"
                                    onChange={(address) => {
                                        if (address.target.value.length >= 5) {
                                            if (address.target.value.length === 7) {
                                                address.preventDefault();
                                            } else {
                                                getCurrentState(
                                                    address.target.value,
                                                    setAddressStateInput
                                                );
                                                setPostcodeInput({
                                                    ...postcodeInput,
                                                    value: address.target.value,
                                                });
                                            }
                                        } else {
                                            setPostcodeInput({
                                                ...postcodeInput,
                                                value: address.target.value,
                                            });
                                        }

                                        // if input is 5 or 6 search in postcode list
                                        // subsDetails.customer_postcode = address.target.value;
                                    }}
                                    isInvalid={postcodeInput.invalid}
                                    required
                                    disabled={
                                        !planDetails.allow_subscription_edit &&
                                        isLoggedIn.userRole !== "merchant"
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {postcodeInput.error}
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                    </Row>
                </>
            )}
            <hr className="bg-black mt-4" />
            <Row className="d-flex flex-col justify-content-end">
                <Button
                    style={{ width: "fit-content" }}
                    onClick={() => {
                        handleEditDetails();
                    }}
                    className="cancel-edit-order"
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => saveDateAndTime()}
                    style={{
                        width: "fit-content",
                        backgroundColor: store.getState().colorState.color,
                        borderColor: store.getState().colorState.color,
                        color: store.getState().colorState.font_color,
                    }}
                >
                    Save Changes
                </Button>
            </Row>
        </>
    );
};

export default EditOrderDate;
