import ListItemIcon from "@material-ui/core/ListItemIcon";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useLayoutEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Swal from "sweetalert2";
import MerchantTable from "../../../../Components/DataTable/index";
import KebabMenu from "../../../../Components/KebabMenu";
import { store } from "../../../../index.js";
import addOnService from "../../../../services/addOnService";
import ArchiveIcon from "@material-ui/icons/Archive";
import "./style.css";

const AddonListing = ({ history }) => {
    const defaultColumn = [
        {
            field: "addon_name",
            headerName: "ITEM NAME",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
            disabled: true,
        },
        {
            field: "addon_price",
            headerName: "ITEM PRICE",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            type: "number",
            flex: 1,
            checked: true,
        },
        {
            field: "action",
            headerName: "ACTIONS",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            type: "date",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => window.location.replace(`/admin/item/${params.row.id}`)}
                        />
                        <KebabMenu options={kebabOptions(params)} />
                    </>
                );
            },
            checked: true,
            disabled: true,
        },
    ];
    useEffect(() => {
        setTimeout(() => {
            sessionStorage.clear();
        }, 3000);
    });
    const [addonData, setAddonData] = useState([]);
    const [allAddonData, setAllAddonData] = useState([]);
    const [columnField, setColumnField] = useState(defaultColumn);
    const [refreshData, setRefreshData] = useState(false);
    const [filterItemId, setFilterItemId] = useState("");
    const [filterItemName, setFilterItemName] = useState("");

    const kebabOptions = (params) => [
        {
            name: "Delete Item",
            icon: (
                <ListItemIcon>
                    <BlockIcon fontSize="small" />
                </ListItemIcon>
            ),

            optionCallback: () => {
                Swal.fire({
                    icon: "warning",
                    title: "Are you sure you want to delete this item ?",
                    text: "This action cannot be undone.",
                    reverseButtons: true,
                    confirmButtonColor: store.getState().colorState.color,
                    confirmButtonText: "Confirm",
                    cancelButtonColor: "white",
                    showCancelButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        addOnService
                            .deleteAddon(params.row.id)
                            .then((success) => {
                                sessionStorage.setItem(
                                    "deleted_row",
                                    JSON.stringify(params.row.id)
                                );
                                setRefreshData(true);
                            })
                            .catch((err) => console.log(err));
                    }
                });
            },
        },
        {
            name: "Archive Item",
            icon: (
                <ListItemIcon>
                     <ArchiveIcon fontSize="small" />
		</ListItemIcon>
            ),

            optionCallback: () => {
                Swal.fire({
                    icon: "warning",
                    title: "Are you sure you want to archive this item ?",
                    text: "This action cannot be undone.",
                    reverseButtons: true,
                    confirmButtonColor: store.getState().colorState.color,
                    confirmButtonText: "Confirm",
                    cancelButtonColor: "white",
                    showCancelButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        addOnService
                            .updateAddon(params.row.id, {})
                            .then((success) => {
                                sessionStorage.setItem(
                                    "deleted_row",
                                    JSON.stringify(params.row.id)
                                );
                                setRefreshData(true);
                            })
                            .catch((err) => console.log(err));
                    }
                });
            },
        },
    ];

    const filterList = [
        // {
        //     key: "addon_id",
        //     component: (
        //         <>
        //             <Form.Label className="mt-3">Item ID</Form.Label>
        //             <Form.Control
        //                 value={filterItemId}
        //                 onChange={(plan) => setFilterItemId(plan.target.value)}
        //             />
        //         </>
        //     ),
        // },
        {
            key: "addon_name",
            component: (
                <>
                    <Form.Label className="mt-1">Item Name</Form.Label>
                    <Form.Control
                        onChange={(duration) => setFilterItemName(duration.target.value)}
                        value={filterItemName}
                    />
                </>
            ),
        },
    ];

    const [columns, setColumns] = useState(defaultColumn);

    const handleHeaderDisplay = (header) => {
        const newColumn = defaultColumn.filter((column, column_index) => {
            if (header[column_index].checked) {
                return column;
            }
        });
        setColumns(newColumn);
    };

    const handleColumns = (columns) => {
        setColumns(columns);
    };

    const handleCheckField = (check) => {
        setColumnField(check);
    };

    useLayoutEffect(() => {
        addOnService
            .getAllAddonWithoutPagination()
            .then((allAddon) => {
                allAddon.addons.forEach((addOn) => {
                    addOn.id = addOn.addon_id;
                    addOn.addon_price = addOn.addon_price.toFixed(2);
                    return addOn;
                });
                console.log(allAddon, "all addon");
                setAllAddonData(allAddon.addons.reverse());
                setAddonData(allAddon.addons.reverse());
            })
            .catch((e) => {});
    }, [refreshData]);
    useEffect(() => {
        //filter addon name
        var filteredData = [];

        if (filterItemName !== "" || filterItemName !== undefined) {
            filteredData = allAddonData.filter((data) => {
                const lowerCaseAddOn = data?.addon_name.toLowerCase();
                const lowerCaseFilterAddOn = filterItemName?.toLowerCase();
                var newData;
                if (lowerCaseAddOn.includes(lowerCaseFilterAddOn)) {
                    newData = data;
                } else if (lowerCaseFilterAddOn === "") {
                    newData = data;
                }
                return newData;
            });
        } else if (filterItemName === "") {
            filteredData = allAddonData;
        }

        if (filterItemId !== "") {
            filteredData = allAddonData.filter((data) => {
                var newData;
                if (data.addon_id === parseInt(filterItemId)) {
                    newData = data;
                } else if (filterItemId === "") {
                    newData = data;
                }
                return newData;
            });
        }
        setAddonData(filteredData);
    }, [filterItemName, filterItemId, refreshData]);
    return (
        <>
            {/* catalog page */}
            <MerchantTable
                Header={"Item"}
                Columns={columns}
                ColumnField={columnField}
                Rows={addonData}
                CreateExist={true}
                RefreshData={refreshData}
                handleRefreshData={() => {
                    setRefreshData(false);
                }}
                ExportExist={true}
                FilterExist={true}
                FilterList={filterList}
                DisplayExist={true}
                SearchBarExist={true}
                handleHeaderDisplay={handleHeaderDisplay}
                handleCheckField={handleCheckField}
                handleColumns={handleColumns}
            />
        </>
    );
};

export default AddonListing;
