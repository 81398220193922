import { callAPI } from "../../utils/apiUtils";
import axios from "axios";
import generalFunctionService from "../generalFunctionService";

const planImagesService = {
    // deletePlanImage: (imageId) => {
    //     const dataBody = {
    //         addon_status: "deleted",
    //     };
    //     return new Promise((resolve, reject) => {
    //         callAPI(`/catalog/addons/${addonId}`, "patch", dataBody)
    //             .then((response) => {
    //                 resolve(response);
    //             })
    //             .catch((response) => reject(response));
    //     });
    // // },
    // createPlanImage: (dataBody) => {
    //     return new Promise((resolve, reject) => {
    //         callAPI(`/catalog/plan_image/`, "post", dataBody)
    //             .then((response) => {
    //                 resolve(response);
    //             })
    //             .catch((response) => reject(response));
    //     });
    // },
    createPlanImage: (dataBody) => {
        var formData = generalFunctionService.convertJsonToFormData(dataBody);
        return new Promise((resolve, reject) => {
            //reject("GG");
            axios
                .post(
                    `${
                        window.location.protocol +
                        "//" +
                        window.location.hostname
                    }${process.env.REACT_APP_SITE_PORT_BACKEND}/catalog/plan_image/`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getPlanImages: () => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/plan_image/`, "get")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    // updateAddon: (addonId, dataBody) => {
    //     return new Promise((resolve, reject) => {
    //         callAPI(`/catalog/plan_image/${addonId}`, "put", dataBody)
    //             .then((response) => {
    //                 resolve(response);
    //             })
    //             .catch((response) => reject(response));
    //     });
    // },
};

export default planImagesService;
