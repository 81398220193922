import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useLayoutEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { ListItemIcon } from "@material-ui/core";
import DataTable from "../../../../Components/DataTable";
import KebabMenu from "../../../../Components/KebabMenu";
import { store } from "../../../../index.js";
import promotionService from "../../../../services/promotionService";
import "./DiscountListing.css";
const DiscountListing = () => {
    const defaultColumn = [
        {
            field: "discount_name",
            headerName: "NAME",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
            disabled: true,
        },
        {
            field: "discount_duration",
            headerName: "DURATION",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
            disabled: false,
        },
        {
            field: "discount_type",
            headerClassName: "super-app-theme--header",
            headerName: "TYPE",
            headerAlign: "center",

            flex: 1,
            checked: true,
            disabled: false,
        },
        {
            field: "discount_amount",
            headerName: "AMOUNT",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",

            type: "number",
            flex: 1,
            checked: true,
            disabled: false,
        },
        {
            field: "start_date",
            headerName: "START DATE",
            headerAlign: "center",
            type: "date",
            headerClassName: "super-app-theme--header",

            // description: "This column has a value getter and is not sortable.",
            // sortable: false,

            // valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
            flex: 1,
            checked: true,
            disabled: false,
        },
        {
            field: "end_date",
            headerName: "END DATE",
            headerAlign: "center",
            type: "date",
            headerClassName: "super-app-theme--header",

            // description: "This column has a value getter and is not sortable.",
            // sortable: false,

            // valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
            flex: 1,
            checked: true,
            disabled: false,
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            headerAlign: "center",
            type: "date",
            headerClassName: "super-app-theme--header",

            // description: "This column has a value getter and is not sortable.",
            // sortable: false,

            // valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push(`/admin/discount/${params.row.id}`)}
                        />
                        <KebabMenu options={kebabOptions(params)} />
                    </>
                );
            },

            checked: true,
            disabled: true,
        },
    ];
    const history = useHistory();
    const [discountData, setDiscountData] = useState([]);
    const [columnField, setColumnField] = useState(defaultColumn);
    const [allDiscountData, setAllDiscountData] = useState([]);
    const [discountName, setDiscountName] = useState("");
    const [discountDuration, setDiscountDuration] = useState("");
    const [change, setChange] = useState(true);
    const [refreshData, setRefreshData] = useState(false);
    const kebabOptions = (params) => [
        {
            name: "Delete discount",
            icon: (
                <ListItemIcon>
                    <BlockIcon fontSize="small" />
                </ListItemIcon>
            ),
            optionCallback: () => {
                console.log(params.row.id, "this is the params");
                Swal.fire({
                    icon: "warning",
                    title: "Are you sure you want to delete this discount?",
                    text: "This action cannot be undone.",
                    reverseButtons: true,
                    confirmButtonColor: store.getState().colorState.color,
                    confirmButtonText: "Confirm",
                    cancelButtonColor: "white",
                    showCancelButton: true,
                }).then((result) => {
                    // delete discount
                    if (result.isConfirmed) {
                        promotionService
                            .deletePromotion(params.row.id)
                            .then((success) => {
                                sessionStorage.setItem(
                                    "deleted_row",
                                    JSON.stringify(params.row.id)
                                );
                                setRefreshData(true);
                            })
                            .catch((err) => console.log(err));
                    }
                });
            },
        },
    ];

    const [columns, setColumns] = useState(defaultColumn);

    const handleHeaderDisplay = (header) => {
        const newColumn = defaultColumn.filter((column, column_index) => {
            if (header[column_index].checked) {
                return column;
            }
        });
        setColumns(newColumn);
    };

    const handleCheckField = (check) => {
        setColumnField(check);
    };

    const handleColumns = (columns) => {
        setColumns(columns);
    };

    const handleChange = (duration) => {
        setChange(false);
        setDiscountDuration(duration.target.value);
    };

    const filterList = [
        {
            key: "discount_code",
            component: (
                <>
                    <Form.Label className="mt-3">Discount Name</Form.Label>
                    <Form.Control
                        value={discountName}
                        onChange={(promo) => setDiscountName(promo.target.value)}
                    />
                </>
            ),
        },
        {
            key: "discount_duration",
            component: (
                <>
                    <Form.Label className="mt-3">Duration</Form.Label>
                    <Form.Select onChange={(duration) => handleChange(duration)}>
                        <option value="">{change ? "Please select" : "All"}</option>
                        <option value={"one_off"}>One-Off</option>
                        <option value={"limited_period"}>Limited Period</option>
                        <option value={"forever"}>Forever</option>
                    </Form.Select>
                </>
            ),
        },
    ];
    useLayoutEffect(() => {
        promotionService

            .getAllPromoWithoutPagination()
            .then((allPromoData) => {
                allPromoData.results.forEach((promo) => {
                    promo.start_date =
                        new Date(promo.start_date).getDate() +
                        "/" +
                        (new Date(promo.start_date).getMonth() + 1) +
                        "/" +
                        new Date(promo.start_date).getFullYear().toString().substr(2, 2);
                    // promo.actions = ;
                    promo.end_date =
                        new Date(promo.end_date).getDate() +
                        "/" +
                        (new Date(promo.end_date).getMonth() + 1) +
                        "/" +
                        new Date(promo.end_date).getFullYear().toString().substr(2, 2);
                    return promo;
                });
                const newActiveData = allPromoData.results.filter(
                    (promo) => promo.promo_status === "active"
                );
                console.log(newActiveData.reverse());
                setAllDiscountData(newActiveData.reverse());
                setDiscountData(newActiveData.reverse());
            })
            .catch((err) => console.error("promotion api failed"));
    }, [refreshData]);
    // get all discount data
    useEffect(() => {
        var filteredData = allDiscountData;
        if (discountName !== "") {
            filteredData = allDiscountData.filter((data) => {
                var newData;
                const lowerCasePromoCode = data.discount_name.toLowerCase();
                const lowerCaseFilterPromoCode = discountName.toLowerCase();
                console.log(lowerCasePromoCode, lowerCaseFilterPromoCode);

                if (lowerCasePromoCode.includes(lowerCaseFilterPromoCode)) {
                    newData = data;
                }
                return newData;
            });
        }

        if (discountDuration !== "") {
            filteredData = allDiscountData.filter((data) => {
                var newData;
                console.log(data);
                if (data.discount_duration === discountDuration) {
                    newData = data;
                }
                return newData;
            });
        }
        // filter discount duration

        setDiscountData(filteredData);
    }, [discountName, discountDuration]);
    return (
        <>
            <DataTable
                Header={"Discount"}
                Columns={columns}
                ColumnField={columnField}
                Rows={discountData}
                CreateExist={true}
                RefreshData={refreshData}
                handleRefreshData={() => {
                    setRefreshData(false);
                }}
                ExportExist={true}
                FilterExist={true}
                FilterList={filterList}
                DisplayExist={true}
                SearchBarExist={true}
                handleHeaderDisplay={handleHeaderDisplay}
                handleCheckField={handleCheckField}
                handleColumns={handleColumns}
            />
        </>
    );
};

export default DiscountListing;
