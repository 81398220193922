import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import { useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { store } from "../../index.js";
import "./tracker.css";
const Tracker = ({
    steps,
    activeStep,
    setActiveStep,
    completed,
    setCompleted,
    handleComplete,
    completedSteps,
    totalSteps,
}) => {
    const isLoggedIn = useSelector((state) => state.loggedInUserReducer.userData);

    const handleStep = (step) => () => {
        setActiveStep(step);
        sessionStorage.setItem("active_step", JSON.stringify(step));
    };

    useEffect(() => {
        console.log(isLoggedIn, "is logged in ");
        if (isLoggedIn) {
            // remove second index in steps
            steps.splice(1, 1);
        }
    }, [activeStep]);

    return (
        <Box
            sx={{
                width: window.innerWidth < 961 ? "100%" : "40%",
                margin: "auto",
                paddingTop: "2em",
                paddingBottom: "1em",
                fontSize: "1rem",
                "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": {
                    color: store.getState().colorState.color,
                },
                "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
                    color: store.getState().colorState.color,
                },
                "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root:active": {
                    color: store.getState().colorState.color,
                },
            }}
        >
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton onClick={handleStep(index)}>{label}</StepButton>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default Tracker;
