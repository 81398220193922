import './style.css'
import notFoundIllustration from '../../Images/404-illustration.svg'
import { Button } from '@material-ui/core'
const NotFoundPage = ({ history }) => {

    const redirectToHomepage = () => {
        history.push("/")
    }

    return (
        <div className="w-100 h-100 container pt-4 d-flex align-items-center justify-content-center">
            <div className="mb-5">
                <h3 className="not-found-title">404 Not Found</h3>
                <p className="not-found-desc">Sorry, the page you are looking for might <br />be removed or is tempararily unavailable.</p>
                <img className="my-2" width="550" src={notFoundIllustration} /><br />
                <Button onClick={redirectToHomepage} className="mt-3" color="primary" variant="outlined">back to homepage</Button>
            </div>
        </div>
    )
}

export default NotFoundPage;