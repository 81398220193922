import ReactPixel from 'react-facebook-pixel';
import subscriptionService from "../subscriptionService";

/***************************************************** 
 * A service wrapper for react facebook pixel
 * ***************************************************/
// TODO: make pixel ID configurable
const pixelService = {
    // initialize pixel tracking and trigger page view event
    init: () => {
        if (localStorage.getItem("pixel-enabled")) {
            console.log("Initializing pixel");
            // this is currently hardcoded to picha's ID
	        ReactPixel.init('513174273358691');
            ReactPixel.pageView();
        }
    },
    // trigger view content event
    trackViewContent : (contentName) => {
        if (localStorage.getItem("pixel-enabled")) {
            console.log("Pixel view content: " + contentName);
            ReactPixel.track('ViewContent', { content_name: contentName });
        }
    },
    // trigger initiate checkout event
    trackInitiateCheckout : (contentName) => {
        if (localStorage.getItem("pixel-enabled")) {
            console.log("Pixel initiate checkout: " + contentName);
            ReactPixel.track('InitiateCheckout', { content_name: contentName });
        }
    },
    // trigger subscribe event, this is mainly used when user clicks the checkout button 
    trackSubscribe : (contentName) => {
        if (localStorage.getItem("pixel-enabled")) {
            console.log("Pixel subscribe: " + contentName);
            ReactPixel.track('Subscribe', { content_name: contentName });
        }
    },
    // trigger purchase event, this is mainly used when user sees the confirmation pop up
    trackPurchase : (subsId) => {
        if (localStorage.getItem("pixel-enabled")) {
            console.log("Pixel subs id: " + subsId);
            subscriptionService.getSingleSubscription(subsId).then((res) => {
                var contentName = res.subs.plan_name
		var subsPrice = res.subs.subs_price
                console.log("Pixel purchase: ", contentName, subsPrice);
                ReactPixel.track('Purchase', { content_name: contentName, value: subsPrice, currency: "MYR" });
            });
        }
    },
};

export default pixelService;
