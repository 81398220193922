import DescriptionIcon from "@mui/icons-material/Description";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useEffect, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { store } from "../../index.js";
import curlec_logo from "../../Images/curlec_logo.png";
import profileService from "../../services/profileService";
import "./sidebar.css";
const Sidebar = ({ isOpen, handleOpenSidebar, handleCloseSidebar, merchantLogo }) => {
    const iconMargin = { marginRight: "12px" };
    let history = useHistory();
    const [navDropdown, setNavDropdown] = useState("none");
    const [navDropdown2, setNavDropdown2] = useState("none");

    const [hover, setHover] = useState("");
    const isLoggedIn = useSelector((state) => state.loggedInUserReducer.userData);
    const customerOptions = [
        <a
            className="menu-item"
            onClick={() => {
                history.push("/customer/profile");
            }}
            onMouseOver={() => setHover("profile")}
            onMouseOut={() => setHover("")}
            style={{
                color: hover === "profile" ? store.getState().colorState.color : "#808080",
                fontWeight: hover === "profile" && "400",
            }}
        >
            <PersonIcon style={iconMargin} />
            My Profile
        </a>,
        <a
            className="menu-item"
            onMouseOver={() => setHover("order")}
            onMouseOut={() => setHover("")}
            style={{
                color: hover === "order" ? store.getState().colorState.color : "#808080",
                fontWeight: hover === "order" && "400",
            }}
            onClick={() => history.push("/customer/order")}
        >
            <ListAltIcon style={iconMargin} />
            My Purchase
        </a>,
        <a
            className="menu-item"
            onMouseOver={() => setHover("invoice")}
            onMouseOut={() => setHover("")}
            style={{
                color: hover === "invoice" ? store.getState().colorState.color : "#808080",
                fontWeight: hover === "invoice" && "400",
            }}
            onClick={() => history.push("/customer/invoice")}
        >
            <DescriptionIcon style={iconMargin} />
            My Invoice
        </a>,
        <a
            className="menu-item"
            onMouseOver={() => setHover("settings")}
            onMouseOut={() => setHover("")}
            style={{
                color: hover === "settings" ? store.getState().colorState.color : "#808080",
                fontWeight: hover === "settings" && "400",
            }}
            onClick={() => history.push("/customer/settings")}
        >
            <SettingsIcon style={iconMargin} />
            Settings
        </a>,
    ];
    const merchantOptions = [
        <div style={{ cursor: "pointer", paddingTop: "10.8px" }} key="product_catalog">
            <div
                onClick={() => {
                    sidebarDropdown();
                }}
                onMouseOver={() => setHover("product_catalogue")}
                onMouseOut={() => setHover("")}
                style={{
                    color:
                        hover === "product_catalogue"
                            ? store.getState().colorState.color
                            : "#808080",
                    fontWeight: hover === "product_catalogue" && "400",
                }}
                className="dropdown-btn-1"
            >
                <MenuBookIcon style={iconMargin} />
                Product Catalogue{" "}
                <KeyboardArrowDownIcon
                    style={{
                        display: isLoggedIn ? "block" : "none",
                        float: "right",
                        transform: navDropdown === "block" ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                />
            </div>
            <div className="dropdown-container slide-bottom" style={{ backgroundColor: "white" }}>
                <li
                    className="not-btm-item pt-1"
                    onClick={() => {
                        history.push("/admin/product");
                    }}
                    onMouseOver={() => setHover("products")}
                    onMouseOut={() => setHover("")}
                    style={{
                        color: hover === "products" ? store.getState().colorState.color : "#808080",
                        fontWeight: hover === "products" && "400",
                    }}
                >
                    Products
                </li>
                <li
                    onClick={() => {
                        history.push("/admin/item");
                    }}
                    onMouseOver={() => setHover("items")}
                    onMouseOut={() => setHover("")}
                    style={{
                        color: hover === "items" ? store.getState().colorState.color : "#808080",
                        fontWeight: hover === "items" && "400",
                    }}
                    className="not-btm-item"
                    href="#2"
                >
                    Items
                </li>
            </div>
        </div>,
        <a
            className="menu-item"
            onMouseOver={() => setHover("order")}
            onMouseOut={() => setHover("")}
            style={{
                color: hover === "order" ? store.getState().colorState.color : "#808080",
                fontWeight: hover === "order" && "400",
            }}
            onClick={() => history.replace("/admin/order")}
            key="order"
        >
            <ListAltIcon style={iconMargin} />
            Order
        </a>,

        <a
            className="menu-item"
            onMouseOver={() => setHover("discount")}
            onMouseOut={() => setHover("")}
            style={{
                color: hover === "discount" ? store.getState().colorState.color : "#808080",
                fontWeight: hover === "discount" && "400",
            }}
            onClick={() => history.replace("/admin/discount")}
            key="discount"
        >
            <LocalOfferIcon style={iconMargin} />
            Discount
        </a>,
        <div style={{ cursor: "pointer", paddingTop: "10.8px" }} key="general">
            <div
                onMouseOver={() => setHover("settings")}
                onMouseOut={() => setHover("")}
                style={{
                    color: hover === "settings" ? store.getState().colorState.color : "#808080",
                    fontWeight: hover === "settings" && "400",
                }}
                onClick={() => {
                    sidebarDropdown_2();
                }}
                className="dropdown-btn-2"
            >
                <SettingsIcon style={iconMargin} />
                Settings
                <KeyboardArrowDownIcon
                    style={{
                        display: isLoggedIn ? "block" : "none",
                        float: "right",
                        transform: navDropdown2 === "block" ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                />
            </div>
            <div className="dropdown-container slide-bottom" style={{ backgroundColor: "white" }}>
                <li
                    className="not-btm-item pt-1"
                    onClick={() => {
                        history.push("/admin/settings/general");
                    }}
                    onMouseOver={() => setHover("general")}
                    onMouseOut={() => setHover("")}
                    style={{
                        color: hover === "general" ? store.getState().colorState.color : "#808080",
                        fontWeight: hover === "general" && "400",
                    }}
                >
                    General
                </li>
                <li
                    className="not-btm-item pt-1"
                    onClick={() => {
                        history.push("/admin/settings/password");
                    }}
                    onMouseOver={() => setHover("password")}
                    onMouseOut={() => setHover("")}
                    style={{
                        color: hover === "password" ? store.getState().colorState.color : "#808080",
                        fontWeight: hover === "password" && "400",
                    }}
                >
                    Change password
                </li>
            </div>
        </div>,
	<a
                        className="menu-item"
                        onMouseOver={() => setHover("ar")}
                        onMouseOut={() => setHover("")}
                        style={{
                                marginTop: "20rem",
                                color: hover === "ar" ? store.getState().colorState.color: "#808080",
                                fontWeight: hover === "ar" && "400",
                        }}
                        onClick={() => {
                                // make api call using current refresh token or available credentials
                                // session storage set item
                                var userToken = JSON.parse(localStorage.getItem("user_token"))
                                sessionStorage.setItem("refresh_token", JSON.stringify(userToken.refresh_token));
                                sessionStorage.setItem("access_token", JSON.stringify(userToken.access_token));
                                sessionStorage.setItem("username", JSON.stringify(store.getState().loggedInUserReducer.userData.name));
                                window.open(`${window.location.protocol}//${window.location.hostname}/ar/customer`)
                        }}
                >
                         <ReceiptLongIcon style={iconMargin} />
                         Accounts Receivable
                 </a>
    ];

    const displayOptions = () => {
        if (isLoggedIn) {
            if (isLoggedIn.userRole === "merchant") {
                return merchantOptions;
            }
            if (isLoggedIn.userRole === "customer") {
                return customerOptions;
            }
        }
        return [];
    };
    const sidebarDropdown = () => {
        var dropdown = document.getElementsByClassName(`dropdown-btn-1`);
        for (var i = 0; i < dropdown.length; i++) {
            dropdown[i].addEventListener("click", function () {
                // this.classList.toggle("dropdown-btn:active");
                var dropdownContent = this.nextElementSibling;
                if (navDropdown === "block") {
                    dropdownContent.style.display = "none";
                    setNavDropdown("none");
                } else {
                    dropdownContent.style.display = "block";
                    setNavDropdown("block");
                }
            });
        }
    };
    const sidebarDropdown_2 = () => {
        var dropdown = document.getElementsByClassName(`dropdown-btn-2`);
        for (var i = 0; i < dropdown.length; i++) {
            dropdown[i].addEventListener("click", function () {
                // this.classList.toggle("dropdown-btn:active");
                var dropdownContent = this.nextElementSibling;
                if (navDropdown2 === "block") {
                    dropdownContent.style.display = "none";
                    setNavDropdown2("none");
                } else {
                    dropdownContent.style.display = "block";
                    setNavDropdown2("block");
                }
            });
        }
    };

    var fakeStyle = {
        bmItem: {
            color: "#0c2651",
            fontWeight: "400",
        },
    };

    var styles = {
        bmBurgerButton: {
            position: "fixed",
            width: "24px",
            left: "40px",
            top: "30px",
            background: store.getState().colorState.color,
            color: "white",
            borderRadius: "4px",
        },
        bmCross: {
            background: store.getState().colorState.color,
            color: "white",
            borderRadius: "4px",
        },
    };
    return (
        <Menu
            isOpen={isOpen}
            onOpen={() => handleOpenSidebar()}
            onClose={() => handleCloseSidebar()}
            customCrossIcon={<KeyboardArrowLeftIcon />}
            customBurgerIcon={<KeyboardArrowRightIcon />}
            onMouseOver={fakeStyle}
            disableCloseOnEsc
            disableAutoFocus
            styles={
                //     {
                //     bmBurgerButton: {
                //         background: "#0c2651",
                //     },
                //     bmCross: {
                //         background: "#0c2651",
                //     },
                // }
                styles
            }
            // noTransition={window.innerWidth < 991 ? false : true}
        >
            <img
                className="menu-logo  mt-3"
                style={{ border: "none", width: "13vw" }}
                src={merchantLogo === "" ? curlec_logo : merchantLogo}
                onClick={() => history.replace("/")}
                alt="Empty Logo."
            ></img>
            	<a
                	className="menu-item mt-4 pt-5"
                	onMouseOver={() => setHover("home")}
                	onMouseOut={() => setHover("")}
                	style={{
                    		color: hover === "home" ? store.getState().colorState.color : "#808080",
                    		fontWeight: hover === "home" && "400",
                	}}
                	onClick={() => history.replace("/")}
            	>
                	<HomeIcon style={iconMargin} />
                	Home
            	</a>
            	{displayOptions()}
	</Menu>
    );
};

export default Sidebar;
