import ListItemIcon from "@material-ui/core/ListItemIcon";
import BlockIcon from "@material-ui/icons/Block";
import DeleteIcon from "@material-ui/icons/Delete";
import PauseIcon from "@material-ui/icons/Pause";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useLayoutEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import DataTable from "../../../../Components/DataTable";
import KebabMenu from "../../../../Components/KebabMenu";
import { store } from "../../../../index.js";
import subscriptionService from "../../../../services/subscriptionService";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { format } from "date-fns";

const OrderListing = () => {
    const isLoggedIn = useSelector((state) => state.loggedInUserReducer.userData);
    const dayInMonthComparator = (v1, v2) => v1.getDate() - v2.getDate();
    const defaultColumn = [
        {
            field: "customer_id",
            headerName: "CUSTOMER ID",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: false,
            disabled: false,
        },
        {
            field: "customer_fname",
            headerName: "CUSTOMER NAME",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
            disabled: true,
        },
        {
            field: "customer_email",
            headerName: "CUSTOMER EMAIL",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: false,
            disabled: false,
        },
        {
            field: "customer_contact_no",
            headerName: "CONTACT",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: false,
            disabled: false,
        },

        {
            field: "plan_name",
            headerName: "PRODUCT NAME",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
        },
        {
            field: "delivery_date",
            headerName: "DELIVERY DATE",
            headerClassName: "super-app-theme--header",
	    headerAlign: "center",
            flex: 1,
            checked: true,
            disabled: false,
 	    type: 'date',
        },
        {
            field: "delivery_time",
            headerName: "DELIVERY TIME",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: false,
            disabled: false,
        },
        {
            field: "delivery_frequency",
            headerName: "DELIVERY FREQUENCY",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: false,
            disabled: false,
        },
        {
            field: "delivery_address",
            headerName: "DELIVERY ADDRESS",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: false,
            disabled: false,
        },
        {
            field: "customer_postcode",
            headerName: "POSTCODE",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: false,
            disabled: false,
        },
        {
            field: "subs_price",
            headerName: "PRICE",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: false,
            disabled: false,
        },

        {
            field: "subs_type",
            headerName: "PRODUCT TYPE",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",

            flex: 1,
            checked: true,
        },
        {
            field: "subs_status",
            headerName: "STATUS",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            type: "number",
            flex: 1,
            checked: true,
        },
        {
            field: "categories_item",
            headerName: "ITEM",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: false,
            disabled: true,
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            headerAlign: "center",
            type: "date",
            headerClassName: "super-app-theme--header",
            flex: 1,
            disabled: true,
            renderCell: (params) => {
                return (
                    <>
                        <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push(`/admin/order/${params.row.id}`)}
                        />
                        {params.row.subs_status === "Pending" && (
                            <KebabMenu options={pendingOptions(params)} />
                        )}
                        {params.row.subs_status === "Paused" && (
                            <KebabMenu options={pauseOptions(params)} />
                        )}
                        {params.row.subs_status === "Active" && (
                            <KebabMenu options={activeOptions(params)} />
                        )}
                        {params.row.subs_status === "Inactive" && (
                            <KebabMenu options={inactiveOptions(params)} />
                        )}
                        {params.row.subs_status === "Cancelled" && (
                            <KebabMenu options={cancelledOptions(params)} />
                        )}
                        {params.row.subs_status === "Awaiting cancellation" && (
                            <KebabMenu options={awaitingCancellationOptions(params)} />
                        )}
			{params.row.subs_status === "Enrolment approved" && (
                            <KebabMenu options={enrolmentApprovedOptions(params)} />
                        )}
                    </>
                );
            },
            checked: true,
        },
    ];

    const history = useHistory();
    const [columns, setColumns] = useState(defaultColumn);
    const [allOrderData, setAllOrderData] = useState([]);
    const [columnField, setColumnField] = useState(defaultColumn);
    const [orderData, setOrderData] = useState([]);
    const [refreshData, setRefreshData] = useState(false);
    const [filterOrderStatus, setFilterOrderStatus] = useState("");
    const [filterOrderType, setFilterOrderType] = useState("");
    const [filterCustomerName, setFilterCustomerName] = useState("");
    const [filterDeliveryStartDate, setFilterDeliveryStartDate] = useState("");
    const [filterDeliveryEndDate, setFilterDeliveryEndDate] = useState("");
    const inactiveOptions = (params) => [
        reactivateOrder(params),
        isLoggedIn.userRole === "merchant" && deleteOrder(params),
    ];
    const activeOptions = (params) => [cancelOrder(params), pauseOrder(params)];
    const pauseOptions = (params) => [
        cancelOrder(params),
        reactivateOrder(params),
        isLoggedIn.userRole === "merchant" && deleteOrder(params),
    ];
    const cancelledOptions = (params) => [
        reactivateOrder(params),
        isLoggedIn.userRole === "merchant" && deleteOrder(params),
    ];
    const pendingOptions = (params) => [
        pauseOrder(params),
        isLoggedIn.userRole === "merchant" && deleteOrder(params),
    ];
    const awaitingCancellationOptions = (params) => [
        cancelOrder(params),
        reactivateOrder(params),
        isLoggedIn.userRole === "merchant" && deleteOrder(params),
    ];
    const enrolmentApprovedOptions = (params) => [
        cancelOrder(params),
        reactivateOrder(params),
        isLoggedIn.userRole === "merchant" && deleteOrder(params),
    ];
    const pauseOrder = (params) => {
        return {
            name: "Pause Subscription",
            icon: (
                <ListItemIcon>
                    <PauseIcon fontSize="small" />
                </ListItemIcon>
            ),
            optionCallback: () => {
                Swal.fire({
                    title: "Are you sure you want to pause this subscription?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: store.getState().colorState.color,
                    cancelButtonColor: "#ffffff",
                    confirmButtonText: "Yes, pause it!",
                }).then((res) => {
                    var deletedSubscription = {
                        subs_status: "paused",
                    };
                    if (res.isConfirmed) {
                        subscriptionService
                            .editSubscription(params.row.id, deletedSubscription)
                            .then((res) => {
                                sessionStorage.setItem("pause_row", JSON.stringify(params.row.id));
                                setRefreshData(true);
                            })
                            .catch((err) => {
                                Swal.fire({
                                    title: "Error Occurs!",
                                    text: err.message,
                                    icon: "error",
                                    confirmButtonColor: store.getState().colorState.color,
                                    confirmButtonText: "OK",
                                });
                            });
                    }
                });
            },
        };
    };

    const deleteOrder = (params) => {
        return {
            name: "Delete Subscription",
            icon: (
                <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                </ListItemIcon>
            ),
            optionCallback: () => {
                Swal.fire({
                    title: "Are you sure you want to delete this subscription?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: store.getState().colorState.color,
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                }).then((res) => {
                    var deletedSubscription = {
                        subs_status: "deleted",
                    };
                    if (res.isConfirmed) {
                        subscriptionService
                            .editSubscription(params.row.id, deletedSubscription)
                            .then((res) => {
                                sessionStorage.setItem("delete_row", JSON.stringify(params.row.id));
                                setRefreshData(true);
                            })
                            .catch((err) => {
                                Swal.fire({
                                    title: "Error Occurs!",
                                    text: err.message,
                                    icon: "error",
                                    confirmButtonColor: store.getState().colorState.color,
                                    confirmButtonText: "OK",
                                });
                            });
                    }
                });
            },
        };
    };
    const cancelOrder = (params) => {
        return {
            name: "Cancel order",
            icon: (
                <ListItemIcon>
                    <BlockIcon fontSize="small" />
                </ListItemIcon>
            ),
            optionCallback: () => {
                console.log(params.row.id, "this is the params");
                Swal.fire({
                    icon: "warning",
                    title: "Are you sure you want to cancel this order?",
                    text: "This action cannot be undone.",
                    reverseButtons: true,
                    confirmButtonColor: store.getState().colorState.color,
                    confirmButtonText: "Confirm",
                    cancelButtonColor: "white",
                    showCancelButton: true,
                }).then((result) => {
                    // delete discount
                    if (result.isConfirmed) {
                        subscriptionService
                            .editSubscription(params.row.id, { subs_status: "cancelled" })
                            .then((success) => {
                                sessionStorage.setItem("cancel_row", JSON.stringify(params.row.id));
                                setRefreshData(true);
                            })
                            .catch((err) => console.log(err));
                    }
                });
            },
        };
    };
    const reactivateOrder = (params) => {
        return {
            name: "Reactivate Subscription",
            icon: (
                <ListItemIcon>
                    <PlaylistAddCheckIcon fontSize="small" />
                </ListItemIcon>
            ),
            optionCallback: () => {
                Swal.fire({
                    title: "Are you sure you want to reactivate this subscription?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: store.getState().colorState.color,
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, reactivate it!",
                }).then((res) => {
                    var reactivatedSubscription = {
                        subs_status: "active",
                    };
                    if (res.isConfirmed) {
                        // console.log(value);
                        subscriptionService
                            .reactivateSubscription(params.row.id, reactivatedSubscription)
                            .then((res) => {
                                sessionStorage.setItem(
                                    "reactivate_row",
                                    JSON.stringify(params.row.id)
                                );
                                setRefreshData(true);
                            })
                            .catch((err) => {
                                console.log(err, "this the err");
                                Swal.fire({
                                    title: "Error occurred!",
                                    text: err.message,
                                    icon: "error",
                                    confirmButtonText: "Ok",
                                });
                            });
                    }
                });
            },
        };
    };
    const handleHeaderDisplay = (header) => {
        const newColumn = defaultColumn.filter((column, column_index) => {
            if (header[column_index].checked) {
                return column;
            }
        });
        setColumns(newColumn);
    };

    const handleCheckField = (check) => {
        setColumnField(check);
    };

    const handleColumns = (columns) => {
        setColumns(columns);
    };
    const filterList = [
        {
            key: "customer_fname",
            component: (
                <>
                    <Form.Label className="mt-1">Customer Name</Form.Label>
                    <Form.Control
                        value={filterCustomerName}
                        onChange={(promo) => setFilterCustomerName(promo.target.value)}
                    />
                </>
            ),
        },
        {
            key: "subs_status",
            component: (
                <>
                    <Form.Label className="mt-3">Order Status</Form.Label>
                    <Form.Select
                        onChange={(duration) => setFilterOrderStatus(duration.target.value)}
                        value={filterOrderStatus}
                        as="select"
                    >
                        <option value="" disabled hidden>
                            Please select
                        </option>
                        <option value={"active"}>Active</option>
                        <option value={"pending"}>Pending</option>
                        <option value={"enrolment approved"}>Enrolment Approved</option>
                        <option value={"inactive"}>Inactive</option>
                    </Form.Select>
                </>
            ),
        },
        {
            key: "subs_type",
            component: (
                <>
                    <Form.Label className="mt-3">Product Type</Form.Label>
                    <Form.Select
                        onChange={(duration) => setFilterOrderType(duration.target.value)}
                        value={filterOrderType}
                        as="select"
                    >
                        <option value="" disabled hidden>
                            Please select
                        </option>
                        <option value={"Recurring"}>Recurring</option>
                        <option value={"One_off"}>One-off</option>
                    </Form.Select>
                </>
            ),
        },
        {
            key: "delivery_date",
            component: (
                <>
                    <Form.Label className="mt-3">Delivery Date</Form.Label>
                    <div className="d-flex">
                        <div>
                            {" "}
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                                value={filterDeliveryStartDate}
                                onChange={(date) => {
                                    setFilterDeliveryStartDate(
                                        format(new Date(date.target.value), "yyyy-MM-dd")
                                    );
                                }}
                                type="date"
                            />
                        </div>
                        <div
                            className="d-flex align-self-center"
                            style={{ padding: "9px", paddingTop: "2.5em" }}
                        >
                            <ArrowRightAltIcon />
                        </div>
                        <div>
                            {" "}
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                                value={filterDeliveryEndDate}
                                onChange={(date) => {
                                    // var newDate = new Date(date)
                                    setFilterDeliveryEndDate(
                                        format(new Date(date.target.value), "yyyy-MM-dd")
                                    );
                                }}
                                type="date"
                            />
                        </div>
                    </div>
                </>
            ),
        },
    ];
    useLayoutEffect(() => {
        subscriptionService.getAllSubscriptionWithoutPagination().then((subs) => {
            subs = subs.filter((sub) => sub.subs_status !== "deleted");
            console.log(subs, "sub listing");

            setOrderData(subs.reverse());
            setAllOrderData(subs);
        });
    }, [refreshData]);
    // get all discount data
    useEffect(() => {
        var filteredData = allOrderData;
        if (filterCustomerName !== "") {
            filteredData = allOrderData.filter((data) => {
                return (
                    data.customer_fname !== null &&
                    data.customer_fname.toLowerCase().includes(filterCustomerName.toLowerCase())
                );
            });
        }
        if (filterOrderStatus !== "") {
            filteredData = filteredData.filter((data) => {
                return data.subs_status.toLowerCase() === filterOrderStatus.toLowerCase();
            });
        }
	console.log(filterOrderType,'this is the filter order type');
	
	if (filterOrderType !== "") {
	    filteredData = filteredData.filter((data) => {
	        console.log(data.subs_type,'this is the subs type');
		return data.subs_type.toLowerCase() === filterOrderType.toLowerCase();
	    })
	}
        // if ONLY (delivery start date)
        // if ONLY (delivery end date)
        // if (delivery start date && delivery end date)
        var validData = [];
        // console.log(allOrderData, "in all order data");
        const parseDateFormat = (date_data) => {
            console.log(date_data.length, "length length length", date_data);
            if (date_data.length <= 10) {
                console.log(date_data, "this is the date data");
                const dateString = date_data;
                const dateParts = dateString.split("/");
                console.log(dateParts, "this is the date data");

                const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
                // console.log(date, "this is the date in parse date format");
                // const formattedData = format(date, "yyyy-MM-dd");

                return date;
            } else {
                return date_data;
            }
        };
        console.log(filterDeliveryStartDate, "start date filer");
        if (filterDeliveryStartDate !== "" && filterDeliveryEndDate !== "") {
            allOrderData.forEach((data) => {
                if (data.delivery_date !== null) {
                    // if data.delivery_date is smaller than filterDeliveryEndDate and bigger than filterDeliveryStartDate
                    const dateValue = data.delivery_date;
                    console.log(dateValue, "date value");
                    const deliveryDate = parseDateFormat(dateValue);
                    const startDate = new Date(filterDeliveryStartDate);
                    const endDate = new Date(filterDeliveryEndDate);

                    const deliveryTime = deliveryDate.getTime();
                    const startTime = startDate.getTime();
                    const endTime = endDate.getTime();
                    // console.log(deliveryDate, "delivery date");
                    if (endTime > deliveryTime && startTime < deliveryTime) {
                        validData.push(data);
                    }
                }
            });
            filteredData = validData;
        } else if (filterDeliveryStartDate !== "" && filterDeliveryEndDate === "") {
            allOrderData.forEach((data) => {
                if (data.delivery_date !== null) {
                    // if data.delivery_date is smaller than filterDeliveryEndDate and bigger than filterDeliveryStartDate
                    const dateValue = data.delivery_date;
                    const deliveryDate = parseDateFormat(dateValue);
                    const startDate = new Date(filterDeliveryStartDate);

                    const deliveryTime = deliveryDate.getTime();
                    const startTime = startDate.getTime();
                    // console.log(deliveryDate, "delivery date");
                    if (startTime < deliveryTime) {
                        validData.push(data);
                    }
                }
            });
            filteredData = validData;
        } else if (filterDeliveryStartDate === "" && filterDeliveryEndDate !== "") {
            allOrderData.forEach((data) => {
                if (data.delivery_date !== null) {
                    // if data.delivery_date is smaller than filterDeliveryEndDate and bigger than filterDeliveryStartDate
                    const dateValue = data.delivery_date;
                    console.log(dateValue, "date value");
                    const deliveryDate = parseDateFormat(dateValue);
                    const endDate = new Date(filterDeliveryEndDate);

                    const deliveryTime = deliveryDate.getTime();
                    const endTime = endDate.getTime();
                    // console.log(deliveryDate, "delivery date");
                    if (endTime > deliveryTime) {
                        validData.push(data);
                    }
                }
            });
            filteredData = validData;
        }

        console.log(filteredData, "filtered data");
        setOrderData(filteredData);
    }, [filterOrderStatus, filterOrderType, filterCustomerName, filterDeliveryStartDate, filterDeliveryEndDate]);
    return (
        <>
            <DataTable
                Header={"Order"}
                Columns={columns}
                ColumnField={columnField}
                Rows={orderData}
                CreateExist={false}
                RefreshData={refreshData}
                handleRefreshData={() => {
                    setRefreshData(false);
                }}
                ExportExist={true}
                FilterExist={true}
                FilterList={filterList}
                DisplayExist={true}
                SearchBarExist={true}
                handleHeaderDisplay={handleHeaderDisplay}
                handleColumns={handleColumns}
                handleCheckField={handleCheckField}
            />
        </>
    );
};

export default OrderListing;
