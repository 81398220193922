import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Edit } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import subscriptionService from "../../../../services/subscriptionService";
import DisplayOrderInformation from "./DisplayOrderInformation/DisplayOrderInformation";
import EditOrderInformation from "./EditOrderInformation/EditOrderInformation";
const SubscriptionView = () => {
    const params = useParams();
    const [editDetails, setEditDetails] = useState(false);
    const [subsDetails, setSubsDetails] = useState();
    useLayoutEffect(() => {
        // get subscription details using params
        getCurrentSubs();
    }, []);
    const getCurrentSubs = () => {
        subscriptionService
            .getSingleSubscription(params.id)
            .then((subs) => setSubsDetails(subs.subs))
            .catch((err) => console.log(err));
    };
    const handleSubsDetails = (subs_details) => {
        console.log(subs_details, "this is the subs details");
        var oldSubsDetails = subsDetails;
        if (subs_details.plan_id !== undefined) {
            oldSubsDetails = {
                ...oldSubsDetails,
                plan_id: subs_details.plan_id,
            };
        }
        if (subs_details.plan_name !== undefined) {
            oldSubsDetails = {
                ...oldSubsDetails,
                plan_name: subs_details.plan_name,
            };
        }
        if (subs_details.categories_item !== undefined) {
            oldSubsDetails = {
                ...oldSubsDetails,
                categories_item: subs_details.categories_item,
            };
        }
        setSubsDetails(oldSubsDetails);
    };
    return !editDetails ? (
        subsDetails !== undefined && (
            <DisplayOrderInformation
                editDetails={editDetails}
                handleEditDetails={() => setEditDetails(true)}
                subsDetails={subsDetails}
            />
        )
    ) : (
        <EditOrderInformation
            editDetails={editDetails}
            handleEditDetails={() => {
                setEditDetails(false);
                getCurrentSubs();
            }}
            subsDetails={subsDetails}
            handleSubsDetails={handleSubsDetails}
        />
    );
};

export default SubscriptionView;
