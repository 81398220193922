import ErrorIcon from "@material-ui/icons/Error";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { store } from "../../index.js";
import userService from "../../services/userService";

import CreateItem from "./Addon/AddonEditCreate/CreateItem";
import AddonListing from "./Addon/AddonListing";
import DiscountEditCreate from "./Discount/DiscountEditCreate/DiscountEditCreate";
import DiscountListing from "./Discount/DiscountListing/DiscountListing";
import ProductForm from "./Plan/PlanForm";
import PlanListing from "./Plan/PlanListing";
import Profile from "./Profile";
import MerchantPasswordSettings from "./Profile/PasswordSettings/PasswordSettings.js";
import DeliverySettings from "./Profile/DeliverySettings/DeliverySettings";
import SubscriptionCreate from "./Subscription/SubscriptionCreate";
import SubscriptionListing from "./Subscription/SubscriptionListing";
import SubscriptionView from "./Subscription/SubscriptionView";
const Admin = ({ history }) => {
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    let { path, url } = useRouteMatch();

    if (!window.location.href.includes("/admin/login")) {
        const hasAdminRight = () => {
            return (
                userService.isLoggedIn &&
                store.getState().loggedInUserReducer.userData?.userRole == "merchant"
            );
        };

        if (!hasAdminRight()) {
            history.replace("/not-found");
            toast.error(
                <div>
                    <ErrorIcon /> Not Authorized
                </div>,
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
        }
    }

    return (
        <>
            <Switch>
                {/* <Route path={`${path}/tax`} exact component={TaxListing} /> */}
                {/* <Route path={`${path}/tax/:id`} component={TaxCreation} /> */}
                <Route path={`${path}/item`} exact component={AddonListing} />
                <Route path={`${path}/item/:id`} component={CreateItem} />
                <Route path={`${path}/order`} exact component={SubscriptionListing} />
                <Route path={`${path}/order/new`} exact component={SubscriptionCreate} />
                <Route path={`${path}/order/:id`} component={SubscriptionView} />
                <Route path={`${path}/discount`} exact component={DiscountListing}></Route>
                <Route path={`${path}/discount/:id`} component={DiscountEditCreate}></Route>
                <Route path={`${path}/settings/general`} component={Profile}></Route>
                <Route
                    path={`${path}/settings/password`}
                    component={MerchantPasswordSettings}
                ></Route>

                {/* <Route path={`${path}/settings/delivery`} component={DeliverySettings}></Route> */}
                <Route path={`${path}/settings/email`} component={Profile}></Route>
                <Route path={`${path}/product`} exact component={PlanListing}></Route>
                <Route path={`${path}/product/:id`} component={ProductForm}></Route>
            </Switch>
        </>
    );
};

export default Admin;
