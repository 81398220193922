import { Button, Col, Form, Row } from "react-bootstrap";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Add } from "@material-ui/icons";
import { useLayoutEffect, useState } from "react";
import planService from "../../../../../../services/planService";
import addOnService from "../../../../../../services/addOnService";
import "./EditOrderItem.css";
import ErrorIcon from "@mui/icons-material/Error";
import subscriptionService from "../../../../../../services/subscriptionService";
import { useHistory } from "react-router-dom";
import { store } from "../../../../../../index.js";
import DeleteIcon from "@mui/icons-material/Delete";
import { gridFilterActiveItemsLookupSelector } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
const EditOrderItem = ({ subsDetails, handleEditDetails, handleSubsDetails }) => {
    const [allPlans, setAllPlans] = useState([]);
    const [allAddons, setAllAddons] = useState([]);
    const [changePlan, setChangePlan] = useState();
    const isLoggedIn = useSelector((state) => state.loggedInUserReducer.userData);
    const [quantityRestriction, setQuantityRestriction] = useState({
        valid: true,
        quantity: [],
    });
    const history = useHistory();
    useLayoutEffect(() => {
        planService
            .getAllPlansWithoutPagination()
            .then((plan) => {
                setAllPlans(plan.plans);
            })
            .catch((err) => console.error(err));
        addOnService.getAllAddonWithoutPagination().then((addon) => {
            setAllAddons(addon.addons);
        });
    }, [subsDetails, changePlan]);
    const calcTotalItem = () => {
        var totalItemPrice = 0;
        var currentPlanData = allPlans.filter((plan) => plan.plan_id === subsDetails.plan_id)[0];

        subsDetails.categories_item?.categories.map((category) => {
            category.items.forEach((item) => {
                if (!item.variant_exist) {
                    if (currentPlanData?.quantity_exist) {
                        totalItemPrice += item.addon_price * item.quantity;
                    } else if (!currentPlanData?.quantity_exist) {
                        totalItemPrice += item.addon_price;
                    }
                } else if (item.variant_exist) {
                    if (currentPlanData?.quantity_exist) {
                        totalItemPrice +=
                            parseFloat(item.chosen_variant_detail.price) * item.quantity;
                    } else if (!currentPlanData?.quantity_exist) {
                        totalItemPrice += parseFloat(item.chosen_variant_detail.price);
                    }
                }
            });
        });
        return totalItemPrice;
    };
    const currentPlanAddon = (category_name) => {
        // need to find current plan, its either change plan or subsdetails.plan_id
        // filter against all plans and retrieve all of its own categories' plans items
        var currentPlanCategoryData = [];
        const selectedPlan = changePlan !== undefined ? changePlan : subsDetails.plan_id;
        const selectedPlanInfo = allPlans.filter((plan) => plan.plan_id === selectedPlan);
        const categoriesItemId = selectedPlanInfo[0].categories_item.categories.filter(
            (category) => category.name === category_name
        );
        // to check if item is id or already has data by itself
        categoriesItemId[0]?.items.forEach((item_id) => {
            // if item_id is int then filter,
            // else add
            if (isNaN(item_id)) {
                currentPlanCategoryData.push(item_id);
            }
            if (!isNaN(item_id)) {
                const itemData = allAddons.filter((addon) => addon.addon_id === item_id);
                currentPlanCategoryData.push(itemData[0]);
            }
        });
        return currentPlanCategoryData;
    };

    const quantityExist = () => {
        // check current plan id and see if it has quantity_exist
        var quantityExist = true;
        var currentPlanData = allPlans.filter((plan) => plan.plan_id === subsDetails.plan_id)[0];
        quantityExist = currentPlanData?.quantity_exist ? true : false;
        return quantityExist;
    };

    const isFlatFee = () => {
        var currentPlanData = allPlans.filter((plan) => plan.plan_id === subsDetails.plan_id)[0];
        return currentPlanData?.pricing_model === "flat_fee" ? true : false;
    };

    const renderItemSurcharge = (item_data) => {
        var currentPlanData = allPlans.filter((plan) => plan.plan_id === subsDetails.plan_id)[0];
        var totalItemPrice = 0.0;
        console.log(item_data, "item data");
        if (!item_data.variant_exist) {
            if (currentPlanData?.quantity_exist) {
                totalItemPrice += item_data.addon_price * item_data.quantity;
            } else if (!currentPlanData?.quantity_exist) {
                totalItemPrice += item_data.addon_price;
            }
        } else if (item_data.variant_exist) {
            if (currentPlanData?.quantity_exist) {
                totalItemPrice +=
                    parseFloat(item_data.chosen_variant_detail.price) * item_data.quantity;
            } else if (!currentPlanData?.quantity_exist) {
                totalItemPrice += parseFloat(item_data?.chosen_variant_detail.price);
            }
        }
        return totalItemPrice;
    };
    const totalPrice = () => {
        var totalItemPrice = calcTotalItem();
        var planPrice = subsDetails.plan_price;
        var deliveryFee = subsDetails.delivery_fee;
        var totalPrice = totalItemPrice + planPrice + deliveryFee;
        return totalPrice;
    };
    const currentPlanItems = (category_name) => {
        var getCurrentAddon = currentPlanAddon(category_name);

        var subsArr = [];
        var availableItemArr = [];
        // compile all addon id from subsDetails
        subsDetails.categories_item.categories.filter((category) => {
            if (category.name === category_name) {
                category.items.filter((item) => subsArr.push(item.addon_id));
            }
        });
        subsArr = Array.from(new Set(subsArr));
        // if subsDetails addon have already existed before, dont add to availableItemArr
        // getCurrentAddon.forEach((data) => {
        //     if (data !== undefined) {
        //         if (!subsArr.includes(data.addon_id)) {
        //             availableItemArr.push(data);
        //         }
        //     }
        // });
        // console.log(availableItemArr, "available item arr");

        return getCurrentAddon;
    };

    const addonSelected = (category_name, addon_id) => {
        var subsAddonIds = [];
        // check against current subs details, if addon id has already existed then return true to disable
        subsDetails.categories_item.categories.filter((category) => {
            if (category.name === category_name) {
                category.items.filter((item) => subsAddonIds.push(item.addon_id));
            }
        });
        console.log(subsDetails.categories_item.categories, "subs detail categories");
        console.log(subsAddonIds, "subs addon ids");
        console.log(addon_id, "addon id");
        if (subsAddonIds.includes(addon_id)) {
            return true;
        }
        return false;
    };
    const saveChanges = () => {
        // get latest subs details and submit to backend
        // need to check if they have restriction
        const currentPlan = allPlans.filter((plan) => plan.plan_id === subsDetails.plan_id)[0];
        var isChangeValid = true;
        // check current plan's restriction and return error if inequal
        if (currentPlan.quantity_exist) {
            const quantityRestrictions = currentPlan.quantity_restrictions;
            if (quantityRestrictions?.apply_to === "individual") {
                subsDetails.categories_item?.categories.forEach((category, category_index) => {
                    const restrictionInfo = currentPlan.quantity_restrictions.category.filter(
                        (info) => info.category === category.name
                    )[0];
                    // check current category total number against restriction info's number
                    var currentCategoryTotal = 0;
                    category.items.forEach((item) => (currentCategoryTotal += item.quantity));
                    var isValid = true;
                    var newQuantity = quantityRestriction.quantity;
                    if (currentCategoryTotal !== restrictionInfo.number) {
                        isValid = false;
                        isChangeValid = false;
                        newQuantity.push(category_index);
                    }
                    if (currentCategoryTotal === restrictionInfo.number) {
                        // only remove current category index from quantityrestrictions.quantity
                        if (newQuantity.includes(category_index)) {
                            newQuantity.splice(newQuantity.indexOf(category_index), 1);
                        }
                    }
                    setQuantityRestriction({
                        valid: isValid,
                        quantity: newQuantity,
                    });
                });
            }
            if (quantityRestrictions?.apply_to === "all") {
                // get number and sum up all value in current subdetails
                const restrictionAmount = quantityRestrictions.category[0].number;
                var currentPlanAmount = 0;

                subsDetails.categories_item.categories.map((category) =>
                    category.items.map((item) => (currentPlanAmount += item.quantity))
                );
                if (restrictionAmount !== currentPlanAmount) {
                    isChangeValid = false;
                    // return validation true and the difference between currentPlanAmount and restrictionAmount
                    setQuantityRestriction({
                        valid: false,
                        quantity: [restrictionAmount],
                    });
                }
                if (restrictionAmount === currentPlanAmount) {
                    setQuantityRestriction({
                        valid: true,
                        quantity: [],
                    });
                }
            }
        }
        if (isChangeValid) {
            // submit subsdetails to server
            subscriptionService
                .editSubscription(subsDetails.id, subsDetails)
                .then((success) => {
                    sessionStorage.setItem("edit_row", JSON.stringify(subsDetails.id));
                    if (isLoggedIn.userRole === "merchant") {
                        history.push("/admin/order/");
                    } else {
                        history.push("/customer/order/");
                    }
                })
                .catch((err) => console.error(err));
        }
    };

    const checkPlanRestriction = (plan_id) => {
        const currentPlan = allPlans.filter((plan) => plan.plan_id === plan_id);

        return currentPlan[0]?.quantity_restrictions?.apply_to;
    };
    const checkIndividualRestriction = (plan_id, category_index) => {
        // take plan restriction and return restriction number
        // use category_index to identify restrction row number
        const currentPlan = allPlans.filter((plan) => plan.plan_id === plan_id)[0];
        return currentPlan.quantity_restrictions.category[category_index].number;
    };
    const renderVariantTitle = () => {
        // check variant_exist
        console.log(subsDetails, "this is the variant title");
        var variantExist = subsDetails.categories_item.categories.map((category) => {
            const filterCategory = category.items.filter((item) => item?.variant_exist);
            return filterCategory.length > 0 && true;
        });
        variantExist = variantExist.filter((variant) => variant);
        return variantExist.length > 0 ? "Variant" : "";
    };
    return (
        <>
            {store.getState().loggedInUserReducer.userData.userRole === "merchant" && (
                <>
                    <Row>
                        <Form.Label>Product</Form.Label>
                    </Row>
                    <Row className="pr-0">
                        <Col lg={5} md={5} xs={5}>
                            <Form.Control disabled value={subsDetails.plan_name} />
                        </Col>
                        <Col
                            className="d-flex align-self-center justify-content-center"
                            lg={1}
                            md={1}
                            xs={1}
                        >
                            <ArrowRightAltIcon />
                        </Col>
                        <Col className="mr-0 pr-0" lg={6} md={6} xs={6}>
                            <Form.Select
                                onChange={(plan) => {
                                    var newPlanDetail = allPlans.filter(
                                        (plan_detail) =>
                                            plan_detail.plan_id === parseInt(plan.target.value)
                                    );
                                    // 1. need to get newPlanDetail[0].categories_item.categories item data from allAddons
                                    newPlanDetail[0].categories_item?.categories.forEach(
                                        (category) => {
                                            var newCategoriesItem = [];
                                            category.items.forEach((item_id) => {
                                                newCategoriesItem.push(
                                                    allAddons.filter((addon) => {
                                                        // manipulate values/key here
                                                        if (addon.addon_id === item_id) {
                                                            addon.price = addon.addon_price;
                                                            addon.quantity = 1;
                                                            if (addon.variant_exist) {
                                                                addon.chosen_variant_detail =
                                                                    addon.variant_detail[0];
                                                            }
                                                            return addon;
                                                        }
                                                    })[0]
                                                );
                                            });
                                            category.items = newCategoriesItem;
                                        }
                                    );
                                    console.log(newPlanDetail, "this is the new plan detail");
                                    // 2. assign to sub details and it should WORK!!!
                                    // getting undefined addon id
                                    handleSubsDetails({
                                        plan_id: parseInt(plan.target.value),
                                        plan_name: newPlanDetail[0].plan_name,
                                        categories_item: newPlanDetail[0].categories_item,
                                    });
                                    setChangePlan(parseInt(plan.target.value));
                                }}
                                value={subsDetails.plan_id}
                            >
                                {allPlans.map((plan) => {
                                    return <option value={plan.plan_id}>{plan.plan_name}</option>;
                                })}
                            </Form.Select>
                        </Col>
                    </Row>
                    <hr className="bg-black mt-3" />
                </>
            )}
            {subsDetails.categories_item?.categories[0].items.length > 0 && (
                <>
                    <Row>
                        <Form.Label>Item Selection</Form.Label>
                    </Row>
                    {checkPlanRestriction(subsDetails.plan_id) === "all" &&
                        !quantityRestriction.valid && (
                            <div
                                className={`${
                                    window.innerWidth < 991 && "mr-4"
                                } d-flex align-items-center p-1 ml-2`}
                                style={{
                                    backgroundColor: "#E86F7D",
                                    color: "white",
                                    maxWidth: "fit-content",
                                }}
                            >
                                <ErrorIcon />
                                <p className="pl-3">
                                    You can only choose {`${quantityRestriction.quantity[0]}`} items
                                    in this category
                                </p>
                            </div>
                        )}
                    {subsDetails.categories_item.categories.map((category, category_index) => {
                        return (
                            <>
                                {/* check quantity restriction, differentiate using apply_to value */}
                                {checkPlanRestriction(subsDetails.plan_id) === "individual" &&
                                    quantityRestriction.quantity.includes(category_index) && (
                                        <div
                                            className="d-flex align-items-center p-1 ml-2 pr-3"
                                            style={{
                                                backgroundColor: "#E86F7D",
                                                color: "white",
                                                maxWidth: "fit-content",
                                            }}
                                        >
                                            <ErrorIcon />
                                            <p className="pl-3">
                                                You can only choose{" "}
                                                {checkIndividualRestriction(
                                                    subsDetails.plan_id,
                                                    category_index
                                                )}{" "}
                                                items in this category
                                            </p>
                                        </div>
                                    )}
                                {category.items.length > 0 && (
                                    <Row className="pt-3 pr-0">
                                        <Col lg={3} md={3} xs={12}>
                                            {category.name}
                                        </Col>
                                        <Col lg={3} md={3} xs={12}>
                                            {renderVariantTitle()}
                                        </Col>
                                        {window.innerWidth > 991 && (
                                            <Col lg={3} md={3} xs={12}>
                                                {quantityExist() && "Quantity"}
                                            </Col>
                                        )}
                                        {window.innerWidth > 991 && (
                                            <Col
                                                className="d-flex justify-content-end"
                                                lg={2}
                                                md={2}
                                                xs={12}
                                            >
                                                Surcharge
                                            </Col>
                                        )}

                                        <Col lg={1} md={1} xs={0}></Col>
                                    </Row>
                                )}
                                {/* Category input value */}
                                {category.items.map((item, item_index) => {
                                    return (
                                        <Row
                                            className={`${
                                                window.innerWidth > 991 ? "pt-3" : "pt-2"
                                            } pr-0`}
                                        >
                                            {window.innerWidth < 991 && <Col>Item</Col>}
                                            <Col
                                                className={`${
                                                    window.innerWidth < 991 && "pt-2"
                                                } ml-0`}
                                                lg={3}
                                                md={3}
                                                xs={12}
                                            >
                                                <Form.Select
                                                    onChange={(addon_id) => {
                                                        var oldItem = [
                                                            ...subsDetails.categories_item
                                                                .categories,
                                                        ];
                                                        // need to update price and name here
                                                        const newAddonInfo = allAddons.filter(
                                                            (addon) =>
                                                                addon.addon_id ===
                                                                parseInt(addon_id.target.value)
                                                        );
                                                        oldItem[category_index].items[item_index] =
                                                            newAddonInfo[0];
                                                        oldItem[category_index].items[
                                                            item_index
                                                        ].addon_id = newAddonInfo[0].addon_id;
                                                        oldItem[category_index].items[
                                                            item_index
                                                        ].price = parseFloat(
                                                            newAddonInfo[0].addon_price
                                                        );
                                                        oldItem[category_index].items[
                                                            item_index
                                                        ].quantity = 1;

                                                        if (newAddonInfo[0].variant_exist) {
                                                            // find lowest price variable
                                                            oldItem[category_index].items[
                                                                item_index
                                                            ].chosen_variant_detail =
                                                                newAddonInfo[0].variant_detail[0];
                                                        }
                                                        console.log(oldItem, "old item");
                                                        handleSubsDetails({
                                                            categories_item: {
                                                                categories: oldItem,
                                                            },
                                                        });
                                                    }}
                                                    value={item.addon_id}
                                                >
                                                    {/* <option hidden value="" disabled></option> */}

                                                    {allPlans.length > 0 &&
                                                        subsDetails !== undefined &&
                                                        currentPlanItems(category.name).map(
                                                            (addon) => {
                                                                return (
                                                                    <option
                                                                        disabled={
                                                                            addon !== undefined &&
                                                                            addon !== null
                                                                                ? addonSelected(
                                                                                      category.name,
                                                                                      addon.addon_id
                                                                                  )
                                                                                : false
                                                                        }
                                                                        value={
                                                                            addon !== undefined &&
                                                                            addon !== null
                                                                                ? addon.addon_id
                                                                                : ""
                                                                        }
                                                                    >
                                                                        {addon !== undefined &&
                                                                        addon !== null
                                                                            ? addon.addon_name
                                                                            : ""}
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                </Form.Select>
                                            </Col>
                                            <Col lg={3} md={3} xs={0}>
                                                {item.variant_exist && (
                                                    <Form.Select
                                                        value={item.chosen_variant_detail?.variant}
                                                        onChange={(e) => {
                                                            var variantData =
                                                                subsDetails.categories_item
                                                                    .categories;
                                                            var currentVariantDetail =
                                                                variantData[category_index].items[
                                                                    item_index
                                                                ];
                                                            currentVariantDetail.chosen_variant_detail =
                                                                currentVariantDetail.variant_detail.filter(
                                                                    (detail) =>
                                                                        detail.variant ===
                                                                        e.target.value
                                                                )[0];
                                                            variantData[category_index].items[
                                                                item_index
                                                            ] = currentVariantDetail;
                                                            handleSubsDetails({
                                                                categories_item: {
                                                                    categories: variantData,
                                                                },
                                                            });
                                                        }}
                                                    >
                                                        {item.variant_detail.map((variant) => {
                                                            return (
                                                                <option value={variant.variant}>
                                                                    {variant.variant}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Select>
                                                )}
                                            </Col>
                                            {window.innerWidth < 991 && (
                                                <Col className="pt-3">
                                                    {quantityExist() && "Quantity"}
                                                </Col>
                                            )}
                                            <Col
                                                // className={`${
                                                //     window.innerWidth > 991 ? "pt-3" : "pt-2"
                                                // }`}
                                                lg={3}
                                                md={3}
                                                xs={12}
                                            >
                                                {quantityExist() && (
                                                    <Form.Control
                                                        type="number"
                                                        onChange={(quantity) => {
                                                            var oldItem = [
                                                                ...subsDetails.categories_item
                                                                    .categories,
                                                            ];
                                                            if (
                                                                oldItem[category_index].items[
                                                                    item_index
                                                                ].quantity === 0 &&
                                                                quantity.target.value === "-1"
                                                            ) {
                                                                oldItem[category_index].items[
                                                                    item_index
                                                                ].quantity = 0;
                                                            } else {
                                                                oldItem[category_index].items[
                                                                    item_index
                                                                ].quantity = parseInt(
                                                                    quantity.target.value
                                                                );
                                                            }
                                                            handleSubsDetails({
                                                                categories_item: {
                                                                    categories: oldItem,
                                                                },
                                                            });
                                                        }}
                                                        value={item.quantity}
                                                    />
                                                )}
                                            </Col>
                                            <Col
                                                className={`${
                                                    window.innerWidth < 991 && "pt-3"
                                                } d-flex justify-content-end`}
                                                lg={2}
                                                md={2}
                                                xs={12}
                                            >
                                                <p style={{ color: "green" }}>
                                                    RM
                                                    {renderItemSurcharge(item).toFixed(2)}
                                                </p>
                                            </Col>
                                            <Col
                                                className={`${
                                                    window.innerWidth < 991 && "pt-3"
                                                } d-flex`}
                                                style={{
                                                    justifyContent:
                                                        window.innerWidth < 991 ? "center" : "end",
                                                }}
                                                lg={1}
                                                md={1}
                                                xs={12}
                                            >
                                                {window.innerWidth > 991 ? (
                                                    <p
                                                        onClick={() => {
                                                            // get current row item
                                                            // remove row from item
                                                            var allCategory =
                                                                subsDetails.categories_item
                                                                    .categories;
                                                            var categoryDataItem = category;
                                                            categoryDataItem.items.splice(
                                                                item_index,
                                                                1
                                                            );
                                                            allCategory[category_index] =
                                                                categoryDataItem;
                                                            handleSubsDetails({
                                                                categories_item: {
                                                                    categories: allCategory,
                                                                },
                                                            });
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        x
                                                    </p>
                                                ) : (
                                                    <Button
                                                        className="w-100 m-0"
                                                        onClick={() => {
                                                            // get current row item
                                                            // remove row from item
                                                            var allCategory =
                                                                subsDetails.categories_item
                                                                    .categories;
                                                            var categoryDataItem = category;
                                                            categoryDataItem.items.splice(
                                                                item_index,
                                                                1
                                                            );
                                                            allCategory[category_index] =
                                                                categoryDataItem;
                                                            handleSubsDetails({
                                                                categories_item: {
                                                                    categories: allCategory,
                                                                },
                                                            });
                                                        }}
                                                        style={{
                                                            borderColor: "red",
                                                            backgroundColor: "red",
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                        Delete
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                    );
                                })}
                                {allPlans.length !== 0 &&
                                    subsDetails.categories_item.categories[category_index].items
                                        .length < currentPlanAddon(category.name).length &&
                                    category.items.length > 0 && (
                                        <Row className="pt-3 pr-0 pl-3">
                                            <Button
                                                onClick={() => {
                                                    // depending on which category,
                                                    // add a row to it
                                                    var allCategory =
                                                        subsDetails.categories_item.categories;
                                                    var categoryData = category;
                                                    //  check available addon id and append to categoryData.items
                                                    var getCurrentPlanAddon = currentPlanAddon(
                                                        category.name
                                                    );
                                                    // get selected addon id
                                                    // compare available and selected addon id
                                                    // add item and select the first option available
                                                    getCurrentPlanAddon = getCurrentPlanAddon.map(
                                                        (addon) => addon.addon_id
                                                    );
                                                    var allCurrentPlanAddon = currentPlanAddon(
                                                        category.name
                                                    );
                                                    categoryData = categoryData.items.map(
                                                        (item) => item.addon_id
                                                    );
                                                    var availableAddon = [];
                                                    categoryData.forEach((data) => {
                                                        if (getCurrentPlanAddon.includes(data)) {
                                                            // get index of data in getcurrentplanaddon and splice it
                                                            var dataIndex =
                                                                getCurrentPlanAddon.indexOf(data);
                                                            getCurrentPlanAddon =
                                                                getCurrentPlanAddon.splice(
                                                                    dataIndex - 1,
                                                                    1
                                                                );
                                                        }
                                                    });
                                                    getCurrentPlanAddon = getCurrentPlanAddon.map(
                                                        (id) =>
                                                            allCurrentPlanAddon.filter((addon) => {
                                                                if (addon.addon_id === id) {
                                                                    if (addon.variant_exist) {
                                                                        addon.chosen_variant_detail =
                                                                            addon.variant_detail[0];
                                                                    }
                                                                    availableAddon.push(addon);
                                                                }
                                                            })
                                                    );
                                                    var addAddon = availableAddon[0];
                                                    var currentCategory = category;
                                                    currentCategory.items = [
                                                        ...currentCategory.items,
                                                        addAddon,
                                                    ];
                                                    allCategory[category_index] = currentCategory;
                                                    handleSubsDetails({
                                                        categories_item: {
                                                            categories: allCategory,
                                                        },
                                                    });
                                                }}
                                                style={{
                                                    maxWidth: "fit-content",
                                                    cursor: "pointer",
                                                    backgroundColor:
                                                        store.getState().colorState.color,
                                                    borderColor: store.getState().colorState.color,
                                                    color: store.getState().colorState.font_color,
                                                }}
                                            >
                                                <Add />
                                                Add Item
                                            </Button>
                                        </Row>
                                    )}
                            </>
                        );
                    })}
                    <hr className="bg-black mt-3" />
                </>
            )}
            {isFlatFee() && subsDetails.plan_price !== null && (
                <Row className="pt-3">
                    <Col>Plan Price</Col>
                    <Col className="d-flex justify-content-end">
                        RM{subsDetails.plan_price !== null && subsDetails.plan_price.toFixed(2)}
                    </Col>
                </Row>
            )}
            {subsDetails.delivery_fee !== null && (
                <Row className="pt-3">
                    <Col>Delivery Fee</Col>
                    <Col className="d-flex justify-content-end">
                        {subsDetails.delivery_fee !== null &&
                            `RM${subsDetails.delivery_fee.toFixed(2)}`}
                    </Col>
                </Row>
            )}
            <Row className="pt-3">
                <Col>Total Price</Col>
                <Col className="d-flex justify-content-end">RM{totalPrice().toFixed(2)}</Col>
            </Row>
            <Row>
                <Col className="pt-3 d-flex flex-col justify-content-end pr-0">
                    <Button className="cancel-edit-order" onClick={() => handleEditDetails()}>
                        Cancel
                    </Button>
                    <Button
                        style={{
                            backgroundColor: store.getState().colorState.color,
                            borderColor: store.getState().colorState.color,
                            color: store.getState().colorState.font_color,
                        }}
                        onClick={() => saveChanges()}
                    >
                        Save Changes
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default EditOrderItem;
