import ErrorIcon from "@material-ui/icons/Error";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { store } from "../../index.js";
import userService from "../../services/userService";
import SubscriptionView from "../Admin/Subscription/SubscriptionView";
import Homepage from "../Homepage/homepage";
import Invoice from "./Invoice";
import Profile from "./Profile";
import Settings from "./Settings";
import Subscription from "./Subscription";

const Customer = ({ history }) => {
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    let { path, url } = useRouteMatch();

    const hasCustomerRight = () => {
        return (
            userService.isLoggedIn &&
            store.getState().loggedInUserReducer.userData?.userRole == "customer"
        );
    };

    if (!hasCustomerRight()) {
        history.replace("/not-found");
        toast.error(
            <div>
                <ErrorIcon /> Not Authorized
            </div>,
            {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        );
    }

    return (
        <Switch>
            <Route path="/" exact component={Homepage} />
            <Route path={`${path}/profile`} exact component={Profile} />
            <Route path={`${path}/order`} exact component={Subscription} />
            <Route path={`${path}/order/:id`} component={SubscriptionView} />
            <Route path={`${path}/invoice`} exact component={Invoice} />
            <Route path={`${path}/settings/`} component={Settings} />
        </Switch>
    );
};

export default Customer;
