import axios from "axios";
import { clearLoggedInUserState, saveLoggedInUserState } from "../../action.js";
import { store } from "../../index.js";

const userService = {
    isLoggedIn: () => {
        var hasToken = !!JSON.parse(localStorage.getItem("user_token"));

        return hasToken;
    },
    login: (username, password) => {
        let loginFormData = new FormData();
        loginFormData.append("username", username);
        loginFormData.append("password", password);
        return new Promise(async (resolve, reject) => {
            await axios
                .post(
                    `${window.location.protocol + "//" + window.location.hostname}${
                        process.env.REACT_APP_SITE_PORT_BACKEND
                    }/api/token/`,
                    loginFormData
                )
                .then((response) => {
                    var tokenInfo = {
                        lastRefresh: new Date().getTime(),
                        access_token: response.data.access,
                        refresh_token: response.data.refresh,
                    };
                    localStorage.setItem("user_token", JSON.stringify(tokenInfo));

                    userService
                        .fetchUserDataWithTokenIntoRedux()
                        .then((response) => {
                            resolve(response);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    logout: () => {
        localStorage.removeItem("user_token");
        store.dispatch(clearLoggedInUserState()); //clear logged in user state
        return Promise.resolve();
    },
    refreshToken: () => {
        let currUser = JSON.parse(localStorage.getItem("user_token"));
        let getUserFormData = new FormData();
        getUserFormData.append("refresh", currUser.refresh_token);
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `${window.location.protocol + "//" + window.location.hostname}${
                        process.env.REACT_APP_SITE_PORT_BACKEND
                    }/api/token/refresh/`,
                    getUserFormData
                )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    fetchUserDataWithTokenIntoRedux: () => {
        let currUser = JSON.parse(localStorage.getItem("user_token"));

        if (!currUser) {
            // if no user in localStorage then the user must enter their credentials to proceed
            return Promise.reject(null);
        }

        axios.defaults.headers.common["Authorization"] = "Bearer " + currUser.access_token;
        return new Promise((resolve, reject) => {
            axios
                .get(
                    `${window.location.protocol + "//" + window.location.hostname}${
                        process.env.REACT_APP_SITE_PORT_BACKEND
                    }/catalog/user/info`
                )
                .then((res) => {
                    console.log(res, "this is res in redux data");
                    if (
                        res.data.userRole === "merchant" &&
                        window.location.href.includes("admin/login")
                    ) {
                        store.dispatch(saveLoggedInUserState(res.data)); //save logged in user state
                        resolve(res);
                    } else if (
                        res.data.userRole === "customer" &&
                        !window.location.href.includes("/admin/login")
                    ) {
                        store.dispatch(saveLoggedInUserState(res.data)); //save logged in user state
                        resolve(res);
                    } else {
                        console.log("error password");
                        sessionStorage.removeItem("user_token");
                        reject("Username Or Password is wrong");
                    }
                })
                .catch((error) => {
                    userService.logout();
                    reject(error);
                });
        });
    },
};

export default userService;
