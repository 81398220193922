import MerchantTable from "../../../../Components/DataTable";
import { useEffect, useLayoutEffect, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import KebabMenu from "../../../../Components/KebabMenu";
import { useHistory } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import BlockIcon from "@material-ui/icons/Block";
import Swal from "sweetalert2";
import planService from "../../../../services/planService";
import EventIcon from "@mui/icons-material/Event";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import { Form, InputGroup } from "react-bootstrap";
import { store } from "../../../../index.js";
import ArchiveIcon from "@mui/icons-material/Archive";
const PlanListing = () => {
    const defaultColumn = [
        {
            field: "plan_name",
            headerName: "NAME",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
            disabled: true,
        },
        {
            field: "created_at",
            headerName: "EFFECTIVE DATE",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
            disabled: false,
        },
        {
            field: "plan_status",
            headerClassName: "super-app-theme--header",
            headerName: "STATUS",
            headerAlign: "center",

            flex: 1,
            checked: true,
            disabled: false,
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            headerAlign: "center",
            type: "date",
            headerClassName: "super-app-theme--header",
            checked: true,
            disabled: true,
            // description: "This column has a value getter and is not sortable.",
            // sortable: false,

            // valueGetter: (params) => `${params.row.firstName || ""} ${params.row.lastName || ""}`,
            flex: 1,
             renderCell: (params) => {
                return (
                    <>
                        <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push(`/admin/product/${params.row.id}`)}
                        />
                        {params.row.plan_status === "Active" && (
                            <KebabMenu options={activeOptions(params)} />
                        )}
                        {params.row.plan_status === "Archived" && (
                            <KebabMenu options={archiveOptions(params)} />
                        )}
                    </>
                );
            },
	},
    ];
    const activeOptions = (params) => [deletePlan(params), archivePlan(params)];
    const archiveOptions = (params) => [deletePlan(params), reactivatePlan(params)];

    const [columns, setColumns] = useState(defaultColumn);
    const [columnField, setColumnField] = useState(defaultColumn);
    const [planData, setPlanData] = useState([]);
    const [filterProductId, setFilterProductId] = useState("");
    const [filterProductName, setFilterProductName] = useState("");
    const [filterProductStatus, setFilterProductStatus] = useState("");
    const history = useHistory();
    const [refreshData, setRefreshData] = useState(false);
    const [allPlanData, setAllPlanData] = useState([]);
    const deletePlan = (params) => {
        return {
            name: "Delete plan",
            icon: (
                <ListItemIcon>
                    <BlockIcon fontSize="small" />
                </ListItemIcon>
            ),
            optionCallback: () => {
                console.log(params.row.id, "this is the params");
                Swal.fire({
                    icon: "warning",
                    title: "Are you sure you want to delete this plan?",
                    text: "This action cannot be undone.",
                    confirmButtonColor: store.getState().colorState.color,
                    confirmButtonText: "Confirm",
                    cancelButtonColor: "white",
                    reverseButtons: true,
                    showCancelButton: true,
                }).then((result) => {
                    // delete plan
                    if (result.isConfirmed) {
                        planService
                            .editPlan({ plan_status: "deleted" }, params.row.id)
                            .then(() => {
                                sessionStorage.setItem(
                                    "deleted_row",
                                    JSON.stringify(params.row.id)
                                );
                                setRefreshData(true);
                            })
                            .catch((e) => console.error(e));
                    }
                });
            },
        };
    };
    const archivePlan = (params) => {
        return {
            name: "Archive plan",
            icon: (
                <ListItemIcon>
                    <ArchiveIcon fontSize="small" />
                </ListItemIcon>
            ),
            optionCallback: () => {
                Swal.fire({
                    icon: "warning",
                    title: "Are you sure you want to delete this plan?",
                    text: "This action cannot be undone.",
                    confirmButtonColor: store.getState().colorState.color,
                    confirmButtonText: "Confirm",
                    cancelButtonColor: "white",
                    reverseButtons: true,
                    showCancelButton: true,
                }).then((result) => {
                    // delete plan
                    if (result.isConfirmed) {
                        planService
                            .editPlan({ plan_status: "archived" }, params.row.id)
                            .then(() => {
                                sessionStorage.setItem(
                                    "deleted_row",
                                    JSON.stringify(params.row.id)
                                );
                                setRefreshData(true);
                            })
                            .catch((e) => console.error(e));
                    }
                });
            },
        };
    };
    const reactivatePlan = (params) => {
        return {
            name: "Reactivate plan",
            icon: (
                <ListItemIcon>
                    <PlaylistAddCheckIcon fontSize="small" />
                </ListItemIcon>
            ),
            optionCallback: () => {
                console.log(params.row.id, "this is the params");

                // delete plan
                planService
                    .editPlan({ plan_status: "active" }, params.row.id)
                    .then(() => {
                        sessionStorage.setItem("reactivate_row", JSON.stringify(params.row.id));
                        setRefreshData(true);
                    })
                    .catch((e) => console.error(e));
            },
        };
    };
    useLayoutEffect(() => {
        planService.getAllPlansWithoutPagination().then((allPlans) => {
            allPlans.plans.forEach((plan) => {
                plan.id = plan.plan_id;
                plan.created_at = new Date(plan.created_at).toLocaleDateString();
            });
            setPlanData(allPlans.plans);
            setAllPlanData(allPlans.plans);
            console.log(allPlans, "this all plan");
        });
    }, [refreshData]);

    useEffect(() => {
        var filteredData = allPlanData;
        if (filterProductId !== "") {
            filteredData = allPlanData.filter((data) => {
                // need to check for product id
                return data.plan_id === parseInt(filterProductId);
            });
        }
        if (filterProductName !== "") {
            var productNameArr = [];
            filteredData = allPlanData.filter((data) => {
                console.log(data, "data");
                if (data.plan_name.toLowerCase().includes(filterProductName.toLowerCase())) {
                    productNameArr.push(data);
                }
            });
            filteredData = productNameArr;
        }
        if (filterProductStatus !== "") {
            console.log(filterProductStatus);
            filteredData = allPlanData.filter((data) => {
                return data.plan_status.toLowerCase() === filterProductStatus;
            });
        }
        // need to check for product name
        // check for product status
        setPlanData(filteredData);
    }, [filterProductId, filterProductName, filterProductStatus]);

    const filterList = [
        // {
        //     key: "plan_id",
        //     component: (
        //         <>
        //             <Form.Label className="mt-3">Product ID</Form.Label>
        //             <Form.Control
        //                 value={filterProductId}
        //                 onChange={(plan) => setFilterProductId(plan.target.value)}
        //             />
        //         </>
        //     ),
        // },
        {
            key: "plan_name",
            component: (
                <>
                    <Form.Label className="mt-1">Product Name</Form.Label>
                    <Form.Control
                        onChange={(duration) => setFilterProductName(duration.target.value)}
                        value={filterProductName}
                    />
                </>
            ),
        },

        {
            key: "plan_status",
            component: (
                <>
                    <Form.Label className="mt-3">Status</Form.Label>
                    <Form.Select onChange={(status) => setFilterProductStatus(status.target.value)}>
                        <option value=""></option>
                        <option value="active">Active</option>
                        <option value="archived">Archived</option>
                    </Form.Select>
                </>
            ),
        },
    ];
    const handleHeaderDisplay = (header) => {
        const newColumn = defaultColumn.filter((column, column_index) => {
            if (header[column_index].checked) {
                return column;
            }
        });
        setColumns(newColumn);
    };

    const handleCheckField = (check) => {
        setColumnField(check);
    };

    const handleColumns = (columns) => {
        setColumns(columns);
    };

    return (
        <MerchantTable
            Header={"Product"}
            Columns={columns}
            ColumnField={columnField}
            Rows={planData}
            CreateExist={true}
            RefreshData={refreshData}
            handleRefreshData={() => {
                setRefreshData(false);
            }}
            ExportExist={true}
            FilterExist={true}
            FilterList={filterList}
            DisplayExist={true}
            SearchBarExist={true}
            handleHeaderDisplay={handleHeaderDisplay}
            handleColumns={handleColumns}
            handleCheckField={handleCheckField}
        />
    );
};

export default PlanListing;
