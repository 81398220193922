import { callAPI } from "../../utils/apiUtils";
import axios from "axios";
import generalFunctionService from "../generalFunctionService";
const addOnService = {
    getAddOnListing: (pageIndex = 1, search = "") => {
        return new Promise((resolve, reject) => {
            callAPI(
                `/catalog/addons/list?page=${pageIndex}&search=${search}&addon_status__in=active,archived`
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getAllAddonWithoutPagination: (statusFilter = []) => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/addons?${statusFilter.map((s) => "&status=" + s).join("")}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getSingleAddon: (addonId) => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/addons/${addonId}`, "get")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    createAddonImage: (dataBody) => {
        var formData = generalFunctionService.convertJsonToFormData(dataBody);
        return new Promise((resolve, reject) => {
            //reject("GG");
            axios
                .post(
                    `${window.location.protocol + "//" + window.location.hostname}${
                        process.env.REACT_APP_SITE_PORT_BACKEND
                    }/catalog/addon_image/`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    deleteAddon: (addonId) => {
        const dataBody = {
            addon_status: "deleted",
        };
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/addons/${addonId}`, "delete", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    createAddon: (dataBody) => {
        // var formData = generalFunctionService.convertJsonToFormData(dataBody);
        console.log(dataBody, "this the databody addon creation");
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `/catalog/addons/`,
                    dataBody
                    // headers: {
                    //     "Content-Type": "multipart/form-data",
                    // },
                )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },

    createProfile: (dataBody) => {
        var formData = generalFunctionService.convertJsonToFormData(dataBody);

        return new Promise((resolve, reject) => {
            axios
                .post(`/settings/`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    updateAddon: (addonId, dataBody) => {
        // var formData = generalFunctionService.convertJsonToFormData(dataBody);

        return new Promise((resolve, reject) => {
            axios
                .put(`/catalog/addons/${addonId}`, dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    archiveAddon: (addonId) => {
        const dataBody = {
            addon_status: "archived",
        };
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/addons/${addonId}`, "patch", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    reactivateAddon: (addonId) => {
        const dataBody = {
            addon_status: "active",
        };
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/addons/${addonId}`, "patch", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    dataRestructure: (data, currency = "RM") => {
        let res = [...data];
        for (let i = 0; i < data.length; i++) {
            if (data[i].pricing_model === "per_unit") {
                res[i].pricing_model_ = "Per Unit";
            } else if (data[i].pricing_model === "flat_fee") {
                res[i].pricing_model_ = "Flat fee";
            } else if (data[i].pricing_model === "tiered") {
                res[i].pricing_model_ = "Tiered pricing model";
            } else if (data[i].pricing_model === "volume") {
                res[i].pricing_model_ = "Volume pricing model";
            } else if (data[i].pricing_model === "stairstep") {
                res[i].pricing_model_ = "Stair-step pricing model";
            }

            if (data[i].charge_by_percentage) {
                res[i].addon_price_ = data[i].addon_price + "%";
            } else {
                res[i].addon_price_ = currency + data[i].addon_price;
            }

            if (data[i].addon_recurrence == "one_off") {
                res[i].addon_recurrence_ = "One Off";
            } else {
                res[i].addon_recurrence_ = "Recurring";
            }
        }
        return res;
    },
};

export default addOnService;
