import ListItemIcon from "@material-ui/core/ListItemIcon";
import BlockIcon from "@material-ui/icons/Block";
import PauseIcon from "@material-ui/icons/Pause";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useLayoutEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import DataTable from "../../../Components/DataTable";
import KebabMenu from "../../../Components/KebabMenu";
import UnsubscribeSurvey from "../../../Components/UnsubscribeSurvey";
import { store } from "../../../index.js";
import customerService from "../../../services/customerService";
import subscriptionService from "../../../services/subscriptionService";
import "./style.css";
import { format } from "date-fns";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const Subscription = () => {
    const defaultColumn = [
        {
            field: "plan_name",
            headerName: "PRODUCT NAME",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
            disabled: true,
        },
        {
            field: "delivery_date",
            headerName: "DELIVERY DATE",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
            disabled: false,
        },
        {
            field: "subs_type",
            headerName: "PRODUCT TYPE",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
        },
        {
            field: "subs_status",
            headerName: "STATUS",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
        },

        {
            field: "actions",
            headerName: "ACTIONS",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            type: "date",
            flex: 1,
            renderCell: (params) => {
                var kebabOptions = [];
                switch (params.row.subs_status) {
                    case "Active":
                        cancelOption(kebabOptions, params.row); 
                        break;
                    case "Paused":
                        reactivateOption(kebabOptions, params.row);
                    case "Pending":
                        cancelOption(kebabOptions, params.row);
                    default:
                        break;
                }
                return (
                    <>
                        <EditIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => history.push(`/customer/order/${params.row.id}`)}
                        />
                        {/* only if customer has mandate existed && current order is paused*/}
                        {kebabOptions.length > 0 && <KebabMenu options={kebabOptions} />}
                    </>
                );
            },
            checked: true,
            disabled: true,
        },
    ];
    const isLoggedIn = useSelector((state) => state.loggedInUserReducer.userData);
    const history = useHistory();
    const [showUnsubscribeSurvey, setShowUnsubscribeSurvey] = useState(false);

    const handleCloseUnsubscribeModal = () => setShowUnsubscribeSurvey(false);
    const [subsData, setSubsData] = useState([]);
    const [allOrderData, setAllOrderData] = useState([]);
    const [refreshData, setRefreshData] = useState(false);
    const [filterDeliveryStartDate, setFilterDeliveryStartDate] = useState("");
    const [filterDeliveryEndDate, setFilterDeliveryEndDate] = useState("");
    const [filterProductName, setFilterProductName] = useState("");
    const [filterProductType, setFilterProductType] = useState("");
    const [filterSubsStatus, setFilterSubsStatus] = useState("");
    const [unsubscribeId, setUnsubscribeId] = useState();
    const [columns, setColumns] = useState(defaultColumn);
    const [columnField, setColumnField] = useState(defaultColumn);
    const cancelOption = (kebabArr, subsData) => {
        kebabArr.push({
            name: "Cancel Subscription",
            icon: (
                <ListItemIcon>
                    <PauseIcon fontSize="small" />
                </ListItemIcon>
            ),
            optionCallback: () => {
                Swal.fire({
                    title: "Are you sure you want to cancel this subscription?",
                    // text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: store.getState().colorState.color,
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, cancel it!",
                }).then((res) => {
                    console.log(res, "subs res");
                    // if merchant
                    if (res.isConfirmed) {
                        if (isLoggedIn.userRole === "merchant") {
                            var pausedSubscription = {
                                subs_status: "paused",
                            };
                            if (res.isConfirmed) {
                                subscriptionService
                                    .editSubscription(subsData.id, pausedSubscription)
                                    .then(() => {
                                        window.location.reload();
                                    })
                                    .catch((err) => {
                                        Swal.fire({
                                            title: "Error Occurs!",
                                            text: err.message,
                                            icon: "error",
                                            confirmButtonText: "ok",
                                        });
                                    });
                            }
                        } else {
                            setUnsubscribeId(subsData.id);
                            console.log(showUnsubscribeSurvey, "show unsubscribe survey");
                            setShowUnsubscribeSurvey(true);
                        }
                    }
                });
            },
        });
    };
    const pauseOption = (kebabArr, subsData) => {
        kebabArr.push({
            name: "Pause Subscription",
            icon: (
                <ListItemIcon>
                    <BlockIcon fontSize="small" />
                </ListItemIcon>
            ),
            optionCallback: () => {
                Swal.fire({
                    title: "Are you sure you want to pause this subscription?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: store.getState().colorState.color,
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, cancel it!",
                }).then((res) => {
                    subsData.subs_price =
                        subsData.subs_price !== null && subsData.subs_price !== undefined
                            ? parseFloat(subsData.subs_price.slice(2))
                            : 0;
                    var cancelledSubscription = {
                        ...subsData,
                        subs_status: "paused",
                    };
                    if (res.isConfirmed) {
                        subscriptionService
                            .editSubscription(subsData.id, cancelledSubscription)
                            .then((res) => {
                                var data_body = {
                                    id: subsData.id,
                                };
                                subscriptionService
                                    .cancelSubEmail(data_body)
                                    .then((res2) => {
                                        console.log("Sent email");
                                        window.location.reload();
                                    })
                                    .catch((err) => {
                                        Swal.fire({
                                            title: "Error Occurs!",
                                            text: err.message,
                                            icon: "error",
                                            confirmButtonText: "ok",
                                        });
                                    });
                            })
                            .catch((err) => {
                                Swal.fire({
                                    title: "Error Occurs!",
                                    text: err.message,
                                    icon: "error",
                                    confirmButtonText: "ok",
                                });
                            });
                    }
                });
            },
        });
    };

    const reactivateOption = (kebabArr, subsData) => {
        kebabArr.push({
            name: "Reactivate Subscription",
            icon: (
                <ListItemIcon>
                    <PlaylistAddCheckIcon fontSize="small" />
                </ListItemIcon>
            ),
            optionCallback: () => {
                Swal.fire({
                    title: "Are you sure you want to reactivate this subscription?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: store.getState().colorState.color,
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, reactivate it!",
                }).then((res) => {
                    var reactivatedSubscription = {
                        subs_status: "active",
                    };
                    if (res.isConfirmed) {
                        // console.log(value);
                        subscriptionService
                            .reactivateSubscription(subsData.id, reactivatedSubscription)
                            .then((res) => {
                                window.location.reload();
                            })
                            .catch((err) => {
                                console.log(err, "this the err");
                                Swal.fire({
                                    title: "Error occurred!",
                                    text: err.message,
                                    icon: "error",
                                    confirmButtonText: "Ok",
                                });
                            });
                    }
                });
            },
        });
    };
    const kebabOptions = (params) => {
        var menuArr = [];
        console.log(params, "this the param");
        menuArr.push([
            // if current order is paused
            {
                name: "Reactivate order",
                icon: (
                    <ListItemIcon>
                        <BlockIcon fontSize="small" />
                    </ListItemIcon>
                ),
                optionCallback: () => {
                    Swal.fire({
                        icon: "warning",
                        title: "Are you sure you want to reactivate this order?",
                        text: "This action cannot be undone.",
                        reverseButtons: true,
                        confirmButtonColor: store.getState().colorState.color,
                        confirmButtonText: "Confirm",
                        cancelButtonColor: "white",
                        showCancelButton: true,
                    }).then((result) => {
                        // delete discount
                        if (result.isConfirmed) {
                            // check mandate
                            subscriptionService
                                .editSubscription(params.row.id, { subs_status: "active" })
                                .then((success) => {
                                    sessionStorage.setItem(
                                        "cancel_row",
                                        JSON.stringify(params.row.id)
                                    );
                                    setRefreshData(true);
                                })
                                .catch((err) => console.log(err));
                        }
                    });
                },
            },
        ]);
        return menuArr;
    };

    const filterList = [
        {
            key: "subs_status",
            component: (
                <>
                    <Form.Label>Order Status</Form.Label>
                    <Form.Select
                        onChange={(duration) => setFilterSubsStatus(duration.target.value)}
                        value={filterSubsStatus}
                        as="select"
                    >
                        <option value="" disabled hidden>
                            Please select
                        </option>
                        <option value={"active"}>Active</option>
                        <option value={"pending"}>Pending</option>
                        <option value={"enrolment approved"}>Enrolment Approved</option>
                        <option value={"inactive"}>Inactive</option>
                    </Form.Select>
                </>
            ),
        },
        {
            key: "subs_type",
            component: (
                <>
                    <Form.Label className="mt-3">Product Type</Form.Label>
                    <Form.Select
                        onChange={(duration) => setFilterProductType(duration.target.value)}
                        value={filterProductType}
                        as="select"
                    >
                        <option value="" disabled hidden>
                            Please select
                        </option>
                        <option value={"Recurring"}>Recurring</option>
                        <option value={"One_off"}>One-off</option>
                    </Form.Select>
                </>
            ),
        },
        {
            key: "delivery_date",
            component: (
                <>
                    <Form.Label className="mt-3">Delivery Date</Form.Label>
                    <div className="d-flex">
                        <div>
                            {" "}
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                                value={filterDeliveryStartDate}
                                onChange={(date) => {
                                    setFilterDeliveryStartDate(
                                        format(new Date(date.target.value), "yyyy-MM-dd")
                                    );
                                }}
                                type="date"
                            />
                        </div>
                        <div
                            className="d-flex align-self-center"
                            style={{ padding: "9px", paddingTop: "2.5em" }}
                        >
                            <ArrowRightAltIcon />
                        </div>
                        <div>
                            {" "}
                            <Form.Label>End Date</Form.Label>
                            <Form.Control
                                value={filterDeliveryEndDate}
                                onChange={(date) => {
                                    // var newDate = new Date(date)
                                    setFilterDeliveryEndDate(
                                        format(new Date(date.target.value), "yyyy-MM-dd")
                                    );
                                }}
                                type="date"
                            />
                        </div>
                    </div>
                </>
            ),
        },
    ];

    const handleCheckField = (check) => {
        setColumnField(check);
    };

    const handleHeaderDisplay = (header) => {
        const newColumn = defaultColumn.filter((column, column_index) => {
            if (header[column_index].checked) {
                return column;
            }
        });
        setColumns(newColumn);
    };

    const handleColumns = (columns) => {
        setColumns(columns);
    };
    useLayoutEffect(() => {
        customerService
            .getSubscriptionListing()
            .then((subs) => {
                subs.results.forEach((sub) => {
                    if (sub.subs_type === "direct_debit") {
                        sub.subs_type = "Recurring";
                    } else if (sub.subs_type === "instant_pay") {
                        sub.subs_type = "One Off";
                    }
                });
                subs.results.reverse();
                setSubsData(subs.results);
                setAllOrderData(subs.results);
            })
            .catch((err) => console.error(err));
    }, [refreshData]);

    useEffect(() => {
        var filteredData = allOrderData;
	console.log(filterProductType,'this is the subs status');
	if (filterProductType !== "" ) {
	    filteredData = filteredData.filter((data) => {
		return data.subs_type.toLowerCase() === filterProductType.toLowerCase();
	    })
	}
        if (filterSubsStatus !== "") {
            filteredData = filteredData.filter((data) => {
		return data.subs_status.toLowerCase() === filterSubsStatus.toLowerCase();
            });
        }
        console.log(filteredData, "this is the filtered data");
        console.log(filterDeliveryStartDate, "start date");
        console.log(filterDeliveryEndDate, "end date");
        var validData = [];
        const parseDateFormat = (date_data) => {
            if (date_data.length <= 10) {
                const dateString = date_data;
                const dateParts = dateString.split("/");
                const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
                return date;
            } else {
                return date_data;
            }
        };
        if (filterDeliveryStartDate !== "" && filterDeliveryEndDate !== "") {
            allOrderData.forEach((data) => {
                if (data.delivery_date !== null) {
                    // if data.delivery_date is smaller than filterDeliveryEndDate and bigger than filterDeliveryStartDate
                    const dateValue = data.delivery_date;
                    const deliveryDate = parseDateFormat(dateValue);
                    const startDate = new Date(filterDeliveryStartDate);
                    const endDate = new Date(filterDeliveryEndDate);
                    const deliveryTime = deliveryDate.getTime();
                    const startTime = startDate.getTime();
                    const endTime = endDate.getTime();
                    if (endTime > deliveryTime && startTime < deliveryTime) {
                        validData.push(data);
                    }
                }
            });
            filteredData = validData;
        } else if (filterDeliveryStartDate !== "" && filterDeliveryEndDate === "") {
            allOrderData.forEach((data) => {
                if (data.delivery_date !== null) {
                    // if data.delivery_date is smaller than filterDeliveryEndDate and bigger than filterDeliveryStartDate
                    const dateValue = data.delivery_date;
                    const deliveryDate = parseDateFormat(dateValue);
                    const startDate = new Date(filterDeliveryStartDate);
                    const deliveryTime = deliveryDate.getTime();
                    const startTime = startDate.getTime();
                    if (startTime < deliveryTime) {
                        validData.push(data);
                    }
                }
            });
            filteredData = validData;
        } else if (filterDeliveryStartDate === "" && filterDeliveryEndDate !== "") {
            allOrderData.forEach((data) => {
                if (data.delivery_date !== null) {
                    // if data.delivery_date is smaller than filterDeliveryEndDate and bigger than filterDeliveryStartDate
                    const dateValue = data.delivery_date;
                    const deliveryDate = parseDateFormat(dateValue);
                    const endDate = new Date(filterDeliveryEndDate);
                    const deliveryTime = deliveryDate.getTime();
                    const endTime = endDate.getTime();
                    if (endTime > deliveryTime) {
                        validData.push(data);
                    }
                }
            });
            filteredData = validData;
        }
        setSubsData(filteredData);
    }, [filterSubsStatus, filterDeliveryStartDate, filterDeliveryEndDate]);

    return (
        <>
            <DataTable
                Header={"Purchase"}
                Columns={columns}
                Rows={subsData}
                ColumnField={columnField}
                RefreshData={refreshData}
                handleRefreshData={() => {
                    setRefreshData(false);
                }}
                ExportExist={true}
                FilterExist={true}
                FilterList={filterList}
                DisplayExist={true}
                SearchBarExist={true}
                handleHeaderDisplay={handleHeaderDisplay}
                handleColumns={handleColumns}
                handleCheckField={handleCheckField}
            />
            <UnsubscribeSurvey
                subsId={unsubscribeId}
                modalState={showUnsubscribeSurvey}
                closeModal={handleCloseUnsubscribeModal}
            />
        </>
    );
};

export default Subscription;
