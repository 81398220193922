import ReactGA from "react-ga4";
import subscriptionService from "../subscriptionService";

/***************************************************** 
 * A service wrapper for react-ga (version 4)
 * Note that this is using latest version of react-ga 
 * which uses GA Measurement ID instead of UA ID
 * ***************************************************/

const ga4Service = {
    // initialize google analytics and trigger page view event
    init: () => {
        if (localStorage.getItem("google-analytics-4-enabled")) {
            console.log("Initializing google analytics version 4");
            // this is currently hardcoded to ketoccino's ID
	        ReactGA.initialize("G-JFXYKQRMDK");
            ReactGA.send("pageview");
        }
    },
    // trigger view plan event
    trackViewPlan : (itemName) => {
        if (localStorage.getItem("google-analytics-4-enabled")) {
            console.log("GA4 view plan: " + itemName);
            ReactGA.event('view_plan', {
                plan_name: itemName
            });
        }
    },
    // trigger continue button event
    trackContinueButton : (pageName) => {
        if (localStorage.getItem("google-analytics-4-enabled")) {
            console.log("GA4 continue button, page: " + pageName);
            ReactGA.event('continue', {
                page_name: pageName
            });
        }
    },
    // KETOCCINO SPECIFIC
    // trigger continue button event with number of servings
    trackContinueButtonWithSelection : (pageName, quantity, type) => {
        if (localStorage.getItem("google-analytics-4-enabled")) {
            console.log("GA4 continue button with servings, page: " 
                + pageName + ", quantity: " + quantity + ", type: " + type);
            if (type == "Recurring") {
                ReactGA.event('continue', {
                    page_name: pageName,
                    plan_type: type,
                    servings: quantity
                });
            } else {
                ReactGA.event('continue', {
                    page_name: pageName,
                    plan_type: type,
                    quantity: quantity
                });
            }
        }
    },
    // trigger continue button event with delivery options
    trackContinueButtonWithDeliveryOptions : (pageName, deliveryDate, deliveryTime) => {
        if (localStorage.getItem("google-analytics-4-enabled")) {
            console.log("GA4 continue button with delivery options, page: " 
                + pageName + ", delivery options: " + deliveryDate, + ", " + deliveryTime);
            ReactGA.event('continue', {
                page_name: pageName,
                delivery_date: deliveryDate,
                delivery_time: deliveryTime
            });
        }
    },
    // trigger subscribe event
    trackBackButton : (pageName) => {
        if (localStorage.getItem("google-analytics-4-enabled")) {
            console.log("GA4 back button, page: " + pageName);
            ReactGA.event('back', {
                page_name: pageName
            });
        }
    },
    // trigger checkout event, this is mainly used when user clicks the checkout button
    trackInitiateCheckout : (subsPrice, planName) => {
        if (localStorage.getItem("google-analytics-4-enabled")) {
            console.log("GA4 checkout button, plan:" + planName);
            ReactGA.event('initiate_checkout', {
                subs_price: subsPrice,
                plan_name: planName
            });
        }
    },
    // trigger checkout completed event, this is mainly used when user sees the confirmation pop up
    trackCheckoutCompleted : (subsId) => {
        if (localStorage.getItem("google-analytics-4-enabled")) {
            console.log("GA4 checkout completed, subsId:" + subsId);
            subscriptionService.getSingleSubscription(subsId).then((res) => {
                var planName = res.subs.plan_name
                var subsPrice = res.subs.subs_price
                    console.log("GA checkout completed: ", planName, subsPrice);
                    ReactGA.event('checkout_completed', {
                        plan_name: planName,
                        subs_price: subsPrice
                    });
                });
        }
    },
    // trigger sign in event
    trackSignIn : (pageName) => {
        if (localStorage.getItem("google-analytics-4-enabled")) {
            console.log("GA4 sign in, page: " + pageName);
            ReactGA.event('sign_in', {
                page_name: pageName
            });
        }
    },
    // trigger cancel button
    trackCancel : (pageName) => {
        if (localStorage.getItem("google-analytics-4-enabled")) {
            console.log("GA4 cancel, page: " + pageName);
            ReactGA.event('cancel', {
                page_name: pageName
            });
        }
    },
    // trigger sign up event
    trackSignUp : (pageName) => {
        if (localStorage.getItem("google-analytics-4-enabled")) {
            console.log("GA4 sign up, page: " + pageName);
            ReactGA.event('sign_up', {
                page_name: pageName
            });
        }
    },
    // trigger forgot password event
    trackForgotPassword : (pageName) => {
        if (localStorage.getItem("google-analytics-4-enabled")) {
            console.log("GA4 forgot password, page: " + pageName);
            ReactGA.event('forgot_password', {
                page_name: pageName
            });
        }
    },
};

export default ga4Service;
