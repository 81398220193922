const generalFunctionService = {
    capitalizeFirstLetter: (string) => {
        return string[0].toUpperCase() + string.slice(1);
    },
    truncate: (string, length) => {
        if (string.length > length) return string.substring(0, length) + "...";
        else return string;
    },
    isObject: (obj) => {
        return (
            obj === Object(obj) &&
            Object.prototype.toString.call(obj) !== "[object Array]"
        ); //Exclude array
    },
    getCurrency: (code) => {
        if (code === "myr") {
            return "RM";
        } else if (code === "usd") {
            return "$";
        }
    },
    /**
     * @function convertJsonToFormData
     *
     * This function is use to convert a javascript object(JSON) into a formData.
     * This function is created mainly because sending data to backend using content/type: application/json
     * does not cater the needs to sent attachment or image file to the backend API. Hence, this function is
     * use convert JSON into formdata to cater the needs.
     * SUPPORTED CONVERSION include :
     * string, array, array[file], object, int, float
     *
     * NOT SUPPORTED CONVERSION:
     * array[Object]
     *
     * NOTE:
     * Conversion of array will be done as follow:
     * Javascript Object
     * {
     *  myArray: [
     *   "string1",
     *   "string2",
     *   "string3"
     *  ]
     * }
     * After Conversion to FormData:
     * {
     *  "myArray": "string1",
     *  "myArray": "string2",
     *  "myArray": "string3",
     * }
     *
     * @param {Object} data     Javascript object which will be convert into formdata.
     *
     * @return {FormData}       Return Javascript object in a form of FormData.
     */
    convertJsonToFormData: (data) => {
        const formData = new FormData();
        const entries = Object.entries(data); // returns array of object property as [key, value]

        const isFile = (input) => {
            if ("File" in window && input instanceof File) return true;
            else return false;
        };

        const isBlob = (input) => {
            if ("Blob" in window && input instanceof Blob) return true;
            else return false;
        };
        for (let i = 0; i < entries.length; i++) {
            // don't try to be smart by replacing it with entries.each, it has drawbacks
            const arKey = entries[i][0];
            let arVal = entries[i][1];
            if (Array.isArray(arVal)) {
                if (isFile(arVal[0]) || isBlob(arVal[0])) {
                    for (let z = 0; z < arVal.length; z++) {
                        formData.append(`${arKey}[]`, arVal[z]);
                    }

                    continue; // we don't need to append current element now, as its elements already appended
                } else if (arVal[0] instanceof Object) {
                    for (let j = 0; j < arVal.length; j++) {
                        if (arVal[j] instanceof Object) {
                            // if first element is not file, we know its not files array
                            for (const prop in arVal[j]) {
                                if (
                                    Object.prototype.hasOwnProperty.call(
                                        arVal[j],
                                        prop
                                    )
                                ) {
                                    // do stuff
                                    if (!isNaN(Date.parse(arVal[j][prop]))) {
                                        formData.append(
                                            `${arKey}[${j}][${prop}]`,
                                            new Date(arVal[j][prop])
                                        );
                                    } else {
                                        formData.append(
                                            `${arKey}[${j}][${prop}]`,
                                            arVal[j][prop]
                                        );
                                    }
                                }
                            }
                        }
                    }
                    continue; // we don't need to append current element now, as its elements already appended
                } else {
                    for (let z = 0; z < arVal.length; z++) {
                        formData.append(`${arKey}`, arVal[z]);
                    }
                    continue;
                }
            }

            formData.append(arKey, arVal);
        }
        return formData;
    },
    planType: (type) => {
        if (type === "usage_based") {
            return "Usage Based";
        } else if (type === "fixed_quantity") {
            return "Fixed Quantity";
        }
    },
    dataRestructure: (data) => {
        let res = { ...data };
        const currency = generalFunctionService.getCurrency(data.currency);
        if (data.plan_type === "usage_based") {
            res.plan_type_ = "Usage Based";
        } else if (data.plan_type === "fixed_quantity") {
            res.plan_type_ = "Fixed Quantity";
        }

        res.plan_status = generalFunctionService.capitalizeFirstLetter(
            data.plan_status
        );

        res.delivery_frequency = generalFunctionService.capitalizeFirstLetter(
            data.delivery_frequency
        );

        let base, min, max;
        if (data.pricing_model === "per_unit") {
            //is decimal
            if (data.charge_by_percentage) {
                res.per_unit_amt.base_ = data.per_unit_amt.base + "%";
            } else {
                res.per_unit_amt.base_ = currency + data.per_unit_amt.base;
            }

            base = data.per_unit_amt.base_ + " per " + data.per_unit_amt.unit;

            if (data.per_unit_amt.min_amt !== null) {
                min = " Minimum - " + currency + data.per_unit_amt.min_amt;
            }
            if (data.per_unit_amt.max_amt !== null) {
                max = " Maximum - " + currency + data.per_unit_amt.max_amt;
            }
            res.bill_amt = {
                base: base,
                min: min,
                max: max,
                desc: data.per_unit_amt.description,
            };
        } else if (data.pricing_model === "flat_fee") {
            base = currency + data.flat_fee_amt.base;

            if (data.flat_fee_amt.min_amt !== null) {
                min = " Minimum - " + currency + data.flat_fee_amt.min_amt;
            }
            if (data.flat_fee_amt.max_amt !== null) {
                max = " Maximum - " + currency + data.flat_fee_amt.max_amt;
            }
            res.bill_amt = {
                base: base,
                min: min,
                max: max,
                desc: data.per_unit_amt.description,
            };
        } else {
            res.bill_amt = [...data.tiered_model.tier];
            if (data.charge_by_percentage) {
                for (let i = 0; i < res.bill_amt.length; i++) {
                    res.bill_amt[i].price_per_unit_ =
                        data.tiered_model.tier[i].price_per_unit * 100 + "%";
                    // res.bill_amt[i].price_per_unit = (res.bill_amt[i].price_per_unit*100).toString() + "%"
                }
            } else {
                for (let i = 0; i < res.bill_amt.length; i++) {
                    res.bill_amt[i].price_per_unit_ =
                        currency + data.tiered_model.tier[i].price_per_unit;
                    // res.bill_amt[i].price_per_unit = (res.bill_amt[i].price_per_unit*100).toString() + "%"
                }
            }
        }

        if (data.min_fee !== null) {
            res.min_fee = currency + data.min_fee;
        }

        if (data.pricing_model === "per_unit") {
            res.pricing_model_ = "Fixed price per unit quantity";
        } else if (data.pricing_model === "flat_fee") {
            res.pricing_model_ = "Flat fee";
        } else if (data.pricing_model === "tiered") {
            res.pricing_model_ = "Tiered pricing model";
        } else if (data.pricing_model === "volume") {
            res.pricing_model_ = "Volume pricing model";
        } else if (data.pricing_model === "stairstep") {
            res.pricing_model_ = "Stair-step pricing model";
        }

        if (data.setup_fee !== null) {
            res.setup_fee = currency + data.setup_fee;
        }

        if (data.tax !== null) {
            res.tax = data.tax.tax_rate * 100 + "% " + data.tax.tax_name;
        }

        res.addons = data.addons.slice();
        for (let i = 0; i < data.addons.length; i++) {
            if (data.addons[i].pricing_model === "per_unit") {
                res.addons[i].pricing_model_ = "Per Unit";
            } else if (data.addons[i].pricing_model === "flat_fee") {
                res.addons[i].pricing_model_ = "Flat fee";
            } else if (data.addons[i].pricing_model === "tiered") {
                res.addons[i].pricing_model_ = "Tiered pricing model";
            } else if (data.addons[i].pricing_model === "volume") {
                res.addons[i].pricing_model_ = "Volume pricing model";
            } else if (data.addons[i].pricing_model === "stairstep") {
                res.addons[i].pricing_model_ = "Stair-step pricing model";
            }

            if (data.addons[i].charge_by_percentage) {
                res.addons[i].addon_price_ = data.addons[i].addon_price + "%";
            } else {
                res.addons[i].addon_price_ =
                    currency + data.addons[i].addon_price;
            }

            if (data.addons[i].addon_recurrence === "one_off") {
                res.addons[i].addon_recurrence_ = "One Off";
            } else {
                res.addons[i].addon_recurrence_ = "Recurring";
            }

            if (data.addons[i].addon_type === "usage_based") {
                res.addons[i].addon_type_ = "Usage Based";
            } else if (data.addons[i].addon_type === "fixed_quantity") {
                res.addons[i].addon_type_ = "Fixed Quantity";
            }

            if (data.addons[i].pricing_model === "tiered") {
                if (data.addons[i].charge_by_percentage) {
                    for (
                        let j = 0;
                        i < data.addons[i].tiered_model.tier.length;
                        j++
                    ) {
                        res.addons[i].tiered_model.tier[j].price_per_unit_ =
                            data.addons[i].tiered_model.tier[j].price_per_unit *
                                100 +
                            "%";
                        // res.bill_amt[i].price_per_unit = (res.bill_amt[i].price_per_unit*100).toString() + "%"
                    }
                } else {
                    for (
                        let j = 0;
                        j < data.addons[i].tiered_model.tier.length;
                        j++
                    ) {
                        res.addons[i].tiered_model.tier[j].price_per_unit_ =
                            currency +
                            data.addons[i].tiered_model.tier[j].price_per_unit;
                        // res.bill_amt[i].price_per_unit = (res.bill_amt[i].price_per_unit*100).toString() + "%"
                    }
                }
            }
        }
        return res;
    },
};

export default generalFunctionService;
