import { callAPI } from "../../utils/apiUtils";
import axios from "axios";

const invoiceService = {
    getInvoiceBySubsId: (subId) => {
        return new Promise((resolve, reject) => {
            callAPI(`/api/invoices/?inv_sub_id=${subId}`, "get")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    customerGetAllInvoice: () => {
        return new Promise((resolve, reject) => {
            callAPI(`/api/invoices/?inv_voided=False`, "get")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getInvoiceByInvId: (invId) => {
        return new Promise((resolve, reject) => {
            callAPI(`/api/invoices/${invId}`, "get")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    createPaymentForInvoice: (dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/api/payments/`, "post", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    createDraftInvoice: (dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/api/invoices/`, "post", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
};

export default invoiceService;
