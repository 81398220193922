import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { React, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from "react-draft-wysiwyg";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import ImageCropper from "../../../../Components/ImageCropper";
import LoginBar from "../../../../Components/LoginBar/loginbar";
import addOnService from "../../../../services/addOnService";
import { readImageFileIntoDataUrl } from "../../../../utils/imageUtils";
import { store } from "../../../../index.js";
import ClearIcon from "@mui/icons-material/Clear";
import "./style.css";

const AddonEditCreate = ({ history, match, readonly = false, cleanBackground = false }) => {
    const [itemName, setItemName] = useState();
    const [itemPrice, setItemPrice] = useState();
    const [previewImg, setPreviewImg] = useState();
    const [imageCropModel, setImageCropModel] = useState({
        isOpened: false,
        selectedImageDataUrl: null,
        crop: { x: 0, y: 0 },
        rotation: 0,
        zoom: 0.4,
        croppedAreaPixels: null,
        croppedImage: null,
    });
    const html = "<p></p>";
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [itemDescription, setItemDescription] = useState(
        EditorState.createWithContent(contentState)
    );

    useEffect(() => {
        if (!!match.params.id) {
            addOnService.getSingleAddon(match.params.id).then((response) => {
                const descArr = [];
                response.addons.addon_desc.map((textBlock) => {
                    descArr.push(`<p>${textBlock}</p>`);
                });
                const html = descArr.join("");
                const contentBlock = htmlToDraft(html);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                if (response.addons.addon_image !== null) {
                    const imagePreview = `${
                        window.location.protocol + "//" + window.location.hostname
                    }${process.env.REACT_APP_SITE_PORT_BACKEND}${response.addons.addon_image}`;
                    setPreviewImg({ imagePreview: imagePreview });
                }
                setItemName(response.addons.addon_name);
                setItemDescription(EditorState.createWithContent(contentState));
                setItemPrice(response.addons.addon_price);
            });
        }
    }, []);

    const createAddon = () => {
        var restructureAddonDesc = convertToRaw(itemDescription.getCurrentContent()).blocks.map(
            (block) => {
                return block.text;
            }
        );
        var dataBody = {
            addon_name: itemName,
            addon_desc: restructureAddonDesc,
            addon_price: itemPrice,
        };
        if (previewImg !== undefined) {
            dataBody = {
                ...dataBody,
                addon_image: previewImg.imageFile,
            };
        }
        addOnService
            .createAddon(dataBody)
            .then((e) => {
                Swal.fire({
                    title: "Success",
                    text: "Item created",
                    icon: "success",
                    confirmButtonColor: store.getState().colorState.color,
                }).then((result) => {
                    history.push("/admin/addon");
                });
            })
            .catch((e) => {
                console.error(e, "error");
            });
    };

    const editAddon = () => {
        var restructureAddonDesc = convertToRaw(itemDescription.getCurrentContent()).blocks.map(
            (block) => {
                return block.text;
            }
        );
        var dataBody = {
            addon_name: itemName,
            addon_desc: restructureAddonDesc,
            addon_price: itemPrice,
        };
        if (previewImg?.imageFile !== undefined) {
            dataBody = {
                ...dataBody,
                addon_image: previewImg.imageFile,
            };
        }

        addOnService
            .updateAddon(match.params.id, dataBody)
            .then((e) => {
                Swal.fire({
                    title: "Success",
                    text: "Item updated",
                    confirmButtonColor: store.getState().colorState.color,
                    icon: "success",
                }).then((result) => {
                    history.push("/admin/addon");
                });
            })
            .catch((e) => {
                console.error(e, "error");
            });
    };
    return (
        <>
            <Grid container>
                <Grid item className="p-3" xs={12} md={12} lg={12}>
                    <Grid className="mobile-view" container>
                        {window.location.href.includes("plan") && (
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container className="mt-4 mb-2">
                                    {/* profile icon and username dropdown */}
                                    <Grid align="left" item xs={6} md={6} lg={6}>
                                        <h4
                                            className="text-left color-contrast-dark ml-4"
                                            style={{
                                                color: "black",
                                                marginLeft: "10px",
                                            }}
                                        >
                                            {match.params.id !== "new" ? (
                                                <strong>Edit item</strong>
                                            ) : (
                                                <strong>Create item</strong>
                                            )}
                                        </h4>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6} align="right">
                                        <LoginBar />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12} md={12} lg={12}>
                            <div className={" pt-2 container-fluid addon-container"}>
                                <div className={"addon-form-container"}>
                                    <Grid align="left" style={{ marginLeft: "20px" }} container>
                                        <Grid item className="mt-4" xs={12} lg={12} md={12}>
                                            <h4
                                                className="color-contrast-dark "
                                                style={{ color: "black" }}
                                            >
                                                <strong>Item details</strong>
                                            </h4>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Form.Group className="mb-3 w-50">
                                                <Form.Label>Item Name</Form.Label>
                                                <Form.Control
                                                    value={itemName}
                                                    onChange={(e) => {
                                                        setItemName(e.target.value);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Form.Group className="mb-3 w-50">
                                                <Form.Label>Item Description</Form.Label>
                                                <Editor
                                                    toolbarHidden
                                                    editorClassName="editor-class"
                                                    onEditorStateChange={(e) => {
                                                        setItemDescription(e);
                                                    }}
                                                    editorState={itemDescription}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Form.Group className="mb-4 w-50">
                                                {" "}
                                                <Form.Label>Item Pricing</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    value={itemPrice}
                                                    onChange={(e) => {
                                                        setItemPrice(e.target.value);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Grid>
                                        <Grid item className="mb-4" xs={12} md={12} lg={12}>
                                            <Button
                                                startIcon={<PhotoLibraryIcon />}
                                                style={{
                                                    backgroundColor:
                                                        store.getState().colorState.color,
                                                    color: store.getState().colorState.font_color,
                                                }}
                                                variant="contained"
                                                component="label"
                                            >
                                                Upload Images
                                                <input
                                                    accept="image/*"
                                                    type="file"
                                                    onChange={async (e) => {
                                                        if (
                                                            !!e.target.files &&
                                                            e.target.files.length > 0
                                                        ) {
                                                            const file = e.target.files[0];

                                                            let imageDataUrl =
                                                                await readImageFileIntoDataUrl(
                                                                    file
                                                                );

                                                            //after an image file is selected open the cropping model
                                                            setImageCropModel({
                                                                ...imageCropModel,
                                                                isOpened: true,
                                                                selectedImageDataUrl: imageDataUrl,
                                                            });
                                                        }
                                                    }}
                                                    onClick={(event) => (event.target.value = null)}
                                                    hidden
                                                />
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Form.Label>Image Preview</Form.Label>
                                        </Grid>
                                        <Grid
                                            className="mb-5 center-image-preview-container"
                                            item
                                            xs={9}
                                            md={3}
                                            lg={3}
                                        >
                                            {previewImg !== undefined ? (
                                                <Image
                                                    className="center-image-preview"
                                                    src={previewImg.imagePreview}
                                                />
                                            ) : (
                                                <PhotoLibraryIcon
                                                    style={{
                                                        transform: "scale(4)",
                                                        position: "absolute",
                                                        top: "40%",
                                                        left: "45%",
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>
                                <ToastContainer />
                            </div>
                            {/* create create button and cancel button */}
                            <Grid container className="mt-4" align="right" xs={12} md={12} lg={12}>
                                <Grid item xs={6} md={6} lg={6}>
                                    <Button
                                        variant="contained"
                                        className="mr-3 "
                                        onClick={() => {
                                            history.push("/admin/addon");
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>

                                <Grid item align="left" xs={6} md={6} lg={6}>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: store.getState().colorState.color,
                                            borderColor: store.getState().colorState.color,
                                            color: store.getState().colorState.font_color,
                                            marginLeft: "10px",
                                        }}
                                        className="mr-3 "
                                        onClick={() => {
                                            if (match.params.id !== "new") {
                                                editAddon();
                                            } else {
                                                createAddon();
                                            }
                                        }}
                                    >
                                        {match.params.id !== "new" ? "Edit" : "Create"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <ImageCropper
                            aspect={1}
                            imgCrop={imageCropModel}
                            handleImgCrop={(imgData) => {
                                setImageCropModel(imgData);
                            }}
                            imgPreview={previewImg}
                            handleImgPreview={(img) => {
                                setPreviewImg({
                                    imageFile: img.imageFile,
                                    imagePreview: img.imagePreview,
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default AddonEditCreate;
