import { Edit } from "@material-ui/icons";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useLayoutEffect, useState } from "react";
import "./EditOrderInformation.css";
import { useSelector } from "react-redux";
import EditOrderItem from "./EditOrderItem/EditOrderItem";
import EditOrderDate from "./EditOrderDate/EditOrderDate";
import { store } from "../../../../../index.js";
import planService from "../../../../../services/planService";
const EditOrderInformation = ({
    editDetails,
    handleEditDetails,
    subsDetails,
    handleSubsDetails,
}) => {
    const [detailsTab, setDetailsTab] = useState("product");
    const [planInfo, setPlanInfo] = useState();
    const isLoggedIn = useSelector((state) => state.loggedInUserReducer.userData);

    useLayoutEffect(() => {
        planService
            .getSinglePlan(subsDetails.plan_id)
            .then((success) => {
                setPlanInfo(success.plans);
            })
            .catch((err) => console.error(err));
    }, []);
    return (
        subsDetails && (
            <div
                className="d-flex flex-column text-left pl-lg-5 pt-3 pr-lg-5 pb-5"
                style={{ padding: "0 1em" }}
            >
                <h2>
                    <strong>
                        Edit {`${isLoggedIn?.userRole === "merchant" ? "Order " : "Purchase "}`}
                        Details
                    </strong>
                </h2>
                <Button
                    onClick={() => handleEditDetails()}
                    style={{
                        width: "fit-content",
                        backgroundColor: store.getState().colorState.color,
                        borderColor: store.getState().colorState.color,
                        color: store.getState().colorState.font_color,
                    }}
                >
                    <ArrowBackIcon />
                    Back to {`${isLoggedIn.userRole === "customer" ? "Purchase" : "Order"}`} Details
                </Button>
                <Container
                    className="mt-3 pt-3 pl-4 pr-4 pb-3 mb-5 pl-4 "
                    style={{
                        backgroundColor: "white",
                        borderRadius: "10px",
                        boxShadow: "4px 4px #E1E1E1",
                        maxWidth: "none",
                    }}
                >
                    {/* Product button and delivery date & time button */}
                    <Row>
                        <Col className="d-flex flex-row pl-0">
                            <Button
                                onClick={() => setDetailsTab("product")}
                                className={`${
                                    detailsTab !== "product" && "tab-not-clicked"
                                } tab-detail ml-0`}
                                style={{
                                    backgroundColor: store.getState().colorState.color,
                                    borderColor: store.getState().colorState.color,
                                    color: store.getState().colorState.font_color,
                                }}
                            >
                                Product
                            </Button>
                            <Button
                                onClick={() => setDetailsTab("delivery")}
                                className={`${
                                    detailsTab !== "delivery" && "tab-not-clicked"
                                } tab-detail ml-0`}
                                style={{
                                    backgroundColor: store.getState().colorState.color,
                                    borderColor: store.getState().colorState.color,
                                    color: store.getState().colorState.font_color,
                                }}
                            >
                                Delivery Details
                            </Button>
                        </Col>
                        <hr className="bg-black mt-3" />
                        {detailsTab === "product" && (
                            <EditOrderItem
                                subsDetails={subsDetails}
                                handleEditDetails={() => handleEditDetails()}
                                handleSubsDetails={handleSubsDetails}
                            />
                        )}
                        {detailsTab === "delivery" && (
                            <EditOrderDate
                                planDetails={planInfo}
                                subsDetails={subsDetails}
                                handleEditDetails={() => handleEditDetails()}
                            />
                        )}
                    </Row>
                </Container>
            </div>
        )
    );
};

export default EditOrderInformation;
