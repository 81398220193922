import {ListItem,ListItemAvatar,ListItemText,List} from "@material-ui/core";
import Avatar from '@mui/material/Avatar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import EmailIcon from "@mui/icons-material/Email";
import ExploreIcon from "@mui/icons-material/Explore";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Button, Card, Figure, ListGroupItem } from "react-bootstrap";
import Moment from "react-moment";
import { urlAction } from "../../../action";
import LoginBar from "../../../Components/LoginBar/loginbar";
import { store } from "../../../index.js";
import customerService from "../../../services/customerService";
import "./styles.css";

const Profile = ({ history, match}) => {
    const [customer, setCustomer] = useState({});
    const [paymentMethod, setPaymentMethod] = useState("Not available");
    const [mandateRef, setMandateRef] = useState("");
    const [selectedBank, setSelectedBank] = useState("")
    const merchantName = urlAction("get_merchant");
    const [custMandateRef, setCustMandateRef] = useState("");

    useEffect(() => {
        customerService
            .getCustomerFromAR(
                store.getState().loggedInUserReducer.userData?.ar_system
                    ?.cust_id
            )
            .then((res) => {
		console.log(res[0], 'this is the customer detail');
                setCustomer(res[0]);
		const customerMandateType = res[0].cust_mandate_type
		if (customerMandateType == 1){
		    setPaymentMethod("FPX (Online Banking)");
		} else if (customerMandateType == 2){
		    setPaymentMethod("Debit/Credit Card");
		} else {
		    setPaymentMethod("Not available");
		}
                const customerDetails = res[0];
                setCustMandateRef(res[0].cust_mandate_ref);
            })
    }, []);
    return (
        <Container className="pb-5">
            <Row>
                <Col className="col-banner">
                    <Card className="banner"></Card>
                </Col>
            </Row>
            <Row className="mt-2">
            <Col align="left">
                <Row className="row-detail">
                    <Col className="col-profile">
                        <Avatar className="avatar-profile">
                            {customer?.cust_fname !== undefined && customer?.cust_fname !== null
                                ? customer?.cust_fname
                                    .charAt(0)
                                    .toUpperCase() +
                                  customer?.cust_lname 
                                    .charAt(0)
                                    .toUpperCase()
                                : ""
                            }
                        </Avatar>
                    </Col>
                    <Col className="col-details" md={12} lg={9} sm={12}>        
                        <Typography className="details-name">
                            {customer?.cust_fname !== undefined && customer?.cust_fname !== null
                                ? customer.cust_fname
                                    .charAt(0)
                                    .toUpperCase() +
                                customer.cust_fname.slice(1) + 
                                " " + 
                                customer.cust_lname
                                    .charAt(0)
                                    .toUpperCase() + 
                                customer.cust_lname.slice(1)
                                : ""
                            }
                        </Typography>
                        <Typography className="details-date">
                            Joined{" "}
                            <Moment format="DD/MM/YYYY">
                            {customer.date_joined}
                            </Moment>
                        </Typography>
                    </Col>
                </Row>
            </Col>
            <Col align="right">
                <Button
                    onClick={() => {
                        localStorage.setItem(
                            "sidebar-state","settings"
                        );
                            
                        history.push(
                            "/customer/settings/"
                        )
                    }}
                >
                Edit Profile
                </Button>
            </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="card-col-spacing">
                        <Card.Body>
                            <Card.Title align="left" className="ml-3">Customer Details</Card.Title>
                            <ListItem dense={true}>
                                <ListItemAvatar>
                                    <Avatar className="avatar-icon">
                                        <PermIdentityIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="ID"
                                    secondary={customer.cust_id}
                                />
                            </ListItem>
                            <ListItem dense={true}>
                                <ListItemAvatar>
                                    <Avatar className="avatar-icon">
                                        <PhoneIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Contact Number"
                                    secondary={customer.cust_contact_no}
                                />
                            </ListItem>
                            <ListItem dense={true}>
                                <ListItemAvatar>
                                    <Avatar className="avatar-icon">
                                        <EmailIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Email"
                                    secondary={customer.cust_email}
                                />
                            </ListItem>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className="card-col-spacing">
                    <Card.Body>
                            <Card.Title align="left" className="ml-3">Address</Card.Title>
                            <ListItem dense={true}>
                                <ListItemAvatar>
                                    <Avatar className="avatar-icon">
                                        <LocationOnIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Address"
                                    // secondary={customer.cust_addr_line_1}
                                    secondary={
                                        customer.cust_addr_line_1 == 
                                        ""
                                            ? "N/A"
                                            : customer.cust_addr_line_1
                                    }
                                />
                            </ListItem>
                            <ListItem dense={true}>
                                <ListItemAvatar>
                                    <Avatar className="avatar-icon">
                                        <ExploreIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Postcode"
                                    // secondary={customer.cust_addr_postcode}
                                    secondary={
                                        customer.cust_addr_postcode == 
                                        ""
                                            ? "N/A"
                                            : customer.cust_addr_postcode
                                    }
                                />
                            </ListItem>
                            <ListItem dense={true}>
                                <ListItemAvatar>
                                    <Avatar className="avatar-icon">
                                        <ApartmentIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Delivery Remarks"
                                    // secondary={customer.cust_delivery_instructions}
                                    secondary={
                                        customer.cust_delivery_instructions == 
                                        ""
                                            ? "N/A"
                                            : customer.cust_delivery_instructions
                                    }
                                />
                            </ListItem>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-3 mb-3">
                <Col>
                    <Card>
                        <Card.Body>
                            <ListItem dense={true}>
                                <ListItemAvatar>
                                    <Avatar className="avatar-icon">
                                        <AttachMoneyIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Payment Method"
                                    secondary={paymentMethod}
                                />
                            </ListItem>
                            <ListItem dense={true}>
                                <ListItemAvatar>
                                    <Avatar className="avatar-icon">
                                        <AccountBalanceIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="Bank"
                                    secondary={
                                        customer.cust_bank == 
                                        ""
                                            ? "N/A"
                                            : customer.cust_bank
                                    }
                                />
                            </ListItem>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Profile;
