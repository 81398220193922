const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
}

function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    return croppedImage;
}

/**
 * @function getCroppedImgInFileObj
 *
 * This function is used for converting a File into a File Object that is capable to sent to backend API
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 *
 * @see createImage,getRadianAngle,dataURLtoFile
 *
 * @param {File} imageDataUrl -  Image File Data url (REQUIRED)
 * @param {Object} [pixelCrop] - pixelCrop Object provided by react-easy-crop (OPTIONAL)
 * @param {number} [rotation] - optional rotation parameter (OPTIONAL)
 *
 * @return {File}       Return Image in a form of File
 */
const getCroppedImgInFileObj = async (
    imageSrc,
    pixelCrop = null,
    rotation = 0
) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    if (pixelCrop == null) {
        // if crop options is not passed in
        // Generate image without crop
        pixelCrop = {
            width: image.width,
            height: image.height,
            x: 0,
            y: 0,
        };
    }

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    const reader = new FileReader();
    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                resolve(dataURLtoFile(reader.result, "cropped.png"));
            };
            // resolve(URL.createObjectURL(file));
        }, "image/png");
    });
};

/**
 * @function readImageFileIntoDataUrl
 *
 * This function is used for converting a Image File Object into DATAUrL
 * A dataUrl is an string that represent the image data. DataUrl can be directly apply to
 * an image src to display the image.
 * EXAMPLE:
 * <img src="${Dataurl}"/>
 *
 * @param {File} imageFile -  Image File (REQUIRED)
 *
 * @return {File}       Return dataUrl(String) that represent the image file
 */
export const readImageFileIntoDataUrl = (file) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
};

export default getCroppedImgInFileObj;
