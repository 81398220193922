import "./style.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "../../../Components/Sidebar/sidebar";
import { CardActionArea, FormControl } from "@material-ui/core";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { TextField } from "@material-ui/core";
import userService from "../../../services/userService";
import { MenuItem } from "@material-ui/core";
import LoginBar from "../../../Components/LoginBar/loginbar";
import { Modal, InputGroup, Form, ButtonToolbar, ToggleButton } from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "@material-ui/core/Button";
import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { urlAction } from "../../../action";
import FpxLogo from "../../../Images/fpx-logo.png";
import cardIcon from "../../../Images/visa-master-logo.png";
import Image from "react-bootstrap/Image";
import customerService from "../../../services/customerService";
import { store } from "../../../index.js";
import profileService from "../../../services/profileService";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, ToggleButtonGroup } from "@mui/material";
import { Link } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import PaymentDetails from "./PaymentDetails";
import merchantService from "../../../services/merchantService";
import subscriptionService from "../../../services/subscriptionService";

const Settings = ({ history, match, mandateRef, custId }) => {
    const [settingsTab, setSettingsTab] = useState("account");
    const forgotPasswordLink =
        String(window.location.origin).split("/")[0] + "/backend/api/password_reset/";
    const [customerData, setCustomerData] = useState("");
    const [customerFName, setCustomerFName] = useState("");
    const [customerLName, setCustomerLName] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [customerCurrentPassword, setCustomerCurrentPassword] = useState("");
    const [customerNewPassword, setCustomerNewPassword] = useState("");
    const [customerConfirmNewPassword, setCustomerConfirmNewPassword] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");
    const [customerAddress, setCustomerAddress] = useState("");
    const [customerState, setCustomerState] = useState("");
    const [customerDeliveryRemarks, setCustomerDeliveryRemarks] = useState("");
    const [customerPostcode, setCustomerPostcode] = useState({value:""});
    const [editAccountInfo, setEditAccountInfo] = useState(false);
    const [editDeliveryInfo, setEditDeliveryInfo] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [countryData, setCountryData] = useState({ value: [] });
    const [vajraCountry, setVajraCountry] = useState();
    const [vajraLanguage, setVajraLanguage] = useState();
    const [vajraKecharaMembership, setVajraKecharaMembership] = useState("");
    const [custMandateRef, setCustMandateRef] = useState("");
    const [TabState, setTabState] = useState("account");
    const [buttonCheck1, setButtonCheck1] = useState(false);
    const [buttonCheck2, setButtonCheck2] = useState(false);
    const merchantName = urlAction("get_merchant");
    const [paymentMethod, setPaymentMethod] = useState();
    const [selectedBank, setSelectedBank] = useState("");
    const [bankList, setBankList] = useState([]);
    const [paymentMethodExist, setPaymentMethodExist] = useState(false);
    const [curlecMerchantId, setCurlecMerchantId] = useState("");
    const [curlecEmployeeId, setCurlecEmployeeId] = useState("");
    const [showModal, setshowModal] = useState(false);
    const [mandateStatus, setMandateStatus] = useState(false);
    const [buttonEdit, setbuttonEdit] = useState(false);
    const [maxMandateFPX, setMaxMandateFPX] = useState("");
    const [maxMandateCC, setMaxMandateCC] = useState("");

    const handleButtonCheck1 = () => {
        setButtonCheck1(true);
        setButtonCheck2(false);
        setPaymentMethod(1);
    };
    const handleButtonCheck2 = () => {
        setButtonCheck1(false);
        setButtonCheck2(true);
        setPaymentMethod(2);
    };

    const handleCancel = () => {
        if (editAccountInfo){
	    setEditAccountInfo(!editAccountInfo);
	}
	if (editDeliveryInfo) {
	   setEditDeliveryInfo(!editDeliveryInfo);
	}
        setCustomerFName({ value: customerData.cust_fname });
        setCustomerLName({ value: customerData.cust_lname });
        setCustomerEmail({ value: customerData.cust_email });
        
	setCustomerPhone({ value: customerData.cust_contact_no });
        setCustomerAddress({ value: customerData.cust_addr_line_1 });
        setCustomerState({ value: customerData.cust_addr_state });
        setCustomerPostcode({ value: customerData.cust_addr_postcode });
        setCustomerDeliveryRemarks({
            value: customerData.cust_delivery_instructions,
        });
    };

    const handleTabState = (state) => {
        setTabState(state);
        handleCancel();
    };

    useEffect(() => {
         profileService
            .getProfile()
            .then((result) => {
                console.log(result, "this is the merchant settings");
                setMaxMandateFPX(result.settings.max_mandate_fpx);
                setMaxMandateCC(result.settings.max_mandate_cc);
            })
            .catch((err) => console.error(err));
	getCustomerData();
    }, []);

    useEffect(() => {
        fetch("https://restcountries.com/v3.1/all")
            .then((res) => {
                res.json().then((data) => {
                    const sortCountryName = data.sort((a, b) =>
                        a.name.official > b.name.official ? 1 : -1
                    );

                    const countryInfo = sortCountryName.map((country) => {
                        return [country.name.official, country.flags.png];
                    });
                    setCountryData({ value: countryInfo });
                });
            })
            .catch((data, status) => {
                console.error("Request failed:", data, status);
            });
    }, []);

    useEffect(() => {
        merchantService.getMerchantInfo().then((e) => {
            console.log(e, "this is the merchant info");
            setCurlecMerchantId(e[0].merch_curlec_merchant_id);
            setCurlecEmployeeId(e[0].merch_curlec_employee_id);
        });

        subscriptionService
            .getBanksForSubscription({ method: "00" })
            .then((res) => {
                var result = res.replace(/'/g, '"');
                var banks = JSON.parse(result);
                setBankList(banks);
            })
            .catch((err) => {
                console.error(err);
                // setPaymentMethodExist(true);
                Swal.fire({
                    title: "Error",
                    text: "Could not retrieve FPX bank list",
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: store.getState().colorState.color,
                });
            });
    }, []);

    const getCustomerData = async () =>
        await customerService
            .getSpecificCustomerFromAR(
                store.getState().loggedInUserReducer.userData.ar_system.cust_id
            )
            .then((res) => {
                setCustomerData(res);
                setCustomerFName({ value: res.cust_fname });
                setCustomerLName({ value: res.cust_lname });
                setCustomerEmail({ value: res.cust_email });
                setCustomerPhone({ value: res.cust_contact_no });
                setCustomerAddress({ value: res.cust_addr_line_1 });
                setCustomerState({ value: res.cust_addr_state });
                setCustomerPostcode({ value: res.cust_addr_postcode !== null ? res.cust_addr_postcode : "" });
                setMandateStatus(res.cust_mandate_status);
                setVajraKecharaMembership({
                    value: res.vajra_kechara_membership,
                });

                setVajraLanguage({ value: res.vajra_language });
                setVajraCountry({ value: res.vajra_country });
                setCustomerDeliveryRemarks({
                    value: res.cust_delivery_instructions,
                });
                setCustMandateRef({ value: res.cust_mandate_ref });
                if (res.cust_mandate_ref !== null) {
                    var detailsBody = {
                        cust_mandate_ref: res.cust_mandate_ref,
                    };
                    customerService
                        .getPaymentDetails(detailsBody)
                        .then((res) => {
                            var bankId = res;
                            setSelectedBank(bankId);
                            setPaymentMethodExist(true);
                        })
                        .catch((err) => {
                            console.error(err, "this is the error");
                        });
                }
            });

    const validateCustEmail = (email) => {
        var isValid = true;
        var errorMessage = "";
        var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (email == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Email cannot be empty";
            console.log(errorMessage);
            isValid = false;
        } else if (!re.test(String(email).toLowerCase())) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Error.Invalid email format";
            console.log(errorMessage);
            isValid = false;
        }
        setCustomerEmail({
            ...customerEmail,
            valid: isValid,
            error: errorMessage,
        });
        return isValid;
    };

    const validateCustFName = (fname) => {
        var isValid = true;
        var errorMessage = "";

        if (fname == "") {
            if (!!errorMessage) errorMessage = "\n";
            errorMessage += "First Name cannot be empty";
            isValid = false;
        }
        setCustomerFName({
            ...customerFName,
            valid: isValid,
            error: errorMessage,
        });
        return isValid;
    };

    const validateCustLName = (lname) => {
        var isValid = true;
        var errorMessage = "";

        if (lname == "") {
            if (!!errorMessage) errorMessage = "\n";
            errorMessage += "Last Name cannot be empty";
            isValid = false;
        }
        setCustomerLName({
            ...customerLName,
            valid: isValid,
            error: errorMessage,
        });
        return isValid;
    };

    const validateCustPhone = (phone) => {
        var isValid = true;
        var errorMessage = "";

        if (phone == "") {
            if (!!errorMessage) errorMessage = "\n";
            errorMessage += "Phone Number cannot be empty";
            isValid = false;
        } else if (
            !/^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/.test(phone) &&
            !/^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/.test(phone)
        ) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Phone number has to start with 60 and can only have 10/11 numbers";
            isValid = false;
        }
        setCustomerPhone({
            ...customerPhone,
            valid: isValid,
            error: errorMessage,
        });
        return isValid;
    };

    const validateCustAddress = (address) => {
        var isValid = true;
        var errorMessage = "";

        //Check empty
        if (address == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Address cannot be empty";
            isValid = false;
        }
        setCustomerAddress({
            ...customerAddress,

            valid: isValid,
            error: errorMessage,
        }); //Set Error message
        return isValid;
    };

    const validateCustState = (state) => {
        var isValid = true;
        var errorMessage = "";

        if (state == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "State cannot be empty";
            isValid = false;
        }
        setCustomerState({
            ...customerState,
            valid: isValid,
            error: errorMessage,
        });
        return isValid;
    };

    const validateCustPostcode = (postcode) => {
        var isValid = true;
        var errorMessage = "";

        //Check empty
        if (postcode == "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Postcode cannot be empty";
            isValid = false;
        }
        // if postcode is neither 5 or 4 digit show error
        else if (postcode.length != 5 && postcode.length != 4) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Postcode consists of 4 to 5 digits";
            isValid = false;
        }
        setCustomerPostcode({
            ...customerPostcode,
            valid: isValid,
            error: errorMessage,
        }); //Set Error message
        return isValid;
    };

    const validateProfile = () => {
        var isValid = true;
        isValid = isValid && validateCustEmail(customerEmail.value);
        isValid = isValid && validateCustFName(customerFName.value);
        isValid = isValid && validateCustLName(customerLName.value);
        isValid = isValid && validateCustPhone(customerPhone.value);
        isValid = isValid && validateCustAddress(customerAddress.value);
        isValid = isValid && validateCustState(customerState.value);
        // isValid = isValid && validateCustPostcode(customerPostcode.value);
        return isValid;
        // validate email format
        // validate postcode format
        // validate phone number format
    };

    const editPassword = () => {
        console.log(customerNewPassword, customerConfirmNewPassword, customerCurrentPassword, 123);
        if (customerNewPassword.value !== customerConfirmNewPassword.value) {
            Swal.fire({
                icon: "error",
                title: "Error",
                confirmButtonColor: store.getState().colorState.color,
                text: "New passwords doesn't match",
            }).then((result) => {
                if (result.isConfirmed) {
                }
            });
        } else {
            var data = {
                username: customerEmail.value,
                oldPassword: customerCurrentPassword.value,
                newPassword: customerNewPassword.value,
            };
            customerService
                .resetPassword(data)
                .then((res) => {
                    console.log(res, "password change successfull");
                    Swal.fire({
                        icon: "success",
                        confirmButtonColor: store.getState().colorState.color,
                        title: "Password has been updated successfully.",
                        timer: 5000,
                    }).then(() => {
                        window.location.reload();
                    });
                })
                .catch((res) => {
                    console.log(res, "account doesnt exist");

                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        confirmButtonColor: store.getState().colorState.color,
                        text: "Current password entered is incorrect.",
                    }).then((result) => {
                        if (result.isConfirmed) {
                        }
                    });
                });
        }
    };

    const editProfile = () => {
        // check postcode if exist in databse
	if (editAccountInfo){
	   if (validateProfile()) {
                var data = {
                    cust_fname: customerFName.value,
                    cust_lname: customerLName.value,
                    cust_email: customerEmail.value,
                    cust_contact_no: customerPhone.value,
                    cust_addr_line_1: customerAddress.value,
                    cust_addr_state: customerState.value,
                    cust_addr_postcode: customerPostcode.value,
                    cust_delivery_instructions: customerDeliveryRemarks.value,
                    };

               customerService
                    .editCustomerFromAR(data, customerData.cust_id)
                     .then((res) => {
                                    // swal fire success and refresh page
                                    Swal.fire({
                                        title: "Success",
                                        text: "Profile updated",
                                        icon: "success",
                                        showCancelButton: false,
                                        confirmButtonColor: store.getState().colorState.color,
                                    confirmButtonText: "OK",
                                }).then((result) => {
                                    if (result.value) {
                                        // userService.logout().then((res) => {
                                        //     // history.push("/");
                                        //     window.location.reload();
                                        // });
                                        window.location.reload();
                                    }
                                });
                            })
                            .catch((err) => {
                                console.error(err, "err for edit profile");
                            });
	   }}
	if (editDeliveryInfo){
            if (validateCustPostcode(customerPostcode.value)) {
                profileService
                    .getPostcode(customerPostcode.value)
                    .then((res) => {
                        if (validateProfile()) {
                            var data = {
                            	cust_fname: customerFName.value,
                            	cust_lname: customerLName.value,
                            	cust_email: customerEmail.value,
                            	cust_contact_no: customerPhone.value,
                            	cust_addr_line_1: customerAddress.value,
                            	cust_addr_state: customerState.value,
                            	cust_addr_postcode: customerPostcode.value,
                            	cust_delivery_instructions: customerDeliveryRemarks.value,
                            };

                            customerService
                                .editCustomerFromAR(data, customerData.cust_id)
                                .then((res) => {
                                    // swal fire success and refresh page
                                    Swal.fire({
                                    	title: "Success",
                                    	text: "Profile updated",
                                    	icon: "success",
                                    	showCancelButton: false,
                                    	confirmButtonColor: store.getState().colorState.color,
                                    confirmButtonText: "OK",
                                }).then((result) => {
                                    if (result.value) {
                                        // userService.logout().then((res) => {
                                        //     // history.push("/");
                                        //     window.location.reload();
                                        // });
                                        window.location.reload();
                                    }
                                });
                            })
                            .catch((err) => {
                                console.error(err, "err for edit profile");
                            });
                    }
                    console.log(res, "res");
                })
                .catch((err) => {
                    console.error(err);
                    setCustomerPostcode({
                        ...customerPostcode,
                        valid: false,
                        error: "We do not deliver to this postcode",
                    });
                });
        } 
    };
    }

    return (
        <Container className="pb-5 mt-2">
            <h2 align="left">Settings</h2>
            {TabState === "account" && (
                <>
                    <Form>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => handleTabState("account")} active>
                                Account
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => handleTabState("payment")}>
                                Payment Details
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => handleTabState("resetPassword")}>
                                Reset Password
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <Card className="mt-3">
                            <Card.Body className="p-4">
                                <Row align="left" className="mt-3 col-align">
                                    <Col lg="3" xs="9" md="9" className="header-fit">
                                        <h2>Account Settings</h2>
                                    </Col>
                                    <Col lg="9" xs="3" md="3">
                                        {editAccountInfo === false && (
                                            <Button
                                                variant="contained"
                                                onClick={() => setEditAccountInfo(true)}
                                                startIcon={<EditIcon />}
                                                className="btn-edit"
                                            >
                                                Edit
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                                <Row align="left" className="mt-3">
                                    <h4>Basic Information</h4>
                                </Row>
                                <Row align="left">
                                    <Col sm={12} lg={6} className="mt-3">
                                        <Form.Group controlId="firstname">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                className="w-60"
                                                type="text"
                                                placeholder="First Name"
                                                required
                                                disabled={!editAccountInfo}
                                                value={
                                                    customerFName?.value !== null &&
                                                    customerFName?.value !== undefined
                                                        ? customerFName.value
                                                              .charAt(0)
                                                              .toUpperCase() +
                                                          customerFName.value.slice(1)
                                                        : ""
                                                }
                                                isInvalid={
                                                    customerFName.valid == false ? true : false
                                                }
                                                onChange={(e) => {
                                                    setCustomerFName({
                                                        value: e.target.value,
                                                    });
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {customerFName.error}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} lg={6} className="mt-3">
                                        <Form.Group controlId="lastname">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                className="w-60"
                                                type="text"
                                                placeholder="Last Name"
                                                required
                                                disabled={!editAccountInfo}
                                                value={
                                                    customerLName?.value !== null &&
                                                    customerLName?.value !== undefined
                                                        ? customerLName.value
                                                              .charAt(0)
                                                              .toUpperCase() +
                                                          customerLName.value.slice(1)
                                                        : ""
                                                }
                                                isInvalid={
                                                    customerLName.valid == false ? true : false
                                                }
                                                onChange={(e) => {
                                                    setCustomerLName({
                                                        value: e.target.value,
                                                    });
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {customerLName.error}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row align="left" className="mt-3">
                                    <Form.Group controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            className="w-60"
                                            type="text"
                                            placeholder="Email"
                                            required
                                            disabled
                                            value={
                                                customerEmail?.value !== null &&
                                                customerEmail?.value !== undefined
                                                    ? customerEmail.value
                                                    : ""
                                            }
                                            isInvalid={customerEmail.valid == false ? true : false}
                                            onChange={(e) => {
                                                setCustomerEmail({
                                                    value: e.target.value,
                                                });
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {customerEmail.error}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="mt-3 mb-3">
                            <Card.Body className="p-4">
                                <Row align="left" className="col-align">
                                    <Col lg="4" xs="10" md="10" className="header-fit">
                                        <h2>Delivery Information</h2>
                                    </Col>
                                    <Col lg="8" xs="2" md="2">
                                        {editDeliveryInfo === false && (
                                            <Button
                                                variant="contained"
                                                onClick={() => setEditDeliveryInfo(true)}
                                                startIcon={<EditIcon />}
                                                className="btn-edit"
                                            >
                                                Edit
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                                <Row align="left" className="mt-3">
                                    <Form.Group>
                                        <Form.Label>Address Line</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Address Line 1"
                                            required
                                            disabled={!editDeliveryInfo}
                                            value={
                                                customerAddress?.value !== null &&
                                                customerAddress?.value !== undefined
                                                    ? customerAddress.value
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setCustomerAddress({
                                                    value: e.target.value,
                                                });
                                            }}
                                            isInvalid={
                                                customerAddress.valid == false ? true : false
                                            }
                                            onKeyUp={(e) => {
                                                if (e.key === "Enter") {
                                                    editProfile();
                                                } else {
                                                    setCustomerAddress({
                                                        ...customerAddress,
                                                        value: e.target.value,
                                                    });
                                                }
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {customerAddress.error}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row align="left" className="mt-3">
                                    <Col md={12} lg={6}>
                                        <Form.Group>
                                            <Form.Label>Postcode</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Postcode"
                                                required
                                                disabled={!editDeliveryInfo}
                                                value={
                                                    customerPostcode?.value !== undefined &&
                                                    customerPostcode?.value !== null
                                                        ? customerPostcode.value
                                                        : ""
                                                }
                                                onChange={(e) => {
                                                    setCustomerPostcode({
                                                        value: e.target.value,
                                                    });
                                                }}
                                                isInvalid={
                                                    customerPostcode.valid == false ? true : false
                                                }
                                                onKeyUp={(e) => {
                                                    if (e.key === "Enter") {
                                                        editProfile();
                                                    } else {
                                                        setCustomerPostcode({
                                                            ...customerPostcode,
                                                            value: e.target.value,
                                                        });
                                                    }
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {customerPostcode.error}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <Form.Group>
                                            <Form.Label>State</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                placeholder="State"
                                                disabled={!editDeliveryInfo}
                                                value={
                                                    customerState?.value !== null &&
                                                    customerState?.value !== undefined
                                                        ? customerState.value
                                                        : ""
                                                }
                                                onChange={(e) => {
                                                    setCustomerState({
                                                        value: e.target.value,
                                                    });
                                                }}
                                                isInvalid={
                                                    customerState.valid == false ? true : false
                                                }
                                                onKeyUp={(e) => {
                                                    if (e.key === "Enter") {
                                                        // log databody
                                                        editProfile();
                                                    } else {
                                                        setCustomerState({
                                                            ...customerState,
                                                            value: e.target.value,
                                                        });
                                                    }
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {customerState.error}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row align="left" className="mt-3">
                                    <Form.Group controlId="phoneno">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            className="w-60"
                                            type="text"
                                            placeholder="Phone No."
                                            required
                                            disabled={!editDeliveryInfo}
                                            value={
                                                customerPhone?.value !== undefined &&
                                                customerPhone?.value !== null
                                                    ? customerPhone.value
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setCustomerPhone({
                                                    value: e.target.value,
                                                });
                                            }}
                                            isInvalid={customerPhone.valid == false ? true : false}
                                            onKeyUp={(e) => {
                                                if (e.key === "Enter") {
                                                    editProfile();
                                                } else {
                                                    setCustomerPhone({
                                                        ...customerPhone,
                                                        value: e.target.value,
                                                    });
                                                }
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {customerPhone.error}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row align="left" className="mt-3">
                                    <Form.Group>
                                        <Form.Label>Delivery Remarks</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Delivery Remarks"
                                            required
                                            disabled={!editDeliveryInfo}
                                            value={
                                                customerDeliveryRemarks?.value !== null &&
                                                customerDeliveryRemarks?.value !== undefined &&
                                                customerDeliveryRemarks?.value !== ""
                                                    ? customerDeliveryRemarks.value
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setCustomerDeliveryRemarks({
                                                    value: e.target.value,
                                                });
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                            </Card.Body>
                        </Card>
                        {(editAccountInfo || editDeliveryInfo) && (
                            <Row className="mt-3 mb-3">
                                <Col lg={6} xs={6}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            // localStorage.removeItem(
                                            //     "sidebar-state"
                                            // );
                                            // history.push("/");
                                            handleCancel();
                                        }}
                                        className="btn-cancel"
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col lg={6} xs={6}>
                                    <Button
                                        // type="submit"
                                        variant="contained"
                                        onClick={() => {
                                            editProfile();
                                        }}
                                        className="btn-save"
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </>
            )}
            {TabState === "payment" && (
                <>
                    <Form>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => handleTabState("account")}>
                                Account
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => handleTabState("payment")} active>
                                Payment Details
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => handleTabState("resetPassword")}>
                                Reset Password
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <Card className="p-4">
                            <Card.Body>
                                <Row align="left" className="mt-3 col-align">
                                    <Col lg="3" xs="9" md="9" className="header-fit">
                                        <h2>Payment Details</h2>
                                    </Col>
                                    <Col lg="9" xs="3" md="3">
                                        {isEdit === false && (
                                            <Tooltip
                                                title={
                                                    mandateStatus ? "" : "You don't have a mandate"
                                                }
                                            >
                                                <span className={mandateStatus ? "" : "disable"}>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => setIsEdit(true)}
                                                        startIcon={<EditIcon />}
                                                        className="btn-edit"
                                                        disabled={!mandateStatus}
                                                    >
                                                        Edit
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <h3 align="left">Payment Method</h3>
                                </Row>
                                <Row className="mt-3">
                                    <ButtonToolbar>
                                        <ToggleButtonGroup
                                            type="radio"
                                            disabled={!isEdit}
                                            className="btn-group"
                                        >
                                            <ToggleButton
                                                value="1"
                                                className={
                                                    buttonCheck1
                                                        ? "btn-toggle-active1"
                                                        : "btn-toggle1"
                                                }
                                                onClick={() => handleButtonCheck1()}
                                                // disabled={paymentMethodExist}
                                            >
                                                Savings/Current Account
                                                <Image src={FpxLogo} className="paymentImg1 ml-3" />
                                            </ToggleButton>

                                            <ToggleButton
                                                value="2"
                                                className={
                                                    buttonCheck2
                                                        ? "btn-toggle-active2"
                                                        : "btn-toggle2"
                                                }
                                                onClick={() => handleButtonCheck2()}
                                            >
                                                Credit/Debit Card
                                                <Image
                                                    src={cardIcon}
                                                    className="paymentImg2 ml-3"
                                                />
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </ButtonToolbar>
                                </Row>
                                {paymentMethod == 1 ? (
                                    <Row className="mt-4" align="left">
                                        <Form.Group>
                                            <Form.Label>
                                                <h5>Select your bank</h5>
                                            </Form.Label>
                                            <Form.Select
                                                onChange={(e) => {
                                                    const bankDetails = bankList.filter(
                                                        (bank) =>
                                                            bank.id[0] === parseInt(e.target.value)
                                                    );
                                                    console.log(
                                                        bankDetails,
                                                        "this is the bank detail"
                                                    );
                                                    setSelectedBank(bankDetails[0].id[0]);
                                                }}
                                                disabled={!isEdit}
                                            >
                                                <option value={undefined}>Select a bank</option>
                                                {bankList !== undefined
                                                    ? bankList.map((bank, index) => {
                                                          return (
                                                              <option value={bank.id}>
                                                                  {bank.display_name}
                                                              </option>
                                                          );
                                                      })
                                                    : ""}
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                ) : (
                                    ""
                                )}
                            </Card.Body>
                        </Card>
                        {isEdit == true && (
                            <Row className="mt-3 mb-5">
                                <Col lg={6} xs={6}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            handleCancel();
                                        }}
                                        className="btn-cancel"
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col lg={6} xs={6}>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            // if cust mandate ref exist,
                                            // append letter to the back of mandate ref
                                            // else, proceed to mandate authorisation
                                            console.log(custMandateRef, "mandateref");
                                            if (selectedBank === undefined) {
                                                Swal.fire({
                                                    title: "Please select a bank",
                                                    icon: "error",
                                                    confirmButtonText: "Ok",
                                                });

                                                // alert("Please select a bank");
                                            } else {
                                                if (custMandateRef.value !== undefined) {
                                                    var refNum = custMandateRef.value;
                                                    if (refNum.length == 15) {
                                                        var lastLetter = refNum[14];
                                                        lastLetter = String.fromCharCode(
                                                            lastLetter.charCodeAt() + 1
                                                        );
                                                        refNum = refNum.substring(0, 13);
                                                        refNum = `${refNum}-${lastLetter}`;
                                                    } else {
                                                        refNum = `${refNum}-A`;
                                                    }
						    console.log(buttonCheck1,'this is the button check 1');
                                                    console.log(
                                                        `${
                                                            process.env.REACT_APP_CURLEC_ENDPOINT
                                                        }/new-mandate?effectiveDate=&method=03&amount=${buttonCheck1 === true ? maxMandateFPX : maxMandateCC
}&frequency=MONTHLY&maximumFrequency=99&merchantId=${curlecMerchantId}&employeeId=${curlecEmployeeId}&emailAddress=${
                                                            store.getState().loggedInUserReducer
                                                                .userData.ar_system.cust_email
                                                        }&name=${
                                                            store.getState().loggedInUserReducer
                                                                .userData.ar_system.cust_fname
                                                        }%20${
                                                            store.getState().loggedInUserReducer
                                                                .userData.ar_system.cust_lname
                                                        }&referenceNumber=${refNum}&purposeOfPayment=edit%20payment%20details&paymentMethod=${paymentMethod}&bankId=${selectedBank}&idType=OTHERS&idValue=12345&linkId=${paymentMethod}`
                                                    );
                                                    window.open(
                                                        `${
                                                            process.env.REACT_APP_CURLEC_ENDPOINT
                                                        }/new-mandate?effectiveDate=&method=03&amount=${ buttonCheck1 === true ? maxMandateFPX : maxMandateCC
}&frequency=MONTHLY&maximumFrequency=99&merchantId=${curlecMerchantId}&employeeId=${curlecEmployeeId}&emailAddress=${
                                                            store.getState().loggedInUserReducer
                                                                .userData.ar_system.cust_email
                                                        }&name=${
                                                            store.getState().loggedInUserReducer
                                                                .userData.ar_system.cust_fname
                                                        }%20${
                                                            store.getState().loggedInUserReducer
                                                                .userData.ar_system.cust_lname
                                                        }&referenceNumber=${refNum}&purposeOfPayment=edit%20payment%20details&paymentMethod=${paymentMethod}&bankId=${selectedBank}&idType=OTHERS&idValue=12345&linkId=${paymentMethod}`,
                                                        "_self"
                                                    );
                                                } else {
                                                    window.open(
                                                        `${
                                                            process.env.REACT_APP_CURLEC_ENDPOINT
                                                        }/new-mandate?effectiveDate=&method=03&amount=${paymentMethod === 1 ? maxMandateFPX : maxMandateCC}&frequency=MONTHLY&maximumFrequency=99&merchantId=${curlecMerchantId}&employeeId=${curlecEmployeeId}&emailAddress=${
                                                            store.getState().loggedInUserReducer
                                                                .userData.ar_system.cust_email
                                                        }&name=${
                                                            store.getState().loggedInUserReducer
                                                                .userData.ar_system.cust_fname
                                                        }%20${
                                                            store.getState().loggedInUserReducer
                                                                .userData.ar_system.cust_lname
                                                        }&referenceNumber=${
                                                            store.getState().loggedInUserReducer
                                                                .userData.ar_system.cust_id
                                                        }&purposeOfPayment=edit%20payment%20details&paymentMethod=${paymentMethod}&bankId=${selectedBank}&idType=OTHERS&idValue=12345&linkId=${paymentMethod}`,
                                                        "_self"
                                                    );
                                                }
                                            }
                                        }}
                                        className="btn-save"
                                    >
                                        Save Changes
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </>
            )}
            {TabState === "resetPassword" && (
                <>
                    <Form>
                        <Breadcrumb>
                            <Breadcrumb.Item onClick={() => handleTabState("account")} active>
                                Account
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => handleTabState("payment")}>
                                Payment Details
                            </Breadcrumb.Item>
                            <Breadcrumb.Item onClick={() => handleTabState("resetPassword")}>
                                Reset Password
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <Card className="mt-3">
                            <Card.Body className="p-4">
                                <Row align="left" className="mt-3 col-align">
                                    <Col lg="3" xs="9" md="9" className="header-fit">
                                        <h2>Reset Password</h2>
                                    </Col>
                                    <Col lg="9" xs="3" md="3">
                                        {isEdit === false && (
                                            <Button
                                                variant="contained"
                                                onClick={() => setIsEdit(true)}
                                                startIcon={<EditIcon />}
                                                className="btn-edit"
                                            >
                                                Edit
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                                <Row align="left" className="mt-3">
                                    <h4> </h4>
                                </Row>
                                <Row align="left" className="mt-3">
                                    <Form.Group controlId="currentpassword">
                                        <Form.Label>Current Password</Form.Label>
                                        <Form.Control
                                            className="w-60"
                                            type="password"
                                            placeholder="Current Password"
                                            required
                                            disabled={!isEdit}
                                            value={customerCurrentPassword?.value}
                                            onChange={(e) => {
                                                setCustomerCurrentPassword({
                                                    value: e.target.value,
                                                });
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {customerCurrentPassword.error}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row align="left">
                                    <Col sm={12} lg={6} className="mt-3">
                                        <Form.Group controlId="newpassword">
                                            <Form.Label>New Password</Form.Label>
                                            <Form.Control
                                                className="w-60"
                                                type="password"
                                                placeholder="New Password"
                                                required
                                                disabled={!isEdit}
                                                value={customerNewPassword?.value}
                                                onChange={(e) => {
                                                    setCustomerNewPassword({
                                                        value: e.target.value,
                                                    });
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {customerNewPassword.error}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={12} lg={6} className="mt-3">
                                        <Form.Group controlId="confirmnewpassword">
                                            <Form.Label>Confirm New Password</Form.Label>
                                            <Form.Control
                                                className="w-60"
                                                type="password"
                                                placeholder="Confirm New Password"
                                                required
                                                disabled={!isEdit}
                                                value={customerConfirmNewPassword?.value}
                                                onChange={(e) => {
                                                    setCustomerConfirmNewPassword({
                                                        value: e.target.value,
                                                    });
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {customerConfirmNewPassword.error}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        {isEdit == true && (
                            <Row className="mt-3 mb-3">
                                <Col lg={6} xs={6}>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            // localStorage.removeItem(
                                            //     "sidebar-state"
                                            // );
                                            // history.push("/");
                                            handleCancel();
                                        }}
                                        className="btn-cancel"
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col lg={6} xs={6}>
                                    <Button
                                        // type="submit"
                                        variant="contained"
                                        onClick={() => {
                                            editPassword();
                                        }}
                                        className="btn-save"
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Form>
                </>
            )}
        </Container>
    );
};

export default Settings;
