import Modal from "react-bootstrap/Modal";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import TimePicker from "react-bootstrap-time-picker";
import Form from "react-bootstrap/Form";
import ClearIcon from "@material-ui/icons/Clear";
import planService from "../../services/planService";
import { toast } from "react-toastify";
import { Checkbox, FormControlLabel, Grid, Button } from "@material-ui/core";
import { store } from "../../index.js";
const DeliveryWindow = ({
    openDeliveryModal,
    handleSetOpenDeliveryModal,
    deliveryWindowId,
    handleDeliveryWindowId,
    deliveryWindowTime,
    handleDeliveryWindowTime,
    deliveryDaySelection,
    handleDeliveryDaySelection,
}) => {
    console.log(deliveryWindowTime, "delivery window time");
    const [hasSpecificTiming, setHasSpecificTiming] = useState(
        deliveryWindowTime?.value.length > 0 ? true : false
    );
    const renderTimeSlot = () => {
        return (
            <>
                <Grid container xs={12} md={12} lg={12}>
                    {deliveryWindowTime.value.map((data, index) => {
                        return (
                            <Grid item className="mt-3" xs={12} md={12} lg={12}>
                                <Grid container>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <TimePicker
                                            value={data["start_time"]}
                                            onChange={(time) => {
                                                // get current date
                                                const temp = deliveryWindowTime.value;
                                                temp[index]["start_time"] = time;
                                                handleDeliveryWindowTime({
                                                    ...deliveryWindowTime,
                                                    value: temp,
                                                });
                                            }}
                                            start="00:00"
                                            end="23:59"
                                            step={30}
                                        />
                                    </Grid>
                                    <Grid item align="center" xs={3} md={3} lg={3}>
                                        <Form.Label
                                            className="text-center mt-2"
                                            style={{
                                                fontSize: "12px",
                                                margin: "auto",
                                            }}
                                        >
                                            To
                                        </Form.Label>
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3}>
                                        <TimePicker
                                            onChange={(date) => {
                                                const tempDelivery = deliveryWindowTime.value;
                                                const deliverySlot = data;
                                                deliverySlot["end_time"] = date;
                                                tempDelivery[index] = deliverySlot;
                                                handleDeliveryWindowTime({
                                                    ...deliveryWindowTime,
                                                    value: tempDelivery,
                                                });
                                            }}
                                            value={data["end_time"]}
                                            start="00:00"
                                            end="23:59"
                                            step={30}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                        onClick={(e) => {
                                            const tempDelivery = deliveryWindowTime.value;
                                            tempDelivery.splice(index, 1);
                                            handleDeliveryWindowTime({
                                                ...deliveryWindowTime,
                                                value: tempDelivery,
                                            });
                                        }}
                                        xs={3}
                                        md={3}
                                        lg={3}
                                    >
                                        <ClearIcon className="ml-2 clear-icon"></ClearIcon>
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </>
        );
    };
    return (
        <Modal
            show={openDeliveryModal}
            onHide={() => {
                handleSetOpenDeliveryModal();
            }}
            animation={false}
            centered
            dialogClassName="delivery-window-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Delivery Window Configuration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Grid container>
                    <Grid item xs={12}>
                        {" "}
                        <p>This product will be available for delivery every:</p>
                    </Grid>
                    <Grid item xs={12}>
                        {Object.keys(deliveryDaySelection.value).map((day, index) => {
                            return (
                                <Button
                                    className={`delivery-day-config-button${
                                        deliveryDaySelection.value[day] == true ? "-active" : ""
                                    }  `}
                                    onClick={(e) => {
                                        const tempSelection = deliveryDaySelection.value;
                                        const currentBool = tempSelection[day];

                                        if (currentBool == true) {
                                            tempSelection[day] = false;
                                        } else {
                                            tempSelection[day] = true;
                                        }
                                        handleDeliveryDaySelection({
                                            ...deliveryDaySelection,
                                            value: tempSelection,
                                        });
                                    }}
                                    style={{
                                        backgroundColor: store.getState().colorState.color,
                                        borderColor: store.getState().colorState.color,
                                        color: store.getState().colorState.font_color,
                                    }}
                                    value={day}
                                >
                                    {day[0]}
                                </Button>
                            );
                        })}
                    </Grid>
                    <Grid item xs={12} className="mt-1">
                        <Form.Label className="w-100 pt-3">
                            Is there a specific timing for delivery?
                        </Form.Label>
                        <div className="w-100" style={{ display: "flex" }}>
                            <Form.Check
                                checked={hasSpecificTiming}
                                onChange={() => setHasSpecificTiming(true)}
                                type={"radio"}
                                label={"Yes"}
                            />
                            <Form.Check
                                checked={!hasSpecificTiming}
                                onChange={() => setHasSpecificTiming(false)}
                                className="ml-4"
                                type={"radio"}
                                label={"No"}
                            />
                        </div>
                    </Grid>
                </Grid>
                <Form.Label className="w-100 pt-3 mb-0">
                    Please select the delivery window:
                </Form.Label>
                {hasSpecificTiming ? renderTimeSlot() : ""}
                {hasSpecificTiming ? (
                    <Grid item className="mt-3" xs={12}>
                        {/* add new time slot button */}
                        {/*  */}
                        <Button
                            variant="contained"
                            onClick={() => {
                                // add time slot
                                const tempDelivery = deliveryWindowTime.value;
                                tempDelivery.push({
                                    start_time: 0,
                                    end_time: 0,
                                });
                                handleDeliveryWindowTime({
                                    ...deliveryWindowTime,
                                    value: tempDelivery,
                                });
                            }}
                            style={{
                                backgroundColor: store.getState().colorState.color,
                                borderColor: store.getState().colorState.color,
                                color: store.getState().colorState.font_color,
                                textTransform: "none",
                            }}
                            endIcon={<AddIcon></AddIcon>}
                        >
                            Add time slot
                        </Button>
                    </Grid>
                ) : (
                    ""
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    className=" mr-3"
                    style={{
                        backgroundColor: store.getState().colorState.color,
                        borderColor: store.getState().colorState.color,
                        color: store.getState().colorState.font_color,
                        textTransform: "none",
                        border: "1px solid #0c2651",
                    }}
                    onClick={() => {
                        handleSetOpenDeliveryModal();
                    }}
                >
                    Close
                </Button>
                <Button
                    variant="primary"
                    style={{
                        backgroundColor: store.getState().colorState.color,
                        borderColor: store.getState().colorState.color,
                        color: store.getState().colorState.font_color,
                        textTransform: "none",
                    }}
                    onClick={() => {
                        // append data here to submit to server
                        const dataBody = {
                            day: deliveryDaySelection.value,
                            merchant_id: 2,
                            time: deliveryWindowTime.value,
                        };
                        planService
                            .createDeliveryWindow(dataBody)
                            .then((e) => {
                                toast.success("Delivery window created", {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                handleDeliveryWindowId(e.delivery_window_id);
                            })
                            .catch((e) => {
                                toast.error(
                                    "Error occured while trying to create delivery window, please try again later",
                                    {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    }
                                );
                            });

                        handleSetOpenDeliveryModal();
                    }}
                >
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeliveryWindow;
