import { Container, Col, Card, Row, Button } from "react-bootstrap";
import no_image from "../../Images/no-image.svg";
import Chip from "@mui/material/Chip";
import "./productListView.css";
const ProductListView = ({ planData }) => {
    const listCards = [0, 1, 2, 3, 4, 5];
    return (
        <>
            {planData.map((card) => {
                return (
                    <Row>
                        <div className="list-view-card">
                            <img className="list-view-card-image" src={no_image} alt="empty" />
                            <div className="list-view-card-content">
                                <Row>
                                    <Col>
                                        <Chip
                                            className="list-view-card-chip  "
                                            style={{
                                                marginTop:
                                                    window.innerWidth < 900 ? "1.5em" : ".8em",
                                            }}
                                            label={<b className="card-chip-text">Property 1</b>}
                                        />
                                        <Chip
                                            style={{ marginTop: ".8em" }}
                                            className="list-view-card-chip"
                                            label={<b className="card-chip-text">Property 2</b>}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <h3 className="card-title">{card.plan_name}</h3>
                                </Row>
                                <Row className="card-description">
                                    <h5>{card.plan_desc}</h5>
                                </Row>
                                <Row>
                                    <p className="card-price-tag">
                                        Starts from RM{card.plan_price}
                                    </p>
                                </Row>
                            </div>
                        </div>
                    </Row>
                );
            })}
        </>
    );
};

export default ProductListView;
