import { Button } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useEffect, useLayoutEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import CurrencyInput from "react-currency-input";
import { useParams } from "react-router-dom";
import { store } from "../../../../../index.js";
const PricingStep = ({
    productData,
    pricingValidation,
    handlePricingValidation,
    subsConfigValidation,
    handleSubsConfigValidation,
    discountValidation,
    handleDiscountValidation,
}) => {
    const [purchaseOptions, setPurchaseOptions] = useState(
        JSON.parse(sessionStorage.getItem("purchase_options")) !== null
            ? JSON.parse(sessionStorage.getItem("purchase_options"))
            : {
                  one_off: false,
                  recurring: true,
              }
    );
    const [subsDuration, setSubsDuration] = useState("forever");
    const [pricingModel, setPricingModel] = useState(
        JSON.parse(sessionStorage.getItem("pricing_model")) !== null
            ? JSON.parse(sessionStorage.getItem("pricing_model"))
            : "flat_fee"
    );
    const [productPrice, setProductPrice] = useState(
        JSON.parse(sessionStorage.getItem("product_price")) !== null
            ? JSON.parse(sessionStorage.getItem("product_price"))
            : 0
    );
    const [discountSetup, setDiscountSetup] = useState(
        JSON.parse(sessionStorage.getItem("discount_setup")) !== null
            ? JSON.parse(sessionStorage.getItem("discount_setup"))
            : []
    );
    const [billingFrequency, setBillingFrequency] = useState(
        JSON.parse(sessionStorage.getItem("billing_frequency")) !== null
            ? JSON.parse(sessionStorage.getItem("billing_frequency"))
            : "month"
    );
    const [limitedPeriodSubs, setLimitedPeriodSubs] = useState(
        JSON.parse(sessionStorage.getItem("limited_period_subs")) !== null
            ? JSON.parse(sessionStorage.getItem("limited_period_subs"))
            : {
                  count: 12,
                  unit: "month",
                  auto_renew: false,
              }
    );
    const [subsAutoRenew, setSubsAutoRenew] = useState(true);
    const [subsCycleUnit, setSubsCycleUnit] = useState(
        JSON.parse(sessionStorage.getItem("subs_cycle_unit")) !== null
            ? JSON.parse(sessionStorage.getItem("subs_cycle_unit"))
            : "month"
    );
    const [subsCycleCount, setSubsCycleCount] = useState(
        JSON.parse(sessionStorage.getItem("subs_cycle_count")) !== null
            ? JSON.parse(sessionStorage.getItem("subs_cycle_count"))
            : 1
    );
    const [productPriceEdited, setProductPriceEdited] = useState(false);
    const [billingFrequencyOptions, setBillingFrequencyOptions] = useState([]);
    const params = useParams();
    useEffect(() => {
        if (productData !== undefined && params.id !== "new") {
            console.log(productData, "product data in pricing step");
            setPurchaseOptions(productData.purchase_option);
            setPricingModel(productData.pricing_model);
            setProductPrice(productData.plan_price);
            setSubsDuration(productData.subs_duration_type);
            if (productData.discount_setup.length > 0) {
                setDiscountSetup(productData.discount_setup);
            }
            setSubsAutoRenew(productData.subs_auto_renew);
            setSubsCycleUnit(productData.subs_cycle_unit);
            setSubsCycleCount(productData.subs_cycle_count);
            setBillingFrequency(productData.billing_frequency);
            sessionStorage.setItem("purchase_options", JSON.stringify(productData.purchase_option));
            sessionStorage.setItem("pricing_model", JSON.stringify(productData.pricing_model));
            sessionStorage.setItem("product_price", JSON.stringify(productData.plan_price));
            sessionStorage.setItem("subs_duration", JSON.stringify(productData.subs_auto_renew));
            sessionStorage.setItem("discount_setup", JSON.stringify(productData.discount_setup));
            sessionStorage.setItem("subs_auto_renew", JSON.stringify(productData.subs_auto_renew));
            sessionStorage.setItem("subs_cycle_unit", JSON.stringify(productData.subs_cycle_unit));
            sessionStorage.setItem(
                "subs_cycle_count",
                JSON.stringify(productData.subs_cycle_count)
            );
            sessionStorage.setItem(
                "billing_frequency",
                JSON.stringify(productData.billing_frequency)
            );
        }
    }, [productData]);

    useEffect(() => {
        // initial setup for pricing model
        if (JSON.parse(sessionStorage.getItem("pricing_model")) === null) {
            sessionStorage.setItem("pricing_model", JSON.stringify(pricingModel));
        }
        if (JSON.parse(sessionStorage.getItem("discount_setup")) === null) {
            sessionStorage.setItem("discount_setup", JSON.stringify(discountSetup));
        }
        if (JSON.parse(sessionStorage.getItem("purchase_options")) === null) {
            sessionStorage.setItem("purchase_options", JSON.stringify(purchaseOptions));
        }
    }, [pricingModel, discountSetup, purchaseOptions]);

    useEffect(() => {
        // for initial setup of purchaseOptions constant and any changes to purchase options
        if (parseFloat(productPrice) === 0.0 && productPriceEdited) {
            handlePricingValidation({
                product_price: { ...pricingValidation.product_price, invalid: true },
            });
        } else {
            sessionStorage.setItem("product_price", JSON.stringify(productPrice));
            handlePricingValidation({
                product_price: { ...pricingValidation.product_price, invalid: false },
            });
        }

        console.log(pricingValidation, "this is hte pricingf validation");
    }, [productPrice]);

    useEffect(() => {
        // for any changes to subscription configuration
        sessionStorage.setItem("subs_duration", JSON.stringify(subsDuration));
        sessionStorage.setItem("subs_auto_renew", JSON.stringify(subsAutoRenew));
        sessionStorage.setItem("billing_frequency", JSON.stringify(billingFrequency));
        sessionStorage.setItem("subs_cycle_unit", JSON.stringify(subsCycleUnit));
        sessionStorage.setItem("subs_cycle_count", JSON.stringify(subsCycleCount));
    }, [subsDuration, subsAutoRenew, billingFrequency, subsCycleUnit, subsCycleCount]);

    useEffect(() => {
        if (limitedPeriodSubs.count === 0 || limitedPeriodSubs.count === null) {
            handleSubsConfigValidation({
                subs_duration: { ...subsConfigValidation.subs_duration, invalid: true },
            });
        } else {
            handleSubsConfigValidation({
                subs_duration: { ...subsConfigValidation.subs_duration, invalid: false },
            });
        }
    }, [limitedPeriodSubs]);

    useEffect(() => {
        // if subsCycleCount is a multiple of 3, then set subsCycleUnit to month
        var billFreqOptions = [
            <option value="every_month">Every month</option>,
            <option value="all_at_once">Full payment upfront</option>,
        ];
        if (subsCycleCount % 3 === 0 || subsCycleUnit === "year") {
            billFreqOptions.push(<option value="every_3_months">Every 3 months</option>);
        }
        if (subsCycleCount % 12 === 0 || subsCycleUnit === "year") {
            billFreqOptions.push(<option value="every_year">Every year</option>);
        }
        setBillingFrequencyOptions(billFreqOptions);
    }, [subsCycleCount, subsCycleUnit]);

    function purchaseOptionsInvalid() {
        var invalid = [];
        for (let key in purchaseOptions) {
            if (purchaseOptions[key] === false) {
                invalid.push(key);
            }
        }
        invalid.length === 2 ? (invalid = true) : (invalid = false);
        return invalid;
    }

    const checkPurchaseOptions = (option) => {
        if (option === "one_off" && purchaseOptions.one_off) {
            return store.getState().colorState.color;
        } else if (option === "recurring" && purchaseOptions.recurring) {
            return store.getState().colorState.color;
        } else if (pricingValidation.purchase_options.invalid && purchaseOptionsInvalid()) {
            return "red";
        }
        return "black";
    };
    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
        },
    });
    function productPriceValidateHelperText() {
        if (pricingValidation.product_price.invalid) {
            return (
                <p
                    style={{
                        color: "red",
                        fontSize: "0.8rem",
                        fontWeight: "400",
                        marginTop: "5px",
                        marginBottom: "auto",
                        paddingLeft: "0",
                    }}
                >
                    Please enter a valid price
                </p>
            );
        }
    }

    const validateAmount = (type, index) => {
        if (type === "mandatory") {
            if (discountValidation.amount.invalid_mandatory_row.includes(index)) {
                return true;
            }
        }
        if (type === "optional") {
            if (discountValidation.amount.invalid_optional_row.includes(index)) {
                return true;
            }
        }
        return false;
    };

    const labelHelper = (label) => {
        return (
            <Form.Label>
                <small style={{ color: "grey" }}>{label}</small>
            </Form.Label>
        );
    };

    useLayoutEffect(() => {
        const section = document.getElementById("scroll-here");
        section.scrollIntoView({ behavior: "smooth", block: "center" });
    }, []);

    return (
        <div className="step-container slide-right">
            {" "}
            <h4 style={{ fontWeight: "500" }}>Pricing Configuration</h4>
            <Row id="scroll-here">
                <Form.Group className="form-group required">
                    <Form.Label className="pt-3 control-label">
                        What are the purchase options available? (Tick as applicable)
                    </Form.Label>
                </Form.Group>
            </Row>
            <Row className="ml-0 mr-0">
                <Button
                    className="mt-2"
                    style={{
                        backgroundColor: "white",
                        textTransform: "none",
                        color: "black",
                        padding: "1em",
                        borderStyle: "solid",
                        borderRadius: "6px",
                        borderWidth: purchaseOptions.one_off ? "medium" : "thin",
                        borderColor: checkPurchaseOptions("one_off"),
                        boxShadow: "none",
                    }}
                    onClick={() => {
                        const newPurchaseOptions = {
                            ...purchaseOptions,
                            one_off: !purchaseOptions.one_off,
                        };
                        setPurchaseOptions(newPurchaseOptions);
                        sessionStorage.setItem(
                            "purchase_options",
                            JSON.stringify(newPurchaseOptions)
                        );
                    }}
                    classes={{ label: "override-button-label" }}
                    variant="contained"
                    color="primary"
                >
                    <p
                        style={{
                            textAlign: "left",
                            marginBottom: "auto",
                            marginTop: "auto",
                            fontWeight: "400",
                        }}
                    >
                        One-time purchase
                    </p>{" "}
                    <Form.Check
                        className="d-flex"
                        type="checkbox"
                        checked={purchaseOptions.one_off}
                    />
                </Button>
            </Row>
            <Row className="ml-0 mr-0 ">
                <Button
                    className="mt-2"
                    style={{
                        backgroundColor: "white",
                        textTransform: "none",
                        color: "black",
                        padding: "1em",
                        borderStyle: "solid",
                        borderRadius: "6px",
                        borderWidth: purchaseOptions.recurring ? "medium" : "thin",
                        borderColor: checkPurchaseOptions("recurring"),
                        boxShadow: "none",
                    }}
                    onClick={() => {
                        const newPurchaseOptions = {
                            ...purchaseOptions,
                            recurring: !purchaseOptions.recurring,
                        };
                        setPurchaseOptions(newPurchaseOptions);
                        sessionStorage.setItem(
                            "purchase_options",
                            JSON.stringify(newPurchaseOptions)
                        );
                    }}
                    classes={{ label: "override-button-label" }}
                    variant="contained"
                    color="primary"
                >
                    <p
                        style={{
                            textAlign: "left",
                            marginBottom: "auto",
                            marginTop: "auto",
                            fontWeight: "400",
                        }}
                    >
                        Ongoing subscriptions
                    </p>
                    <Form.Check
                        className="d-flex"
                        type="checkbox"
                        checked={purchaseOptions.recurring}
                    />
                </Button>
                {pricingValidation.purchase_options.invalid && purchaseOptionsInvalid() && (
                    <p
                        style={{
                            color: "red",
                            fontSize: "0.8rem",
                            fontWeight: "400",
                            marginTop: "5px",
                            marginBottom: "auto",
                            paddingLeft: "0",
                        }}
                    >
                        Please select at least one purchase option
                    </p>
                )}
            </Row>{" "}
            <Form.Group className="form-group required">
                <Form.Label className="pt-3 control-label">
                    Pricing Model
                    <CustomWidthTooltip
                        arrow
                        PopperProps={{
                            sx: {
                                "& .MuiTooltip-tooltip": {
                                    border: "solid 1px",
                                    backgroundColor: "#f5f5f5",
                                    borderRadius: "6px",
                                    borderColor: "black",
                                    color: "black",
                                },
                            },
                        }}
                        title={
                            <>
                                <p>
                                    A flat fee is for products that have a fixed recurring charge.
                                </p>
                                <br />
                                <p>
                                    A variable fee is for products that have items with variants.
                                    The price shown on the product card will be the lowest possible
                                    price that a customer could checkout.
                                </p>
                            </>
                        }
                        placement="right"
                    >
                        <InfoIcon className="ml-2 mb-1" />
                    </CustomWidthTooltip>
                </Form.Label>{" "}
            </Form.Group>
            <Form.Select
                value={pricingModel}
                onChange={(pricing) => {
                    setPricingModel(pricing.target.value);
                    sessionStorage.setItem("pricing_model", JSON.stringify(pricing.target.value));
                }}
                aria-label="Default select example"
            >
                <option value="flat_fee">Flat Fee</option>
                <option value="variable_fee">Variable Fee</option>
            </Form.Select>
            {pricingModel === "flat_fee" && (
                <Row className="pt-3">
                    <Form.Group className="form-group required">
                        <Form.Label className=" w-100 control-label">Price</Form.Label>{" "}
                    </Form.Group>
                    <Col>
                        <CurrencyInput
                            className="w-100"
                            style={{
                                borderRadius: "6px",
                                borderColor: pricingValidation.product_price.invalid
                                    ? "red"
                                    : "black",
                                borderWidth: "thin",
                                padding: "0.375rem 1.75rem 0.375rem 0.75rem",
                            }}
                            onChange={(price) => {
                                console.log(price, "this is the new price");
                                // remove prefix
                                const newPrice = price.replace(/[^0-9.]/g, "");
                                setProductPrice(newPrice);
                                setProductPriceEdited(true);
                                sessionStorage.setItem("product_price", JSON.stringify(newPrice));
                            }}
                            // ref={(node) => (this.input = node)}
                            prefix="RM"
                            value={productPrice}
                        />
                        {productPriceValidateHelperText()}
                    </Col>
                    {purchaseOptions.recurring && (
                        <Col>
                            <Form.Select
                                value={subsCycleUnit}
                                onChange={(title) => {
                                    setSubsCycleUnit(title.target.value);
                                    sessionStorage.setItem(
                                        "subs_cycle_unit",
                                        JSON.stringify(title.target.value)
                                    );
                                }}
                                type="select"
                                placeholder=""
                                // isInvalid={validation.section_name.invalid}
                                required
                            >
                                <option value="year">per year</option>
                                <option value="month">per month</option>
                            </Form.Select>
                        </Col>
                    )}
                </Row>
            )}
            {pricingModel === "variable_fee" && purchaseOptions.recurring && (
                <Row className="pt-3">
                    <Form.Group className="form-group required">
                        <Form.Label className=" w-100 control-label">Billing Frequency</Form.Label>{" "}
                    </Form.Group>

                    <Col>
                        <Form.Select
                            value={subsCycleUnit}
                            onChange={(title) => {
                                setSubsCycleUnit(title.target.value);
                                sessionStorage.setItem(
                                    "subs_cycle_unit",
                                    JSON.stringify(title.target.value)
                                );
                            }}
                            type="select"
                            placeholder=""
                            // isInvalid={validation.section_name.invalid}
                            required
                        >
                            <option value="year">per year</option>
                            <option value="month">per month</option>
                        </Form.Select>
                    </Col>
                </Row>
            )}
            {/* {pricingModel === "property_pricing" && (
                <Row>
                    <Col>
                        <Form.Label className="pt-3 w-100">Price</Form.Label>
                        <CurrencyInput
                            className="w-100"
                            style={{
                                borderRadius: "6px",
                                borderColor: !pricingValidation.product_price.invalid
                                    ? "red"
                                    : "black",
                                borderWidth: "thin",
                                padding: "0.375rem 1.75rem 0.375rem 0.75rem",
                            }}
                            onChange={(price) => {
                                console.log(price, "this is the new price");
                                // remove prefix
                                const newPrice = price.replace(/[^0-9.]/g, "");
                                setProductPrice(newPrice);
                                sessionStorage.setItem("product_price", JSON.stringify(newPrice));
                            }}
                            ref={(node) => (this.input = node)}
                            prefix="RM"
                            value={productPrice}
                        />
                    </Col>
                    <Col>
                        <Form.Select
                            value={subsCycleUnit}
                            onChange={(title) => {
                                SetSubsCycleUnit(title.target.value);
                                sessionStorage.setItem(
                                    "subs_cycle_unit",
                                    JSON.stringify(title.target.value)
                                );
                            }}
                            type="select"
                            placeholder=""
                            // isInvalid={validation.section_name.invalid}
                            required
                        >
                            <option value="year">per year</option>
                            <option value="month">per month</option>
                        </Form.Select>
                    </Col>
                </Row>
            )}
            {(pricingModel === "property_pricing" || pricingModel === "tiered_pricing") && (
                <>
                    <Form.Label className="pt-3 w-100">Pricing Model</Form.Label>
                    <Form.Select
                        value={pricingModel}
                        onChange={(pricing) => setPricingModel(pricing.target.value)}
                        aria-label="Default select example"
                    >
                        <option hidden></option>
                    </Form.Select>
                </>
            )} */}
            {purchaseOptions.recurring && (
                <>
                    <h5 className="mt-3 mb-lg-0" style={{ fontWeight: "450", paddingTop: "2em" }}>
                        Subscription configuration
                    </h5>{" "}
                    {labelHelper("This section only applies to ongoing subscriptions")}
                    {/* <Form.Group className="form-group required w-100">
                        <Form.Label className="pt-2 control-label">
                            What is the duration of the subscription?{" "}
                            <CustomWidthTooltip
                                arrow
                                PopperProps={{
                                    sx: {
                                        "& .MuiTooltip-tooltip": {
                                            border: "solid 1px",
                                            backgroundColor: "#f5f5f5",
                                            borderRadius: "6px",
                                            borderColor: "black",
                                            color: "black",
                                        },
                                    },
                                }}
                                title={
                                    <p>
                                        {" "}
                                        "Forever" means that the subscription is continuously
                                        ongoing until a customer chooses to cancel their
                                        subscription. <br /> <br />
                                        "Limited Period" means that the subscription expires after
                                        the specified period. After this period, the subscription
                                        may automatically terminate or renew, depending on your
                                        selection.
                                    </p>
                                }
                                placement="right"
                            >
                                <InfoIcon className=" mb-1" />
                            </CustomWidthTooltip>
                        </Form.Label>
                    </Form.Group> */}
                    {/* <div style={{ display: "flex", flexDirection: "row" }}>
                        {" "}
                        <Form.Check
                            checked={subsDuration === "forever"}
                            onChange={() => {
                                setSubsDuration("forever");
                                sessionStorage.setItem("subs_duration", JSON.stringify("forever"));
                            }}
                            type={"radio"}
                            label={"Forever"}
                        />
                        <Form.Check
                            checked={subsDuration === "limited_period"}
                            onChange={() => {
                                setSubsDuration("limited_period");
                                sessionStorage.setItem(
                                    "subs_duration",
                                    JSON.stringify("limited_period")
                                );
                            }}
                            className="ml-4"
                            type={"radio"}
                            label={"Limited Period"}
                        />
                    </div> */}
                    <Form.Group className="form-group required">
                        <Form.Label className="pt-3 w-100 control-label">
                            What is the subscription period?
                            <CustomWidthTooltip
                                arrow
                                PopperProps={{
                                    sx: {
                                        "& .MuiTooltip-tooltip": {
                                            border: "solid 1px",
                                            backgroundColor: "#f5f5f5",
                                            borderRadius: "6px",
                                            borderColor: "black",
                                            color: "black",
                                        },
                                    },
                                }}
                                title={
                                    <p>
                                        The subscription period determines the tenure of the
                                        subscription. After this period, you can choose for the
                                        subscription to be automatically renewed or terminated.
                                        <br />
                                        <br />
                                        For a forever subscription, set the subscription period to{" "}
                                        <strong>1</strong> and select the <strong>“Renew”</strong>{" "}
                                        option.
                                    </p>
                                }
                                placement="right"
                            >
                                <InfoIcon className="ml-2 mb-1" />
                            </CustomWidthTooltip>
                        </Form.Label>{" "}
                    </Form.Group>
                    <Row>
                        <Col lg={6}>
                            <InputGroup className="w-75" hasValidation>
                                <Form.Control
                                    value={subsCycleCount}
                                    onChange={(duration) => {
                                        setSubsCycleCount(duration.target.value);
                                        sessionStorage.setItem(
                                            "subs_cycle_count",
                                            JSON.stringify(duration.target.value)
                                        );
                                    }}
                                    style={{ borderRightColor: "white" }}
                                    min={1}
                                    type="number"
                                />
                                <InputGroup.Text
                                    style={{
                                        backgroundColor: "white",
                                        borderColor: "black",
                                        borderRadius: "0 6px 6px 0",
                                        borderLeftStyle: "none",
                                    }}
                                >
                                    {subsCycleUnit === "month" ? "month(s)" : "year(s)"}
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                        {/* <Col>
                            <Form.Select
                                value={limitedPeriodSubs.unit}
                                onChange={(month) => {
                                    setLimitedPeriodSubs({
                                        ...limitedPeriodSubs,
                                        unit: month.target.value,
                                    });
                                }}
                            >
                                <option hidden></option>
                                <option value="day">Days</option>
                                <option value="week">Weeks</option>
                                <option value="month">Months</option>
                                <option value="year">Years</option>{" "}
                            </Form.Select>
                        </Col> */}
                    </Row>
                    <Form.Group className="form-group required">
                        <Form.Label className="pt-3 control-label">
                            Does the subscription automatically renew or terminate upon expiry?
                        </Form.Label>
                    </Form.Group>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {" "}
                        <Form.Check
                            checked={subsAutoRenew}
                            onChange={() => {
                                setSubsAutoRenew(true);
                            }}
                            type={"radio"}
                            label={"Renew"}
                        />
                        <Form.Check
                            checked={!subsAutoRenew}
                            onChange={() => {
                                setSubsAutoRenew(false);
                            }}
                            className="ml-4"
                            type={"radio"}
                            label={"Terminate"}
                        />
                    </div>
                    <Form.Label className="w-100 pt-3">
                        What is the billing frequency for this subscription?
                    </Form.Label>
                    <Form.Select
                        value={billingFrequency}
                        onChange={(title) => {
                            setBillingFrequency(title.target.value);
                            sessionStorage.setItem(
                                "billing_frequency",
                                JSON.stringify(title.target.value)
                            );
                        }}
                        type="select"
                        placeholder=""
                        // isInvalid={validation.section_name.invalid}
                        required
                    >
                        {/* <option value="monthly">Every month</option>
                        <option value="quarterly">Every 3 months</option>
                        <option value="yearly">Every year</option>
                        <option value="upfront">Full payment upfront</option> */}
                        {billingFrequencyOptions.map((option) => {
                            return option;
                        })}
                    </Form.Select>
                </>
            )}
            <h5 style={{ fontWeight: "450", paddingTop: "2.5em" }}>
                Discount Setup
                <CustomWidthTooltip
                    arrow
                    PopperProps={{
                        sx: {
                            "& .MuiTooltip-tooltip": {
                                border: "solid 1px",
                                backgroundColor: "#f5f5f5",
                                borderRadius: "6px",
                                borderColor: "black",
                                color: "black",
                            },
                        },
                    }}
                    title={
                        <p>
                            This discount is automatically applied to the product if a customer
                            selection meets your specified criteria.
                        </p>
                    }
                    placement="right"
                >
                    <InfoIcon className="ml-2 mt-1 mb-1" />
                </CustomWidthTooltip>
            </h5>
            {discountSetup.map((setup, index) => {
                return (
                    <>
                        {/* <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                            }}
                        >
                            <h6 style={{ paddingTop: ".5em" }}>Discount</h6>
                        </div> */}
                        <Row
                            style={{
                                backgroundColor: "#E1E1E1",
                                borderColor: "#808080",
                                borderStyle: "solid",
                                borderRadius: "6px",
                                padding: "1em",
                                marginLeft: "0",
                                marginRight: "0",
                                display: "flex",
                                flexDirection: "row",
                            }}
                            xs={12}
                            lg={12}
                        >
                            <Col
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                lg={2}
                                xs={1}
                                // className=
                            >
                                <p>IF</p>
                            </Col>
                            <Col lg={10} xs={10}>
                                <Row className="w-100">
                                    <Col
                                        xs={12}
                                        lg={4}
                                        className={`p-2 ${
                                            window.innerWidth < 991 && "pt-1"
                                        } discount-setup-input`}
                                    >
                                        <Form.Select
                                            value={setup.mandatory_row.discount_on}
                                            onChange={(discountOn) => {
                                                const updateDiscountSetupRow = [...discountSetup];

                                                updateDiscountSetupRow[
                                                    index
                                                ].mandatory_row.discount_on =
                                                    discountOn.target.value;

                                                setDiscountSetup(updateDiscountSetupRow);
                                                sessionStorage.setItem(
                                                    "discount_setup",
                                                    JSON.stringify(updateDiscountSetupRow)
                                                );
                                            }}
                                            aria-label="Default select example"
                                        >
                                            <option hidden></option>
                                            <option value="total_price">Total Price</option>

                                            {discountSetup[index].optional_row?.discount_on !==
                                                "purchase_option" && (
                                                <option value="purchase_option">
                                                    Purchase option
                                                </option>
                                            )}

                                            <option
                                                hidden={
                                                    JSON.parse(
                                                        sessionStorage.getItem("quantity_checker")
                                                    ) === true
                                                        ? false
                                                        : true
                                                }
                                                value="quantity"
                                            >
                                                Quantity
                                            </option>
                                        </Form.Select>
                                    </Col>
                                    <Col xs={12} lg={5} className=" p-2  discount-setup-input">
                                        <Form.Select
                                            value={setup.mandatory_row.discount_operator}
                                            onChange={(operator) => {
                                                const updateDiscountSetupRow = [...discountSetup];

                                                updateDiscountSetupRow[
                                                    index
                                                ].mandatory_row.discount_operator =
                                                    operator.target.value;

                                                setDiscountSetup(updateDiscountSetupRow);
                                                sessionStorage.setItem(
                                                    "discount_setup",
                                                    JSON.stringify(updateDiscountSetupRow)
                                                );
                                            }}
                                            aria-label="Default select example"
                                        >
                                            <option hidden></option>

                                            {discountSetup[index].mandatory_row.discount_on ===
                                            "purchase_option" ? (
                                                <>
                                                    {purchaseOptions.one_off && (
                                                        <option value="one_time_subs">
                                                            One-time purchase
                                                        </option>
                                                    )}
                                                    {purchaseOptions.recurring && (
                                                        <option value="is_subs">
                                                            Ongoing subscriptions
                                                        </option>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <option value="greater">is greater than</option>
                                                    <option value="lesser">is less than</option>
                                                    <option value="equal">is equal to</option>
                                                </>
                                            )}
                                        </Form.Select>
                                    </Col>
                                    <Col xs={12} lg={3}>
                                        {discountSetup[index].mandatory_row.discount_on !==
                                            "purchase_option" && (
                                            <Row>
                                                <Col className="discount-setup-input p-2">
                                                    <InputGroup className="" hasValidation>
                                                        <Form.Control
                                                            value={setup.mandatory_row.amount}
                                                            onChange={(amount) => {
                                                                const updateDiscountSetupRow = [
                                                                    ...discountSetup,
                                                                ];

                                                                updateDiscountSetupRow[
                                                                    index
                                                                ].mandatory_row.amount =
                                                                    amount.target.value;

                                                                setDiscountSetup(
                                                                    updateDiscountSetupRow
                                                                );
                                                                sessionStorage.setItem(
                                                                    "discount_setup",
                                                                    JSON.stringify(
                                                                        updateDiscountSetupRow
                                                                    )
                                                                );
                                                            }}
                                                            isInvalid={validateAmount(
                                                                "mandatory",
                                                                index
                                                            )}
                                                            type="number"
                                                            placeholder="Amount"
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {/* {d.subs_duration.error} */}
                                                            {validateAmount("mandatory", index) && (
                                                                <p> Amount cannot be 0</p>
                                                            )}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                            </Col>

                            <Col
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                lg={0}
                            >
                                {discountSetup[index].optional_row === null && (
                                    <AddIcon
                                        onClick={() => {
                                            const addOptionalRow = [...discountSetup];
                                            addOptionalRow[index].optional_row = {
                                                operator: "or",
                                                discount_on: "total_price",
                                                discount_operator: "greater",
                                                amount: 0,
                                            };
                                            setDiscountSetup(addOptionalRow);
                                            sessionStorage.setItem(
                                                "discount_setup",
                                                JSON.stringify(addOptionalRow)
                                            );
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>
                        {discountSetup[index].optional_row !== null && (
                            <Row
                                style={{
                                    backgroundColor: "#E1E1E1",
                                    borderColor: "#808080",
                                    borderStyle: "solid",
                                    borderRadius: "6px",
                                    marginLeft: "0",
                                    marginRight: "0",
                                    marginTop: "1em",
                                    padding: "1em",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}
                            >
                                {/* <Col xs={1} lg={0} /> */}

                                <Col lg={12} xs={10}>
                                    <Row>
                                        <Col
                                            className={`p-2 ${window.innerWidth < 991 && "pt-1"}`}
                                            lg={2}
                                            xs={12}
                                        >
                                            <Form.Select
                                                value={setup.optional_row.operator}
                                                onChange={(operator) => {
                                                    const updateDiscountSetupRow = [
                                                        ...discountSetup,
                                                    ];

                                                    updateDiscountSetupRow[
                                                        index
                                                    ].optional_row.operator = operator.target.value;
                                                    setDiscountSetup(updateDiscountSetupRow);
                                                    sessionStorage.setItem(
                                                        "discount_setup",
                                                        JSON.stringify(updateDiscountSetupRow)
                                                    );
                                                }}
                                                aria-label="Default select example"
                                            >
                                                <option value="and">AND</option>
                                                <option value="or">OR</option>
                                            </Form.Select>
                                        </Col>
                                        <Col
                                            className="discount-setup-input p-2 pt-2"
                                            lg={3}
                                            xs={12}
                                        >
                                            <Form.Select
                                                value={setup.optional_row.discount_on}
                                                onChange={(discountOn) => {
                                                    const updateDiscountSetupRow = [
                                                        ...discountSetup,
                                                    ];

                                                    updateDiscountSetupRow[
                                                        index
                                                    ].optional_row.discount_on =
                                                        discountOn.target.value;
                                                    setDiscountSetup(updateDiscountSetupRow);
                                                    sessionStorage.setItem(
                                                        "discount_setup",
                                                        JSON.stringify(updateDiscountSetupRow)
                                                    );
                                                }}
                                                aria-label="Default select example"
                                            >
                                                <option hidden></option>
                                                <option value="total_price">Total Price</option>
                                                {(purchaseOptions.recurring ||
                                                    purchaseOptions.one_off) &&
                                                    discountSetup[index].mandatory_row
                                                        .discount_on !== "purchase_option" && (
                                                        <option value="purchase_option">
                                                            Purchase option
                                                        </option>
                                                    )}
                                                <option
                                                    hidden={
                                                        JSON.parse(
                                                            sessionStorage.getItem(
                                                                "quantity_checker"
                                                            )
                                                        ) === true
                                                            ? false
                                                            : true
                                                    }
                                                    value="quantity"
                                                >
                                                    Quantity
                                                </option>
                                            </Form.Select>
                                        </Col>
                                        <Col className="discount-setup-input p-2" lg={4}>
                                            <Form.Select
                                                value={setup.optional_row.discount_operator}
                                                onChange={(operator) => {
                                                    const updateDiscountSetupRow = [
                                                        ...discountSetup,
                                                    ];
                                                    updateDiscountSetupRow[
                                                        index
                                                    ].optional_row.discount_operator =
                                                        operator.target.value;
                                                    setDiscountSetup(updateDiscountSetupRow);
                                                    sessionStorage.setItem(
                                                        "discount_setup",
                                                        JSON.stringify(updateDiscountSetupRow)
                                                    );
                                                }}
                                                aria-label="Default select example"
                                            >
                                                <option hidden></option>
                                                {discountSetup[index].optional_row.discount_on ===
                                                "purchase_option" ? (
                                                    <>
                                                        {purchaseOptions.one_off && (
                                                            <option value="one_time_subs">
                                                                One-time purchase
                                                            </option>
                                                        )}
                                                        {purchaseOptions.recurring && (
                                                            <option value="is_subs">
                                                                Ongoing subscriptions
                                                            </option>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <option value="greater">
                                                            is greater than
                                                        </option>
                                                        <option value="lesser">is less than</option>
                                                        <option value="equal">is equal to</option>
                                                    </>
                                                )}
                                            </Form.Select>
                                        </Col>
                                        <Col lg={2} xs={12}>
                                            {discountSetup[index].optional_row.discount_on !==
                                                "purchase_option" && (
                                                <Row>
                                                    <Col className="discount-setup-input p-2">
                                                        {" "}
                                                        <InputGroup className="" hasValidation>
                                                            <Form.Control
                                                                value={setup.optional_row.amount}
                                                                onChange={(amount) => {
                                                                    const updateDiscountSetupRow = [
                                                                        ...discountSetup,
                                                                    ];
                                                                    updateDiscountSetupRow[
                                                                        index
                                                                    ].optional_row.amount =
                                                                        amount.target.value;
                                                                    setDiscountSetup(
                                                                        updateDiscountSetupRow
                                                                    );
                                                                    sessionStorage.setItem(
                                                                        "discount_setup",
                                                                        JSON.stringify(
                                                                            updateDiscountSetupRow
                                                                        )
                                                                    );
                                                                }}
                                                                placeholder="Amount"
                                                                isInvalid={validateAmount(
                                                                    "optional",
                                                                    index
                                                                )}
                                                            />{" "}
                                                            <Form.Control.Feedback type="invalid">
                                                                {/* {d.subs_duration.error} */}
                                                                {validateAmount(
                                                                    "optional",
                                                                    index
                                                                ) && <p> Amount cannot be 0</p>}
                                                            </Form.Control.Feedback>{" "}
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>{" "}
                                        <Col
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                            lg={1}
                                        >
                                            <CloseButton
                                                onClick={() => {
                                                    // remove optional row from discount setup array
                                                    const updateDiscountSetupRow = [
                                                        ...discountSetup,
                                                    ];
                                                    updateDiscountSetupRow[index].optional_row =
                                                        null;
                                                    setDiscountSetup(updateDiscountSetupRow);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col lg={6}>
                                {" "}
                                <Form.Group className="form-group required">
                                    <Form.Label className="pt-3 control-label">
                                        Discount Type
                                    </Form.Label>
                                </Form.Group>
                                <Form.Select
                                    value={setup.discount_type}
                                    onChange={(discount) => {
                                        const updateDiscountSetupRow = [...discountSetup];
                                        updateDiscountSetupRow[index].discount_type =
                                            discount.target.value;
                                        setDiscountSetup(updateDiscountSetupRow);
                                        sessionStorage.setItem(
                                            "discount_setup",
                                            JSON.stringify(updateDiscountSetupRow)
                                        );
                                    }}
                                    aria-label="Default select example"
                                >
                                    <option value="fixed_amount">Fixed Amount</option>
                                    <option value="percentage">Percentage</option>
                                </Form.Select>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className=" form-group required">
                                    <Form.Label className="pt-3 control-label">
                                        Discount Amount{" "}
                                        {`${
                                            setup.discount_type === "fixed_amount" ? "(RM)" : "(%)"
                                        }`}
                                    </Form.Label>
                                </Form.Group>
                                <InputGroup className="" hasValidation>
                                    <Form.Control
                                        value={setup.discount_amount}
                                        onChange={(amount) => {
                                            const updateDiscountSetupRow = [...discountSetup];
                                            updateDiscountSetupRow[index].discount_amount =
                                                parseFloat(amount.target.value);
                                            setDiscountSetup(updateDiscountSetupRow);
                                            sessionStorage.setItem(
                                                "discount_setup",
                                                JSON.stringify(updateDiscountSetupRow)
                                            );
                                        }}
                                        isInvalid={discountValidation.discount_amount.invalid}
                                        type="number"
                                        aria-label="Default select example"
                                    />{" "}
                                    <Form.Control.Feedback type="invalid">
                                        {discountValidation.discount_amount.error}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                            {setup.discount_type === "percentage" && (
                                <Col lg={6}>
                                    <Form.Label className="pt-3">Discount Cap (RM)</Form.Label>
                                    <InputGroup className="" hasValidation>
                                        <Form.Control
                                            value={setup.discount_cap}
                                            onChange={(amount) => {
                                                const updateDiscountSetupRow = [...discountSetup];
                                                updateDiscountSetupRow[index].discount_cap =
                                                    parseFloat(amount.target.value);
                                                setDiscountSetup(updateDiscountSetupRow);
                                                sessionStorage.setItem(
                                                    "discount_setup",
                                                    JSON.stringify(updateDiscountSetupRow)
                                                );
                                            }}
                                            isInvalid={discountValidation.discount_cap.invalid}
                                            type="number"
                                            aria-label="Default select example"
                                        />{" "}
                                        <Form.Control.Feedback type="invalid">
                                            {discountValidation.discount_cap.error}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            )}
                            <Col className="pt-3" lg={12}>
                                <Button
                                    startIcon={<DeleteIcon />}
                                    style={{
                                        backgroundColor: "red",
                                        color: "white",
                                        textTransform: "none",
                                    }}
                                    onClick={() => {
                                        // splice discount from array
                                        const newDiscountSetup = [...discountSetup];
                                        newDiscountSetup.splice(index, 1);
                                        setDiscountSetup(newDiscountSetup);
                                        sessionStorage.setItem(
                                            "discount_setup",
                                            JSON.stringify(newDiscountSetup)
                                        );
                                    }}
                                    variant="contained"
                                >
                                    Delete discount
                                </Button>
                            </Col>
                        </Row>
                    </>
                );
            })}
            {discountSetup.length === 0 && (
                <Button
                    className="mt-3"
                    startIcon={<AddIcon />}
                    style={{
                        backgroundColor: store.getState().colorState.color,
                        borderColor: store.getState().colorState.color,
                        color: store.getState().colorState.font_color,
                        display: discountSetup.length >= 2 ? "none" : "",
                        textTransform: "none",
                    }}
                    onClick={() => {
                        const updateDiscountSetupRow = [...discountSetup];
                        updateDiscountSetupRow.push({
                            mandatory_row: {
                                discount_on: "total_price",
                                discount_operator: "greater",
                                amount: 0,
                            },
                            optional_row: {
                                operator: "or",
                                discount_on: "total_price",
                                discount_operator: "greater",
                                amount: 0,
                            },
                            discount_type: "fixed_amount",
                            discount_amount: 0,
                        });
                        setDiscountSetup(updateDiscountSetupRow);
                        sessionStorage.setItem(
                            "discount_setup",
                            JSON.stringify(updateDiscountSetupRow)
                        );
                    }}
                    variant="contained"
                    component="label"
                >
                    Add Discount
                </Button>
            )}
        </div>
    );
};

export default PricingStep;
