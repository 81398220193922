import axios from "axios";
import userService from "../../services/userService";

axios.defaults.baseURL =
    window.location.protocol +
    "//" +
    window.location.hostname +
    process.env.REACT_APP_SITE_PORT_BACKEND;
// Add a request interceptor
axios.interceptors.request.use(function (config) {
    let currUser = JSON.parse(localStorage.getItem("user_token"));

    if (!!currUser) {
        config.headers.Authorization = "Bearer " + currUser.access_token;
    }

    return config;
});

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        let userTokenInfo = JSON.parse(localStorage.getItem("user_token"));
        let response = error.response;
        let isRefresh =
            response &&
            response.config &&
            response.config.url &&
            response.config.url.indexOf("/api/token/refresh") !== -1;

        if (error.response.status === 401 && !!userTokenInfo && !isRefresh) {
            // 401 is Unauthorized error
            // which means that this request failed
            // what we need to do is send a refresh request then resend the same request
            // that failed but with the new access point.
            // We can do this automatically using axios interceptors
            return userService
                .refreshToken()
                .then((response) => {
                    var newUserTokenInfo = {
                        refresh_token: userTokenInfo.refresh_token, //set the refresh token
                        access_token: response.data.access, //update new access token
                        lastRefresh: new Date().getTime(),
                    };
                    localStorage.setItem("user_token", JSON.stringify(newUserTokenInfo));

                    // Set default headers to have authorization the access token as authorization for future requests
                    axios.defaults.headers.common["Authorization"] =
                        "Bearer " + response.data.access;

                    // Get the original that failed due to 401 and resend it
                    // with the new access token
                    const config = error.config;
                    config.headers.Authorization = "Bearer " + response.data.access;

                    // Resending original request
                    return new Promise((resolve, reject) => {
                        axios
                            .request(config)
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    });
                })
                .catch((error) => {
                    // if refresh token failed logout
                    userService.logout().then(function (result) {
                        window.location.replace("https://" + window.location.hostname);
                    });
                    Promise.reject(error);
                });
        }

        return new Promise((resolve, reject) => {
            reject(error);
        });
    }
);

export const callAPI = (endpoint, method = "get", data = {}) => {
    return new Promise((resolve, reject) => {
        axios({
            method,
            url: `${endpoint}`,
            data: data,
        })
            .then((res) => resolve(res.data))
            .catch((err) => {
                reject({
                    status: err?.response?.status || "",
                    message: err?.response?.data?.error || "",
                });
            });
    });
};
