import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Tooltip } from "@mui/material";
import axios from "axios";
import { useEffect, useLayoutEffect, useState } from "react";
import Swal from "sweetalert2";
import DataTable from "../../../Components/DataTable";
import { store } from "../../../index.js";
import invoiceService from "../../../services/invoiceService";
import "./style.css";
// import { Form } from "react-bootstrap/lib/Navbar";
import { Form } from "react-bootstrap";

const Invoice = () => {
    useEffect(() => {
        setTimeout(() => {
            sessionStorage.clear();
        }, 3000);
    });

    const [invoiceData, setInvoiceData] = useState([]);
    const [allInvoiceData, setAllInvoiceData] = useState([]);
    const [refreshData, setRefreshData] = useState(false);
    const [invoiceId, setInvoiceId] = useState("");
    const [invoiceStatus, setInvoiceStatus] = useState("");

    useLayoutEffect(() => {
        invoiceService.customerGetAllInvoice().then((allInvoiceData) => {
            allInvoiceData.forEach((Invoice) => {
                Invoice.id = Invoice.inv_id;
                Invoice.issue_date = Invoice.inv_issue_date;
            });
	    setAllInvoiceData(allInvoiceData);
            setInvoiceData(allInvoiceData);
        });
    }, [refreshData]);

    useEffect(() => {
        var filteredData = allInvoiceData;
	console.log(invoiceId,'this is the input invoice id');
        if (invoiceId !== "") {
            filteredData = allInvoiceData.filter((data) => {
                console.log(data.inv_id,'the invoice id');
		console.log(invoiceId, 'this is the invoice id');
		return data.inv_id.toLowerCase().includes(invoiceId.toLocaleLowerCase());
            });
        }
        if (invoiceStatus !== "") {
            filteredData = allInvoiceData.filter((data) => {
                const invoiceResolved = (
                    invoice_resolved,
                    invoice_collection_status,
                    inv_collection_batch,
                    dueDateStr
                ) => {
                    var today = new Date();
                    var todayDate = new Date(
                        today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate() + 5
                    );
                    var dueDate = new Date(dueDateStr.slice(0, 10));

                    if (
                        (invoice_resolved == "true" || invoice_resolved == true) &&
                        parseInt(invoice_collection_status) == 0
                    ) {
                        return "Paid";
                    } else if (
                        (invoice_collection_status != null &&
                            parseInt(invoice_collection_status) != 0) ||
                        todayDate > dueDate
                    ) {
                        return "Overdue";
                    } else {
                        return "Awaiting Payment";
                    }
                };

                const retrieveInvoiceStatus = invoiceResolved(
                    data.inv_resolved.toString(),
                    data.inv_collection_status,
                    data.inv_collection_batch,
                    data.inv_due_date
                );
                return retrieveInvoiceStatus.toLowerCase() === invoiceStatus.toLowerCase();
            });
        }
	
        console.log(filteredData,'this is the data');	
	setInvoiceData(filteredData);
    }, [invoiceId, invoiceStatus]);

    const defaultColumn = [
        {
            field: "inv_id",
            headerName: "INVOICE ID",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
            disabled: true,
        },
        {
            field: "inv_issue_date",
            headerName: "DATE GENERATED",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
        },
        {
            field: "inv_due_date",
            headerName: "PAYMENT DATE",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
        },
        {
            field: "inv_total_amt",
            headerName: "INVOICE TOTAL",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
        },
        {
            field: "inv_collection_status",
            headerName: "STATUS",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            flex: 1,
            checked: true,
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            headerClassName: "super-app-theme--header",
            headerAlign: "center",
            type: "date",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Download Invoice as pdf">
                            <a onClick={downloadInvoice.bind(this, params.row.inv_id)}>
                                <PictureAsPdfIcon className="invoice-action-icon" />
                            </a>
                        </Tooltip>
                    </>
                );
            },
            checked: true,
            disabled: true,
        },
    ];

    const [columns, setColumns] = useState(defaultColumn);
    const [columnField, setColumnField] = useState(defaultColumn);

    const handleCheckField = (check) => {
        setColumnField(check);
    };
    const handleHeaderDisplay = (header) => {
        const newColumn = defaultColumn.filter((column, column_index) => {
            if (header[column_index].checked) {
                return column;
            }
        });
        setColumns(newColumn);
    };

    const handleColumns = (columns) => {
        setColumns(columns);
    };

    const filterList = [
        {
            key: "inv_id",
            component: (
                <>
                    <Form.Label className="mt-1">Invoice ID</Form.Label>
                    <Form.Control
                        value={invoiceId}
                        onChange={(inv) => setInvoiceId(inv.target.value)}
                    />
                </>
            ),
        },
        {
            key: "inv_collection_status",
            component: (
                <>
                    <Form.Label className="mt-3">Status</Form.Label>
                    <Form.Select
                        onChange={(status) => setInvoiceStatus(status.target.value)}
                        value={invoiceStatus}
                        as="select"
                    >
                        <option value="" hidden disabled>
                            Please select
                        </option>
                        <option value="paid">Paid</option>
                        <option value="overdue">Overdue</option>
                        <option value="awaiting_payment">Awaiting Payment</option>
                    </Form.Select>
		</>
            ),
        },
    ];

    const downloadInvoice = (invId) => {
        console.log(invId, "this is the inv id");
        axios({
            method: "POST",
            url: `${
                window.location.protocol +
                "//" +
                window.location.hostname +
                process.env.REACT_APP_SITE_PORT_BACKEND
            }/api/invoice_pdf/`,
            data: { inv_id: invId },
            responseType: "blob",
        })
            .then((res) => {
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                var url = window.URL.createObjectURL(res.data);
                a.href = url;
                a.download = `${invId}.pdf`;
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((err) => {
                console.error(err, "error");
                Swal.fire({
                    icon: "error",
                    title: "Could not download the invoice now",
                    text: "Please try again later.",
                    confirmButtonColor: store.getState().colorState.color,
                });
            });
    };

    return (
        <>
            <DataTable
                Header={"Invoices"}
                Columns={columns}
                Rows={invoiceData}
                RefreshData={refreshData}
                handleRefreshData={() => {
                    setRefreshData(false);
                }}
                ColumnField={columnField}
                ExportExist={true}
                FilterExist={true}
                FilterList={filterList}
                DisplayExist={true}
                SearchBarExist={true}
                handleHeaderDisplay={handleHeaderDisplay}
                handleColumns={handleColumns}
                handleCheckField={handleCheckField}
            />
        </>
    );
};

export default Invoice;
