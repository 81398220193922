import { Col, Form, InputGroup, Row } from "react-bootstrap";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Input,
    ListItemText,
    MenuItem,
    Select,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import { FormHelperText, Modal, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useParams } from "react-router-dom";
import addOnService from "../../../../../services/addOnService";
import CreateItem from "../../../Addon/AddonEditCreate/CreateItem";
import "../style.css";
import { store } from "../../../../../index.js";
const ItemStep = ({
    productData,
    itemValidation,
    handleItemValidation,
    quantityValidation,
    handleQuantityValidation,
}) => {
    const [quantityRestrictions, setQuantityRestrictions] = useState(
        JSON.parse(sessionStorage.getItem("quantity_restrictions_checker")) !== null
            ? JSON.parse(sessionStorage.getItem("quantity_restrictions_checker"))
            : false
    );
    const [quantityChecker, setQuantityChecker] = useState(
        JSON.parse(sessionStorage.getItem("quantity_checker")) !== null
            ? JSON.parse(sessionStorage.getItem("quantity_checker"))
            : false
    );
    const [itemChecker, setItemChecker] = useState(
        JSON.parse(sessionStorage.getItem("item_checker")) !== null
            ? JSON.parse(sessionStorage.getItem("item_checker"))
            : false
    );

    const [addonChecked, setAddonChecked] = useState(
        JSON.parse(sessionStorage.getItem("addons_checked")) !== null
            ? JSON.parse(sessionStorage.getItem("addons_checked"))
            : false
    );
    const [addons, setAddons] = useState(
        JSON.parse(sessionStorage.getItem("addons")) !== null
            ? { items: JSON.parse(sessionStorage.getItem("addons")) }
            : { items: [] }
    );

    const [addonData, setAddonData] = useState([]);
    const [categoriesColumn, setCategoriesColumn] = useState(
        JSON.parse(sessionStorage.getItem("categories_column")) === null
            ? [
                  {
                      name: "",
                      items: [],
                  },
              ]
            : JSON.parse(sessionStorage.getItem("categories_column"))
    );

    const [openItemCreation, setOpenItemCreation] = useState(false);

    const [restrictionsApplyTo, setRestrictionsApplyTo] = useState(
        JSON.parse(sessionStorage.getItem("restrictions_apply_to")) === null
            ? "all"
            : JSON.parse(sessionStorage.getItem("restrictions_apply_to"))
    );

    const [restrictions, setRestrictions] = useState(
        JSON.parse(sessionStorage.getItem("restrictions")) === null
            ? [{ category: "", number: 1 }]
            : JSON.parse(sessionStorage.getItem("restrictions"))
    );
    const params = useParams();

    useEffect(() => {
        if (params.id === "new") {
            if (JSON.parse(sessionStorage.getItem("quantity_restrictions_checker")) === null) {
                sessionStorage.setItem("quantity_restrictions_checker", quantityRestrictions);
            }
            if (JSON.parse(sessionStorage.getItem("item_checker")) === null) {
                sessionStorage.setItem("item_checker", JSON.stringify(itemChecker));
            }
            if (JSON.parse(sessionStorage.getItem("quantity_checker")) === null) {
                sessionStorage.setItem("quantity_checker", JSON.stringify(quantityChecker));
            }
            if (JSON.parse(sessionStorage.getItem("restrictions")) === null) {
                sessionStorage.setItem("restrictions", JSON.stringify(restrictions));
            }
            if (JSON.parse(sessionStorage.getItem("restrictions_apply_to")) === null) {
                sessionStorage.setItem(
                    "restrictions_apply_to",
                    JSON.stringify(restrictionsApplyTo)
                );
            }
        }
    }, [quantityRestrictions, itemChecker, quantityChecker, restrictions, restrictionsApplyTo]);

    useEffect(() => {
        // if restrictions apply to all,
        let newRestrictions = [];
        if (
            quantityRestrictions &&
            params.id === "new" &&
            JSON.parse(sessionStorage.getItem("restrictions")) === null
        ) {
            // set restrictions according to categoriesColumn
            categoriesColumn.forEach((category, index) => {
                newRestrictions.push({
                    category: category.name,
                    number: 1,
                });
            });
            setRestrictions(newRestrictions);
            sessionStorage.setItem("restrictions", JSON.stringify(newRestrictions));
        }
    }, [restrictionsApplyTo, categoriesColumn]);

    useEffect(() => {
        if (productData !== undefined && params.id !== "new") {
            // console.log(productData.categories_item.categories, "item step product data");
            if (productData.categories_item !== null) {
                if (JSON.parse(sessionStorage.getItem("categories_column")) === null) {
                    productData.categories_item.categories.map((category) => {
                        var convertCategoryItems = [];
                        category.items.forEach((item) => {
                            convertCategoryItems.push(item.addon_id);
                        });
                        category.items = convertCategoryItems;
                    });
                    sessionStorage.setItem(
                        "categories_column",
                        JSON.stringify(productData.categories_item.categories)
                    );
                }

                const newAddon =
                    productData.addon.length > 0 ? { items: productData.addon } : { items: [] };
                setItemChecker(productData.item_exist);
                setQuantityChecker(productData.quantity_exist);
                setQuantityRestrictions(productData.quantity_restrictions_exist);
                if (JSON.parse(sessionStorage.getItem("restrictions")) === null) {
                    if (productData.quantity_restrictions?.category !== undefined) {
                        setRestrictions(productData?.quantity_restrictions?.category);
                        sessionStorage.setItem(
                            "restrictions",
                            JSON.stringify(productData.quantity_restrictions?.category)
                        );
                    }
                }
                if (productData.quantity_restrictions !== null) {
                    setRestrictionsApplyTo(productData.quantity_restrictions.apply_to);
                }
                setAddonChecked(productData.addon_exist);
                setCategoriesColumn(productData.categories_item.categories);
                setAddons(newAddon);

                sessionStorage.setItem("item_checker", JSON.stringify(productData.item_exist));
                sessionStorage.setItem(
                    "quantity_checker",
                    JSON.stringify(productData.quantity_exist)
                );
                sessionStorage.setItem(
                    "quantity_restrictions_checker",
                    productData.quantity_restrictions_exist
                );

                if (productData.quantity_restrictions?.apply_to !== undefined && productData.quantity_restrictions?.apply_to !== null) {
                    sessionStorage.setItem(
                        "restrictions_apply_to",
                        JSON.stringify(productData.quantity_restrictions?.apply_to)
                    );
                } 

                sessionStorage.setItem("addons_checked", JSON.stringify(productData.addon_exist));

                sessionStorage.setItem("addons", JSON.stringify(newAddon.items));
            }
        }
    }, [productData]);

    useEffect(() => {
        categoriesColumn.map((category, index) => {
            const categoryNameInvalid = validateCategoryNameError(index);
            const categoryItemInvalid = validateCategoryItemsError(index);
            if (category.name !== "" && categoryNameInvalid) {
                // remove index from itemValidation.category_name.index
                itemValidation.category_name.index = itemValidation.category_name.index.filter(
                    (key) => key !== index
                );
                itemValidation.category_name.invalid = false;
            } else if (category.name === "" && categoryNameInvalid) {
                itemValidation.category_name.index.push(index);
                itemValidation.category_name.invalid = true;
            }
            if (category.items.length > 0 && categoryItemInvalid) {
                itemValidation.category_items.index = itemValidation.category_items.index.filter(
                    (key) => key !== index
                );
                itemValidation.category_items.invalid = false;
            } else if (category.items.length === 0 && categoryItemInvalid) {
                itemValidation.category_items.index.push(index);
                itemValidation.category_items.invalid = true;
            }
        });
        handleItemValidation({
            ...itemValidation,
        });
    }, [categoriesColumn, itemChecker]);

    useEffect(() => {
        if (addons.items.length > 0) {
            handleItemValidation({
                ...itemValidation,
                addons: {
                    value: false,
                    error: "",
                },
            });
        } else if (addons.items.length === 0) {
            handleItemValidation({
                ...itemValidation,
                addons: {
                    value: true,
                    error: "Please select at least one item",
                },
            });
        }
    }, [addons]);

    useEffect(() => {
        if (restrictionsApplyTo === "all" && JSON.parse(sessionStorage.getItem("restrictions"))) {
            const restrictionNum = parseInt(restrictions[0]?.number);

            if (
                isNaN(restrictionNum) ||
                restrictionNum === 0 ||
                restrictionNum === "" ||
                restrictionNum === null
            ) {
                handleQuantityValidation({
                    ...quantityValidation,
                    item_amount: {
                        ...quantityValidation.item_amount,
                        invalid: true,
                    },
                });
            } else {
                handleQuantityValidation({
                    ...quantityValidation,
                    item_amount: {
                        ...quantityValidation.item_amount,
                        invalid: false,
                    },
                });
            }
        }
    }, [restrictions]);

    useEffect(() => {
        addOnService
            .getAllAddonWithoutPagination()
            .then((res) => {
                console.log(res, "addon data");
                setAddonData(res.addons);
                if (JSON.parse(sessionStorage.getItem("categories_column")) === null) {
                    setCategoriesColumn([
                        {
                            name: "",
                            items: [],
                        },
                    ]);
                    sessionStorage.setItem(
                        "categories_column",
                        JSON.stringify([
                            {
                                name: "",
                                items: [],
                            },
                        ])
                    );
                }
            })
            .catch((e) => {});
    }, [openItemCreation]);

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
        },
    });

    const renderCategoryRestrictions = () => {
        const renderRestrictions = (
            <>
                <Form.Label className="pt-2">Restrictions apply to </Form.Label>
                <Form.Select
                    onChange={(e) => {
                        setRestrictionsApplyTo(e.target.value);
                        sessionStorage.setItem(
                            "restrictions_apply_to",
                            JSON.stringify(e.target.value)
                        );
                        if (e.target.value === "individual") {
                            // need to check how many categories column and add restriction based on that
                            console.log(categoriesColumn, "this is the categories column");
                            var createRestrictionsColumn = [];
                            categoriesColumn.map((category) => {
                                createRestrictionsColumn.push({
                                    category: category.name,
                                    number: 1,
                                });
                            });
                            console.log(
                                createRestrictionsColumn,
                                "this is the create restriction column"
                            );
                            setRestrictions(createRestrictionsColumn);
                            sessionStorage.setItem(
                                "restrictions",
                                JSON.stringify(createRestrictionsColumn)
                            );
                        }
                    }}
                    value={restrictionsApplyTo}
                    aria-label="Default select example"
                >
                    <option value="all">All categories</option>
                    {categoriesColumn.length > 1 && (
                        <option value="individual">Individual categories</option>
                    )}
                </Form.Select>
                {/* based on number of categories created, if all is chosen, 
        all categories will be displayed with only needing customer's input */}
                {/* if individual is chosen, then single line with disabled disabled and customer's input  */}
                {restrictionsApplyTo !== "all" ? (
                    restrictions.map((restriction, index) => {
                        return (
                            <Row key={index}>
                                {
                                    <Col xs={6}>
                                        <Form.Label
                                            className={`${
                                                window.innerWidth > 991 ? "pt-2" : "mt-4 pt-2"
                                            }`}
                                        >
                                            Category Name
                                        </Form.Label>
                                        <Form.Select
                                            disabled={restrictionsApplyTo === "all"}
                                            onChange={(e) => {
                                                const newRestrictions = restrictions.map(
                                                    (restriction, key) => {
                                                        if (index === key) {
                                                            return {
                                                                ...restriction,
                                                                category: e.target.value,
                                                            };
                                                        }
                                                        return restriction;
                                                    }
                                                );
                                                setRestrictions(newRestrictions);
                                                sessionStorage.setItem(
                                                    "restrictions",
                                                    JSON.stringify(newRestrictions)
                                                );
                                            }}
                                            className=""
                                            value={restriction.category}
                                            aria-label="Default select example"
                                        >
                                            {categoriesColumn.map((category, index) => {
                                                // if category is checked, then dont display it
                                                return (
                                                    <option key={index} value={category.name}>
                                                        {category.name}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                    </Col>
                                }
                                <Col xs={6}>
                                    <Form.Label className="pt-2">
                                        Number of selected items
                                    </Form.Label>
                                    <InputGroup className="" hasValidation>
                                        <Form.Control
                                            type="number"
                                            // max={categoriesColumn[index]?.items?.length}
                                            onChange={(e) => {
                                                const newRestrictions = restrictions.map(
                                                    (restriction, key) => {
                                                        if (index === key) {
                                                            return {
                                                                ...restriction,
                                                                number: parseInt(e.target.value),
                                                            };
                                                        }
                                                        return restriction;
                                                    }
                                                );
                                                setRestrictions(newRestrictions);
                                                sessionStorage.setItem(
                                                    "restrictions",
                                                    JSON.stringify(newRestrictions)
                                                );
                                            }}
                                            // min={1}
                                            isInvalid={quantityValidation.item_amount.index.includes(
                                                index
                                            )}
                                            value={restrictions[index].number}
                                        />{" "}
                                        <Form.Control.Feedback type="invalid">
                                            {/* {d.subs_duration.error} */}
                                            {quantityValidation.item_amount.index.includes(index) &&
                                                quantityValidation.item_amount.error}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                        );
                    })
                ) : (
                    <Col xs={6}>
                        <Form.Label className="pt-2">Number of selected items</Form.Label>
                        <InputGroup className="" hasValidation>
                            <Form.Control
                                onChange={(e) => {
                                    const newRestrictions = [
                                        { category: "", number: parseInt(e.target.value) },
                                    ];
                                    setRestrictions(newRestrictions);
                                    sessionStorage.setItem(
                                        "restrictions",
                                        JSON.stringify(newRestrictions)
                                    );
                                }}
                                min={1}
                                isInvalid={quantityValidation.item_amount.invalid}
                                value={restrictions !== null && restrictions[0]?.number}
                                type="number"
                            />{" "}
                            <Form.Control.Feedback type="invalid">
                                {/* {d.subs_duration.error} */}
                                {quantityValidation.item_amount.invalid &&
                                    quantityValidation.item_amount.error}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Col>
                )}
                {restrictionsApplyTo === "all" ? null : (
                    // if restrictions.length === categoriesColumn.length, allow to remove restrictions
                    // if restrictions.length > 1, allow to remove restrictions
                    // if restrictions.length === 1, dont show both buttons
                    <div className="d-flex mt-3">
                        {restrictions.length < categoriesColumn.length && (
                            <Button
                                startIcon={<AddIcon />}
                                style={{
                                    backgroundColor: store.getState().colorState.color,
                                    color: store.getState().colorState.font_color,
                                    borderColor: store.getState().colorState.color,
                                    textTransform: "none",
                                }}
                                onClick={() => {
                                    // allow create based on number of columns in categories column
                                    setRestrictions([...restrictions, { category: "", number: 1 }]);
                                    sessionStorage.setItem(
                                        "restrictions",
                                        JSON.stringify(restrictions)
                                    );
                                }}
                                variant="contained"
                                component="label"
                            >
                                {" "}
                                Add restriction
                            </Button>
                        )}
                        {restrictions.length > 1 && (
                            <Button
                                startIcon={<DeleteIcon />}
                                style={{
                                    backgroundColor: "red",
                                    color: store.getState().colorState.font_color,
                                    borderColor: store.getState().colorState.color,
                                    textTransform: "none",
                                }}
                                onClick={() => {
                                    // remove last item
                                    setRestrictions(restrictions.slice(0, -1));
                                    sessionStorage.setItem(
                                        "restrictions",
                                        JSON.stringify(restrictions)
                                    );
                                }}
                                className={`${
                                    restrictions.length < categoriesColumn.length && "ml-3"
                                }`}
                                variant="contained"
                                component="label"
                            >
                                Remove restriction
                            </Button>
                        )}
                    </div>
                )}
            </>
        );
        return (
            <>
                <div>
                    {" "}
                    <h4 style={{ fontWeight: "450", paddingTop: "2em" }}>Quantity Restrictions</h4>
                    <Form.Group className="form-group required">
                        <Form.Label className="pt-1 control-label">
                            Are customers allowed to purchase multiple quantities of an item within
                            a category?
                        </Form.Label>
                    </Form.Group>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {" "}
                        <Form.Check
                            onChange={(event) => {
                                setQuantityChecker(true);
                                sessionStorage.setItem("quantity_checker", JSON.stringify(true));
                            }}
                            checked={quantityChecker === true}
                            type={"radio"}
                            label={"Yes"}
                        />
                        <Form.Check
                            onChange={(event) => {
                                setQuantityChecker(false);
                                sessionStorage.setItem("quantity_checker", JSON.stringify(false));
                            }}
                            checked={quantityChecker === false}
                            className="ml-4"
                            type={"radio"}
                            label={"No"}
                        />
                    </div>
                    {quantityChecker && (
                        <>
                            <Form.Group className="form-group required">
                                <Form.Label className="pt-2 control-label">
                                    Are there any quantity restrictions within the categories?
                                </Form.Label>
                            </Form.Group>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                {" "}
                                <Form.Check
                                    onChange={(event) => {
                                        setQuantityRestrictions(true);
                                        sessionStorage.setItem(
                                            "quantity_restrictions_checker",
                                            true
                                        );
					sessionStorage.setItem("restrictions_apply_to", JSON.stringify("all"));
                                    }}
                                    checked={quantityRestrictions}
                                    type={"radio"}
                                    label={"Yes"}
                                />
                                <Form.Check
                                    onChange={(event) => {
                                        setQuantityRestrictions(false);
                                        sessionStorage.setItem(
                                            "quantity_restrictions_checker",
                                            false
                                        );
					sessionStorage.setItem("restrictions_apply_to", JSON.stringify(null));
                                    }}
                                    checked={!quantityRestrictions}
                                    className="ml-4"
                                    type={"radio"}
                                    label={"No"}
                                />
                            </div>
                        </>
                    )}
                    {quantityRestrictions && quantityChecker ? renderRestrictions : null}
                </div>
            </>
        );
    };

    const validateCategoryNameError = (index) => {
        // if index is in itemValidation.category_name.index
        if (itemValidation.category_name.index.includes(index)) {
            return true;
        }
    };

    const validateCategoryItemsError = (index) => {
        // if index is in itemValidation.category_name.index
        if (itemValidation.category_items.index.includes(index)) {
            return true;
        } else {
            return false;
        }
    };

    const renderCategoriesColumn = () => {
        return categoriesColumn.map((category, index) => (
            <div className="w-100 mt-2">
                <Form.Group className="form-group ">
                    <Form.Label>{`Category ${index + 1}`}</Form.Label>
                    <InputGroup className="mt-1" hasitemValidation>
                        <Form.Control
                            placeholder="Name"
                            onChange={(name) => {
                                // change current column's name value
                                const newCategoriesColumn = categoriesColumn.map((column, i) => {
                                    if (i === index) {
                                        return { ...column, name: name.target.value };
                                    }
                                    return column;
                                });
                                setCategoriesColumn(newCategoriesColumn);
                                sessionStorage.setItem(
                                    "categories_column",
                                    JSON.stringify(newCategoriesColumn)
                                );
                            }}
                            value={category.name}
                            isInvalid={validateCategoryNameError(index)}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            {validateCategoryNameError(index) && itemValidation.category_name.error}
                        </Form.Control.Feedback>
                    </InputGroup>{" "}
                </Form.Group>
                <Form.Group>
                    <Form.Label className="mt-3">{`Category ${index + 1} Items`}</Form.Label>
                    <FormControl className="w-100" error={validateCategoryItemsError(index)}>
                        <Select
                            multiple
                            className="w-100"
                            value={categoriesColumn[index].items}
                            onChange={(event) => {
                                var selected = event.target.value;
                                // update menus in key's menus
                                var newCategoriesColumn = [...categoriesColumn];
                                newCategoriesColumn[index].items = selected;
                                setCategoriesColumn(newCategoriesColumn);
                                sessionStorage.setItem(
                                    "categories_column",
                                    JSON.stringify(newCategoriesColumn)
                                );
                            }}
                            input={
                                <Input
                                    style={{
                                        borderStyle: "solid",
                                        borderRadius: "6px",
                                        borderWidth: "thin",
                                        // if error, border color turns to red
                                        borderColor: validateCategoryItemsError(index) ? "red" : "",
                                    }}
                                />
                            }
                            renderValue={(selectedAddonIds) => {
                                var displayText = [];
                                selectedAddonIds.forEach((selectedAddonId) => {
                                    // console.log(selectedAddonIds, "this is the selected addon ids");
                                    // console.log(addonData, "selected addon id");
                                    displayText.push(
                                        addonData.find(
                                            (addon) => addon.addon_id === selectedAddonId
                                        )?.addon_name
                                    );
                                });
                                var moreText = displayText
                                    .map(
                                        (text) =>
                                            `<div id="selected-menu"><p id="selected-menu-text">` +
                                            text +
                                            "</p></div>"
                                    )
                                    .join(" ");
                                return (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        {ReactHtmlParser(moreText)}
                                    </div>
                                );
                            }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "right",
                                },
                                keepMounted: true,
                                getContentAnchorEl: null,

                                PaperProps: {
                                    style: {
                                        maxHeight: 400,
                                        minWidth: "200px",
                                    },
                                },
                            }}
                        >
                            {addonData.length > 0 &&
                                addonData.map((item) => {
                                    return (
                                        <MenuItem
                                            color="primary"
                                            key={item.addon_id}
                                            value={item.addon_id}
                                        >
                                            <Checkbox
                                                color="primary"
                                                checked={
                                                    categoriesColumn[index].items.indexOf(
                                                        item.addon_id
                                                    ) > -1
                                                }
                                            />
                                            <ListItemText primary={item.addon_name} />
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                        {validateCategoryItemsError(index) && (
                            <FormHelperText style={{ color: "red", fontSize: " 0.875em" }}>
                                Please select at least one item
                            </FormHelperText>
                        )}
                    </FormControl>
                </Form.Group>
                {index > 0 && (
                    <Button
                        startIcon={<DeleteIcon />}
                        style={{
                            backgroundColor: "red",
                            color: "white",
                            textTransform: "none",
                        }}
                        onClick={() => {
                            // remove current column
                            const newCategoriesColumn = categoriesColumn.filter(
                                (column, i) => i !== index
                            );
                            setCategoriesColumn(newCategoriesColumn);
                            sessionStorage.setItem(
                                "categories_column",
                                JSON.stringify(newCategoriesColumn)
                            );
                        }}
                        variant="contained"
                        className="mt-2"
                        component="label"
                    >
                        Delete Category
                    </Button>
                )}
            </div>
        ));
    };
    const labelHelper = (label) => {
        return (
            <Form.Label>
                <small style={{ color: "grey" }}>{label}</small>
            </Form.Label>
        );
    };

    useEffect(() => {
        const section = document.getElementById("scroll-here");
        section.scrollIntoView({ behavior: "smooth", block: "center" });
        console.log("scrolllll");
    }, []);

    return (
        <div className="step-container slide-right" id="scroll-here">
            <h4 style={{ fontWeight: "500" }}>Item Configuration</h4>
            <Form.Label className="pt-2">Does this product have items?</Form.Label>
            <CustomWidthTooltip
                arrow
                PopperProps={{
                    sx: {
                        "& .MuiTooltip-tooltip": {
                            border: "solid 1px",
                            backgroundColor: "#f5f5f5",
                            borderRadius: "6px",
                            borderColor: "black",
                            color: "black",
                        },
                    },
                }}
                title={
                    <p>
                        Items refer to sub-products which are components of the main product.
                        <br />
                        <br />
                        Example 1: A meal plan (product) has a meat dish (item) and a drink (item).
                        <br />
                        <br />
                        Example 2: A gym subscription (product) comes with a free body consultation
                        (item), 30 class credits (item) and meal planning (item).
                    </p>
                }
                placement="right"
            >
                <InfoIcon className="ml-2 mb-1" />
            </CustomWidthTooltip>{" "}
            <div style={{ display: "flex", displayDirection: "row" }}>
                <Form.Check
                    onChange={(event) => {
                        setItemChecker(true);
                        sessionStorage.setItem("item_checker", true);
                    }}
                    checked={itemChecker}
                    type={"radio"}
                    label={"Yes"}
                />{" "}
                <Form.Check
                    onChange={(event) => {
                        setItemChecker(false);
                        sessionStorage.setItem("item_checker", false);
                    }}
                    className="ml-4"
                    checked={!itemChecker}
                    type={"radio"}
                    label={"No"}
                />
            </div>
            {itemChecker && (
                <>
                    <h5 style={{ fontWeight: "450" }} className="pt-3 mb-0">
                        Categories
                        <CustomWidthTooltip
                            arrow
                            PopperProps={{
                                sx: {
                                    "& .MuiTooltip-tooltip": {
                                        border: "solid 1px",
                                        backgroundColor: "#f5f5f5",
                                        borderRadius: "6px",
                                        borderColor: "black",
                                    },
                                },
                            }}
                            title={
                                <div
                                    className="p-3"
                                    style={{
                                        color: "black",
                                        backgroundColor: "#f5f5f5",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <p>
                                        Categories will appear on the product details page as shown
                                        below.
                                    </p>
                                    <div className="pt-3" style={{ width: "100%" }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                displayDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Skeleton
                                                className="d-flex justify-content-center"
                                                variant="circular"
                                                width={20}
                                                height={20}
                                            >
                                                1
                                            </Skeleton>
                                            <Skeleton
                                                variant="text"
                                                width={40}
                                                height={5}
                                                style={{
                                                    margin: " auto .8em",
                                                }}
                                            />

                                            <Skeleton
                                                className="d-flex justify-content-center"
                                                variant="circular"
                                                width={20}
                                                height={20}
                                            >
                                                2{" "}
                                            </Skeleton>

                                            <Skeleton
                                                variant="text"
                                                width={40}
                                                height={5}
                                                style={{
                                                    margin: " auto .8em",
                                                }}
                                            />

                                            <Skeleton
                                                className="d-flex justify-content-center"
                                                variant="circular"
                                                width={20}
                                                height={20}
                                            >
                                                3
                                            </Skeleton>
                                        </div>
                                        <div>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "100px",
                                                    marginTop: "1em",
                                                    backgroundColor: "white",
                                                    borderRadius: "6px",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                }}
                                            >
                                                <Skeleton
                                                    style={{
                                                        marginTop: "auto",
                                                        marginBottom: "auto",
                                                        marginLeft: "1em",
                                                    }}
                                                    variant="rectangular"
                                                    width={70}
                                                    height={70}
                                                />
                                                <div
                                                    style={{
                                                        marginTop: "auto",
                                                        marginBottom: "auto",
                                                        marginLeft: "1em",
                                                    }}
                                                >
                                                    <Skeleton variant="text" width={180} />
                                                    <Skeleton variant="text" width={70} />
                                                    <Skeleton variant="text" width={70} />
                                                    <Skeleton variant="text" width={100} />
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: "1em",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <p className="w-100" style={{ marginBottom: "0" }}>
                                                    Category Name 1
                                                </p>
                                                <div className="d-flex flex-row">
                                                    <div
                                                        style={{
                                                            width: "50%",
                                                            height: "50px",
                                                            marginTop: "1em",
                                                            backgroundColor: "white",
                                                            borderRadius: "6px",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                        }}
                                                    >
                                                        <Skeleton
                                                            style={{
                                                                marginTop: "auto",
                                                                marginBottom: "auto",
                                                                borderRadius: "6px 0px 0px 6px",
                                                            }}
                                                            variant="rectangular"
                                                            width={50}
                                                            height={50}
                                                        />
                                                        <div className="mt-1">
                                                            <p className="ml-2">Item Name 1</p>
                                                            <Skeleton
                                                                variant="text"
                                                                className="ml-2"
                                                                width={70}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                className="ml-2"
                                                                width={35}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "50%",
                                                            height: "50px",
                                                            marginTop: "1em",
                                                            backgroundColor: "white",
                                                            borderRadius: "6px",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                        }}
                                                        className="ml-3"
                                                    >
                                                        <Skeleton
                                                            style={{
                                                                marginTop: "auto",
                                                                marginBottom: "auto",
                                                                borderRadius: "6px 0px 0px 6px",
                                                            }}
                                                            variant="rectangular"
                                                            width={50}
                                                            height={50}
                                                        />
                                                        <div className="mt-1">
                                                            <p className="ml-2">Item Name 2</p>
                                                            <Skeleton
                                                                variant="text"
                                                                className="ml-2"
                                                                width={70}
                                                            />
                                                            <Skeleton
                                                                variant="text"
                                                                className="ml-2"
                                                                width={35}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        marginTop: "1em",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <p
                                                        className="w-100"
                                                        style={{ marginBottom: "0" }}
                                                    >
                                                        Category Name 2
                                                    </p>
                                                    <div className="d-flex flex-row">
                                                        <div
                                                            style={{
                                                                width: "50%",
                                                                height: "50px",
                                                                marginTop: "1em",
                                                                backgroundColor: "white",
                                                                borderRadius: "6px",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                            }}
                                                        >
                                                            <Skeleton
                                                                style={{
                                                                    marginTop: "auto",
                                                                    marginBottom: "auto",
                                                                    borderRadius: "6px 0px 0px 6px",
                                                                }}
                                                                variant="rectangular"
                                                                width={50}
                                                                height={50}
                                                            />
                                                            <div className="mt-1">
                                                                <p className="ml-2">Item Name 3</p>
                                                                <Skeleton
                                                                    variant="text"
                                                                    className="ml-2"
                                                                    width={70}
                                                                />
                                                                <Skeleton
                                                                    variant="text"
                                                                    className="ml-2"
                                                                    width={35}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: "50%",
                                                                height: "50px",
                                                                marginTop: "1em",
                                                                backgroundColor: "white",
                                                                borderRadius: "6px",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                            }}
                                                            className="ml-3"
                                                        >
                                                            <Skeleton
                                                                style={{
                                                                    marginTop: "auto",
                                                                    marginBottom: "auto",
                                                                    borderRadius: "6px 0px 0px 6px",
                                                                }}
                                                                variant="rectangular"
                                                                width={50}
                                                                height={50}
                                                            />
                                                            <div className="mt-1">
                                                                <p className="ml-2">Item Name 4</p>
                                                                <Skeleton
                                                                    variant="text"
                                                                    className="ml-2"
                                                                    width={70}
                                                                />
                                                                <Skeleton
                                                                    variant="text"
                                                                    className="ml-2"
                                                                    width={35}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            placement="right"
                        >
                            <InfoIcon className="ml-2 mb-1" />
                        </CustomWidthTooltip>
                    </h5>
                    {labelHelper("Categories classify items into sections.")}
                    {renderCategoriesColumn()}
                    <div className="pt-2">
                        {categoriesColumn[0].items.length > 0 && (
                            <Button
                                startIcon={<AddIcon />}
                                style={{
                                    backgroundColor: store.getState().colorState.color,
                                    borderColor: store.getState().colorState.color,
                                    color: store.getState().colorState.font_color,
                                    textTransform: "none",
                                }}
                                onClick={() => {
                                    const newColumns = [
                                        ...categoriesColumn,
                                        {
                                            name: "",
                                            items: [],
                                        },
                                    ];
                                    // add another column in categoriesColumn
                                    setCategoriesColumn([
                                        ...categoriesColumn,
                                        { name: "", items: [] },
                                    ]);
                                    sessionStorage.setItem(
                                        "categories_column",
                                        JSON.stringify(newColumns)
                                    );
                                }}
                                variant="contained"
                                component="label"
                            >
                                Add Category
                            </Button>
                        )}
                        <Button
                            startIcon={<AddIcon />}
                            style={{
                                backgroundColor: store.getState().colorState.color,
                                borderColor: store.getState().colorState.color,
                                color: store.getState().colorState.font_color,
                                marginLeft:
                                    categoriesColumn[0].items.length > 0 &&
                                    window.innerWidth > 961 &&
                                    ".9em",
                                textTransform: "none",
                            }}
                            onClick={() => {
                                setOpenItemCreation(true);
                            }}
                            variant="contained"
                            component="label"
                            className={`${window.innerWidth < 961 && "mt-2  w-50"}`}
                        >
                            Add Item
                        </Button>
                        <Modal
                            open={openItemCreation}
                            onClose={() => {
                                setOpenItemCreation(false);
                            }}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            style={{ overflow: "scroll" }}
                        >
                            <Box
                                style={{
                                    width: window.innerWidth < 991 ? "80vw" : "50vw",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    backgroundColor: "white",
                                    borderRadius: "6px",
                                }}
                            >
                                <CreateItem handleModalClose={() => setOpenItemCreation(false)} />
                            </Box>
                        </Modal>
                    </div>
                    {categoriesColumn[0].items.length > 0 && renderCategoryRestrictions()}
                </>
            )}
        </div>
    );
};

export default ItemStep;
