/* eslint-disable jsx-a11y/alt-text */
import { Container, Col, Card, Row, Button } from "react-bootstrap";
import no_image from "../../Images/no-image.svg";
import "./productGridView.css";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import NotificationModal from "../NotificationModal/NotificationModal";
import { useState } from "react";
import planService from "../../services/planService";
import Swal from "sweetalert2";
import ListingKebabOptions from "../ListingKebabOptions";
import { useSelector } from "react-redux";
import { store } from "../../index.js";
import { useHistory } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { Chip } from "@material-ui/core";
import { CatchingPokemonSharp } from "@mui/icons-material";

const ProductGridView = ({ planData, addonData }) => {
    const isLoggedIn = useSelector((state) => state.loggedInUserReducer.userData);
    const history = useHistory();

    const renderImage = (path) => {
        const imagePath =
            window.location.protocol +
            "//" +
            window.location.hostname +
            process.env.REACT_APP_SITE_PORT_BACKEND +
            path;
        return imagePath;
    };
    const renderCarouselItem = (card) => {
        if (card.plan_image.length > 0) {
            return card.plan_image.map((image, index) => {
                return (
                    <Carousel.Item style={{}} key={index}>
                        <div style={{ position: "absolute", top: "12px", left: "12px" }}>
                            {card?.product_tags.map((tag) => {
                                return (
                                    <Chip
                                        style={{
                                            borderRadius: "2px",
                                            backgroundColor:
                                                store.getState().colorState.secondary_color,
                                            color: "white",
                                            textTransform: "uppercase",
                                            margin: ".5em .5em .5em 0em",
                                        }}
                                        label={tag}
                                    />
                                );
                            })}
                        </div>

                        <Card.Img
                            onClick={() => history.push("/product/" + card.plan_id)}
                            variant="top"
                            className="card-image-design"
                            src={renderImage(image.image_name)}
                        />
                    </Carousel.Item>
                );
            });
        } else {
            return (
                <Carousel.Item>
                    <Card.Img variant="top" className="card-image-design" src={no_image} />
                </Carousel.Item>
            );
        }
    };
    const lowestVariableFee = (plan_detail) => {
        // check through categories_item, filter through each data and compare price
        // if variant exist, get the lowest price variant
        // if variant does not exist, get addon price

        if (addonData !== undefined) {
            var cheapestItem = 999999999.0;

            // 2 conditions
            // 1. if apply_to === "individual", count every category's item and get lowest price
            // 2. if apply_to === "all", depending on the quantity restrictions number, get the smallest value from each category
            if (plan_detail.quantity_restrictions?.apply_to === "all" && addonData.data) {
                // go through all categories and grab the cheapest price item
                plan_detail?.categories_item?.categories.map((category) => {
                    // find the cheapest item through all the categories
                    console.log(addonData,'this is the addon in cheapeast item');
		    category?.items.map((item) => {
                        addonData.addons !== undefined &&
                            addonData.addons.filter((addon) => {
                                if (addon.addon_id === item) {
                                    if (addon.variant_exist) {
                                        // loop through variant detail and get the lowest price
                                        addon.variant_detail.forEach(
                                            (detail) =>
                                                (cheapestItem =
                                                    parseFloat(detail.price) < cheapestItem
                                                        ? parseFloat(detail.price).toFixed(2)
                                                        : cheapestItem)
                                        );
                                    } else if (!addon.variant_exist) {
                                        // compare with addon price
                                        cheapestItem =
                                            addon.addon_price < cheapestItem
                                                ? addon.addon_price
                                                : cheapestItem;
                                    }
                                }
                            });
                    });

                    cheapestItem =
                        cheapestItem * plan_detail.quantity_restrictions.category[0].number;
                    return `From RM${cheapestItem}`;
                });
            } else if (plan_detail.quantity_restrictions?.apply_to === "individual") {
                plan_detail?.categories_item?.categories.map((category) => {
                    // only validate categories with restriction
                    // cheapest item times each categories' restriction
                    // store in array and get min amount in array
                    var currentRestriction = plan_detail.quantity_restrictions.category.filter(
                        (cat) => cat.category === category.name
                    );
                    if (currentRestriction.length > 0) {
                        category?.items.map((item) =>
                            addonData?.addons?.filter((addon) => {
                                if (addon.addon_id === item) {
                                    if (addon.variant_exist) {
                                        // loop through variant detail and get the lowest price
                                        addon.variant_detail.forEach(
                                            (detail) =>
                                                (cheapestItem =
                                                    parseFloat(detail.price) *
                                                        currentRestriction[0].number <
                                                    cheapestItem
                                                        ? parseFloat(detail.price).toFixed(2)
                                                        : cheapestItem)
                                        );
                                    } else if (!addon.variant_exist) {
                                        // compare with addon price
                                        cheapestItem =
                                            addon.addon_price * currentRestriction[0].number <
                                            cheapestItem
                                                ? addon.addon_price
                                                : cheapestItem;
                                    }
                                }
                            })
                        );
                    }
                });
                return `From RM${cheapestItem}`;
            } else {
                plan_detail?.categories_item?.categories.map((category) => {
                    // find the cheapest item through all the categories
                    category?.items.map((item) => {
                        addonData.addons !== undefined &&
                            addonData.addons.filter((addon) => {
                                if (addon.addon_id === item) {
                                    if (addon.variant_exist) {
                                        // loop through variant detail and get the lowest price
                                        addon.variant_detail.forEach(
                                            (detail) =>
                                                (cheapestItem =
                                                    parseFloat(detail.price) < cheapestItem
                                                        ? parseFloat(detail.price).toFixed(2)
                                                        : cheapestItem)
                                        );
                                        console.log(cheapestItem, "cheapest item");
                                    } else if (!addon.variant_exist) {
                                        // compare with addon price
                                        cheapestItem =
                                            addon.addon_price < cheapestItem
                                                ? addon.addon_price
                                                : cheapestItem;
                                    }
                                }
                            });
                    });
                });
                return `From RM${cheapestItem}`;
            }
        }
    };
    return (
        <>
            <Row
                sm={1}
                xs={1}
                md={2}
                style={{ marginBottom: "4em" }}
                lg={isLoggedIn && store.getState().sidebarState.isSidebarOpen === true ? 3 : 4}
            >
                {planData.map((card) => {
                    return (
                        <Col className="card-layout hover-card">
                            <Card className="card-design">
                                <Carousel interval={null}>{renderCarouselItem(card)}</Carousel>
                                <Card.Body
                                    className="p-2 pl-3"
                                    style={{
                                        height: "16.9em",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <Card.Title
                                        onClick={() => history.push("/product/" + card.plan_id)}
                                    >
                                        {card.plan_name}
                                    </Card.Title>
                                    <Card.Subtitle
                                        onClick={() => history.push("/product/" + card.plan_id)}
                                        className="mt-3 mb-3"
                                    >
                                        {console.log(card, "card in homepage")}
                                        {card.pricing_model === "flat_fee" &&
                                            `From RM${card.plan_price.toFixed(2)}`}
                                        {card.pricing_model === "variable_fee" &&
                                            `${lowestVariableFee(card)}`}
                                    </Card.Subtitle>
                                    <Card.Text
                                        onClick={() => history.push("/product/" + card.plan_id)}
                                        className="pb-2"
                                    >
                                        {card.plan_summary}
                                    </Card.Text>
                                    {isLoggedIn && isLoggedIn.userRole === "merchant" && (
                                        <Col style={{ display: "flex" }}>
                                            <Button
                                                style={{
                                                    fontSize: ".9rem",
                                                    textTransform: "none",
                                                    backgroundColor:
                                                        store.getState().colorState.color,
                                                    borderColor: store.getState().colorState.color,
                                                    color: store.getState().colorState.font_color,
                                                    marginTop: "auto",
                                                }}
                                                onClick={() => {
                                                    history.push("/admin/product/" + card.plan_id);
                                                    console.log(card, "this is card detail");
                                                }}
                                                className="ml-0 mb-2 w-50"
                                            >
                                                <EditIcon style={{ fontSize: "1.3em" }} /> Edit
                                            </Button>
                                            <Button
                                                style={{
                                                    fontSize: ".9rem",
                                                    textTransform: "none",
                                                    backgroundColor:
                                                        store.getState().colorState.color,
                                                    borderColor: store.getState().colorState.color,
                                                    color: store.getState().colorState.font_color,
                                                    marginTop: "auto",
                                                }}
                                                className="ml-0 mb-2 w-50"
                                                onClick={() => {
                                                    Swal.fire({
                                                        title: "Are you sure you want to archive this plan?",
                                                        text: "You won't be able to revert this!",
                                                        icon: "warning",
                                                        reverseButtons: true,
                                                        showCancelButton: true,
                                                        reverseButtons: true,
                                                        confirmButtonColor:
                                                            store.getState().colorState.color,
                                                        cancelButtonColor: "white",
                                                        confirmButtonText: "Archive",
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            planService
                                                                .archivePlan(card.plan_id)
                                                                .then((res) => {
                                                                    Swal.fire({
                                                                        title: "Product archived",
                                                                        text: "Your plan has been successfully archived.",
                                                                        confirmButtonColor:
                                                                            store.getState()
                                                                                .colorState.color,
                                                                        iconColor:
                                                                            store.getState()
                                                                                .colorState.color,
                                                                        icon: "success",
                                                                        confirmButtonText: "Ok",
                                                                    }).then(() => {
                                                                        window.location.reload();
                                                                    });
                                                                })
                                                                .catch((res) => {
                                                                    Swal.fire({
                                                                        title: "Error Occurs!",
                                                                        text: res.message,
                                                                        icon: "error",
                                                                        confirmButtonText: "OK",
                                                                    });
                                                                });
                                                        }
                                                    });
                                                }}
                                            >
                                                <ArchiveIcon style={{ fontSize: "1.3em" }} />{" "}
                                                Archive
                                            </Button>
                                        </Col>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    );
                })}
            </Row>
        </>
    );
};

export default ProductGridView;
