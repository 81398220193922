import { callAPI } from "../../utils/apiUtils";
import axios from "axios";
import generalFunctionService from "../generalFunctionService";

const planService = {
    createPlan: (dataBody) => {
        var formData = generalFunctionService.convertJsonToFormData(dataBody);
        return new Promise((resolve, reject) => {
            //reject("GG");
            axios
                .post(
                    `${window.location.protocol + "//" + window.location.hostname}${
                        process.env.REACT_APP_SITE_PORT_BACKEND
                    }/catalog/plans/`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    editPlan: (dataBody, planId) => {
        var formData = generalFunctionService.convertJsonToFormData(dataBody);
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/plans/${planId}`, "put", formData)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getSinglePlan: (planId) => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/plans/${planId}`, "get")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getAllPlansWithoutPagination: (statusFilter = []) => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/plans?${statusFilter.map((s) => "&status=" + s).join("")}`, "get")
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    createDeliveryWindow: (dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/delivery_window/`, "post", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    patchDeliveryWindow: (deliveryWindowId, dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/delivery_window/${deliveryWindowId}`, "patch", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    getPlanListing: (pageIndex = 1, search = "") => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/plans/list?page=${pageIndex}&search=${search}&plan_status__in=active`)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    deletePlan: (planId) => {
        const dataBody = {
            plan_status: "deleted",
        };
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/plans/${planId}`, "delete", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    updatePlan: (planId, dataBody) => {
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/plans/${planId}`, "put", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    archivePlan: (planId) => {
        const dataBody = {
            plan_status: "archived",
        };
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/plans/${planId}`, "patch", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    reactivatePlan: (planId) => {
        const dataBody = {
            plan_status: "active",
        };
        return new Promise((resolve, reject) => {
            callAPI(`/catalog/plans/${planId}`, "patch", dataBody)
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
    // TODO: Integrate to a better flow
    instantPay: () => {
        return new Promise((resolve, reject) => {
            callAPI(
                `https://demo.curlec.com/new-mandate?referenceNumber=M006&effectiveDate=2020-11-11&expiryDate=&amount=1000.00&frequency=MONTHLY&maximumFrequency=99&purposeOfPayment=Loans&businessModel=B2C&name=Joe&emailAddress=test@test.com&phoneNumber=&idType=BUSINESS_REGISTRATION_NUMBER&idValue=88585858599&bankId=19&linkId=Notes&merchantId=1&employeeId=2&method=02&details=%5B%7B%22detailsNumber%22%3A%22DT0001%22%2C%22detailsDescription%22%3A%22DetailsOne%22%2C%22detailsAmount%22%3A%2210.00%22%7D%2C%7B%22detailsNumber%22%3A%22DT0002%22%2C%22detailsDescription%22%3A%22DetailsTwo%22%2C%22detailsAmount%22%3A%2220.00%22%7D%5D

            `,
                "get"
            )
                .then((response) => {
                    resolve(response);
                })
                .catch((response) => reject(response));
        });
    },
};

export default planService;
