import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { Grid } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { ButtonGroup, Form, InputGroup, Modal, Button } from "react-bootstrap";
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { urlAction } from "../../action";
import axios from "axios";
import { toggleLoginState } from "../../action.js";
import { store } from "../../index.js";
import customerService from "../../services/customerService";
import merchantService from "../../services/merchantService";
import planService from "../../services/planService";
import profileService from "../../services/profileService";
import userService from "../../services/userService";
import SignIn from "../../Images/undraw_secure_login_pdn4.svg";
import SignUp from "../../Images/undraw_online_ad_re_ol62.svg";
import ForgotPass from "../../Images/undraw_forgot_password_re_hxwm.svg";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useHistory } from "react-router-dom";
import "./style.css";
const LoginModal = ({ show, handleClose }) => {
    const history = useHistory();
    const forgotPasswordLink =
        String(window.location.origin).split("/")[0] + "/backend/api/password_reset/";
    const [passwordSent, setPasswordSent] = useState(false);
    const [merchant, setMerchant] = useState({});
    const [tabState, setTabState] = useState("sign in");
    const [vajraCountry, setVajraCountry] = useState();
    const [vajraLanguage, setVajraLanguage] = useState();
    const [vajraKecharaMembership, setVajraKecharaMembership] = useState();
    const [signInUsername, setSignInUsername] = useState({
        value: "",
        valid: true,
    });
    const [signInPassword, setSignInPassword] = useState({
        value: "",
        valid: true,
    });
    const [registerNRIC, setRegisterNRIC] = useState({
        value: "",
        valid: true,
    });

    const [registerEmail, setRegisterEmail] = useState({
        value: "",
        valid: true,
    });
    const [registerPassword, setRegisterPassword] = useState({
        value: "",
        valid: true,
    });
    const [registerCPassword, setRegisterCPassword] = useState({
        value: "",
        valid: true,
    });
    const [registerFName, setRegisterFName] = useState({
        value: "",
        valid: true,
    });
    const [registerLName, setRegisterLName] = useState({
        value: "",
        valid: true,
    });
    const [registerBday, setRegisterBday] = useState({
        value: "",
        valid: true,
    });
    const [registerPhoneNum, setRegisterPhoneNum] = useState({
        value: "",
        valid: true,
    });
    const [registerGender, setRegisterGender] = useState({
        value: "",
        valid: true,
    });
    const [registerFestival, setRegisterFestival] = useState({
        value: "",
        valid: true,
    });
    const [registerDeliveryAddr, setRegisterDeliveryAddr] = useState({
        value: "",
        valid: true,
    });
    const [registerPostcode, setRegisterPostcode] = useState({
        value: "",
        valid: true,
    });
    const [registerDistrict, setRegisterDistrict] = useState({
        value: "",
        valid: true,
    });
    const [registerConsent, setRegisterConsent] = useState({ value: true });
    const [registerDiscovery, setRegisterDiscovery] = useState({ value: "" });
    const [registerDeliveryInstruction, setRegisterDeliveryInstruction] = useState({ value: "" });
    const [deliverableSection, setDeliverableSection] = useState(false);
    const dispatch = useDispatch();

    const [loadingBar, setLoadingBar] = useState(false);
    const [countryData, setCountryData] = useState({});

    let theme = createTheme();
    theme = responsiveFontSizes(theme);

    useEffect(() => {
        fetch("https://restcountries.com/v3.1/all")
            .then((res) => {
                res.json().then((data) => {
                    const sortCountryName = data.sort((a, b) =>
                        a.name.official > b.name.official ? 1 : -1
                    );
                    const countryInfo = sortCountryName.map((country) => {
                        return [country.name.official, country.flags.png];
                    });
                    setCountryData({ value: countryInfo });
                });
            })
            .catch((data, status) => {
                console.log("Request failed:", data, status);
            });
    }, []);

    useEffect(() => {
        const splitURL = urlAction("get_merchant");
        merchantService.getMerchantInfo().then((e) => {
            let merchantInfo = e;
            let merchantName = merchantInfo
                .filter((obj) => obj.merch_name === splitURL)
                .map((obj) => {
                    return obj.merch_name;
                });
            setMerchant(merchantName);
        });
        planService.getAllPlansWithoutPagination(["active"]).then((e) => {
            let allPlan = e.plans;
            // filter through allPlan's is_shippable field, if there is a true return true, if no true, return false
            let isShippable = allPlan.filter((obj) => obj.is_shippable === true);
            if (isShippable.length > 0) {
                setDeliverableSection(true);
            }
        });
        planService.getAllPlansWithoutPagination(["active"]).then((e) => {
            let allPlan = e.plans;
            // filter through allPlan's is_shippable field, if there is a true return true, if no true, return false
            let isShippable = allPlan.filter((obj) => obj.is_shippable === true);
            if (isShippable.length > 0) {
                setDeliverableSection(true);
            }
        });
    }, []);
    const toggleLoggedIn = () => {
        dispatch(toggleLoginState());
    };
    const login = async () => {
        if (validateLoginForm()) {
            await userService
                .login(signInUsername.value, signInPassword.value)
                .then((userData) => {
                    // if window location href has plan in it
                    // if (window.location.href.indexOf("plan") > -1) {
                    //     sessionStorage.setItem("stepper", 3);
                    // }
                    handleClose();
                    console.log(userData, "userData");
                    window.location.reload();
                })
                .catch((res) => {
                    Swal.fire({
                        icon: "error",
                        confirmButtonColor: store.getState().colorState.color,
                        title: "Username or password is wrong.",
                        text: "Username or password is wrong. Please try again later.",
                    });
                });
        }
    };

    const validateLoginForm = () => {
        var isValid = true;

        isValid = isValid && validateUsernameLogin(signInUsername.value);
        isValid = isValid && validatePasswordLogin(signInPassword.value);

        return isValid;
    };

    const validateUsernameLogin = (newValue) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        //Check empty
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Username cannot be empty";
            isValid = false;
        }

        setSignInUsername({ ...signInUsername, error: errorMessage }); //Set Error message

        return isValid;
    };

    const validatePasswordLogin = (newValue) => {
        var isValid = true;

        //Reset the Error message
        var errorMessage = "";

        //Check empty
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Password cannot be empty";
            isValid = false;
        }

        setSignInPassword({ ...signInPassword, error: errorMessage }); //Set Error message

        return isValid;
    };

    const resetPassword = () => {
        var data = { email: registerEmail.value };
        customerService
            .forgotPassword(data)
            .then((res) => {
                console.log(res, "account exist");
                Swal.fire({
                    icon: "success",
                    confirmButtonColor: store.getState().colorState.color,
                    title: "Please check your email to reset your password",
                    timer: 5000,
                }).then(() => {
                    window.location.reload();
                });
            })
            .catch((res) => {
                console.log(res, "account doesnt exist");

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    confirmButtonColor: store.getState().colorState.color,
                    text: "Email doesn't exist in the system. Please try again.",
                }).then((result) => {
                    if (result.isConfirmed) {
                    }
                });
            });
    };
    const register = () => {
        var dataBody = {
            username: registerEmail.value,
            password: registerPassword.value,
            cust_fname: registerFName.value,
            cust_lname: registerLName.value,
            cust_contact_no: registerPhoneNum.value,
            cust_email: registerEmail.value,
        };
        // not required
        if (registerDeliveryAddr.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_addr_line_1: registerDeliveryAddr.value,
            };
        }
        if (registerPostcode.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_addr_postcode: registerPostcode.value,
            };
        }
        if (registerDeliveryInstruction.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_delivery_instructions: registerDeliveryInstruction.value,
            };
        }
        if (registerBday.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_birthday: new moment(registerBday.value).toISOString(),
            };
        }
        if (registerConsent.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_consent: registerConsent.value,
            };
        }
        if (registerGender.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_gender: registerGender.value,
            };
        }
        if (registerFestival.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_festival: registerFestival.value,
            };
        }
        if (registerDiscovery.value !== undefined) {
            dataBody = {
                ...dataBody,
                cust_discovery: registerDiscovery.value,
            };
        }
        if (window.location.href.includes("vajrasecrets")) {
            dataBody = {
                ...dataBody,
                vajra_kechara_membership: vajraKecharaMembership,
                vajra_language: vajraLanguage,
                vajra_country: vajraCountry.value,
            };
        }
        if (registerPostcode.value === "" || registerPostcode.value === null) {
            // console.log(validateForm());
            if (validateForm()) {
                customerService
                    .createCustomer(dataBody)
                    .then((res) => {
                        userService
                            .login(dataBody.username, dataBody.password)
                            .then((userData) => {
                                Swal.fire({
                                    icon: "success",
                                    confirmButtonColor: store.getState().colorState.color,
                                    title: "Successfully created account",
                                    timer: 3000,
                                }).then((result) => {
                                    toggleLoggedIn();
                                    handleClose();

                                    if (window.location.href.indexOf("plan") > -1) {
                                        sessionStorage.setItem("stepper", 3);
                                    }

                                    // window.location.reload();
                                });
                            })
                            .catch((res) => {
                                Swal.fire({
                                    icon: "error",
                                    title: "Login Error",
                                    text: "Please try again later.",
                                    confirmButtonColor: store.getState().colorState.color,
                                }).then((result) => {});
                            });
                    })
                    .catch((res) => {
                        console.log(res, "this is the first");

                        Swal.fire({
                            icon: "error",
                            title: "Account creation error",
                            confirmButtonColor: store.getState().colorState.color,
                            text: "Please check input and try again later or contact us if the problem persists.",
                        }).then((result) => {
                            if (result.isConfirmed) {
                            }
                        });
                    });
            }
        } else {
            profileService
                .getPostcode(registerPostcode.value)
                .then((e) => {
                    if (validateForm()) {
                        customerService
                            .createCustomer(dataBody)
                            .then((res) => {
                                userService
                                    .login(dataBody.username, dataBody.password)
                                    .then((userData) => {
                                        Swal.fire({
                                            icon: "success",
                                            title: "Successfully created account",
                                            timer: 3000,
                                            confirmButtonColor: store.getState().colorState.color,
                                        }).then((result) => {
                                            toggleLoggedIn();
                                            handleClose();
                                            if (window.location.href.indexOf("plan") > -1) {
                                                sessionStorage.setItem("stepper", 3);
                                            }
                                            // window.location.reload();
                                        });
                                    })
                                    .catch((res) => {
                                        Swal.fire({
                                            icon: "error",
                                            title: "Login Error",
                                            confirmButtonColor: store.getState().colorState.color,
                                            text: "Please try again later.",
                                        }).then((result) => {});
                                    });
                            })
                            .catch((res) => {
                                console.log(res, "this is the second");
                                Swal.fire({
                                    icon: "error",
                                    title: "Account creation error",
                                    confirmButtonColor: store.getState().colorState.color,
                                    text: "Please check input and try again later or contact us if the problem persists.",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                    }
                                });
                            });
                    }
                    // set user data here
                })
                .catch((e) => {
                    var errorMessage = "Sorry, we do not deliver to this postcode";
                    var isValid = false;
                    setRegisterPostcode({
                        ...registerPostcode,
                        error: errorMessage,
                        valid: isValid,
                    });
                });
        }
    };

    const validateForm = () => {
        var isValid = true;

        isValid = validateRegisterEmail(registerEmail.value) && isValid;
        isValid = validateRegisterPassword(registerPassword.value) && isValid;
        isValid = validateRegisterCPassword(registerCPassword.value) && isValid;
        isValid = validateRegisterFName(registerFName.value) && isValid;
        isValid = validateRegisterLName(registerLName.value) && isValid;
        isValid = validateRegisterPhoneNum(registerPhoneNum.value) && isValid;
        if (registerDeliveryAddr.value !== "") {
            isValid = validateRegisterDeliveryAddr(registerDeliveryAddr.value) && isValid;
        }

        if (registerBday.value !== "") {
            isValid = validateRegisterBday(registerBday.value) && isValid;
        }

        if (registerNRIC.value === "" && merchant === "farcapital") {
            isValid = validateRegisterNRIC(registerNRIC.value) && isValid;
        }

        return isValid;
    };

    const validateRegisterNRIC = (nric) => {
        var isValid = true;
        var errorMessage = "";
        // regex malaysian ic and passport
        var regex = /^[A-Z]{1}[0-9]{7}[A-Z]{1}$/;
        if (!regex.test(nric)) {
            errorMessage = "Invalid NRIC/Passport No.";
            isValid = false;
        }
        setRegisterNRIC({
            ...registerNRIC,
            error: errorMessage,
            valid: isValid,
        });
        return isValid;
    };

    const validateRegisterEmail = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        var emailRegex = ".+@.+..+";
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Email field cannot be empty";
            isValid = false;
        }

        if (!newValue.match(emailRegex) && newValue !== "") {
            if (errorMessage) errorMessage += "\n";
            errorMessage += "\nEmail format is incorrect";
            isValid = false;
        }
        //TODO: email format
        setRegisterEmail({
            ...registerEmail,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };

    const validateRegisterPassword = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Password field cannot be empty";
            isValid = false;
        }

        setRegisterPassword({
            ...registerPassword,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };

    const validateRegisterCPassword = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Password field cannot be empty";
            isValid = false;
        }
        if (newValue !== registerPassword.value) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Password must be the same";
            isValid = false;
        }

        setRegisterCPassword({
            ...registerCPassword,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };

    const validateRegisterFName = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "First name field cannot be empty";
            isValid = false;
        }

        setRegisterFName({
            ...registerFName,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };

    const validateRegisterLName = (newValue) => {
        var isValid = true;
        var errorMessage = "";

        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Last name field cannot be empty";
            isValid = false;
        }

        setRegisterLName({
            ...registerLName,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };

    const validateRegisterBday = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Birthday field cannot be empty";
            isValid = false;
        }
        if (newValue > moment().format("YYYY-MM-DD")) {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Birth date cannot exceed current date";
            isValid = false;
        }

        setRegisterBday({
            ...registerBday,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };

    const validateRegisterPhoneNum = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue === "") {
            if (!!errorMessage) errorMessage = "\n";
            errorMessage = "Contact number field cannot be empty";
            isValid = false;
        }

        //TODO: regex and 10 numbers
        var phoneNumberRegex = "^(601)[0-46-9]*[0-9]{8,9}$";
        if ((!newValue.match(phoneNumberRegex) && newValue.length < 11) || newValue.length > 12) {
            if (!!errorMessage) errorMessage = "\n";
            errorMessage =
                "Phone number must have at least 10 or 11 digits and starts off with '60' \n E.g: 60123456789";
            isValid = false;
        }
        setRegisterPhoneNum({
            ...registerPhoneNum,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };

    const [visible, setVisible] = useState(false);
    const [formType, setFormType] = useState("password");

    const handleFormType = () => {
        setVisible(!visible);

        {
            visible ? setFormType("password") : setFormType("text");
        }
    };

    const validateRegisterDeliveryAddr = (newValue) => {
        var isValid = true;
        var errorMessage = "";
        if (newValue === "") {
            if (!!errorMessage) errorMessage += "\n";
            errorMessage += "Address field cannot be empty";
            isValid = false;
        }

        setRegisterDeliveryAddr({
            ...registerDeliveryAddr,
            error: errorMessage,
            valid: isValid,
        }); //Set Error message

        return isValid;
    };
    if (loadingBar) {
        return <Spinner className="mt-5" animation="border" />;
    } else {
        return (
            <Modal
                size="lg"
                dialogClassName="modal-width"
                centered
                show={show}
                onHide={handleClose}
            >
                <Grid container>
                    {tabState === "sign in" && (
                        <>
                            <Grid container>
                                <Grid item lg={7} md={7} style={{ padding: "40px" }}>
                                    <ThemeProvider theme={theme}>
                                        <h3>Sign In</h3>
                                        <h4>
                                            Don't have an account ?
                                            <span
                                                className="header-link"
                                                onClick={() => {
                                                    setTabState("sign up");
                                                }}
                                            >
                                                Sign Up
                                            </span>
                                        </h4>
                                    </ThemeProvider>
                                    <Grid container>
                                        <Grid item xs={12} lg={12} className="mt-4">
                                            <Form>
                                                <Form.Group className="mb-3" controlId="email">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        onKeyUp={(e) => {
                                                            if (e.key === "Enter") {
                                                                login();
                                                            } else {
                                                                setSignInUsername({
                                                                    ...signInUsername,
                                                                    value: e.target.value,
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="password">
                                                    <Form.Label>Password</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type={formType}
                                                            onKeyUp={(e) => {
                                                                if (e.key === "Enter") {
                                                                    login();
                                                                } else {
                                                                    setSignInPassword({
                                                                        ...signInPassword,
                                                                        value: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <InputGroup.Text id="inputGroupPrepend">
                                                            {visible ? (
                                                                <VisibilityIcon
                                                                    onClick={() => handleFormType()}
                                                                />
                                                            ) : (
                                                                <VisibilityOffIcon
                                                                    onClick={() => handleFormType()}
                                                                />
                                                            )}
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Grid item xs={12} lg={12}>
                                                    <Grid container>
                                                        <Grid item align="left" xs={6} lg={6}>
                                                            <Form.Group controlId="remember">
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    id="remember"
                                                                    label="Remember Me"
                                                                />
                                                            </Form.Group>
                                                        </Grid>
                                                        <Grid item align="right" xs={6} lg={6}>
                                                            <span
                                                                className="header-link"
                                                                onClick={() => {
                                                                    setTabState("forgot password");
                                                                }}
                                                            >
                                                                Forgot Password ?
                                                            </span>
                                                        </Grid>
                                                        <Button
                                                            variant="contained"
                                                            style={{
                                                                backgroundColor:
                                                                    store.getState().colorState
                                                                        .color,
                                                                borderColor:
                                                                    store.getState().colorState
                                                                        .color,
                                                                color: store.getState().colorState
                                                                    .font_color,
                                                            }}
                                                            className="mt-3 btn-login"
                                                            onClick={() => {
                                                                login();
                                                            }}
                                                        >
                                                            Sign In
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Hidden smDown>
                                    <Grid
                                        item
                                        style={{
                                            backgroundColor: store.getState().colorState.color,
                                            borderColor: store.getState().colorState.color,
                                            color: store.getState().colorState.font_color,
                                        }}
                                        container
                                        lg={5}
                                        md={5}
                                        sm={5}
                                        className="grid-img"
                                    >
                                        <img src={SignIn} className="img-fluid modal-img" />
                                        <h6 className="mt-4">Sign In</h6>
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </>
                    )}
                    {tabState === "sign up" && (
                        <>
                            <Grid container>
                                <Grid item lg={7} md={7} style={{ padding: "40px" }}>
                                    <h3>Sign Up</h3>
                                    <h4>
                                        Got an account ?
                                        <span
                                            className="header-link"
                                            onClick={() => {
                                                setTabState("sign in");
                                            }}
                                        >
                                            Sign In
                                        </span>
                                    </h4>
                                    <Grid container>
                                        <Grid item xs={12} lg={12} className="mt-4">
                                            <Form>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} lg={6} sm={12}>
                                                        <Form.Group controlId="firstname">
                                                            <Form.Label>First Name</Form.Label>
                                                            <InputGroup hasValidation>
                                                                <Form.Control
                                                                    type="text"
                                                                    required
                                                                    isInvalid={
                                                                        registerFName.valid ===
                                                                        false
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onKeyUp={(e) => {
                                                                        if (e.key === "Enter") {
                                                                            register();
                                                                        } else {
                                                                            setRegisterFName({
                                                                                ...registerFName,
                                                                                value: e.target
                                                                                    .value,
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {registerFName.error}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Grid>
                                                    <Grid item xs={12} lg={6} sm={12}>
                                                        <Form.Group
                                                            className="mb-3"
                                                            controlId="lastname"
                                                        >
                                                            <Form.Label>Last Name</Form.Label>
                                                            <InputGroup hasValidation>
                                                                <Form.Control
                                                                    type="text"
                                                                    required
                                                                    isInvalid={
                                                                        registerLName.valid ===
                                                                        false
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onKeyUp={(e) => {
                                                                        if (e.key === "Enter") {
                                                                            register();
                                                                        } else {
                                                                            setRegisterLName({
                                                                                ...registerLName,
                                                                                value: e.target
                                                                                    .value,
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {registerFName.error}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Grid>
                                                </Grid>

                                                <Form.Group controlId="email" className="mb-3">
                                                    <Form.Label>Email</Form.Label>
                                                    <InputGroup hasValidation>
                                                        <Form.Control
                                                            type="email"
                                                            required
                                                            isInvalid={
                                                                registerEmail.valid === false
                                                                    ? true
                                                                    : false
                                                            }
                                                            onKeyUp={(e) => {
                                                                if (e.key === "Enter") {
                                                                    register();
                                                                } else {
                                                                    setRegisterEmail({
                                                                        ...registerEmail,
                                                                        value: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {registerEmail.error}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>

                                                <Form.Group controlId="phone" className="mb-3">
                                                    <Form.Label>Phone Number</Form.Label>
                                                    <InputGroup hasValidation>
                                                        <Form.Control
                                                            required
                                                            isInvalid={
                                                                registerPhoneNum.valid === false
                                                                    ? true
                                                                    : false
                                                            }
                                                            onKeyUp={(e) => {
                                                                if (e.key === "Enter") {
                                                                    register();
                                                                } else {
                                                                    setRegisterPhoneNum({
                                                                        ...registerPhoneNum,
                                                                        value: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {registerPhoneNum.error}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>

                                                <Form.Group controlId="password" className="mb-3">
                                                    <Form.Label>Password</Form.Label>
                                                    <InputGroup hasValidation>
                                                        <Form.Control
                                                            required
                                                            type={formType}
                                                            isInvalid={
                                                                registerPassword.valid === false
                                                                    ? true
                                                                    : false
                                                            }
                                                            onKeyUp={(e) => {
                                                                if (e.key === "Enter") {
                                                                    register();
                                                                } else {
                                                                    setRegisterPassword({
                                                                        ...registerPassword,
                                                                        value: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <InputGroup.Text id="inputGroupPrepend">
                                                            {visible ? (
                                                                <VisibilityIcon
                                                                    onClick={() => handleFormType()}
                                                                />
                                                            ) : (
                                                                <VisibilityOffIcon
                                                                    onClick={() => handleFormType()}
                                                                />
                                                            )}
                                                        </InputGroup.Text>
                                                        <Form.Control.Feedback type="invalid">
                                                            {registerPassword.error}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>

                                                <Form.Group
                                                    controlId="confirmpassw"
                                                    className="mb-3"
                                                >
                                                    <Form.Label>Confirm Password</Form.Label>
                                                    <InputGroup hasValidation>
                                                        <Form.Control
                                                            required
                                                            type={formType}
                                                            isInvalid={
                                                                registerCPassword.valid === false
                                                                    ? true
                                                                    : false
                                                            }
                                                            onKeyUp={(e) => {
                                                                if (e.key === "Enter") {
                                                                    register();
                                                                } else {
                                                                    setRegisterCPassword({
                                                                        ...registerCPassword,
                                                                        value: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <InputGroup.Text id="inputGroupPrepend">
                                                            {visible ? (
                                                                <VisibilityIcon
                                                                    onClick={() => handleFormType()}
                                                                />
                                                            ) : (
                                                                <VisibilityOffIcon
                                                                    onClick={() => handleFormType()}
                                                                />
                                                            )}
                                                        </InputGroup.Text>
                                                        <Form.Control.Feedback type="invalid">
                                                            {registerCPassword.error}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>

                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        backgroundColor:
                                                            store.getState().colorState.color,
                                                        borderColor:
                                                            store.getState().colorState.color,
                                                        color: store.getState().colorState
                                                            .font_color,
                                                    }}
                                                    className="mt-3 btn-signup"
                                                    onClick={() => {
                                                        register();
                                                    }}
                                                >
                                                    Sign Up
                                                </Button>
                                            </Form>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Hidden smDown>
                                    <Grid
                                        item
                                        lg={5}
                                        md={5}
                                        container
                                        style={{
                                            backgroundColor: store.getState().colorState.color,
                                            borderColor: store.getState().colorState.color,
                                            color: store.getState().colorState.font_color,
                                        }}
                                        className="grid-img"
                                    >
                                        <img src={SignIn} className="img-fluid modal-img" />
                                        <h6 className="mt-4">Sign Up</h6>
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </>
                    )}

                    {tabState === "forgot password" && (
                        <>
                            <Grid container>
                                <Grid item lg={6} md={6} style={{ padding: "30px" }}>
                                    <h3>Forgot Password</h3>
                                    <h4>Enter your email to reset your password</h4>
                                    <Grid container>
                                        <Grid item xs={12} lg={12} className="mt-4">
                                            <Form>
                                                <Form.Group className="mb-3" controlId="email">
                                                    <Form.Label>Email</Form.Label>
                                                    <InputGroup hasValidation>
                                                        <Form.Control
                                                            type="email"
                                                            required
                                                            isInvalid={
                                                                registerEmail.valid === false
                                                                    ? true
                                                                    : false
                                                            }
                                                            onKeyUp={(e) => {
                                                                if (e.key === "Enter") {
                                                                    register();
                                                                } else {
                                                                    setRegisterEmail({
                                                                        ...registerEmail,
                                                                        value: e.target.value,
                                                                    });
                                                                }
                                                            }}
                                                        />
			    				{passwordSent && <p style={{fontSize:".8rem", textAlign:"justify", color:"green", paddingTop:".5em"}}>A password reset email has been sent to the email address provided. Please check your inbox and follow the instructions in the email to reset your password.</p>}
                                                        <Form.Control.Feedback type="invalid">
                                                            {registerEmail.error}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>

                                                <Button
                                                    variant="contained"
                                                    className="mt-3 w-100 ml-0"
                                                    style={{
                                                        backgroundColor:
                                                            store.getState().colorState.color,
                                                        borderColor:
                                                            store.getState().colorState.color,
                                                        color: store.getState().colorState
                                                            .font_color,
                                                        textTransform: "none",
						    }}
						    onKeyDown={(event) => {
  							if (event.key === "Enter"){

							event.preventDefault();
						    }}}
                                                    onClick={() => {
                                                    	console.log('resetting password...');
						    	console.log(String(window.location.origin).split("/")[0]);
						    	const databody = {
								email: registerEmail.value
							} 
							axios
                .post(
                    `${window.location.protocol + "//" + window.location.hostname}${
                        process.env.REACT_APP_SITE_PORT_BACKEND
                    }/api/password_reset/`, databody
                )
                .then((res) => {
		    setPasswordSent(true);
                    console.log(res, "this is the res");
                })
                .catch((err) => {
		    setPasswordSent(true);
		    console.error(err, "this is the error");
		})}}
                                                >
                                                    Reset Password
                                                </Button>
                                            </Form>
                                        </Grid>
                                        <Grid item className="mt-5">
                                            <span
                                                className="header-link"
                                                onClick={() => {
                                                    setTabState("sign in");
                                                }}
                                            >
                                                {/* <ArrowBackIcon/> */}
                                                Back To Login
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Hidden smDown>
                                    <Grid
                                        item
                                        container
                                        lg={6}
                                        md={6}
                                        style={{
                                            backgroundColor: store.getState().colorState.color,
                                            borderColor: store.getState().colorState.color,
                                            color: store.getState().colorState.font_color,
                                        }}
                                        className="grid-img"
                                    >
                                        <img src={ForgotPass} className="img-fluid modal-img" />
                                        <h6 className="mt-4">Forgot your password ?</h6>
                                    </Grid>
                                </Hidden>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Modal>
        );
    }
};

export default LoginModal;
