// import all missing
import { FormControl, MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import { store } from "../../index.js";
import userService from "../../services/userService";
const LoginBar = () => {
    const [loadingBar, setLoadingBar] = useState(false);
    const history = useHistory();
    const forgotPasswordLink =
        String(window.location.origin).split("/")[0] +
        "/backend/api/password_reset/";
    const loginBarRedirect = async (option) => {
        switch (option) {
            case "profile":
                history.push("/customer/profile");
                break;
            case "change_password":
                return history.push(
                    String(window.location.origin).split("/")[0] +
                        "/backend/api/password_reset/"
                );

            case "logout":
                setLoadingBar(true);
                await userService.logout().then((res) => {
                    history.push("/");
                    window.location.reload();
                    setLoadingBar(false);
                });
                break;
            default:
                return null;
        }
    };
    const renderCustomerSideBar = () => {
        return (
            <>
                <FormControl className="mt-1 mr-1">
                    <AccountCircleIcon
                        onClick={() => {
                            localStorage.setItem("sidebar-state", "profile");
                            history.push("/customer/profile");
                        }}
                    />
                </FormControl>
                <FormControl className="mr-2">
                    <TextField
                        style={{ borderBottom: "none" }}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        id="select"
                        value="profile"
                        onChange={(e) => {
                            // get value and use cases
                            loginBarRedirect(e.target.value);
                        }}
                        select
                    >
                        <MenuItem value="profile">
                            {store
                                .getState()
                                .loggedInUserReducer.userData.ar_system.cust_fname.charAt(
                                    0
                                )
                                .toUpperCase() +
                                store
                                    .getState()
                                    .loggedInUserReducer.userData.ar_system.cust_fname.slice(
                                        1
                                    ) +
                                " " +
                                store
                                    .getState()
                                    .loggedInUserReducer.userData.ar_system.cust_lname.charAt(
                                        0
                                    )
                                    .toUpperCase() +
                                store
                                    .getState()
                                    .loggedInUserReducer.userData.ar_system.cust_lname.slice(
                                        1
                                    )}
                        </MenuItem>
                        <MenuItem value="change_password">
                            <a
                                style={{ color: "black", underline: "none" }}
                                href={forgotPasswordLink}
                                target="_blank"
                            >
                                Change Password
                            </a>
                        </MenuItem>
                        <MenuItem value="logout">Log Out</MenuItem>
                    </TextField>
                </FormControl>
            </>
        );
    };

    const renderMerchantSideBar = () => {
        return (
            <>
                <FormControl className="mt-1 mr-1">
                    <AccountCircleIcon
                        onClick={() => {
                            localStorage.setItem("sidebar-state", "settings");

                            history.push("/admin/profile");
                        }}
                    />
                </FormControl>
                <FormControl>
                    <TextField
                        style={{ borderBottom: "none" }}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        id="select"
                        value="profile"
                        onChange={(e) => {
                            // get value and use cases
                            loginBarRedirect(e.target.value);
                        }}
                        select
                    >
                        <MenuItem value="profile">
                            {store
                                .getState()
                                .loggedInUserReducer.userData.userRole.charAt(0)
                                .toUpperCase() +
                                store
                                    .getState()
                                    .loggedInUserReducer.userData.userRole.slice(
                                        1
                                    )}
                        </MenuItem>
                        <MenuItem value="logout">Log Out</MenuItem>
                    </TextField>
                </FormControl>
            </>
        );
    };
    if (loadingBar) {
        return <Spinner className="mt-5" animation="border" />;
    } else {
        return (
            <>
                {store.getState().loggedInUserReducer.userData?.userRole ===
                "customer"
                    ? renderCustomerSideBar()
                    : renderMerchantSideBar()}
            </>
        );
    }
};

export default LoginBar;
