import { Grid } from "@material-ui/core";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { store } from "../../../../index.js";
import customerService from "../../../../services/customerService";
import Swal from "sweetalert2";
const PasswordSettings = () => {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const editPassword = () => {
        if (newPassword !== confirmNewPassword){
            Swal.fire({
                icon: "error",
                title: "Error",
                confirmButtonColor: store.getState().colorState.color,
                text: "New passwords doesn't match",
            }).then((result) => {
                if (result.isConfirmed) {
                }
            });
        }else{
            var data = {
                username: 'merchant',
                oldPassword: oldPassword,
                newPassword: newPassword,
            };
            customerService
                .resetPassword(data)
                .then((res) => {
                    console.log(res, "password change successfull");
                    Swal.fire({
                        icon: "success",
                        confirmButtonColor: store.getState().colorState.color,
                        title: "Password has been updated successfully.",
                        timer: 5000,
                    }).then(() => {
                        window.location.reload();
                    });
                })
                .catch((res) => {
                    console.log(res, "account doesnt exist");
    
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        confirmButtonColor: store.getState().colorState.color,
                        text: "Current password entered is incorrect.",
                    }).then((result) => {
                        if (result.isConfirmed) {
                        }
                    });
                });
        }
    }
    return (
        <>
            {console.log(store, "this is user store in forgot password change")}
            <Grid container>
                <Grid className="mt-4 mb-2" align="left" item xs={6} md={6} lg={6}>
                    <h1
                        className="ml-lg-4 text-left color-contrast-dark"
                        style={{ color: "black" }}
                    >
                        Change Password
                    </h1>
                </Grid>

                <Grid
                    align="left"
                    className="ml-lg-4 container-fluid addon-form-container "
                    container
                    spacing={1}
                    lg={10}
                >
                    <Grid item xs={12} md={12} lg={12} className="p-3">
                        <Grid xs={12} md={6} lg={6}>
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control
                                value={oldPassword}
                                type="password"
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid xs={12} md={6} lg={6} className="pt-3">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                value={newPassword}
                                type="password"
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid xs={12} md={6} lg={6} className="pt-3">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control
                                value={confirmNewPassword}
                                type="password"
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className="pt-3" container>
                <Grid className="d-flex justify-content-end " item xs={6} md={6} lg={5}>
                    <Button
                        style={{
                            color: "black",
                        }}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Button
                        style={{
                            color: store.getState().colorState.font_color,
                            backgroundColor: store.getState().colorState.color,
                        }}
                        onClick={() =>
                            editPassword({ oldPassword: oldPassword, newPassword: newPassword })
                        }
                        className="d-flex"
                    >
                        Change Password
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
export default PasswordSettings;

