import { Button, Card, CardContent } from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import Chip from "@mui/material/Chip";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { Col, Form, Image, InputGroup, Row } from "react-bootstrap";
import "react-bootstrap-tagsinput/dist/index.css";
import CloseButton from "react-bootstrap/CloseButton";
import { useParams } from "react-router-dom";
import { WithContext as ReactTags } from "react-tag-input";
import ImageCropper from "../../../../../Components/ImageCropper";
import { readImageFileIntoDataUrl } from "../../../../../utils/imageUtils";
import { store } from "../../../../../index.js";
import "../style.css";
const InformationStep = ({
    productData,
    validation,
    handleValidation,
    previewImg,
    imageCropModel,
    productImage,
    handlePreviewImg,
    handleImageCropModel,
    handleProductImage,
    changePreviewImg,
    changeProductImg,
}) => {
    const [productName, setProductName] = useState(
        JSON.parse(sessionStorage.getItem("product_name")) === null
            ? { value: "" }
            : { value: JSON.parse(sessionStorage.getItem("product_name")) }
    );
    const [productSummary, setProductSummary] = useState(
        JSON.parse(sessionStorage.getItem("product_summary")) === null
            ? { value: "" }
            : { value: JSON.parse(sessionStorage.getItem("product_summary")) }
    );
    const [productDesc, setProductDesc] = useState(
        JSON.parse(sessionStorage.getItem("product_desc")) === null
            ? { value: "" }
            : { value: JSON.parse(sessionStorage.getItem("product_desc")) }
    );
    const [productTags, setProductTags] = useState(
        JSON.parse(sessionStorage.getItem("product_tags")) === null
            ? []
            : JSON.parse(sessionStorage.getItem("product_tags"))
    );
    const params = useParams();

    // fetch initial data if plan is being edited
    useEffect(() => {
        // console.log(productData, "this is product data");
        if (productData !== undefined && params.id !== "new") {
            if (productData.product_tags !== null) {
                // console.log(productData.product_tags, "is this product datas");
                // var productTags = productData.product_tags[0].split(",");
                const newProductTags = productData.product_tags.map((tag) => {
                    return { id: tag, text: tag };
                });
                setProductTags(newProductTags);
                sessionStorage.setItem("product_tags", JSON.stringify(newProductTags));
            }
            setProductName({ value: productData.plan_name });
            setProductSummary({
                value: productData.plan_summary !== null ? productData.plan_summary : "",
            });
            setProductDesc({ value: productData.plan_desc !== null ? productData.plan_desc : "" });
            sessionStorage.setItem("product_name", JSON.stringify(productData.plan_name));
            sessionStorage.setItem("product_summary", JSON.stringify(productData.plan_summary));
            sessionStorage.setItem("product_desc", JSON.stringify(productData.plan_desc));
        }
    }, [productData]);

    const tooltipChipList = [1, 2];
    const KeyCodes = {
        comma: 188,
        enter: 13,
    };
    const handleDelete = (i) => {
        const newTags = productTags.filter((tag, index) => index !== i);
        setProductTags(productTags.filter((tag, index) => index !== i));
        sessionStorage.setItem("product_tags", JSON.stringify(newTags));
    };

    const handleAddition = (tag) => {
        const newTags = [...productTags, tag];
        setProductTags([...productTags, tag]);
        sessionStorage.setItem("product_tags", JSON.stringify(newTags));
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = productTags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setProductTags(newTags);

        sessionStorage.setItem("product_tags", JSON.stringify(newTags));
    };

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
        },
    });

    const popperStyling = {
        sx: {
            "& .MuiTooltip-tooltip": {
                border: "solid 1px",
                backgroundColor: "#f5f5f5",
                borderRadius: "6px",
                borderColor: "black",
            },
        },
    };

    const deletePreviewImg = (key) => {
        // remove the image from the preview using index
        let newPreviewImg = [...previewImg];
        let newImageId = [...productImage];
        console.log(newPreviewImg, "new preview image");
        console.log(newImageId, "new image id");
        newPreviewImg.splice(key, 1);
        newImageId.splice(key, 1);
        console.log(newPreviewImg, "This is the new preview image");
        console.log(newImageId, "this is the new image id");
        changePreviewImg(newPreviewImg);
        changeProductImg(newImageId);
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    useEffect(() => {
        // check if product name is more than 50 characters, if yes, show error
        if (productName?.value?.length > 50) {
            handleValidation({
                product_name: {
                    error: "Product name should be less than 50 characters",
                    invalid: true,
                },
            });
        } else {
            handleValidation({
                product_name: {
                    error: "",
                    invalid: false,
                },
            });
        }
    }, [productName]);

    useEffect(() => {
        if ((productSummary?.value?.length + (productSummary.value.match(/\n/) !== null ? productSummary.value.match(/\n/gm).length: 0)) > 150) {
            handleValidation({
                product_summary: {
                    error: "Product summary should be less than 150 characters",
                    invalid: true,
                },
            });
        } else {
            handleValidation({
                product_summary: {
                    error: "",
                    invalid: false,
                },
            });
        }
    }, [productSummary]);

    useEffect(() => {
        if ((productDesc?.value?.length + (productDesc.value.match(/\n/) !== null ? productDesc.value.match(/\n/gm).length: 0)) > 500) {
            handleValidation({
                product_desc: {
                    error: "Product description should be less than 500 characters",
                    invalid: true,
                },
            });
        } else {
            handleValidation({
                product_desc: {
                    error: "",
                    invalid: false,
                },
            });
        }
    }, [productDesc]);

    useEffect(() => {
        if (productTags.length >= 3) {
            document.getElementById("product-tags").disabled = true;
        } else {
            document.getElementById("product-tags").disabled = false;
        }
    }, [productTags]);

    const labelHelper = (label) => {
        return (
            <Form.Label>
                <small style={{ color: "grey" }}>{label}</small>
            </Form.Label>
        );
    };

    const section1 = document.getElementById("name");
    const section2 = document.getElementById("summary");
    const section3 = document.getElementById("desc");

    useEffect(() => {
        if(validation.product_name.invalid)
            section1.scrollIntoView({behavior:'smooth', block:'center'})
        if(validation.product_summary.invalid)
            section2.scrollIntoView({behavior:'smooth', block:'center'})
        if(validation.product_desc.invalid)
            section3.scrollIntoView({behavior:'smooth', block:'center'})
    }, [validation])


    return (
        <div className="step-container slide-right">
            <h4 style={{ fontWeight: "500" }}>Product Information</h4>
            <div className="pt-2"> 
                <Form.Group className="mb-3 form-group required">
                    <Form.Label className="w-100 mb-0 control-label">
                        <p style={{ float: "left" }}>Product Name</p>
                        <p
                            style={{
                                float: "right",
                                color: validation.product_name.invalid ? "red" : "black",
                            }}
                        >
                            {50 - productName?.value?.length}
                        </p>
                    </Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            onChange={(product_name) => {
                                setProductName({
                                    ...productName,
                                    value: product_name.target.value,
                                });
                                sessionStorage.setItem(
                                    "product_name",
                                    JSON.stringify(product_name.target.value)
                                );
                            }}
                            value={productName.value}
                            isInvalid={validation.product_name.invalid}
                            required
                        />
                        <Form.Control.Feedback type="invalid" id="name">
                            {validation.product_name.error}
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Label className="w-100 mb-0 ">
                    <div>
                        <Form.Group className="form-group required">
                            <Form.Label className="w-100 mb-0 control-label">
                                <p style={{ float: "left" }}>Product Summary</p>
                                <p
                                    style={{
                                        float: "right",
                                        color: validation.product_summary.invalid ? "red" : "black",
                                    }}
                                >
                                    {150 - (productSummary?.value?.length + (productSummary?.value?.match(/\n/) !== null ? productSummary?.value?.match(/\n/gm).length: 0))}
                                </p>{" "}
                            </Form.Label>
                        </Form.Group>
                    </div>
                </Form.Label>{" "}
                {labelHelper("This appears on the product cards.")}
                <InputGroup hasValidation>
                    <Form.Control
                        as="textarea"
                        placeholder="This is an example of a product summary."
                        style={{ height: "100px" }}
                        onChange={(product_summary) => {
                            setProductSummary({
                                ...productSummary,
                                value: product_summary.target.value,
                            });
                            sessionStorage.setItem(
                                "product_summary",
                                JSON.stringify(product_summary.target.value)
                            );
                        }}
                        value={productSummary.value}
                        isInvalid={validation.product_summary.invalid}
                        required
                        // maxLength={150}
                    />
                    <Form.Control.Feedback type="invalid" id="summary">
                        {validation.product_summary.error}
                    </Form.Control.Feedback>{" "}
                </InputGroup>
                <Form.Group className="form-group required pt-3">
                    <Form.Label className="w-100 mb-0 control-label">
                        <p style={{ float: "left" }}>Product Description</p>
                        <p
                            style={{
                                float: "right",
                                color: validation.product_desc.invalid ? "red" : "black",
                            }}
                        >
                            {500 - (productDesc?.value?.length + (productDesc?.value?.match(/\n/) !== null ? productDesc?.value?.match(/\n/gm).length: 0))}
                        </p>
                    </Form.Label>
                </Form.Group>
                {labelHelper(
                    "This appears on a new page when the product card is clicked on and provides a detailed description of the product."
                )}
                <InputGroup hasValidation>
                    <Form.Control
                        as="textarea"
                        placeholder="This is an example of a product description."
                        style={{ height: "100px" }}
                        onChange={(product_desc) => {
                            setProductDesc({
                                ...productDesc,
                                value: product_desc.target.value,
                            });
                            sessionStorage.setItem(
                                "product_desc",
                                JSON.stringify(product_desc.target.value)
                            );
                        }}
                        value={productDesc.value}
                        isInvalid={validation.product_desc.invalid}
                        required
                    />
                    <Form.Control.Feedback type="invalid" id="desc">
                        {validation.product_desc.error}
                    </Form.Control.Feedback>{" "}
                </InputGroup>
                <Form.Label className="pt-3 w-100 mb-0">
                    <p style={{ float: "left" }}> Tags/Labels (Maximum of 3)</p>
                    <p
                        style={{
                            float: "right",
                            color: "black",
                        }}
                    >
                        {`${3 - productTags.length}`}
                    </p>
                    {/* (Maximum of 3) */}
                    <CustomWidthTooltip
                        arrow
                        PopperProps={popperStyling}
                        title={
                            <div className="p-3 tag-tooltip">
                                <p>
                                    Your tags/labels will appear on your product cards as shown
                                    below.
                                </p>
                                <Card style={{ maxWidth: 260 }}>
                                    <CardContent>
                                        <div className="mb-2 tag-tooltip-card">
                                            {tooltipChipList.map((chip) => {
                                                return (
                                                    <Chip
                                                        size="small"
                                                        className="ml-1 tag-tooltip-chip"
                                                        label={`Property ${chip}`}
                                                    />
                                                );
                                            })}{" "}
                                        </div>
                                        <Skeleton variant="text" width={150} />
                                        <Skeleton variant="text" width={75} />
                                        <Skeleton variant="text" width={225} />
                                        <Skeleton variant="text" width={225} />
                                        <Skeleton variant="text" width={225} />
                                    </CardContent>
                                </Card>
                            </div>
                        }
                        placement="right"
                    >
                        <InfoIcon className="ml-2" />
                    </CustomWidthTooltip>
                </Form.Label>
                {labelHelper("Keywords that describe the product.")}
                <div>
                    <ReactTags
                        tags={productTags}
                        delimiters={delimiters}
                        handleDelete={handleDelete}
                        handleAddition={handleAddition}
                        handleDrag={handleDrag}
                        inputFieldPosition="top"
                        id="product-tags"
                        autofocus={false}
                        placeholder=""
                    />
                </div>
                {labelHelper(`Type in your tags, separated by pressing "Enter".`)}
                <Form.Group className="form-group required pt-3">
                    <Form.Label className="w-100 control-label">Plan Image</Form.Label>
                </Form.Group>
                <Button
                    startIcon={<PhotoLibraryIcon />}
                    style={{
                        backgroundColor: store.getState().colorState.color,
                        borderColor: store.getState().colorState.color,
                        color: store.getState().colorState.font_color,
                        textTransform: "none",
                        border: validation.product_image.invalid && "1px solid red",
                    }}
                    variant="contained"
                    component="label"
                >
                    Upload Images
                    <input
                        accept="image/*"
                        type="file"
                        onChange={async (e) => {
                            const file = e.target.files[0];
                            let imageDataUrl = await readImageFileIntoDataUrl(file);
                            //after an image file is selected open the cropping model
                            handleImageCropModel({
                                ...imageCropModel,
                                isOpened: true,
                                selectedImageDataUrl: imageDataUrl,
                            });
                        }}
                        onClick={(event) => (event.target.value = null)}
                        hidden
                    />
                </Button>
                {validation.product_image.invalid && (
                    <p style={{ color: "red", fontSize: ".9rem", paddingTop: ".4em" }}>
                        {validation.product_image.error}
                    </p>
                )}
                <ImageCropper
                    imgCrop={imageCropModel}
                    handleImgCrop={(imgData) => {
                        handleImageCropModel(imgData);
                    }}
                    imgPreview={previewImg}
                    handleImgPreview={(img) => {
                        handlePreviewImg(img);
                    }}

               
                />
            </div>
            {previewImg.length > 0 && (
                <Row lg={3} className="img-preview-container">
                    {previewImg.map((img, key) => {
                        return (
                            <Col style={{ position: "relative" }}>
                                <Image className="img-preview-images" src={img.imagePreview} />
                                <CloseButton
                                    className="img-cancel-button"
                                    onClick={() => {
                                        deletePreviewImg(key);
                                    }}
                                />
                            </Col>
                        );
                    })}
                </Row>
            )}
        </div>
    );
};

export default InformationStep;
