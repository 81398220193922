import { Button, Grid } from "@material-ui/core";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { useEffect, useState } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import ImageCropper from "../../../../Components/ImageCropper";
import { store } from "../../../../index.js";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CurrencyInput from "react-currency-input-field";
import profileService from "../../../../services/profileService";
import { readImageFileIntoDataUrl } from "../../../../utils/imageUtils";
const MerchantSettings = ({ pcData = undefined, arData = undefined }) => {
    const history = useHistory();
    const [previewImg, setPreviewImg] = useState();
    const [merchantName, setMerchantName] = useState("");
    const [SSTNumber, setSSTNumber] = useState("");
    const [curlecMerchantId, setCurlecMerchantId] = useState();
    const [curlecEmployeeId, setCurlecEmployeeId] = useState();
    const [maxMandateCC, setMaxMandateCC] = useState(0);
    const [maxMandateFPX, setMaxMandateFPX] = useState(0);
    const [settingsId, setSettingsId] = useState();
    const [primaryColor, setPrimaryColor] = useState("#0c2651");
    const [secondaryColor, setSecondaryColor] = useState("#3395FF");
    const [merchantAddress, setMerchantAddress] = useState();
    const [imageCropModel, setImageCropModel] = useState({
        isOpened: false,
        selectedImageDataUrl: null,
        crop: { x: 0, y: 0 },
        rotation: 0,
        zoom: 0.4,
        croppedAreaPixels: null,
        croppedImage: null,
        aspect: 3,
    });

    useEffect(() => {
        console.log(pcData, "this is the pc data");
        console.log(arData, "this is the ar data");

        if (pcData !== undefined) {
            if (pcData.company_logo !== null) {
                const imagePreview = `${
                    window.location.protocol + "//" + window.location.hostname
                }${process.env.REACT_APP_SITE_PORT_BACKEND}${pcData.company_logo}`;
                setPreviewImg({ imagePreview: imagePreview });
            }
            setSettingsId(pcData.settings_id);
            if (pcData.primary_colour !== "") {
                setPrimaryColor(pcData.primary_colour);
            }
            if (pcData.secondary_colour !== "") {
                setSecondaryColor(pcData.secondary_colour);
            }
            setMaxMandateCC(pcData.max_mandate_cc);
            setMaxMandateFPX(pcData.max_mandate_fpx);
        }

        if (arData !== undefined) {
            setCurlecEmployeeId(arData.merch_curlec_employee_id);
            setCurlecMerchantId(arData.merch_curlec_merchant_id);
            setMerchantName(arData.merch_display_name);
            setMerchantAddress(arData.merch_address);
            setSSTNumber(arData.merch_sst_no);
        }
    }, [pcData, arData]);
    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
        },
    });
    const updateGeneralSettings = async () => {
        // databody put in logo file, primary color and secondary color
        // if image is old one, then upload image, else put to databody
        // if settings id never existed, create a new one
        // if settings id exists, editSettings instead
        var dataBody = {
            primary_colour: primaryColor,
            secondary_colour: secondaryColor,
        };
        console.log(previewImg, "this is the preview img");

        if (!!previewImg.imageFile) {
            dataBody = {
                ...dataBody,
                company_logo: previewImg.imageFile,
            };
        } else if (!!previewImg.imagePreview) {
            console.log(previewImg, "this is the preview img");
        }
        if (maxMandateCC !== undefined) {
            dataBody = {
                ...dataBody,
                max_mandate_cc: maxMandateCC,
            };
        }
        if (maxMandateFPX !== undefined) {
            dataBody = {
                ...dataBody,
                max_mandate_fpx: maxMandateFPX,
            };
        }
        console.log(dataBody, "this is the databody");
        if (settingsId === undefined) {
            await profileService
                .createProfile(dataBody)
                .then((res) => {
                    Swal.fire({
                        title: "Profile created",
                        icon: "success",
                        confirmButtonColor: store.getState().colorState.color,
                    })
                        .then((result) => {
                            window.location.reload();
                        })
                        .catch((e) => {
                            console.error(e);
                        });
                })
                .catch((response) => console.error(response));
        } else {
            // patch settings
            await profileService
                .updateProfile(settingsId, dataBody)
                .then((res) => {
                    Swal.fire({
                        title: "Profile updated",
                        icon: "success",
                        confirmButtonColor: store.getState().colorState.color,
                    })
                        .then((result) => {
                            window.location.reload();
                        })
                        .catch((e) => {
                            console.error(e);
                        });
                })
                .catch((e) => {
                    console.error(e, "error");
                });
        }
    };

    return (
        <>
            <Grid
                align="left"
                className="ml-lg-4 container-fluid addon-form-container "
                container
                spacing={1}
                lg={10}
            >
                <Grid item xs={12} md={12} lg={12}>
                    <h4 className="color-contrast-dark mt-2" style={{ color: "black" }}>
                        <strong>Merchant Settings</strong>
                    </h4>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control disabled value={merchantName} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Form.Label>Company Address</Form.Label>
                    <Form.Control disabled value={merchantAddress} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Form.Label>SST Number</Form.Label>
                    <Form.Control
                        value={SSTNumber}
                        onChange={(e) => {
                            setSSTNumber(e.target.value);
                        }}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Form.Label className="m-0 pt-3">
                        <strong>Max Mandate Amount</strong>
                        <CustomWidthTooltip
                            arrow
                            PopperProps={{
                                sx: {
                                    "& .MuiTooltip-tooltip": {
                                        border: "solid 1px",
                                        backgroundColor: "#f5f5f5",
                                        borderRadius: "6px",
                                        borderColor: "black",
                                        color: "black",
                                    },
                                },
                            }}
                            title={
                                <p>
                                    Max mandate amount is the maximum amount we can pull from the
                                    customer's card/bank account in any single transaction.
                                    <br />
                                    <br />
                                    This max mandate amount will only apply to new mandates created
                                    after the changes are made.
                                </p>
                            }
                            placement="right"
                        >
                            <InfoIcon className=" ml-1 mb-1" />
                        </CustomWidthTooltip>
                    </Form.Label>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <Form.Label>FPX</Form.Label>
                    <CurrencyInput
                        className="w-100"
                        style={{
                            borderRadius: "6px",
                            borderWidth: "thin",
                            padding: "0.375rem 1.75rem 0.375rem 0.75rem",
                        }}
                        onChange={(price) => {
                            const restructuredValue = price.target.value.replace(/[^0-9.]/g, "");
                            setMaxMandateCC(restructuredValue);
                        }}
                        prefix="RM"
                        disableGroupSeparators={true}
                        value={maxMandateCC}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <Form.Label>Credit Card</Form.Label>

                    <CurrencyInput
                        className="w-100"
                        style={{
                            borderRadius: "6px",
                            borderWidth: "thin",
                            padding: "0.375rem 1.75rem 0.375rem 0.75rem",
                        }}
                        onChange={(price) => {
                            const restructuredValue = price.target.value.replace(/[^0-9.]/g, "");
                            setMaxMandateFPX(restructuredValue);
                        }}
                        prefix="RM"
                        disableGroupSeparators={true}
                        value={maxMandateFPX}
                        // ref={(node) => (this.input = node)}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Form.Label>Logo Image</Form.Label>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Button
                        startIcon={<PhotoLibraryIcon />}
                        style={{
                            backgroundColor: store.getState().colorState.color,
                            borderColor: store.getState().colorState.color,
                            color: store.getState().colorState.font_color,
                        }}
                        variant="contained"
                        color="primary"
                        component="label"
                    >
                        Upload Logo File
                        <input
                            accept="image/*"
                            type="file"
                            onChange={async (e) => {
                                if (!!e.target.files && e.target.files.length > 0) {
                                    const file = e.target.files[0];

                                    let imageDataUrl = await readImageFileIntoDataUrl(file);

                                    //after an image file is selected open the cropping model
                                    setImageCropModel({
                                        ...imageCropModel,
                                        isOpened: true,
                                        selectedImageDataUrl: imageDataUrl,
                                    });
                                }
                            }}
                            onClick={(event) => (event.target.value = null)}
                            hidden
                        />
                    </Button>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Form.Label>Image Preview</Form.Label>
                </Grid>
                <Grid
                    className="d-flex settings-image-container mb-4"
                    style={{ verticalAlign: "center" }}
                    item
                    xs={12}
                    md={6}
                    lg={6}
                >
                    {previewImg !== undefined ? (
                        <Grid
                            className="d-flex justify-content-center align-items-center"
                            item
                            xs={12}
                            md={12}
                            lg={12}
                        >
                            <Image
                                className="settings-image-centered"
                                src={previewImg.imagePreview}
                            ></Image>
                        </Grid>
                    ) : (
                        <Grid item xs={12} md={12} lg={12}>
                            <PhotoLibraryIcon className="default-settings-image" />
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <ImageCropper
                imgCrop={imageCropModel}
                handleImgCrop={(imgData) => {
                    setImageCropModel(imgData);
                }}
                imgPreview={previewImg}
                handleImgPreview={(img) => {
                    setPreviewImg({
                        imageFile: img.imageFile,
                        imagePreview: img.imagePreview,
                    });
                }}
            />
            <Grid container className="pt-4">
                <Grid
                    align="left"
                    className="ml-lg-4 pt-2 container-fluid addon-form-container"
                    container
                    spacing={1}
                    lg={10}
                >
                    <Grid item xs={12} md={12} lg={12}>
                        <h4 className="color-contrast-dark mt-3 " style={{ color: "black" }}>
                            <strong>Brand Settings</strong>
                        </h4>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Form.Label>Colors</Form.Label>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Row lg={4}>
                            <Col>
                                {/* <Form.Control />
                                    <Form.Control
                                        type="color"
                                        id="exampleColorInput"
                                        defaultValue="#563d7c"
                                        title="Choose your color"
                                    /> */}
                                <Form.Label>Primary Color</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={primaryColor}
                                        onChange={(color) => {
                                            setPrimaryColor(color.target.value);
                                        }}
                                    />
                                    <Form.Control
                                        type="color"
                                        value={primaryColor}
                                        onChange={(color) => {
                                            setPrimaryColor(color.target.value);
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <Form.Label>Secondary Color</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        value={secondaryColor}
                                        onChange={(color) => {
                                            setSecondaryColor(color.target.value);
                                        }}
                                    />
                                    <Form.Control
                                        type="color"
                                        value={secondaryColor}
                                        onChange={(color) => {
                                            setSecondaryColor(color.target.value);
                                        }}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <small
                                style={{ color: "#3395FF", cursor: "pointer" }}
                                onClick={() => {
                                    setPrimaryColor("#0c2651");
                                    setSecondaryColor("#3395FF");
                                }}
                            >
                                Reset to defaults
                            </small>
                        </Row>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className="mt-3 pb-4 mb-3" xs={12} md={12} lg={12}>
                {/* create two button cancel and save */}
                <Grid item xs={12} md={12} lg={12} className="pb-3 ">
                    <Button
                        onClick={() => history.goBack()}
                        className="mr-3 "
                        style={{
                            backgroundColor: "white",
                            color: "black",
                        }}
                        variant="contained"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: store.getState().colorState.color,
                            borderColor: store.getState().colorState.color,
                            color: store.getState().colorState.font_color,
                        }}
                        onClick={() => {
                            // uploadImage();
                            updateGeneralSettings();
                        }}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
export default MerchantSettings;
