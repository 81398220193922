import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";

import "./index.css";

const KebabMenu = ({ options }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (optionCallback) => {
        setAnchorEl(null);
        if (!!optionCallback) optionCallback();
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };

    return (
        <div className="d-inline-block">
            <IconButton
                className="p-0"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                onClose={closeMenu}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                    },
                }}
            >
                {options.map((option, i) => (
                    <MenuItem key={i} onClick={handleClose.bind(this, option.optionCallback)}>
                        {option.icon}
                        {option.name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default KebabMenu;
