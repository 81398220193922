import { Grid } from "@material-ui/core";
import { React, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import LoginBar from "../../../Components/LoginBar/loginbar";
import Customization from "./Customization";
import MerchantSettings from "./MerchantSettings";
import profileService from "../../../services/profileService";
import merchantService from "../../../services/merchantService";
import "./style.css";
const Profile = ({ history }) => {
    const [tabClicked, setTabClicked] = useState("merchant");
    const [arData, setArData] = useState();
    const [pcData, setPcData] = useState();
    useEffect(() => {
        getMerchantData();
        getMerchantARData();
    }, []);

    const getMerchantData = async () => {
        await profileService
            .getProfile()
            .then((res) => {
                setPcData(res.settings);
            })
            .catch((e) => {});
    };

    const getMerchantARData = async () => {
        await merchantService
            .getMerchantInfo()
            .then((res) => {
                setArData(res[0]);
            })
            .catch((e) => {});
    };
    return (
        <Grid container>
            <Grid className="mt-4 mb-2" align="left" item xs={6} md={6} lg={6}>
                <h1 className="ml-lg-4 text-left color-contrast-dark" style={{ color: "black" }}>
                    General
                </h1>
            </Grid>

            <MerchantSettings pcData={pcData} arData={arData} />
        </Grid>
    );
};

export default Profile;
