import { Box } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterListIcon from "@mui/icons-material/FilterList";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Container, Form, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { store } from "../../index.js";
import "./style.css";
import { format } from "date-fns";
import moment from 'moment/min/moment-with-locales';


const DataTable = ({
    Header,
    Columns,
    ColumnField,
    Rows,
    CreateExist,
    ExportExist,
    RefreshData,
    handleRefreshData,
    FilterExist,
    FilterList,
    DisplayExist,
    handleHeaderDisplay,
    handleCheckField,
}) => {
    const history = useHistory();
    const [displayField, setDisplayField] = useState([]);
    const [checkField, setCheckField] = useState([]);
    const [fileType, setFileType] = useState("xlsx");
    const [exportField, setExportField] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [exportRow, setExportRow] = useState([]);
    const [overlayControl, setOverlayControl] = useState({
        export: false,
        filter: false,
        display: false,
    });
    useLayoutEffect(() => {
        var exportFieldsArr = [];
        Columns.map((field) => {
            if (field.field !== "actions")
                exportFieldsArr.push({
                    field: field.field,
                    header_name: field.headerName,
                    checked: true,
                });
        });

        setExportField(exportFieldsArr);
    }, []);

    useEffect(() => {
        // update exportdata row based on checked field
        var allData = [];
        var checkedHeader = exportField.filter((field) => field.checked);
        allData.push(checkedHeader.map((header) => header.header_name));
        var allExportData = exportRow.length < 1 ? Rows : exportRow;

        allExportData.forEach((data) => {
            var filteredData = [];
            checkedHeader.map((header) => {
                if (header.field === "categories_item") {
                    if (
                        data[header.field]?.categories !== null &&
                        data[header.field]?.categories !== undefined
                    ) {
                        data[header.field].categories.map((category) => {
                            category.items.map((item) => {
                                if (item.checked) {
                                    var itemData = `${item.addon_name}(${item.quantity})`;
                                    filteredData.push(itemData);
                                }
                            });
                        });
                    }
                    return;
                }
                filteredData.push(data[header.field]);
            });
            allData.push(filteredData);
        });
        setFilteredData(allData);
    }, [exportField, Rows, exportRow]);

    const onRowSelection = (id) => {
        const selectedRowsData = id.map((id) => Rows.find((row) => row.id === id));
        setExportRow(selectedRowsData);
    };

    const handleExport = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.aoa_to_sheet(filteredData);
        XLSX.utils.book_append_sheet(wb, ws, `${Header}-list`);
        if (fileType === "xlsx") {
            // xlsx type
            XLSX.writeFile(wb, `${Header}_List.xlsx`);
        }
        if (fileType === "csv") {
            // csv type
            XLSX.writeFile(wb, `${Header}_List.csv`, { bookType: "csv" });
        }
    };

    useLayoutEffect(() => {
        var displayField = [];
        ColumnField.map((field) => {
            displayField.push({
                field: field.field,
                header_name: field.headerName,
                checked: field.checked,
                disabled: field.disabled,
            });
        });
        handleHeaderDisplay(displayField);
        setDisplayField(displayField);
        setCheckField(displayField);
    }, []);
    useEffect(() => {
        restructureTableData();
    }, [Rows]);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (JSON.parse(sessionStorage.getItem("new_row")) !== null) {
                toast.success(
                    <div className="d-flex">
                        <CheckCircleIcon className="mr-2" />
                        <p>{Header} created successfully</p>
                    </div>,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
            if (JSON.parse(sessionStorage.getItem("deleted_row")) !== null) {
                toast.success(
                    <div className="d-flex">
                        <CheckCircleIcon className="mr-2" />
                        <p>{Header} deleted successfully</p>
                    </div>,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
            if (JSON.parse(sessionStorage.getItem("pause_row")) !== null) {
                toast.success(
                    <div className="d-flex">
                        <CheckCircleIcon className="mr-2" />
                        <p>{Header} paused successfully</p>
                    </div>,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }

            if (JSON.parse(sessionStorage.getItem("cancel_row")) !== null) {
                toast.success(
                    <div className="d-flex">
                        <CheckCircleIcon className="mr-2" />
                        <p>{Header} cancelled successfully</p>
                    </div>,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
            if (JSON.parse(sessionStorage.getItem("reactivate_row")) !== null) {
                toast.success(
                    <div className="d-flex">
                        <CheckCircleIcon className="mr-2" />
                        <p>{Header} reactivated successfully</p>
                    </div>,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
            if (JSON.parse(sessionStorage.getItem("delete_row")) !== null) {
                toast.success(
                    <div className="d-flex">
                        <CheckCircleIcon className="mr-2" />
                        <p>{Header} deleted successfully</p>
                    </div>,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
            if (JSON.parse(sessionStorage.getItem("edit_row")) !== null) {
                toast.success(
                    <div className="d-flex">
                        <CheckCircleIcon className="mr-2" />
                        <p>{Header} edited successfully</p>
                    </div>,
                    {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
            handleRefreshData();
            sessionStorage.clear();
        }, 50);
        return () => {
            clearTimeout(timer);
        };
    }, [RefreshData]);

    const restructureTableData = () => {
        // have a switch case that determines which header's data should be modified
        Rows.map((row) => {
            switch (Header) {
                // uppercase first letter of data
                case "Product":
                    row.plan_status =
                        row?.plan_status.charAt(0).toUpperCase() + row?.plan_status.slice(1);
                    return row;
                case "Purchase":
                case "Order":
                    row.subs_status =
                        row?.subs_status.charAt(0).toUpperCase() + row?.subs_status.slice(1);
		    if (row.delivery_date !== null){
		    	row.delivery_date = moment(row.delivery_date).format('L');
		    }
                    row.subs_price = `RM${parseInt(row.subs_price).toFixed(2)}`;
                    row.subs_type = row.subs_type.charAt(0).toUpperCase() + row.subs_type.slice(1);
                    return row;
		case "Invoices":
		    const invoiceResolved = (
                        invoice_resolved,
                        invoice_collection_status,
                        dueDateStr
                    ) => {
                        var today = new Date();
                        var todayDate = new Date(
                            today.getFullYear() +
                                "-" +
                                (today.getMonth() + 1) +
                                "-" +
                                today.getDate()
                        );
                        var dueDate = new Date(dueDateStr.slice(0, 10));

                        if (
                            (invoice_resolved == "true" || invoice_resolved == true) &&
                            parseInt(invoice_collection_status) == 0
                        ) {
                            return "Paid";
                        } else if (
                            (invoice_collection_status != null &&
                                parseInt(invoice_collection_status) != 0) ||
                            todayDate > dueDate
                        ) {
                            return "Overdue";
                        } else {
                            return "Awaiting Payment";
                        }
                    };
                    row.inv_collection_status = invoiceResolved(
                        row.inv_resolved,
                        row.inv_collection_status,
                        row.inv_due_date
                    );
		    row.inv_due_date = new Date(row.inv_due_date).toLocaleDateString();
		    row.inv_issue_date = new Date(row.inv_issue_date).toLocaleDateString();
		    row.inv_total_amt = `RM${parseInt(row.inv_total_amt).toFixed(2)}`;

		    return row;
		default:
                    return row;
            }
        });
    };
    const filterPopover = (
        <Popover
            style={{
		maxWidth: Header !== "Invoices" && "min-content"	
	    }}
	    id="popover-basic"
        >
            <Popover.Header>Filter</Popover.Header>
            <Popover.Body>
                <div className="d-flex flex-wrap">
                    {FilterList.map((filter) => {
                        return filter.component;
                    })}
                </div>
            </Popover.Body>
        </Popover>
    );
    const exportPopover = (
        <Popover style={{ zIndex: "9999" }} id="popover-basic">
            <Popover.Header>Export</Popover.Header>
            <Popover.Body>
                <h6>Fields to export</h6>
                <div className="d-flex flex-wrap">
                    {exportField.map((field, field_key) => {
                        return (
                            <Col xs={12} md={6} lg={6}>
                                <Form.Check
                                    className="pr-3 pb-2 pt-2"
                                    checked={field.checked}
                                    onChange={() => {
                                        var allExportField = [...exportField];
                                        allExportField[field_key].checked = !field.checked;
                                        setExportField(allExportField);
                                    }}
                                    type="checkbox"
                                    label={<p>{field.header_name}</p>}
                                />
                            </Col>
                        );
                    })}
                </div>
                <h6 className="pt-3">File type</h6>
                <div className="d-flex flex-wrap">
                    <Form.Check
                        value={"xlsx"}
                        onChange={() => setFileType("xlsx")}
                        checked={fileType === "xlsx"}
                        className="pr-3 pb-2 pt-2"
                        type="radio"
                        label=".xlsx"
                    />
                    <Form.Check
                        value={"csv"}
                        onChange={() => setFileType("csv")}
                        checked={fileType === "csv"}
                        className="pr-3 pb-2 pt-2"
                        type="radio"
                        label=".csv"
                    />
                </div>
                <div className="pt-3 d-flex justify-content-end">
                    {/* <Button className="cancel-export" onClick={handleClose}>
                    Cancel
                </Button> */}
                    <Button
                        style={{
                            backgroundColor: store.getState().colorState.color,
                            borderColor: store.getState().colorState.color,
                            color: store.getState().colorState.font_color,
                        }}
                        onClick={handleExport}
                    >
                        Export
                    </Button>
                </div>{" "}
            </Popover.Body>
        </Popover>
    );
    const displayPopover = (
        <Popover id="popover-basic">
            <Popover.Header>
                <h6>Displayed Column</h6>
            </Popover.Header>

            <div className="d-flex flex-column">
                {ColumnField.map((field, field_key) => {
                    return (
                        <Form.Check
                            checked={field.checked}
                            className="ml-3 pr-3 pb-2 pt-2"
                            onChange={() => {
                                var allDisplayField = [...displayField];
                                allDisplayField[field_key].checked =
                                    !allDisplayField[field_key].checked;

                                var changeKeyName = allDisplayField;
                                changeKeyName.map((field) => {
                                    field.headerName = field.header_name;
                                });
                                setDisplayField(allDisplayField);
                                handleCheckField(changeKeyName);
                                handleHeaderDisplay(allDisplayField);
                            }}
                            type="checkbox"
                            disabled={field.disabled}
                            label={<p>{field.headerName}</p>}
                        />
                    );
                })}
            </div>
        </Popover>
    );
    return (
        <Container className="text-left pl-sm-2 pl-lg-4 pr-sm-2  pr-lg-4">
            <Row className="pt-lg-3 pt-sm-2">
                <h1 style={{ fontWeight: "bolder" }}>
                    <strong>{Header}</strong>
                </h1>{" "}
            </Row>
            {/* row space between */}
            {/* display none if button is false */}

            <Row>
                <Col className="d-flex">
                    {CreateExist && (
                        <Col lg={4} className="w-auto">
                            <Button
                                onClick={() => {
                                    history.push(`/admin/${Header.toLowerCase()}/new`);
                                }}
                                className="d-flex pt-2 pb-2 ml-0"
                                style={{
                                    backgroundColor: store.getState().colorState.color,
                                    borderColor: store.getState().colorState.color,
                                    color: store.getState().colorState.font_color,
                                    textTransform: "none",
                                }}
                            >
                                <AddIcon />
                                <p className="hide-button-text-mobile">Add {Header}</p>
                            </Button>
                        </Col>
                    )}
                    {ExportExist && (
                        <Col>
                            <OverlayTrigger
                                trigger="click"
                                placement="bottom"
                                overlay={exportPopover}
                                show={overlayControl.export}
                                onToggle={(e) => {
                                    setOverlayControl({
                                        export: !overlayControl.export,
                                        filter: false,
                                        display: false,
                                    });
                                }}
                            >
                                <Button
                                    // onClick={() => setOpenExportModal(true)}
                                    className="d-flex pt-2 pb-2"
                                    style={{
                                        backgroundColor: store.getState().colorState.color,
                                        borderColor: store.getState().colorState.color,
                                        color: store.getState().colorState.font_color,
                                        textTransform: "none",
                                    }}
                                >
                                    <FileDownloadIcon />
                                    <p className="hide-button-text-mobile">Export</p>
                                </Button>
                            </OverlayTrigger>
                            {/* <ExportModal
                                show={openExportModal}
                                handleClose={() => {
                                    setOpenExportModal(false);
                                }}
                                header={Header}
                                exportFields={Columns}
                                exportData={Rows}
                            /> */}
                        </Col>
                    )}
                </Col>
                <Col className="d-flex justify-content-end  mr-0">
                    {FilterExist && (
                        <Col style={{ "max-width": "fit-content" }}>
                            <OverlayTrigger
                                trigger="click"
                                placement="bottom"
                                overlay={filterPopover}
                                show={overlayControl.filter}
                                onToggle={(e) => {
                                    setOverlayControl({
                                        export: false,
                                        filter: !overlayControl.filter,
                                        display: false,
                                    });
                                }}
                            >
                                <Button
                                    // onClick={() => setOpenFilterModal(true)}
                                    className="d-flex pt-2 pb-2 w-auto"
                                    style={{
                                        backgroundColor: store.getState().colorState.color,
                                        borderColor: store.getState().colorState.color,
                                        color: store.getState().colorState.font_color,
                                        textTransform: "none",
                                    }}
                                >
                                    <FilterListIcon />
                                    <p className="hide-button-text-mobile">Filter</p>
                                </Button>
                            </OverlayTrigger>
                            {/* <FilterModal
                                show={openFilterModal}
                                handleClose={() => {
                                    setOpenFilterModal(false);
                                }}
                                filterList={FilterList}
                            /> */}
                        </Col>
                    )}
                    {DisplayExist && (
                        <Col
                            style={{ "max-width": "fit-content" }}
                            className="d-flex justify-content-end"
                        >
                            <OverlayTrigger
                                trigger="click"
                                placement="bottom"
                                overlay={displayPopover}
                                show={overlayControl.display}
                                onToggle={(e) => {
                                    setOverlayControl({
                                        export: false,
                                        filter: false,
                                        display: !overlayControl.display,
                                    });
                                }}
                            >
                                <Button
                                    // onClick={() => setOpenDisplayModal(true)}
                                    className="d-flex pt-2 pb-2 w-auto "
                                    style={{
                                        backgroundColor: store.getState().colorState.color,
                                        borderColor: store.getState().colorState.color,
                                        color: store.getState().colorState.font_color,
                                        textTransform: "none",
                                    }}
                                >
                                    <ViewColumnIcon />
                                    <p className="hide-button-text-mobile">Display</p>
                                </Button>
                            </OverlayTrigger>
                            {/* <DisplayModal
                                show={openDisplayModal}
                                handleClose={() => setOpenDisplayModal(false)}
                                displayList={Columns}
                                handleHeaderDisplay={handleHeaderDisplay}
                            /> */}
                        </Col>
                    )}
                    {/* {SearchBarExist && (
                        <Col
                            style={{ "max-width": "fit-content" }}
                            className="align-self-center w-auto"
                        >
                            <Form.Control className="w-100" />
                        </Col>
                    )} */}
                </Col>
            </Row>
            <Box className="pt-3 pb-5" style={{ height: "fit-content" }}>
                <DataGrid
                    sx={{
                        ".MuiDataGrid-columnSeparator": {
                            display: "none",
                        },
                        "& .super-app-theme--header": {
                            backgroundColor: store.getState().colorState.color,
                            borderColor: store.getState().colorState.color,
                            color: store.getState().colorState.font_color,
                        },
                        "& .MuiDataGrid-columnHeaderCheckbox": {
                            backgroundColor: store.getState().colorState.color,
                            borderColor: store.getState().colorState.color,
                            color: store.getState().colorState.font_color,
                            height: "100%",
                        },
                        "& .MuiDataGrid-columnHeadersInner ": {
                            backgroundColor: store.getState().colorState.color,
                            borderColor: store.getState().colorState.color,
                            color: store.getState().colorState.font_color,
                        },
                        "& .MuiDataGrid-columnHeader ": {
                            // width: "auto !important",
                            // maxWidth: "none !important",
                            // minWidth: "none !important",
                        },
                        "& .MuiDataGrid-cell": {
                            justifyContent: "center",
                            // maxWidth: "none !important",
                            // minWidth: "none !important",
                        },
                        "& .css-17jjc08-MuiDataGrid-footerContainer": {
                            backgroundColor: store.getState().colorState.color,
                            borderColor: store.getState().colorState.color,
                            color: store.getState().colorState.font_color,
                        },
                        "& .MuiTablePagination-toolbar": {
                            backgroundColor: store.getState().colorState.color,
                            borderColor: store.getState().colorState.color,
                            color: store.getState().colorState.font_color,
                        },
                        "& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root": {
                            color: store.getState().colorState.font_color,
                        },
                        // "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root": {
                        //     color: store.getState().colorState.font_color,
                        // },
                    }}
                    getRowClassName={(params) => {
                        if (params.row.id === JSON.parse(sessionStorage.getItem("new_row"))) {
                            return "new-create";
                        }
                        if (params.row.id === JSON.parse(sessionStorage.getItem("edit_row"))) {
                            return "new-create";
                        }
                    }}
                    autoHeight
                    rows={Rows}
                    columns={Columns}
                    pageSize={10}
                    onSelectionModelChange={(id) => onRowSelection(id)}
                    checkboxSelection
                    disableColumnSelector
                    disableColumnMenu
                    disableColumnFilter
                />
                {/* <small>Expand -></small> */}
            </Box>
        </Container>
    );
};

export default DataTable;
